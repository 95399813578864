<h2>Modification Produit</h2>
<div class="w-100">
  <!-- ************************* Modifiacation Produit***************************************************** -->
  <div class="row">
    <div class="col-sm ml-3 block-modive-prodruit">
      <form class="checkbox-filter p-2">
        <div class="">
          <label for="name">Nom</label>
          <input [(ngModel)]="product.name" class="form-control" id="name" name="name" [formControl]="nameFormControl">
          </div>
        <div class="">
          <label for="description">Description</label>
          <textarea [(ngModel)]="product.description" class="form-control" id="description" name="description"></textarea>
          </div>
        <div class="">
          <label for="type">Type</label>
          <select [(ngModel)]="product.type" [formControl]="typeFormControl" class="form-control" id="type" name="type">
            <option *ngFor="let type of typeArray" [value]="type">{{type}}</option>
          </select>
          </div>
        <div class="">
          <label for="version">Version</label>
          <div class="d-flex">
            <button class="font-weight-bold version-button" [disabled]="product.description <= 0"
              (click)="incriseDecriseVersion('-')">-
            </button>
            <input [(ngModel)]="product.version" class="form-control ml-2 mr-2" id="version" name="version">
            <button class="font-weight-bold version-button" (click)="incriseDecriseVersion('+')">+</button>
            </div>
            </div>
<div class="form-group mt-4 text-align center">
  <mat-checkbox (change)="onChangeCheckbox('actif')" class="ml-2" [checked]="product.actif"></mat-checkbox>
  <label class="ml-2"> Actif </label>
</div>

<div class="form-group mt-4">
  <label for="role">Role</label>
  <mat-select [ngModel]="selectedRole" multiple [(value)]="selectedRole" class="form-control" id="role" name="role">
    <mat-option *ngFor="let role of roleArray" [value]="role.item_id">{{role.item_text}}</mat-option>
  </mat-select>
</div>

        <div class="btn-group mt-4 mb-2">
          <div>
            <button (click)="selectChangeHandlerSoumettre()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
          </div>
        
          <div>
            <button (click)="selectChangeHandlerAnnuler()" class="btn btn100px border-dark btn-light p-2">Annuler</button>
          </div>
        </div>
        </form>
        </div>
    <!-- ************************* Nom de Parametre***************************************************** -->
    <div class="col-sm ml-3 pt-3">
      <div class="form-group ml-2">
        <!-- <div class=""> -->
        <button (click)="SelectChangeHandlerAjouterParametre()" class="btn btn-warning" data-toggle="modal"
          data-target="#popup" aria-pressed="false" autocomplete="off">
          <mat-icon>add_circle</mat-icon> Parametre
        </button>
        <!-- </div> -->
        </div>
        
        <div class="form-group">
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-bordered table-striped mb-0" style="border: solid silver;">
              <thead>
                <tr>
                  <th>Nom de Parametre</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
        
              <tbody>
                <ng-container *ngIf="variablePar?.length === 0">
                
                  <h6>Aucun parametre n'est trouvé, veuillez en rajouter</h6>
                
                </ng-container>
                <ng-container *ngIf="variablePar?.length !== 0">
                  <tr *ngFor="let parametre of variablePar; let i = index" style="text-align: center" [id]="i">
                    <td>{{parametre.nom_parametre}}</td>
                    <td>
                      <!--<button (click)="deleteParametre(parametre.parametre_id)" mat-mini-fab color="primary">-->
                      <button (click)="dialogParametre(parametre.parametre_id, parametre.nom_parametre)"
                        class="btn btn-dark btn-round-40 m-auto">
                        <mat-icon>delete</mat-icon>
                      </button>
                      </td>
                      </tr>
                      </ng-container>
                      </tbody>
                      </table>
                      </div>
                      </div>
                      </div>
    <!-- *********************************************************Concession***************************************************************************** -->
    <div class="col-sm ml-3 pt-3">
      <div class="form-group ml-2">
        <!-- <div class=""> -->
        <button (click)="SelectChangeHandlerAjouterConcession()" class="btn btn-warning" data-toggle="modal"
          data-target="#popup1" aria-pressed="false" autocomplete="off">
          <mat-icon>add_circle</mat-icon> Concession
        </button>
        <!-- </div> -->
        </div>
        
        <div class="form-group">
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-bordered table-striped mb-0" style="border: solid silver;">
              <thead>
                <tr>
                  <th>Nom de Concession</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
        
              <tbody>
                <ng-container *ngIf="variableCon?.length === 0">
                
                  <h6>Aucune concession n'est trouvée, veuillez en rajouter</h6>
                
                </ng-container>
                <ng-container *ngIf="variableCon?.length !== 0">
                  <tr *ngFor="let concession of variableCon; let i = index" style="text-align: center" [id]="i">
                    <td>{{concession.nom_concession}}</td>
                    <td>
                      <!--<button (click)="deleteConcession(concession.concession_id)" mat-mini-fab color="primary"> -->
                      <button (click)="dialogConcession(concession.concession_id, concession.nom_concession)"
                        class="btn btn-dark btn-round-40 m-auto">
                        <mat-icon>delete</mat-icon>
                      </button>
                      </td>
                      </tr>
                      </ng-container>
                      </tbody>
                      </table>
                      </div>
        </div>
        </div>
  </div>
  </div>
  <!-- ****************************************************************Liste Des Parametres****************************** -->
<div>
  <div class="modal fade" id="popup" tabindex="-1" role="dialog" aria-labelledby="popupTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="title">Liste Des Parametres</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-bordered table-striped mb-0">
              <thead>
              <tr>
                <th>No.</th>
                <th>Nom de Parametre</th>
                <th>Selectionner</th>
              </tr>
              </thead>

              <tbody>
                <tr *ngFor = "let parametres of variableParametre; let i = index" style="text-align: center" [id]="i">
                  <th>{{i+1}}</th>
                  <td>{{parametres.nom_parametre}}</td>
                  <td>
                    <input type="checkbox" [value]="parametres.parametre_id" (change)="checkClickParametre($event)"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="modal-footer">
          <button (click)="rechargerPageParametre()" type="button" class="btn btn-warning" data-dismiss="modal">Ajouter</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ************************************************************Liste Des Concessions******************************************** -->

<div>
  <div class="modal fade" id="popup1" tabindex="-1" role="dialog" aria-labelledby="popupTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="title1">Liste Des Concessions</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-bordered table-striped mb-0">
              <thead>
              <tr>
                <th>No.</th>
                <th>Nom de Concession</th>
                <th>Selectionner</th>
              </tr>
              </thead>

              <tbody>
              <tr *ngFor = "let concession of variableConcession; let i = index" style="text-align: center" [id]="i">
                <th>{{i+1}}</th>
                <td>{{concession.nom_concession}}</td>
                <td>
                  <input type="checkbox" [value]="concession.concession_id" (change)="checkClickConcession($event)"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="modal-footer">
          <button (click)="rechargerPageConcession()" type="button" class="btn btn-warning" data-dismiss="modal">Ajouter</button>
        </div>
      </div>
    </div>
  </div>
</div>
