<h2>Création campagne SMS</h2>
<div class="wrapper">
  <form>

    <div class="form-group mt-3" style="width: 445px">
      <label for="name">Nom :</label>
      <input [(ngModel)]="campaignDiscounts.name" class="form-control" id="name" name="name">
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3" >
        <label for="brand">Marque</label>
<!--        [(value)]="campaignDiscounts.brand_id"-->
        <select class="form-control" name="brand" id="brand">
          <option *ngFor="let brand of brandArray" [value]="brand.id">
            {{brand.name}}
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="limit">Limite :</label>
        <input [(ngModel)]="campaignDiscounts.limite" class="form-control" id="limit" name="limit">
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="count_actual_app">Compte sur l'App :</label>
        <input [(ngModel)]="campaignDiscounts.count_actual_app" class="form-control" id="count_actual_app" name="count_actual_app">
      </div>

      <div class="form-group col-md-3">
        <label for="count_actual_site">Compte sur le site :</label>
        <input [(ngModel)]="campaignDiscounts.count_actual_site" class="form-control" id="count_actual_site" name="count_actual_site" >
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="start_date">Date de début :</label>
        <input [(ngModel)]="campaignDiscounts.start_date" class="form-control" id="start_date" name="start_date" type="date">
      </div>

      <div class="form-group col-md-3">
        <label for="end_date">Date de fin :</label>
        <input [(ngModel)]="campaignDiscounts.end_date" class="form-control" id="end_date" name="end_date" type="date">
      </div>
    </div>

    <div class="form-group mt-3" style="width: 445px">
      <label for="message">Message :</label>
      <textarea [(ngModel)]="campaignDiscounts.message" class="form-control" id="message" name="message" placeholder="Message"></textarea>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="requirement">requirement :</label>
        <input [(ngModel)]="campaignDiscounts.requirement" class="form-control" id="requirement" name="requirement">
      </div>

      <div class="form-group col-md-3">
        <label for="requirement_operator">requirement_operator :</label>
        <input [(ngModel)]="campaignDiscounts.requirement_operator" class="form-control" id="requirement_operator" name="requirement_operator">
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Mode debug :</label>
        <mat-checkbox (change)="onChangeCheckbox('debug')" class="ml-2"></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Actif :</label>
        <mat-checkbox (change)="onChangeCheckbox('active')" class="ml-2" checked></mat-checkbox>
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createCampaignDiscounts()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
