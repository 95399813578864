<h2>Création d'une mobilité pour {{concessionName}}</h2>
<div class="wrapper">
  <form>

    <div class="form-group mt-3" style="width: 445px">
      <label for="softNet_id">ID SOFT Net :</label>
      <input [(ngModel)]="mobilite.softNet_id" class="form-control" id="softNet_id" name="softNet_id">
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="nom">Nom :</label>
        <input [(ngModel)]="mobilite.nom" class="form-control" id="nom" name="nom">
      </div>

      <div class="form-group col-md-3">
        <label for="prix">Prix :</label>
        <input [(ngModel)]="mobilite.prix" class="form-control" id="prix" name="prix" >
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="icon">Icône :</label>
        <select [(ngModel)]="mobilite.icon" class="form-control" name="icon" id="icon">
          <option *ngFor="let icon of iconArray" [value]="icon">
            {{icon}} <i class="fa-solid {{icon}}"></i>
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="ordre">Ordre :</label>
        <input [(ngModel)]="mobilite.ordre" class="form-control" id="ordre" name="ordre">
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="description">Description :</label>
        <input [(ngModel)]="mobilite.description" class="form-control" id="description" name="description">
      </div>

      <div class="form-group col-md-3">
        <label for="brand_authorized">Marque Autorisée :</label>
        <input [(ngModel)]="mobilite.brand_authorized" class="form-control" id="brand_authorized" name="brand_authorized">
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Actif :</label>
        <mat-checkbox (change)="onChangeCheckbox('actif')" class="ml-2"></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Flotte :</label>
        <mat-checkbox (change)="onChangeCheckbox('is_external_fleet')" class="ml-2" checked></mat-checkbox>
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>En attente :</label>
        <mat-checkbox (change)="onChangeCheckbox('is_waiting')" class="ml-2"></mat-checkbox>
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createMobilite()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
