import { Component, OnInit } from '@angular/core';
import {FormBuilder, NgForm} from '@angular/forms';
import {AuthenticationService} from '../../utils/authentication.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {AppComponent} from '../../app.component';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  isSubmited = false;
  error = {err: false, code: 200};

  constructor(
    private appComponent: AppComponent,
    private authenticationService: AuthenticationService,
    private route: Router,
    private formBuilder: FormBuilder

  ) {
    this.loginForm = this.formBuilder.group({
      email: '',
      password: '',
      u2fa: null
    });
  }

  found: boolean = false;

  ngOnInit() {
  }

  onSubmit(post) {
    const is2faValid = this.loginForm.value.email.toLowerCase() == this.authenticationService.getCookie('sg2FaValidity') ? 'valid' : null;
    post.u2fa = is2faValid;
    this.isSubmited = true;
    this.authenticationService.Login(post).subscribe(data => {
        this.authenticationService.loginSuccess(data);
        // const jwtPayload = jwt_decode(data.token);
        // if (jwtPayload.roles.includes('ADMIN')) {
        //   sessionStorage.setItem('isConnected', 'true');
        //   sessionStorage.setItem('User', jwtPayload.firstname + ' ' + jwtPayload.lastname);
        //   sessionStorage.setItem('token', data.token);
        //   this.appComponent.isConnected = true;
        //   this.route.navigate(['../'])
        //     .then(() => {
        //       window.location.reload();
        //     });
        // } else {
        //   this.error = {err: true, code: 404};
        //   this.isSubmited = false;
        //   swal.fire({
        //     title: 'Erreur',
        //     text: 'Non autorisé',
        //     type: 'error',
        //     confirmButtonText: 'Ok'
        //   });
        // }
      },
      err => {
        this.error = {err: true, code: err.error.code};
        this.isSubmited = false;
        swal.fire({
          title: 'Erreur',
          text: 'Email ou mot de passe invalide',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
  }

  Annuler() {
    this.route.navigate(['../']);
  }
}
