<h1 class="text-center mt-2">Gestion des produits</h1>



<div class="user-table">
  <div class="mt-4 d-flex justify-content-between align-items-center">
    <div class="container-row btn float-right">
      <div class="btn1">
        <button (click)="SelectChangeHandlerCreer()" id="btn-add-product" class="btn btn-dark mr-4">
          <mat-icon>add_circle</mat-icon> Produit
        </button>
      </div>
    </div>
    <form class="d-flex flex-column justify-content-end mr-5">
      <mat-form-field style="width: 250px; margin-left: auto;">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter()"  [(ngModel)]="maintainDataSearch" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <div class="d-flex checkbox-row align-items-center">
        <div>
          Afficher les produits :
        </div>
        <input class="align-items-center btn-create p-2 ml-4" type="checkbox" [checked]="showTechnical" [disabled]="!showCommercial" (click)="filteredProductsByType('technical')" /> <span class="m-2">Techniques</span>
        <input class="align-items-center btn-create p-2 ml-4" type="checkbox" [checked]="showCommercial" [disabled]="!showTechnical" (click)="filteredProductsByType('commercial')" /> <span class="m-2">Commercials</span>
      </div>
    </form>
  </div>
</div>

<div class="con3">
    <div>
      <table class="table table-striped table-bordered user-table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Nom de produit</th>
          <th>Description</th>
          <th>Type</th>
          <th>Actif</th>
          <th>Version</th>
          <th>Modifier</th>
        </tr>
        </thead>

        <tbody>

          <tr *ngFor = "let product of dataSource.filteredData" style="text-align: center">
            <th>{{product.produit_id}}</th>
            <td>{{product.nom_produit}}</td>
            <td>{{product.description}}</td>
            <td>{{product.type}}</td>
            <td><mat-icon *ngIf="product.actif">check</mat-icon><mat-icon *ngIf="!product.actif">close</mat-icon></td>
            <td>{{product.version}}</td>
            <td>
              <button (click)="SelectChangeHandlerModifier(product)" class="btn btn-dark btn-round-40 m-auto">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>

