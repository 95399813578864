import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataFormationService extends HandleErrorService {
  private errorMessage: string;
  apiFormation = environment.globalApiUrl + 'api/resources/records/formation';
  // crudFormation = environment.globalApiUrl + 'api/formation';

  getFormation() {
    return this.http.get<any>(environment.globalApiUrl + 'api/formations/all', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getFormationById(id) {
    return this.http.get<any>(this.apiFormation + '/' + id , environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  createFormation(post) {
    const jsonPost = JSON.stringify(post);
    return this.http.post<any>(this.apiFormation, jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  updateFormation(post, id) {
    const jsonPost = JSON.stringify(post);
    return this.http.put<any>(this.apiFormation + '/' + id , jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  deleteFormation(id) {
    return this.http.delete<any>(this.apiFormation + '/' + id, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getProducts() {
    return this.http.get<any>(environment.globalApiUrl + 'api/resources/records/produit', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
