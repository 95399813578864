<h1 class="text-center mt-2" id="fonction-manager-top-page">Gestion des fonctions</h1>

<div class="form-add-fonction mt-5">
  <h3 id="fonction-create-update-title">Ajouter une fonction : </h3>
  <form [formGroup]="fonctionForm" (ngSubmit)="isFonctionDuplicate(fonctionName)"
        class="d-flex fonction-form mr-5">
    <mat-form-field style="width: 400px" class="mr-5 mt-4">
      <mat-label>Nom de la fonction</mat-label>
      <input matInput [formControl]="fonctionName">
    </mat-form-field>
    <div class="row">
      <button type="submit" class="btn btn-dark d-flex align-items-center btn-create p-2 ml-4'" id="fonction-create-update-btn">
        <mat-icon class="mr-2">add_circle</mat-icon>
        Ajouter la fonction
      </button>
      <button (click)="onCancel()" *ngIf="isEditing" class="ml-2 btn btn-outline-dark d-flex align-items-center btn-create p-2 ml-4'" id="fonction-cancel-btn">
        Annuler
      </button>
    </div>
  </form>
</div>

<div class="user-table">
  <div class="mt-5 col-12 text-right d-flex justify-content-end align-items-center">
    <mat-form-field style="width: 250px">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>
<table class="mt-4 table table-striped table-bordered table-fonction">
  <thead>
  <th (click)="sortId()" style="width: 100px" class="pointer">ID</th>
  <th (click)="sortName()" class="pointer">Nom</th>
  <th style="width: 50px">Modifier</th>
  <th style="width: 50px">Supprimer</th>
  </thead>
  <tbody>
  <tr *ngFor="let fonction of fonctions.filteredData">
    <td class="text-center">{{fonction.id_conf}}</td>
    <td class="text-center">{{fonction.valeur}}</td>
    <td class="text-center" style="cursor: pointer" (click)="onEdit(fonction.id_conf, fonction.valeur)"><mat-icon>edit</mat-icon></td>
    <td class="text-center" style="cursor: pointer; color: red;" (click)="onDelete(fonction.id_conf)"><mat-icon>delete</mat-icon></td>
  </tr>
  </tbody>
</table>
