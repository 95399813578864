import {Component, OnInit, Input, Output, EventEmitter, HostListener, } from '@angular/core';
import { DataConsultationService } from '../utils/data-services/dataConsultation.service';
import { DataInteractionService } from '../utils/data-services/dataInteraction.service';
import { AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { Valeur } from './valeur';
import {DataModificationService} from '../utils/data-services/dataModification.service';
import {DatePipe} from '@angular/common';
import swal from 'sweetalert2';
import {DataSmsService} from "../utils/data-services/data-sms.service";

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.css']
})

export class ConsultationComponent implements OnInit, AfterViewInit {
  tableData: Valeur[] = [];
  sortedTableData: Valeur[];
  filteredTableData: Valeur[];
  filtreParametre: any;

  // tslint:disable-next-line:max-line-length variable-name
  constructor(
    private dataConsultationService: DataConsultationService,
    private dataInteractionService: DataInteractionService,
    private dataModificationService: DataModificationService,
    private dataSms: DataSmsService,
    private datePipe: DatePipe,
    private route: Router) {
      this.sortedTableData = this.tableData.slice();
      this.filteredTableData = this.sortedTableData;
    }

  /* Consultation page display variables */
  variableConcession: any;
  filteredDealership: any[] = [];
  variableProduit: any;
  filteredProduct: any[] = [];
  variableParametre: any;
  filteredParam: any[] = [];
  variableValeur: any = [];
  variable: any;
  tagArray: any;
  filteredTag: any[] = [];
  /* End */

  /* DropDownList selected variables */
  selectedConcessionId: any;
  selectedProduitId: any;
  selectedParametreId: any;
  selectedTagId: any;
  /* End */

  /* Variables that will be transfered to other components */
  transmettreConcessionId: any;
  transmettreProduitId: any;
  transmettreParametreId: any;
  transmettreValeurId: any;
  transmettreValeur: any;
  transmettreDateDebut: any;
  transmettreDateFin: any;
  transmettreMarqueId: any;
  /* End */

  /* Localstorage variables in case of back navigation */
  localStorageIdConcession: any;
  localStorageNomConcession: any;
  localStorageIdProduit: any;
  localStorageNomProduit: any;
  localStorageIdParametre: any;
  localStorageNomParametre: any;
  localStorageNomTag: any;
  localStorageIdTag: any;
  /* End */

  /* Filter table variables */
  filter: boolean;
  variableValeurFiltered: any = [];
  /* End*/

  todayDate: any;
  todayDateFormated: any;

  defautSelectedConcession: any;
  defautSelectedProduct: any;
  defautSelectedParametre: any = [];
  defautSelectedTag: any;
  isAllParametreSelected: boolean = true;
  defaultVariableParametre: any;

  ngOnInit() {
    this.todayDate = new Date();
    this.todayDateFormated = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.filter = true;
    this.localStorageIdConcession = localStorage.getItem('softgateConcessionIdVariable');
    this.localStorageIdProduit = localStorage.getItem('softgateProduitIdVariable');
    this.localStorageIdParametre = JSON.parse(localStorage.getItem('softgateParametreList'));
    this.localStorageIdTag = localStorage.getItem('softageTogIdVariable');

    /* Fill DropDownLists */
// Concession ---------------------------------------------------------------------------------------------------------------
    this.dataConsultationService.getConcession().subscribe(dataConcession => {
      this.variableConcession = dataConcession.records.sort(function sortFunction(a, b): any {
        return a.nom_concession > b.nom_concession ? 1 : -1;
      });
      if (this.localStorageIdConcession != null && this.localStorageIdConcession !== 'all' && this.localStorageIdConcession !== 'undefined') {
        this.defautSelectedConcession = this.variableConcession.find(x =>{
          return x.concession_id == this.localStorageIdConcession;
        });
      }
      else if (this.localStorageIdConcession == 'all'){
        this.defautSelectedConcession = 'all';
      }
      else if (dataConcession.records.length > 0) {
        this.defautSelectedConcession = dataConcession.records[0];
        this.dataInteractionService.sendIdConcession(this.defautSelectedConcession.concession_id);
        localStorage.setItem('softgateConcessionIdVariable', this.defautSelectedConcession.concession_id);
      }
      this.filteredDealership = this.variableConcession;
    });
// Produit ---------------------------------------------------------------------------------------------------------------
    this.dataConsultationService.getProduit().subscribe(dataProduit => {
      this.filteredProduct = this.variableProduit = dataProduit.records.sort(function sortFunction(a, b): any {
        return a.nom_produit > b.nom_produit ? 1 : -1;
      });

    if (this.localStorageIdProduit != null && this.localStorageIdProduit !== 'all' && this.localStorageIdProduit !== 'undefined') {
      this.defautSelectedProduct = dataProduit.records.find(x =>{
        return x.produit_id == this.localStorageIdProduit;
      });
    }

    else if (this.localStorageIdProduit == 'all'){
      this.defautSelectedProduct = 'all';
    }

    else if (dataProduit.records.length > 0) {
      this.defautSelectedProduct = dataProduit.records[0];
      this.dataInteractionService.sendIdProduit(this.defautSelectedProduct.produit_id);
      localStorage.setItem('softgateProduitIdVariable', this.defautSelectedProduct.produit_id);
      }
    });
// Parametre ---------------------------------------------------------------------------------------------------------------
    this.dataConsultationService.getParametre().subscribe(dataParametre => {
      this.filteredParam = this.variableParametre = this.defaultVariableParametre = dataParametre.records.sort(function sortFunction(a, b): any {
        return a.nom_parametre > b.nom_parametre ? 1 : -1;
      });

      if (this.localStorageIdParametre !== 'undefined' && this.localStorageIdParametre !== null && this.localStorageIdParametre.length !== 0) {
        this.defautSelectedParametre = [];
        this.isAllParametreSelected = this.localStorageIdParametre.some(x => { return x == 'all' });
        if (this.localStorageIdParametre.some(x => { return x == 'all' })) {
          this.defautSelectedParametre.push('all');
        }
        for (const parameter of this.variableParametre) {
          if (this.localStorageIdParametre.some(x => { return x.parametre_id == parameter.parametre_id })) {
            this.defautSelectedParametre.push(parameter);
          }
        }
      } else {
        this.defautSelectedParametre = [...this.variableParametre, ...['all']];
        localStorage.setItem('softgateParametreList', JSON.stringify(this.defautSelectedParametre));
      }
      if (this.localStorageIdProduit != null && this.localStorageIdProduit !== 'all' && this.localStorageIdProduit !== 'undefined') {
        this.variableParametre = this.defaultVariableParametre.filter((x) => {
          return x.produit_id == this.localStorageIdProduit;
        });
      }
      });

    /* End */
    this.SelectChangeHandlerValeur();
    this.getTag();
  }

  ngAfterViewInit() {
    this.sortData({ active: 'nom_parametre', direction: 'asc' });
  }
// Data TAG ------------------------------------------------------------------------------------------------
  getTag() {
    this.dataSms.getTag().subscribe(result => {
      this.filteredTag = this.tagArray = result;
      if (this.localStorageIdTag != null && this.localStorageIdTag !== 'all' && this.localStorageIdTag !== 'undefined') {
        this.defautSelectedTag = result.find(x => {
          return x.id == this.localStorageIdTag;
        });
      } else {
        this.defautSelectedTag = 'all';
        this.dataInteractionService.sendIdTag(this.defautSelectedTag);
        localStorage.setItem('softgateTagIdVariable', this.defautSelectedTag);
      }
    });
  }
  // Manage concession variable selection from DropDownList
  SelectChangeHandlerConcession() {
    if (this.defautSelectedConcession == 'all'){
      this.selectedConcessionId = this.defautSelectedConcession;
    }
    else {
      this.selectedConcessionId = this.defautSelectedConcession.concession_id;
    }
    this.dataInteractionService.sendIdConcession(this.selectedConcessionId);
    localStorage.setItem('softgateConcessionIdVariable', this.selectedConcessionId);
  }

  // Manage product variable selection from DropDownList
  SelectChangeHandlerProduit() {
    if (this.defautSelectedProduct == 'all'){
      this.selectedProduitId = this.defautSelectedProduct;
    }
    else {
      this.selectedProduitId = this.defautSelectedProduct.produit_id;
    }
    this.dataInteractionService.sendIdProduit(this.selectedProduitId);
    localStorage.setItem('softgateProduitIdVariable', this.selectedProduitId);
    if (this.selectedProduitId != null && this.selectedProduitId !== 'all' && this.selectedProduitId !== 'undefined') {
      this.variableParametre = this.defaultVariableParametre.filter((x) => {
        return x.produit_id == this.selectedProduitId;
      });
    }
  }
  SelectChangeHandlerTag() {
    if (this.defautSelectedTag == 'all'){
      this.selectedTagId = this.defautSelectedTag;
    }
    else {
      this.selectedTagId = this.defautSelectedTag.id;
    }
    this.dataInteractionService.sendIdTag(this.selectedTagId)
    localStorage.setItem('softgateTagIdVariable', this.selectedTagId);
  }

  // Manage parameter variable selection from DropDownList
  SelectChangeHandlerParametre() {
    if (!this.isAllParametreSelected && (this.defautSelectedParametre.some(x => { return x == 'all' }) || this.defautSelectedParametre.length == this.variableParametre.length)) {
      this.defautSelectedParametre = [...this.variableParametre, ...['all']];
      this.isAllParametreSelected = true;

    } else if (!this.defautSelectedParametre.some(x => { return x == 'all' }) && this.isAllParametreSelected) {
      this.defautSelectedParametre = [];
      this.isAllParametreSelected = false;

    } else {
      this.isAllParametreSelected = false;

      if (this.defautSelectedParametre.some(x => { return x == 'all' })) {
        const tempParametre = [];
        for (const parametre of this.defautSelectedParametre) {
          if (parametre != 'all') {
            tempParametre.push(parametre);
          }
        }
        this.defautSelectedParametre = tempParametre;
      }
    }

    localStorage.setItem('softgateParametreList', JSON.stringify(this.defautSelectedParametre));
  }

  // Retrieve values after pressing soumettre button.
  SelectChangeHandlerValeur() {
    this.localStorageIdConcession = localStorage.getItem('softgateConcessionIdVariable');
    this.localStorageIdProduit = localStorage.getItem('softgateProduitIdVariable');
    this.localStorageIdParametre = JSON.parse(localStorage.getItem('softgateParametreList'));
    this.localStorageIdTag = localStorage.getItem('softgateTagIdVariable');
    this.dataConsultationService.getConcessionLocalStorage(this.localStorageIdConcession).subscribe( dataC => {
      this.localStorageNomConcession = dataC.records;
    });

    this.dataConsultationService.getProduitLocalStorage(this.localStorageIdProduit).subscribe(dataPr => {
      this.localStorageNomProduit = dataPr.records;
    });

    this.dataConsultationService.getValeur(this.localStorageIdConcession, this.localStorageIdProduit, this.localStorageIdParametre, this.localStorageIdTag).subscribe(dataValeur => {
      this.variableValeurFiltered = [];
      this.variableValeur = dataValeur.records;
      this.filterValeurs(this.filter);
    });
  }

  /***
   * Filter values table based on start values date
   * Parameter: filter, Type: Boolean
   ***/
  filterValeurs(arg: boolean) {
    if (arg) {
      for (let i = 0; i < this.variableValeur.length; i++) {
        if (new Date(this.variableValeur[i].date_debut_param) <= this.todayDate && new Date(this.variableValeur[i].date_fin_param) >= this.todayDate) {
          this.variableValeurFiltered.push(this.variableValeur[i]);
        }
      }
    } else {
      this.variableValeurFiltered = this.variableValeur;
    }
    this.tableData = this.variableValeurFiltered;
    this.sortedTableData = this.tableData.slice();
    this.filteredTableData = this.sortedTableData;
    this.sortData({ active: 'nom_parametre', direction: 'asc' });
  }

  filterToggle($event: any) {
    this.variableValeurFiltered = [];
    if ($event.target.checked) {
      this.filter = true;
    } else {
      this.filter = false;
    }
    this.filterValeurs(this.filter);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filteredTableData = this.sortedTableData.filter(data => data.valeur.toLowerCase().includes(filterValue));
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
  filterDealership(event): any {
    const regexp = new RegExp(event.target.value.toLowerCase());
    this.filteredDealership = this.variableConcession.filter(x => {
      return regexp.exec(x.nom_concession.toLowerCase());
    });
  }
  filterProduct(event): any {
    const regexp = new RegExp(event.target.value.toLowerCase());
    this.filteredProduct = this.variableProduit.filter(x => {
      return regexp.exec(x.nom_produit.toLowerCase());
    });
  }
  filterParam(event): any {
    const regexp = new RegExp(event.target.value.toLowerCase());
    this.filteredParam = this.variableParametre.filter(x => {
      return regexp.exec(x.nom_parametre.toLowerCase());
    });
  }
  filterTag(event): any {
    const regexp = new RegExp(event.target.value.toLowerCase());
    this.filteredTag = this.tagArray.filter(x => {
      return regexp.exec(x.nom.toLowerCase());
    });
  }

  SelectChangeHandlerId(index1: any, index2: any, index3: any, index4: any, index5: any, index6: any, index7: any, index8: any) {
    this.transmettreConcessionId = index1;
    this.transmettreProduitId = index2;
    this.transmettreParametreId = index3;
    this.transmettreValeur = index4;
    this.transmettreDateDebut = index5;
    this.transmettreDateFin = index6;
    this.transmettreValeurId = index7;
    this.transmettreMarqueId = index8;
    this.dataInteractionService.sendIdConcession(this.transmettreConcessionId);
    this.dataInteractionService.sendIdProduit(this.transmettreProduitId);
    this.dataInteractionService.sendIdParametre(this.transmettreParametreId);
    this.dataInteractionService.sendvaleur(this.transmettreValeur);
    this.dataInteractionService.sendDateDebut(this.transmettreDateDebut);
    this.dataInteractionService.sendDateFin(this.transmettreDateFin);
    this.dataInteractionService.sendIdValeur(this.transmettreValeurId);
    this.dataInteractionService.sendIdMarque(this.transmettreMarqueId);
    this.route.navigate(['../modification']);
  }

  SelectChangeHandlerId2(index1: any, index2: any, index3: any, index4: any, index5: any, index6: any, index7: any, index8: any) {
    localStorage.setItem('softgateConcessionIdVariable', index1);
    localStorage.setItem('softgateProduitIdVariable', index2);
    localStorage.setItem('valeurCreation', index4);
    localStorage.setItem('softgateParametreIdCreation', index3);
    this.route.navigate(['../creation']);
  }

  /***
   * Send selected (concession_id, produit_id, parametre_id) to CreationNouvelle component if there was no value found
   ***/
  SelectChangeHandlerId3() {
    this.dataInteractionService.sendIdConcession(this.selectedConcessionId);
    this.dataInteractionService.sendIdProduit(this.selectedProduitId);
    this.dataInteractionService.sendIdParametre(this.selectedParametreId);
    this.route.navigate(['../creationNouvelle']);
  }

  /***
   * Send selected (concession_id, produit_id, parametre_id) to CreationSelection component if soumettre button wasn't pressed
   ***/
  SelectChangeHandlerId4() {
    this.dataInteractionService.sendIdConcession(this.selectedConcessionId);
    this.dataInteractionService.sendIdProduit(this.selectedProduitId);
    this.dataInteractionService.sendIdParametre(this.selectedParametreId);
    this.route.navigate(['../creationSelection']);
  }

  pauseConsultation(valeurId, isActive) {
    // Set body
    const body = { date_fin_param: '9999-01-01'};
    if (isActive) {
      body.date_fin_param = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    }
    // Send request
    this.dataModificationService.updateDateFinValeur(body, valeurId).subscribe(res => {
      swal.fire({
        title: 'Succès!',
        text: 'La modification du paramètre a bien été effectuée.',
        type: 'success',
        confirmButtonText: 'Ok'
      });
      this.SelectChangeHandlerValeur();
    }, error => {
      swal.fire({
        title: 'Erreur!',
        text: 'Une erreur est surnevue lors de la modification du paramètre.',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }

  /***
   * Sort values table
   ***/
  sortData(sort: Sort) {
    const data = this.tableData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedTableData = data;
      this.filteredTableData = this.sortedTableData;
      return;
    }
    this.sortedTableData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'concession_id': return compare(a.concession_id, b.concession_id, isAsc);
        case 'nom_concession': return compare(a.nom_concession, b.nom_concession, isAsc);
        case 'nom_produit': return compare(a.nom_produit, b.nom_produit, isAsc);
        case 'nom_parametre': return compare(a.nom_parametre.toUpperCase(), b.nom_parametre.toUpperCase(), isAsc);
        case 'valeur': return compare(a.valeur, b.valeur, isAsc);
        case 'date_debut_param': return compare(a.date_debut_param, b.date_debut_param, isAsc);
        case 'date_fin_param': return compare(a.date_fin_param, b.date_fin_param, isAsc);
        case 'nom_marque': return compare(a.nom_marque, b.nom_marque, isAsc);
        default: return 0;
      }
    });
    this.filteredTableData = this.sortedTableData;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

