import { Component, OnInit } from '@angular/core';
import {DataCampaignDiscountsService} from '../../utils/data-services/data-campaign-discounts.service';
import {Router} from '@angular/router';
import * as url from 'url';

@Component({
  selector: 'app-creation-campaign-discounts',
  templateUrl: './creation-campaign-discounts.component.html',
  styleUrls: ['./creation-campaign-discounts.component.scss']
})
export class CreationCampaignDiscountsComponent implements OnInit {
  campaignDiscounts = {
    name: '',
    limite: 0,
    count_actual_app: 0,
    count_actual_site: 0,
    start_date: new Date(),
    end_date: new Date(),
    message: '',
    requirement: '',
    requirement_operator: '',
    active: false,
    brand_id: 66,
    debug: false
  };
  brandArray = [];
  constructor( private dataService: DataCampaignDiscountsService, private route: Router ) { }

  ngOnInit() {
    this.getAllBrand();
  }
  createCampaignDiscounts() {
    this.dataService.createCampaignDiscounts(this.campaignDiscounts).subscribe(result => {
      this.route.navigate(['../gestion-campagne-sms']);
    });
  }
  getAllBrand() {
    this.dataService.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-campagne-sms']);
  }
  onChangeCheckbox(type) {
    this.campaignDiscounts[type] = !this.campaignDiscounts[type];
  }
}
