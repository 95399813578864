import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataRuleService} from '../../utils/data-services/dataRule.service';
import {Router} from '@angular/router';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';

@Component({
  selector: 'app-creation-regle',
  templateUrl: './creation-regle.component.html',
  styleUrls: ['./creation-regle.component.scss']
})
export class CreationRegleComponent implements OnInit {

  ruleCreationForm: any;
  errors = {
    logic: {err: false, message: ''},
    day: {err: false, message: ''},
    title: {err: false, message: ''},
    description_softnrg: {err: false, message: ''},
    description_kidi: {err: false, message: ''},
    first_field: {err: false, message: ''},
    first_comparison_operator: {err: false, message: ''},
    first_value: {err: false, message: ''},
    date_notification: {err: false, message: ''},
    recurrent_start: {err: false, message: ''},
    concessions: {err: false, message: ''},
    logical_operator: {err: false, message: ''},
    second_fields: {err: false, message: ''},
    priority: {err: false, message: ''},
  };
  isFormValid: boolean;
  concessions = [];
  dropdownConcessionList = [];
  selectedConcession = [];
  dropdownSettings = {};

  constructor(
    private formBuilder: FormBuilder,
    private dataConcessionService: DataConcessionService,
    private dataRuleService: DataRuleService,
    private route: Router
  ) {
    this.ruleCreationForm = this.formBuilder.group({
      id: null,
      title: '',
      description_softnrg: '',
      description_kidi: '',
      first_field: '',
      first_comparison_operator: '',
      first_value: '',
      logical_operator: '',
      second_field: '',
      second_comparison_operator: '',
      second_value: '',
      is_active: '',
      is_recurrent: '',
      day_sign: '',
      day: '',
      date_notification: '',
      recurrent_start: '',
      color: '',
      priority: ''
    });
  }

  ngOnInit() {
    this.getDataConcession();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  getDataConcession(): void {
    this.dataConcessionService.getConcession().subscribe(data => {
      const concessionList = [];
      for (const concession of data.records) {
        concessionList.push({ item_id: concession.concession_id, item_text: concession.nom_concession });
      }
      this.dropdownConcessionList = concessionList;
    });
  }

  onConcessionSelect(item: any) {
    this.concessions.push(item.item_id);
  }

  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessions.length; i++) {
      if (this.concessions[i] === item.item_id) {
        this.concessions.splice(i, 1);
      }
    }
  }

  setFieldToNull(): void {
    if (this.ruleCreationForm.controls.logical_operator.value === '') {
      this.ruleCreationForm.controls.second_field.value  = '';
      this.ruleCreationForm.value.second_field  = '';
      this.ruleCreationForm.controls.second_comparison_operator.value   = '';
      this.ruleCreationForm.value.second_comparison_operator  = '';
      this.ruleCreationForm.controls.second_value.value   = '';
      this.ruleCreationForm.value.second_value  = '';
    }
  }

  onSubmit(post): any {
    // reset errors //
    this.isFormValid = true;
    this.errors = {
      logic: {err: false, message: ''},
      day: {err: false, message: ''},
      title: {err: false, message: ''},
      description_softnrg: {err: false, message: ''},
      description_kidi: {err: false, message: ''},
      first_field: {err: false, message: ''},
      first_comparison_operator: {err: false, message: ''},
      first_value: {err: false, message: ''},
      date_notification: {err: false, message: ''},
      recurrent_start: {err: false, message: ''},
      concessions: {err: false, message: ''},
      logical_operator: {err: false, message: ''},
      second_fields: {err: false, message: ''},
      priority: {err: false, message: ''},
    };

    // form validation errors //
    this.errors.title = post.title === '' ? {err: true, message: 'Champ requis'} : this.errors.title;
    this.errors.description_softnrg = post.description_softnrg === '' ? {err: true, message: 'Champ requis'} : this.errors.description_softnrg;
    this.errors.description_kidi = post.description_kidi === '' ? {err: true, message: 'Champ requis'} : this.errors.description_kidi;
    this.errors.first_field = post.first_field === '' ? {err: true, message: 'Champ requis'} : this.errors.first_field;
    this.errors.first_comparison_operator = post.first_comparison_operator === '' ? {err: true, message: 'Champ requis'} : this.errors.first_comparison_operator;
    this.errors.first_value = post.first_value === '' ? {err: true, message: 'Champ requis'} : this.errors.first_value;
    this.errors.date_notification = post.date_notification === '' ? {err: true, message: 'Champ requis'} : this.errors.date_notification;
    this.errors.recurrent_start = post.recurrent_start === '' ? {err: true, message: 'Champ requis'} : this.errors.recurrent_start;
    this.errors.day = post.day === '' || post.day === null ? {err: true, message: 'Champ requis'} : this.errors.day;
    this.errors.priority = post.priority === '' || post.priority === null ? {err: true, message: 'Champ requis'} : this.errors.priority;
    if (post.day <= 0 && post.is_recurrent === 1) {
      this.errors.logic = {err: true, message: 'Récurrence de ' + post.day + ' jour(s) impossible.'};
    }
    if (this.concessions.length === 0) {
      this.errors.concessions = {err: true, message: 'vous devez choisir au moins une concession'};
    }
    if (
      (post.logical_operator === '' && post.second_value !== '') ||
      (post.logical_operator === '' && post.second_field !== '') ||
      (post.logical_operator === '' && post.second_comparison_operator !== '')
    ) {
      this.errors.logical_operator = {err: true, message: 'Champ requis'};
    }
    if (
      (post.logical_operator !== '' && post.second_value === '') ||
      (post.logical_operator !== '' && post.second_field === '') ||
      (post.logical_operator !== '' && post.second_comparison_operator === '')
    ) {
      this.errors.second_fields = {err: true, message: 'Veuillez remplir les 3 champs'};
    }

    // tslint:disable-next-line:triple-equals
    post.is_recurrent = post.is_recurrent == false ? 0 : 1;
    // tslint:disable-next-line:triple-equals
    post.is_active = post.is_active == false ? 0 : 1;
    // tslint:disable-next-line:triple-equals
    post.recurrent_start = post.recurrent_start == false ? 0 : 1;

    // check if form is valid //
    for (const error of Object.entries(this.errors)) {
      if (error[1].err) {
        this.isFormValid = false;
      }
    }

    // send request if form is valid //
    if (this.isFormValid) {
      this.dataRuleService.requestCreateRule(post).subscribe(response => {
        for (const concession of this.concessions) {
            this.dataRuleService.requestCreateConcesssionRule(response, concession).subscribe();
        }
        this.route.navigate(['/gestion-regle']);
      });
    }
  }
}
