import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-configuration',
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.scss']
})
export class CreateConfigurationComponent implements OnInit {
  configuration = {
    name: null,
    type: null,
    field: null,
    order: null
  };
  typeArray = [];
  fieldTypeArray = [];
  constructor( private dataService: DataConfigurationConcessionService, private router: Router ) { }

  ngOnInit() {
    this.getType();
    this.getFieldType();
  }
  getType() {
    this.dataService.getConfigurationType().subscribe(result => {
      this.typeArray = result;
    });
  }
  onCreate() {
    this.dataService.createConfiguration(this.configuration).subscribe(result => {
      this.router.navigate(['/gestion-configuration']);
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-configuration']);
  }
  getFieldType() {
    this.dataService.getFieldType().subscribe(result => {
      this.fieldTypeArray = result;
    });
  }
}
