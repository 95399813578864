<h1 class="text-center mt-2">Gestion d'informations des produits</h1>
<!-- Filter and product search  -->
<div class="user-table mt-4" style="width: auto; display: flex; flex-wrap: nowrap; justify-content: end; align-items: center;">
  <div class="row mr-3">
    <button class="btn btn-outline-dark mr-2" [class.filterBtnCliked]="isTechniqueFilter" (click)="technique()">Technique </button>
    <button class="btn btn-outline-dark" [class.filterBtnCliked]="isCommercialFilter" (click)="commercial()">Commercial</button>
  </div>
  <div class="m-3">
   <mat-form-field style="width: 250px">
     <mat-label>Cherchez un produit</mat-label>
     <input matInput (keyup)="applyFilter()" [(ngModel)]="searchValue" >
   </mat-form-field>
  </div>
</div>
<!-- Products Block -->
<div class="row wrapper center" style=" margin: 0; padding: 20px; width: auto; ">

   <div class="boxes card pointer" *ngFor="let product of dataSource?.filteredData" (click)="selectedProduct(product.produit_id)">
        <div class="imgBoxes">
            <img
             style="height: 100%; width: 100%;" src="assets/Imgs/carWorkShop.jpg" alt="Img Automobile workshop image">
        </div>
        <strong style="margin: 10px;">{{product.nom_produit}}</strong>
        <div class="cardType" [class.technique]="product.type == 'technique'" [class.commercial]="product.type == 'commercial'">{{product.type | titlecase}}</div>
   </div>
</div>













    <!-- Pour la page upload file
    <div class="">
      <input type="file" name="file" (change)="inChangeFile($event)" >
    </div> -->

