<h2>Création d'une opération pour {{concessionName}}</h2>
<div class="wrapper">
  <form>

    <div class="form-group mt-3" style="width: 445px">
      <label for="softNet_id">ID SoftNet :</label>
      <input [(ngModel)]="operation.softNet_id" class="form-control" id="softNet_id" name="softNet_id">
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="bmw_id">ID BMW :</label>
        <input [(ngModel)]="operation.bmw_id" class="form-control" id="bmw_id" name="bmw_id">
      </div>

      <div class="form-group col-md-3">
        <label for="nom">Nom :</label>
        <input [(ngModel)]="operation.nom" class="form-control" id="nom" name="nom" >
      </div>
    </div>

    <div class="form-group mt-3" style="width: 445px">
      <label for="brand_authorized">Catégorie</label>
      <select [(ngModel)]="operation.categorie_id" class="form-control" name="brand_authorized" id="brand_authorized">
        <option *ngFor="let categorie of categorieArray" [value]="categorie.id">
          {{categorie.name}}
        </option>
      </select>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="prix">Prix :</label>
        <input [(ngModel)]="operation.prix" class="form-control" id="prix" name="prix">
      </div>

      <div class="form-group col-md-3">
        <label for="vt">VT :</label>
        <input [(ngModel)]="operation.vt" class="form-control" id="vt" name="vt">
      </div>
    </div>

    <div class="form-group mt-3" style="width: 445px">
      <label for="commentaireAdditionnel">Commentaire additionnel :</label>
      <textarea [(ngModel)]="operation.commentaireAdditionnel" class="form-control" id="commentaireAdditionnel" name="commentaireAdditionnel" placeholder="Commentaire additionnel"></textarea>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3" >
        <label for="type">Type</label>
        <select [(ngModel)]="operation.type_id" class="form-control" name="type" id="type">
          <option *ngFor="let type of typeArray" [value]="type.id">
            {{type.libelle}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <label for="iconOperation">Icon :</label>
        <mat-select [(ngModel)]="operation.iconOperation" class="form-control" name="iconOperation" id="iconOperation">
          <mat-option *ngFor="let icon of iconArray" [value]="icon">
            <img with="40" height="40" src="assets/icons_operations_PNGO/{{icon}}">
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="minimum_delay">Délai minimum :</label>
        <input [(ngModel)]="operation.minimum_delay" class="form-control" id="minimum_delay" name="minimum_delay">
      </div>

      <div class="form-group col-md-3">
        <label for="ordre">Ordre :</label>
        <input [(ngModel)]="operation.ordre" class="form-control" id="ordre" name="dynamicTest">
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Actif :</label>
        <mat-checkbox (change)="onChangeCheckbox('actif')" class="ml-2" [checked]="operation.actif"></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Aide additionnel :</label>
        <mat-checkbox (change)="onChangeCheckbox('aideAdditionnel')" class="ml-2" [checked]="operation.aideAdditionnel"></mat-checkbox>
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Test dynamique :</label>
        <mat-checkbox (change)="onChangeCheckbox('dynamicTest')" class="ml-2" [checked]="operation.dynamicTest"></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Gamme standard :</label>
        <mat-checkbox (change)="onChangeCheckbox('requiredStandartRangeService')" class="ml-2" [checked]="operation.requiredStandartRangeService"></mat-checkbox>
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Bloqué :</label>
        <mat-checkbox (change)="onChangeCheckbox('blocked')" class="ml-2" [checked]="operation.blocked"></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Attente autorisé :</label>
        <mat-checkbox (change)="onChangeCheckbox('attente_autorise')" class="ml-2" [checked]="operation.attente_autorise"></mat-checkbox>
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createCampaignDiscounts()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
