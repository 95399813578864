import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataFaqService} from '../../dataFaq.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-creation-faq-questions',
  templateUrl: './creation-faq-questions.component.html',
  styleUrls: ['./creation-faq-questions.component.scss']
})
export class CreationFaqQuestionsComponent implements OnInit {
  form: any;
  nbCaracter = 0;
  themes = [];
  dropdownThemesList = [];
  selectedThemes = [];
  dropdownSettings = {};

  constructor(
    private formBuilder: FormBuilder,
    private dataFaqService: DataFaqService,
    private datePipe: DatePipe,
    private route: Router
  ) {
    this.form = this.formBuilder.group({
      id: null,
      question: '',
      answer: '',
      created_at: '',
      is_active: false,
      themes: []
    });
  }

  ngOnInit() {
    this.getAllThemes();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }
  getAllThemes(): any {
    this.dataFaqService.getAllGrandThemes().subscribe(themes => {
      const themesList = [];
      for (const theme of themes.records) {
        themesList.push({ item_id: theme.id, item_text: theme.name });
      }
      this.dropdownThemesList = themesList;
    });
  }
  onThemeSelect(item: any) {
    this.themes.push(item.item_id);
  }
  onThemeDeselect(item: any) {
    for (let i = 0; i < this.themes.length; i++) {
      if (this.themes[i] === item.item_id) {
        this.themes.splice(i, 1);
      }
    }
  }
  onSubmit(post): any {
    post.themes = this.selectedThemes;
    post.created_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.dataFaqService.postQuestion(post).subscribe(() => {
      this.route.navigate(['/gestion-faq-question']);
    });
  }
  countCaraters(event) {
    this.nbCaracter = event.target.value.length;
  }
}
