import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DataCreationConcessionService} from "../utils/data-services/dataCreation-concession.service";
import swal from "sweetalert2";
import {DataSmsSubscriptionService} from "../utils/data-services/data-sms-subscription.service";
import {DataConfigurationConcessionService} from "../utils/data-services/data-configuration-concession.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {Router} from "@angular/router";

@Component({
  selector: 'app-gestion-configuration-section',
  templateUrl: './gestion-configuration-section.component.html',
  styleUrls: ['./gestion-configuration-section.component.scss']
})
export class GestionConfigurationSectionComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;
  variable: any;
  /* Variable list for the Textarea components */
  nom: any;
  dataSource = new MatTableDataSource();
  // tslint:disable-next-line:variable-name
  constructor( private _liveAnnouncer: LiveAnnouncer, private dataConfiguration: DataConfigurationConcessionService, private route: Router) {  }
  displayedColumns: string[] = ['name', 'order', 'update', 'delete'];

  ngOnInit() {
    this.getConfiguration();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getConfiguration() {
    this.dataConfiguration.getConfigurationType().subscribe(result => {
      this.dataSource.data = result;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onUpdate(id) {
    this.route.navigate(['/update-section-configuration/' + id]);
  }
  onCreate() {
    this.route.navigate(['/create-section-configuration']);
  }
  onDelete(id: any) {
    swal.fire({
      title: 'Supprimer',
      text: 'Voulez-vous supprimer cette section ?',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (!result.dismiss) {
        this.dataConfiguration.deleteConfigurationType(id).subscribe(() => {
          this.getConfiguration();
        });
      }
    });
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
