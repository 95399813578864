<h2>Création d'un scénario</h2>
<div class="wrapper">
  <form>

    <div class="form-row mt-3 ml-1" style="width: 440px">
      <label for="label">Label :</label>
      <input [(ngModel)]="snapshotScenario.label" class="form-control" id="label" name="label">
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="operations">Opérations :</label>
        <input [(ngModel)]="snapshotScenario.operations" class="form-control" id="operations" name="operations">
      </div>
      <div class="form-row form-group col-md-3">
        <label for="brand">Marque</label>
        <select [(ngModel)]="snapshotScenario.brand_id" class="form-control" name="brand" id="brand">
          <option *ngFor="let brand of brandArray" [value]="brand.id">
            {{brand.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-row mt-3" style="width: 600px">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>En attente :</label>
        <mat-checkbox (change)="onChangeCheckbox('is_wating')" class="ml-2"></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Actif :</label>
        <mat-checkbox (change)="onChangeCheckbox('is_active')" class="ml-2" checked></mat-checkbox>
      </div>

      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Véhicle de courtoisie :</label>
        <mat-checkbox (change)="onChangeCheckbox('is_courtesy_car')" class="ml-2"></mat-checkbox>
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createSnapshotScenario()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
