import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {ActivatedRoute, Router} from "@angular/router";
import swal from "sweetalert2";
import {DataDmsService} from "../../utils/data-services/data-dms.service";
import moment from "moment";

@Component({
  selector: 'app-modification-configuration-concession',
  templateUrl: './modification-configuration-concession.component.html',
  styleUrls: ['./modification-configuration-concession.component.scss']
})
export class ModificationConfigurationConcessionComponent implements OnInit {
  idConcession: any;
  dealershipConfiguration: any;
  configurationArray = [];
  configurationTypeArray = [];
  dmsArrayDefault = [];
  dmsArrayFiltered = [];
  editorArray = [];
  bmwCorrespondentArray = [];
  configurationValueArray = [];
  // tslint:disable-next-line:max-line-length
  constructor(private activeRoute: ActivatedRoute, private dataService: DataConfigurationConcessionService, private dataDmsService: DataDmsService, private route: Router) { }

  ngOnInit() {
    this.idConcession = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getDealershipConfiguration();
    this.getConfiguration();
    this.getConfigurationType();
    this.getDms();
    this.getEditor();
    this.getBmwCorrespondant();
  }
  getDealershipConfiguration() {
    this.dataService.getDealershipConfigurationById(this.idConcession).subscribe(response => {
      this.dealershipConfiguration = response;
    });
  }
  getConfiguration() {
    this.dataService.getConfiguration().subscribe(response => {
      this.configurationArray = response;
      for (const configuration of this.configurationArray) {
        this.getConfigurationOptions(configuration);
      }
    });
  }
  getConfigurationType() {
    this.dataService.getConfigurationType().subscribe(response => {
      this.configurationTypeArray = response;
    });
  }
  getSelectOptions(id: number): any {
    switch (id) {
      case 21 : return this.filterDmsArray();
      case 29 : return this.editorArray;
      default: return this.configurationValueArray[id];
    }
  }
  getDms() {
    this.dataDmsService.getDms().subscribe(result => {
      this.dmsArrayDefault = result;
      this.dmsArrayFiltered = result;
    });
  }
  getEditor() {
    this.dataDmsService.getEditor().subscribe(result => {
      this.editorArray = result;
    });
  }
  getBmwCorrespondant() {
    this.dataService.getBmwCorrespondent().subscribe(result => {
      this.bmwCorrespondentArray = result;
    });
  }
  filterDmsArray() {
    return this.dmsArrayDefault.filter((dms) => dms.editorName === this.dealershipConfiguration['Editeur DMS']);
  }
  filterConfiguration(array, text) {
    return array.filter(conf => conf.type == text);
  }
  getConfigurationOptions(configuration) {
   if (configuration.field === 'select') {
      this.dataService.getConfigurationValue(configuration.id).subscribe(result => {
        this.configurationValueArray[configuration.id] = result;
      });
    }
  }
  changeSelectedValue() {
    const selectedArray = this.getSelectOptions(21);
    if (selectedArray.length === 1) {
      this.dealershipConfiguration.DMS = selectedArray[0].name;
    }
  }
  camelCaseToTitleCase(text: string): string {
    const result = text.replace('Version', '');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  selectChangeHandlerCreer() {
    this.dataService.updateDealershipConfiguration(this.dealershipConfiguration, this.idConcession).subscribe( response => {
      this.route.navigate(['../gestion-configuration-concession']);
    }, error => {
      swal.fire({
        title: 'Erreur!',
        text: 'Un problème est survenu lors de la modification de la configuration',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  selectHandlerCancel() {
    this.route.navigate(['../gestion-configuration-concession']);
  }
}
