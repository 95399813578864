import { Component, OnInit } from '@angular/core';
import {DataCampaignDiscountsService} from "../../utils/data-services/data-campaign-discounts.service";
import {Router} from "@angular/router";
import {DataSnapshotScenarioService} from "../../utils/data-services/data-snapshot-scenario.service";

@Component({
  selector: 'app-create-snapshot-scenario',
  templateUrl: './create-snapshot-scenario.component.html',
  styleUrls: ['./create-snapshot-scenario.component.scss']
})
export class CreateSnapshotScenarioComponent implements OnInit {
  snapshotScenario = {
    brand_name: null,
    brand_id: null,
    label: null,
    operations: null,
    is_courtesy_car: false,
    is_wating: false,
    is_active: false
  };
  brandArray = [];
  constructor( private dataService: DataSnapshotScenarioService, private dataCampaignDiscountsSerive: DataCampaignDiscountsService, private route: Router ) { }

  ngOnInit() {
    this.getAllBrand();
  }
  createSnapshotScenario() {
    this.snapshotScenario.operations = this.snapshotScenario.operations.split(',');
    this.dataService.createSnapshotScenario(this.snapshotScenario).subscribe(result => {
      this.route.navigate(['../gestion-snapshot-scenario']);
    });
  }
  getAllBrand() {
    this.dataCampaignDiscountsSerive.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-snapshot-scenario']);
  }
  onChangeCheckbox(type) {
    this.snapshotScenario[type] = !this.snapshotScenario[type];
  }

}
