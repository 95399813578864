<h2>Création abonnement</h2>
<div class="wrapper">
  <form>
    <div class="form-group col-md-3">
      <label for="monthlyCost">Coût mensuel :</label>
      <input [(ngModel)]="subscription.monthlyCost" class="form-control" id="monthlyCost" name="monthlyCost" required>
    </div>
    <div class="form-group col-md-3">
      <label for="smsCost">Coût SMS :</label>
      <input [(ngModel)]="subscription.smsCost" class="form-control" id="smsCost" name="smsCost" required>
    </div>
    <div class="form-group col-md-3">
      <label for="color">Couleur :</label>
      <input [(ngModel)]="subscription.color" class="form-control" id="color" name="color" type="color" required>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="onCreate()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
