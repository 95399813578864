import {Component, OnInit, ViewChild} from '@angular/core';
import {DataSmsService} from "../utils/data-services/data-sms.service";
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DataProduitConcessionServiceService} from "../utils/data-services/data-produit-concession-service.service";
import {DatePipe} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {DataProduitService} from "../utils/data-services/dataProduit.service";
import {FormControl} from "@angular/forms";
import writeXlsxFile from "write-excel-file";
import {DataCreationConcessionService} from "../utils/data-services/dataCreation-concession.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-gestion-sms',
  templateUrl: './gestion-sms.component.html',
  styleUrls: ['./gestion-sms.component.scss']
})
export class GestionSmsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource = new MatTableDataSource();
  smsArray: any;
  brandArray: any;
  dealershipDontHaveSmsArray = [];
  constructor(private datePipe: DatePipe, private activeRoute: ActivatedRoute, private dataServiceSms: DataSmsService, private  dataBrandService: DataCreationConcessionService) { }
  exportCollumnNameFr = ['ID', 'Nom', 'Ville', 'Confirmation de rendez-vous', 'Confirmation de rendez-vous en ligne', 'Rappel de rendez-vous', 'Réception client', 'Rendez-vous suspendu', 'Retard', 'Travaux terminés', 'SMS Marketing simple', 'SMS Marketing "intelligent"', 'SMS Remerciement', 'SMS Campagne Excel', 'SMS soft-gate', 'SMS Campagne Excel tarif préférentiel'];
  displayedColumns: string[] = ['id', 'name', 'town', 'confirmation', 'onlineConfirmation', 'reminder', 'reception', 'suspended', 'delay', 'finished', 'simple-marketing', 'smart-marketing', 'thanking', 'softgate'];
  smsFormControl = new FormControl('');
  brandFormControl = new FormControl('');
  activeFormControl = new FormControl('');
  originalDataSource: any;
  showOwnSms = true;
  public filteredSms = [];

  ngOnInit() {
    this.getSmsByDealership();
    this.getSms();
    this.getBrands();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getSms() {
    this.dataServiceSms.getTag().subscribe(result => {
      this.smsArray = result;
    });
  }
  getBrands() {
    this.dataBrandService.getBrands().subscribe(result => {
      this.brandArray = result.records;
    });
  }
  filterBySms() {
    let data = this.originalDataSource;
    data = this.activeFormControl.value.length > 0 ? data.filter(dealership => dealership.isActive === this.activeFormControl.value[0]) : data;
    data = this.smsFormControl.value.length > 0 ? data.filter(dealership => {
      let isInclude = true;
      for (const sms of this.smsFormControl.value) {
        if (this.showOwnSms) {
          // @ts-ignore
          if (!dealership.sms.includes(sms)) {
            isInclude = false;
          }
        } else {
          // @ts-ignore
          if (dealership.sms.includes(sms)) {
            isInclude = false;
          }
        }
      }
      return isInclude;
    }) : data;
    data = this.brandFormControl.value.length > 0 ? data.filter(dealership => {
      let isInclude = true;
      for (const brand of this.brandFormControl.value) {
        // @ts-ignore
        if (!dealership.brand.includes(brand)) {
          isInclude = false;
        }
      }
      return isInclude;
    }) : data;
    this.dataSource.data = data;
  }
  getSmsByDealership() {
    this.dataServiceSms.getSms().subscribe(result => {
      this.dataSource.data = result;
      this.originalDataSource = result;
    });
  }
  changeOwnSms() {
    this.showOwnSms = !this.showOwnSms;
    this.filterBySms();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  dealershipHasSms(array, sms) {
    return array.includes(sms);
  }
  async exportExcel(): Promise<any> {
    const fileName = 'Export_SMS_' + this.datePipe.transform(new Date(), 'yyyyMMdd') + '.xlsx';

    const HEADER_ROW = [];
    const ARRAY_ROW = [];
    const dataFinal = [];

    // création de la ligne de header
    for (const headerName of this.exportCollumnNameFr) {
      HEADER_ROW.push({
        value: headerName,
        fontWeight: 'bold'
      });
    }
    let i = 0;
    // création des lignes de données
    for (const dataSms of this.dataSource.filteredData) {
      ARRAY_ROW.push([
        {
          type: Number,
          // @ts-ignore
          value: dataSms.id
        },
        {
          type: String,
          // @ts-ignore
          value: dataSms.name
        },
        {
          type: String,
          // @ts-ignore
          value: dataSms.town
        }
      ]);
      for (const sms of this.smsArray) {
        ARRAY_ROW[i].push({
          type: String,
          // @ts-ignore
          value: this.dealershipHasSms(dataSms.sms, sms.nom) ? 'oui' : 'non'
        });
      }
      i++;
    }

    // création de l'object final (header + lignes de données)
    dataFinal.push(HEADER_ROW);
    for (const dataRow of ARRAY_ROW) {
      dataFinal.push(dataRow);
    }

    // téléchargement du fichier
    await writeXlsxFile(dataFinal, {
      fileName
    });
  }
}
