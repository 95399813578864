import { Component, OnInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {AuthenticationService} from "../../utils/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService, private route: Router) { }

  ngOnInit() {
  }

  onSubmit(f: NgForm) {
    // if (f.value.MDP !== f.value.RMDP) {
    //   swal.fire({
    //     title: 'Oops...!',
    //     text: 'Il faut que les mots de passe soient identiques',
    //     type: 'error',
    //     confirmButtonText: 'Ok'
    //   });
    // } else {
    //   this.authenticationService.Register(f.value.User, f.value.Mail, f.value.MDP).subscribe( data => {
    //     swal.fire({
    //       title: 'Success!',
    //       text: 'Utilisateur a bien été enregistré',
    //       type: 'success',
    //       confirmButtonText: 'Ok'
    //     });
    //     // window.alert('Success code: 200');
    //     this.route.navigate(['../login']);
    //   });
    // }
  }

  Annuler() {
    this.route.navigate(['../consultation']);
  }

}
