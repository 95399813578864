import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DataBackupConcessionService} from "../../utils/data-services/data-backup-concession.service";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";

@Component({
  selector: 'app-create-backup-concession',
  templateUrl: './create-backup-concession.component.html',
  styleUrls: ['./create-backup-concession.component.scss']
})
export class CreateBackupConcessionComponent implements OnInit {
  backupConcession = {
    concession: null,
    dealership_id: '',
    project: null
  };
  concessionArray = {
    filtered: null,
    original: null
  };
  constructor(private dataServiceConcession: DataConcessionService, private dataService: DataBackupConcessionService, private route: Router ) { }

  ngOnInit() {
    this.getAllConcession();
  }
  createBackupConcession() {
    this.dataService.createBackupConcession(this.backupConcession).subscribe(result => {
      this.route.navigate(['../gestion-backup-concession']);
    });
  }
  getAllConcession() {
    this.dataServiceConcession.getConcession().subscribe(result => {
      this.concessionArray.filtered = result['records'];
      this.concessionArray.original = result['records'];
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-backup-concession']);
  }
  onChangeCheckbox(type) {
    this.backupConcession[type] = !this.backupConcession[type];
  }
  dealershipFilter(event: any) {
    // @ts-ignore
    this.concessionArray.filtered = this.concessionArray.original.filter(x => {
      const regExpString = '(.+)?' + event.target.value.toLowerCase() + '(.+)?';
      const regExpFind = new RegExp(regExpString, 'gi');
      return event.target.value === '' || regExpFind.test(x.nom_concession);
    });
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
}
