import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataNotificationService} from '../../utils/data-services/dataNotification.service';
import swal from 'sweetalert2';
import {SpinnerComponent} from '../../utils/spinner/spinner.component';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-modification-notification',
  templateUrl: './modification-notification.component.html',
  styleUrls: ['./modification-notification.component.scss']
})
export class ModificationNotificationComponent implements OnInit {
  currentNotificationId: number;
  notificationForm: any;
  isLink = false;
  linkLabel = '';
  linkUrl = '';
  targets = [];
  selectedTarget = [];
  types = [
    {label: 'Problème', name: 'danger'},
    {label: 'Divers', name: 'warning'},
    {label: 'Information', name: 'info'},
    {label: 'Résolution', name: 'success'},
  ];
  error = {
    title: false,
    type: false,
    target: false,
    notification: false,
  };
  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private dataNotificationService: DataNotificationService,
    private activeRoute: ActivatedRoute,
    private appComponent: AppComponent,
  ) {
    this.notificationForm = this.formBuilder.group({
      id: null,
      title: '',
      type: null,
      target: [],
      text: null,
      is_active: false,
    });
  }

  ngOnInit() {
    this.currentNotificationId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.dataNotificationService.getNotificationTargets().subscribe(res => {
      this.targets = res;
    });
    this.dataNotificationService.getNotificationById(this.currentNotificationId).subscribe(notification => {
      this.notificationForm.controls.id.setValue(notification.id);
      this.notificationForm.controls.title.setValue(notification.title);
      this.notificationForm.controls.type.setValue(notification.type);
      this.notificationForm.controls.target.setValue([notification.target]);
      this.selectedTarget = [notification.target];
      this.notificationForm.controls.text.setValue(notification.text);
      this.notificationForm.controls.is_active.setValue(notification.is_active);
    });
  }

  addLinkForm(): void {
    this.isLink = !this.isLink;
    if (!this.isLink) {
      this.linkLabel = '';
      this.linkUrl = '';
    }
  }
  addLink(): void {
    const value = this.notificationForm.controls.text.value ? this.notificationForm.controls.text.value : '';
    this.notificationForm.controls.text.value = value +
      '<a href="' + this.linkUrl + '" target="_blank">' + this.linkLabel + '</a>';
    this.addLinkForm();
  }

  onSubmit(post): any {
    this.appComponent.isLoading = true;
    this.error = {
      title: false,
      type: false,
      target: false,
      notification: false,
    };
    post.is_active = post.is_active === false ? 0 : 1;
    if (post.title === '' || post.title === null) {
      this.error.title = true;
    }
    if (post.type === '' || post.type === null) {
      this.error.notification = true;
    }
    if (!this.error.notification && !this.error.title) {
      for (const target of this.selectedTarget) {
        this.dataNotificationService.getNotificationByTargetAndIsActive(post.target).subscribe(notification => {
          if (notification.records.length > 0) {
            notification.records[0].is_active = 0;
            this.dataNotificationService.updateNotification(notification.records[0], notification.records[0].id).subscribe(response => {
              post.target = target;
              this.updateNotification(post);
            }, error => {
              swal.fire({
                title: 'Oops...!',
                text: 'Une erreur est surnevue lors de la sauvegarde..',
                type: 'error',
                confirmButtonText: 'Ok'
              });
            });
          } else {
            post.target = target;
            this.updateNotification(post);
          }
        });
      }
    }
  }
  getTargetValue(): any {
    const target = this.targets.find(x => x.slug == this.notificationForm.controls.target.value);
    if (target) {
      return target.label;
    } else {
      return 'NC';
    }
  }
  updateNotification(post): void {
    this.dataNotificationService.updateNotification(post, post.id).subscribe(response => {
      this.appComponent.isLoading = false;
      swal.fire({
        title: 'Success!',
        text: 'La notification a bien été créée',
        type: 'success',
        confirmButtonText: 'Ok'
      }).then(() => {
        this.route.navigate(['/gestion-notification']);
      });
    }, error => {
      this.appComponent.isLoading = false;
      swal.fire({
        title: 'Oops...!',
        text: 'Une erreur est surnevue lors de la sauvegarde..',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
}
