import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataParametreService extends HandleErrorService {

  getParametre() {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
