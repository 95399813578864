<div class="main-content-inner mt-2">
  <h1 class="text-center">FAQ - Gestion des questions </h1>
  <div class="header-rules-action w-75 mx-auto">
    <div class="col-12 text-right">
      <mat-form-field style="width: 250px">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
      <button type="button" id="btn-add-param" class="btn btn-dark" routerLink="/creation-faq-question">
        <mat-icon>add_circle</mat-icon> Ajouter une question
      </button>
    </div>
  </div>
  <table class="mat-elevation-z8 mx-auto mt-2" mat-table [dataSource]="dataSource" style="width: 90%">
    <ng-container matColumnDef="id">
      <th class="text-center" mat-header-cell *matHeaderCellDef> id</th>
      <td class="text-center" mat-cell *matCellDef="let element"
          [ngStyle]="{'border-left': 'solid' + element.color}"> {{element.id}}
      </td>
    </ng-container>
    <ng-container matColumnDef="question">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Question</th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.question}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Editer</th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onEdit(element.id)"
          style="cursor: pointer; width: 50px">
        <mat-icon>edit</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Supprimer</th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onDelete(element.id)"
          style="cursor: pointer; color: red; width: 30px">
        <mat-icon>delete</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="!isDataSourceExists" style="display: flex;justify-content: center;padding: 25px; align-items: center">
    <span class="material-icons" style="padding: 5px">error</span> Aucun résultat trouvé.
  </div>
</div>
