import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataFaqService} from '../../dataFaq.service';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-modification-faq-questions',
  templateUrl: './modification-faq-questions.component.html',
  styleUrls: ['./modification-faq-questions.component.scss']
})
export class ModificationFaqQuestionsComponent implements OnInit {
  form: any;
  nbCaracter = 0;
  themes = [];
  dropdownThemesList = [];
  selectedThemes = [];
  dropdownSettings = {};
  currentId: number;
  currentQuestion: any;

  constructor(
    private formBuilder: FormBuilder,
    private dataFaqService: DataFaqService,
    private datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    private route: Router
  ) {
    this.form = this.formBuilder.group({
      id: null,
      question: '',
      answer: '',
      created_at: '',
      is_active: false,
      themes: []
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  ngOnInit() {
    this.getAllThemes();
    this.currentId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.dataFaqService.getQuestionById(this.currentId).subscribe(question => {
      this.currentQuestion = question;
      const themes = [];
      const selectedThemes = [];
      for (const theme of question.themes) {
        selectedThemes.push({ item_id: theme.id, item_text: theme.name });
        themes.push(theme.id);
      }
      this.themes = themes;
      this.selectedThemes = selectedThemes;
      this.form.controls.id.setValue(question.id);
      this.form.controls.question.setValue(question.question);
      this.form.controls.answer.setValue(question.answer);
      this.form.controls.created_at.setValue(question.created_at);
      this.form.controls.is_active.setValue(question.is_active);
    });
  }
  getAllThemes(): any {
    this.dataFaqService.getAllGrandThemes().subscribe(themes => {
      const themesList = [];
      for (const theme of themes.records) {
        themesList.push({ item_id: theme.id, item_text: theme.name });
      }
      this.dropdownThemesList = themesList;
    });
  }
  onThemeSelect(item: any) {
    this.themes.push(item.item_id);
  }
  onThemeDeselect(item: any) {
    for (let i = 0; i < this.themes.length; i++) {
      if (this.themes[i] === item.item_id) {
        this.themes.splice(i, 1);
      }
    }
  }
  onSubmit(post): any {
    post.themes = this.selectedThemes;
    this.dataFaqService.updateQuestion(this.currentId, post).subscribe(res => {
      this.route.navigate(['/gestion-faq-question']);
    });
  }
  countCaraters(event) {
    this.nbCaracter = event.target.value.length;
  }
}
