<h1 class="text-center mt-2">Gestion des mobilités de {{concessionName}}</h1>
<div class="container-row top-field">
  <div class="btn1">
    <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
      <mat-icon>add_circle</mat-icon> Mobilité
    </button>
  </div>
  <mat-form-field>
    <mat-label>Cherchez une mobilité</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">

    <ng-container matColumnDef="sofNet_id">
      <th mat-header-cell *matHeaderCellDef> ID SOFT Net </th>
      <td mat-cell *matCellDef="let element"> {{element.sofNet_id}} </td>
    </ng-container>

    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
    </ng-container>

    <ng-container matColumnDef="prix">
      <th mat-header-cell *matHeaderCellDef > Prix </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.prix">{{element.prix}} €</span>
        <span *ngIf="!element.prix">-</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef > Icône </th>
      <td mat-cell *matCellDef="let element"><i class="fa-solid {{element.icon}}"></i></td>
    </ng-container>
    <ng-container matColumnDef="ordre">
      <th mat-header-cell *matHeaderCellDef > Ordre </th>
      <td mat-cell *matCellDef="let element"> {{element.ordre}} </td>
    </ng-container>

    <ng-container matColumnDef="actif">
      <th mat-header-cell *matHeaderCellDef > Actif </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.actif">check</mat-icon>
        <mat-icon *ngIf="!element.actif">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="brand_authorized">
      <th mat-header-cell *matHeaderCellDef > Marque autorisée </th>
      <td mat-cell *matCellDef="let element"> {{element.brand_authorized}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef > Description </th>
      <td mat-cell *matCellDef="let element">
<!--        {{element.description}}-->
        <mat-icon (click)="onClickMessage(element.description)" class="concession-icon pointer"> message</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_external_fleet">
      <th mat-header-cell *matHeaderCellDef > Flotte </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_external_fleet">check</mat-icon>
        <mat-icon *ngIf="!element.is_external_fleet">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_waiting">
      <th mat-header-cell *matHeaderCellDef > Attente </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_waiting">check</mat-icon>
        <mat-icon *ngIf="!element.is_waiting">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button class="m-2 btn btn-round-40 btn-dark" (click)="SelectChangeHandlerModifier(element.id)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
