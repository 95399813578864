<h1 class="text-center mt-2">Gestion des produits par concession</h1>
<div class="user-table">
  <div class="container-row top-field ml-4">
    <mat-form-field>
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
    <div class="btn1">
      <button class="btn btn-dark d-flex" (click)="exportExcel()">
        <mat-icon class="mr-1">file_copy</mat-icon> <span>Export</span>
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-between ml-4">
    <div class="d-flex">
      <mat-form-field class="mr-2" appearance="fill" style="width: 150px">
        <mat-label>Produits</mat-label>
        <mat-select placeholder="Produits" [formControl]="productFormControl" multiple (selectionChange)="filterByProduct()">
          <mat-option *ngFor="let product of productArray" [value]="product.nom_produit">
            {{product.nom_produit}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill" style="width: 150px">
        <mat-label>Marques</mat-label>
        <mat-select placeholder="Marques" [formControl]="brandFormControl" multiple (selectionChange)="filterByProduct()">
          <mat-option *ngFor="let brand of brandArray" [value]="brand.nom">
            {{brand.nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill" style="width: 100px">
        <mat-label>Concession Active</mat-label>
        <mat-select placeholder="Actif" [formControl]="activeFormControl" multiple (selectionChange)="filterByProduct()">
          <mat-option [value]="true">Oui</mat-option>
          <mat-option [value]="false">Non</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group class="mr-2 mt-2" name="productType" aria-label="Type de produit" [formControl]="typeFormControl" (change)="changeProductType()" style="height: fit-content">
        <mat-button-toggle value="commercial">Commercial</mat-button-toggle>
        <mat-button-toggle value="technical">Technique</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-slide-toggle class="pt-3" (change)="changeOwnProduct()" checked>Produits possédés</mat-slide-toggle>
    </div>
    <div class="d-flex">
      <span class="mr-3" style="color: #42A948; height: fit-content" matTooltip="{{this.dataSource.data.length}} concessions possèdent {{productFormControl.value}}">{{this.dataSource.data.length}}</span>
      <span class="mr-3" style="color: #ca2222; height: fit-content" matTooltip="{{this.originalDataSource.length-this.dataSource.data.length}} concessions ne possèdent pas {{productFormControl.value}}">{{this.originalDataSource.length-this.dataSource.data.length}}</span>
    </div>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="town">
      <th mat-header-cell *matHeaderCellDef> Ville </th>
      <td mat-cell *matCellDef="let element"> {{element.town}} </td>
    </ng-container>

    <ng-container *ngFor="let product of productArray" matColumnDef="{{product.nom_produit}}">
      <th mat-header-cell *matHeaderCellDef > {{product.nom_produit}} </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="dealershipHasProduct(element.products, product.nom_produit)" class="active-product">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasProduct(element.products, product.nom_produit)" class="inactive-product">close</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" class="user-table mb-3"></mat-paginator>
</div>

