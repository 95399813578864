import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-default-configuration',
  templateUrl: './create-default-configuration.component.html',
  styleUrls: ['./create-default-configuration.component.scss']
})
export class CreateDefaultConfigurationComponent implements OnInit {
  defaultConfiguration = {
    configuration: null,
    type: null,
    value: null
  };
  configurationArray = [];
  constructor( private dataService: DataConfigurationConcessionService, private router: Router ) { }

  ngOnInit() {
    this.getConfiguration();
  }
  getConfiguration() {
    this.dataService.getConfiguration().subscribe(result => {
      this.configurationArray = result;
    });
  }
  onCreate() {
    this.dataService.createDefaultConfiguration(this.defaultConfiguration).subscribe(result => {
      this.router.navigate(['/gestion-default-configuration']);
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-default-configuration']);
  }
}
