import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import swal from 'sweetalert2';
import {DatePipe} from '@angular/common';
import {LogoutService} from './logout.service';
import {Router} from '@angular/router';
import {AppComponent} from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {

  constructor(
    protected http: HttpClient,
    protected route: Router,
    protected datePipe: DatePipe,
  ) { }

  /**
   * Generalized method to handle http errors
   */
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCodeCorrespondence = '';
    if (error.status === 401 || error.status === 403) {
      statusCodeCorrespondence = 'Non Autorisé';
    } else if (error.status === 404) {
      statusCodeCorrespondence = 'Introuvable';
    } else if (error.status >= 500) {
      statusCodeCorrespondence = 'Erreur Serveur, veuillez contacter le support technique.';
    } else {
      statusCodeCorrespondence = 'Erreur Inconnue';
    }
    if (error.error.message === 'Expired JWT Token' || error.error.message === 'Invalid JWT Token') {
      statusCodeCorrespondence = 'Token de sécurité invalide ou expiré';
    }
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `${error.error.detail}`;
    }
    swal.fire({
      title: 'Erreur',
      text: `Code Erreur : [${error.status}] - ${statusCodeCorrespondence}`,
      type: 'error',
      confirmButtonText: 'Ok'
    }).then(() => {
      if (error.error.message === 'Expired JWT Token' || error.error.message === 'Invalid JWT Token') {
        LogoutService.prototype.onDisconnect();
        document.location.reload();
      }
    });
    return throwError(errorMessage);
  }
  handleErrorSubscription(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCodeCorrespondence = '';
    if (error.status === 401) {
      statusCodeCorrespondence = 'Non Autorisé';
    } else if (error.status === 403) {
      statusCodeCorrespondence = 'Cet abonnement rentre en conflit avec les dates suivantes : ' + error.error.details;
    } else if (error.status === 404) {
      statusCodeCorrespondence = 'Introuvable';
    } else if (error.status >= 500) {
      statusCodeCorrespondence = 'Erreur Serveur, veuillez contacter le support technique.';
    } else {
      statusCodeCorrespondence = 'Erreur Inconnue';
    }
    if (error.error.message === 'Expired JWT Token' || error.error.message === 'Invalid JWT Token') {
      statusCodeCorrespondence = 'Token de sécurité invalide ou expiré';
    }
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `${error.error.detail}`;
    }
    swal.fire({
      title: 'Erreur',
      text: `Code Erreur : [${error.status}] - ${statusCodeCorrespondence}`,
      type: 'error',
      confirmButtonText: 'Ok'
    }).then(() => {
      if (error.error.message === 'Expired JWT Token' || error.error.message === 'Invalid JWT Token') {
        LogoutService.prototype.onDisconnect();
        document.location.reload();
      }
    });
    return throwError(errorMessage);
  }
}
