import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataDmsService extends HandleErrorService {
  getDms() {
    const apiBackupConcession = environment.globalApiUrl + 'api/dms';
    return this.http.get<any>(apiBackupConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getEditor() {
    const apiBackupConcession = environment.globalApiUrl + 'api/editor';
    return this.http.get<any>(apiBackupConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
