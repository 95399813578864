import { Injectable } from '@angular/core';
import {HandleErrorService} from "../handleError.service";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataRentDealershipsAgenciesService  extends HandleErrorService {
  getRentDealershipAgency() {
    const apiRentDealershipAgency = environment.pngoApi + 'api/backoffice/rent-dealerships-agencies';
    return this.http.get<any>(apiRentDealershipAgency, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createRentDealershipAgency(body) {
    const apiRentDealershipAgency = environment.pngoApi + 'api/backoffice/rent-dealerships-agencies/create';
    return this.http.post<any>(apiRentDealershipAgency, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateRentDealershipAgency(body) {
    const apiRentDealershipAgency = environment.pngoApi + 'api/backoffice/rent-dealerships-agencies/update';
    return this.http.put<any>(apiRentDealershipAgency, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getRentDealershipAgencyById(parameter) {
    const apiRentDealershipAgency = environment.pngoApi + 'api/backoffice/rent-dealerships-agencies/' + parameter;
    return this.http.get<any>(apiRentDealershipAgency, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
