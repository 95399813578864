import { Component, OnInit } from '@angular/core';
import { DataProduitService } from '../utils/data-services/dataProduit.service';
import { AfterViewInit } from '@angular/core';
//import { ProductListComponent } from '@angular/core';
import { DataInteractionService } from '../utils/data-services/dataInteraction.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';


@Component({
  selector: 'app-gestion-produit',
  templateUrl: './gestion-produit.component.html',
  styleUrls: ['./gestion-produit.component.css']
})
export class GestionProduitComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line:max-line-length
  constructor(private dataProduitService: DataProduitService, private dataInteractionService: DataInteractionService , private route: Router) {
  }

  variable: any;
  /* Variable list for the Textarea components */
  produitID: any;
  nom: any;
  texte: any;
  webLink: any;
  // etat: any;
  imge: any;
  commerceLink: any;
  produitVersion: any;
  produitType: any;
  actif: any;

  /* Variable Filter data */
  searchText: string = '';
  showTechnical: boolean = true;
  showCommercial: boolean = true;

  dataSource = new MatTableDataSource();

  originalDataSource: any [];

  maintainDataSearch: string = '';

  ngOnInit() {

    this.dataProduitService.getProduit().subscribe(data => {
      this.dataSource.data = this.originalDataSource = data.records;
    });

  }

  ngAfterViewInit() {
  }

  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-produit']);
  }

  SelectChangeHandlerModifier(product) {

    localStorage.setItem('softgateProduitID', product.produit_id);
    this.produitID = product.produit_id;

    localStorage.setItem('softgateProduitNom', product.nom_produit);
    this.nom = product.nom_produit;

    localStorage.setItem('softgateProduitDescription', product.description);
    this.texte = product.description;

    localStorage.setItem('softgateProduitLien', product.lien);
    this.webLink = product.lien;

    localStorage.setItem('softgateProduitActif', product.actif);
    this.actif = product.actif;

    localStorage.setItem('softgateProduitImage', product.image);
    this.imge = product.image;

    localStorage.setItem('softgateProduitLienCommerce', product.lien_commerce);
    this.commerceLink = product.lien_commerce;

    localStorage.setItem('softgateProduitVersion', product.version);
    this.produitVersion = product.version;

    localStorage.setItem('softgateProduitType', product.type);
    this.produitType = product.type;


    this.dataInteractionService.sendNomProduit(this.nom);
    this.dataInteractionService.sendDescriptionProduit(this.texte);
    this.dataInteractionService.sendLienProduit(this.webLink);
    this.dataInteractionService.sendActifProduit(this.actif);
    this.dataInteractionService.sendImgProduit(this.imge);
    this.dataInteractionService.sendLienCommerceProduit(this.commerceLink);
    this.dataInteractionService.sendProduitId(this.produitID);
    this.dataInteractionService.sendProduitVersion(this.produitVersion);
    this.dataInteractionService.sendProduitType(this.produitType);

    this.route.navigate(['../modification-produit']);
  }

  filteredProductsByType(type): any {
    const data = this.originalDataSource;
      if (type == 'technical') {
        this.showTechnical = !this.showTechnical
      } else {
        this.showCommercial = !this.showCommercial
      }
    this.dataSource.filteredData = this.dataSource.data = data.filter(x => {
        if (this.showTechnical && !this.showCommercial) {
          return x.type == 'technique';
        } else if (!this.showTechnical && this.showCommercial) {
          return x.type == 'commercial';
        } else if (!this.showTechnical && !this.showCommercial) {
          return false;
        }
        return true;
      });

      this.applyFilter();
    }

    applyFilter() {
      const filterValue = this.maintainDataSearch;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }


}
