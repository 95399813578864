import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataRuleService} from '../../utils/data-services/dataRule.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';

@Component({
  selector: 'app-modification-regle',
  templateUrl: './modification-regle.component.html',
  styleUrls: ['./modification-regle.component.scss']
})
export class ModificationRegleComponent implements OnInit {

  ruleEditForm: any;
  currentRule = {
    id: null,
    title: '',
    description_softnrg: '',
    description_kidi: '',
    first_field: '',
    first_comparison_operator: '',
    first_value: '',
    logical_operator: '',
    second_field: '',
    second_comparison_operator: '',
    second_value: '',
    is_active: '',
    is_recurrent: '',
    day_sign: '',
    day: '',
    date_notification: '',
    recurrent_start: '',
    color: '',
    priority: ''
  };
  currentRuleId: any;
  errors = {
    logic: {err: false, message: ''},
    day: {err: false, message: ''},
    title: {err: false, message: ''},
    description_softnrg: {err: false, message: ''},
    description_kidi: {err: false, message: ''},
    first_field: {err: false, message: ''},
    first_comparison_operator: {err: false, message: ''},
    first_value: {err: false, message: ''},
    date_notification: {err: false, message: ''},
    recurrent_start: {err: false, message: ''},
    concessions: {err: false, message: ''},
    logical_operator: {err: false, message: ''},
    second_fields: {err: false, message: ''},
    priority: {err: false, message: ''},
  };
  isFormValid = true;
  concessions = [];
  dropdownConcessionList = [];
  selectedConcession = [];
  dropdownSettings = {};

  constructor(
    private formBuilder: FormBuilder,
    private dataRuleService: DataRuleService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private dataConcessionService: DataConcessionService,
  ) {
    this.ruleEditForm = this.formBuilder.group(this.currentRule);
  }

  ngOnInit() {
    this.currentRuleId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getDataConcession();
    this.getRuleConcessions(this.currentRuleId);
    this.dataRuleService.requestGetRuleById(this.currentRuleId).subscribe(rule => {
      this.currentRule = rule;
      this.ruleEditForm = this.formBuilder.group(this.currentRule);
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  getDataConcession(): void {
    this.dataConcessionService.getConcession().subscribe(data => {
      const concessionList = [];
      for (const concession of data.records) {
        concessionList.push({ item_id: concession.concession_id, item_text: concession.nom_concession });
      }
      this.dropdownConcessionList = concessionList;
    });
  }

  getRuleConcessions(ruleId) {
    this.dataConcessionService.getConcessionByRuleId(ruleId).subscribe(data => {
      const selectedConcessions = [];
      const concessions = [];
      let i = 1;

      for (const concession of data.records ) {
        this.dataConcessionService.getConcessionById(concession.concession_id).subscribe(concessionData => {
          if (i < data.records.length) {
            const userConcession = { item_id: concessionData.concession_id, item_text: concessionData.nom_concession };
            selectedConcessions.push(userConcession);
            concessions.push(concessionData.concession_id);
            i++;
          } else {
            const userConcession = { item_id: concessionData.concession_id, item_text: concessionData.nom_concession };
            selectedConcessions.push(userConcession);
            concessions.push(concessionData.concession_id);
            this.selectedConcession = selectedConcessions;
            this.concessions = concessions;
          }
        });
      }
    });
  }

  onConcessionSelect(item: any) {
    this.concessions.push(item.item_id);
  }

  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessions.length; i++) {
      if (this.concessions[i] === item.item_id) {
        this.concessions.splice(i, 1);
      }
    }
  }

  setFieldToNull(): void {
    if (this.ruleEditForm.controls.logical_operator.value === '-') {
      this.currentRule.second_field = '-';
      this.currentRule.second_comparison_operator = '-';
      this.currentRule.second_value = '-';
    }
  }

  onSubmit(post): any {
    // reset errors //
    this.isFormValid = true;
    this.errors = {
      logic: {err: false, message: ''},
      day: {err: false, message: ''},
      title: {err: false, message: ''},
      description_softnrg: {err: false, message: ''},
      description_kidi: {err: false, message: ''},
      first_field: {err: false, message: ''},
      first_comparison_operator: {err: false, message: ''},
      first_value: {err: false, message: ''},
      date_notification: {err: false, message: ''},
      recurrent_start: {err: false, message: ''},
      concessions: {err: false, message: ''},
      logical_operator: {err: false, message: ''},
      second_fields: {err: false, message: ''},
      priority: {err: false, message: ''},
    };
    this.errors.title = post.title === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.description_softnrg = post.description_softnrg === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.description_kidi = post.description_kidi === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.first_field = post.first_field === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.first_comparison_operator = post.first_comparison_operator === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.first_value = post.first_value === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.day = post.day === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.date_notification = post.date_notification === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.recurrent_start = post.recurrent_start === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};
    this.errors.priority = post.priority === '' ? {err: true, message: 'Empty field'} : {err: false, message: ''};

    // tslint:disable-next-line:triple-equals
    post.is_recurrent = post.is_recurrent == false ? 0 : 1;
    // tslint:disable-next-line:triple-equals
    post.is_active = post.is_active == false ? 0 : 1;
    // tslint:disable-next-line:triple-equals
    post.recurrent_start = post.recurrent_start == false ? 0 : 1;
    post.id = this.currentRuleId;

    if (post.day <= 0 && post.is_recurrent === 1) {
      this.errors.logic = {err: true, message: 'Récurrence de ' + post.day + ' jour(s) impossible.'};
    }
    if (this.concessions.length === 0) {
      this.errors.concessions = {err: true, message: 'vous devez choisir au moins une concession.'};
    }
    if (
      post.logical_operator === '-' && post.second_field !== '-' ||
      post.logical_operator === '-' && post.second_comparison_operator !== '-' ||
      post.logical_operator === '-' && post.second_value !== '-'
    ) {
      this.errors.logic = {err: true, message: 'Vous devez choisir un opérateur logique.'};
      this.errors.logical_operator = {err: true, message: 'Champ requis'};
    }
    if (
      post.logical_operator !== '-' && post.second_field === '-' ||
      post.logical_operator !== '-' && post.second_comparison_operator === '-' ||
      post.logical_operator !== '-' && post.second_value === '-'
    ) {
      this.errors.logic = {err: true, message: 'Vous devez choisir les champs n°2.'};
    }

    // check if form is valid //
    for (const error of Object.entries(this.errors)) {
      if (error[1].err) {
        this.isFormValid = false;
      }
    }

    if (this.isFormValid) {
      this.dataRuleService.requestUpdateRule(post, post.id).subscribe(response => {
        this.dataRuleService.requestGetConcessionRuleByRuleId(this.currentRuleId).subscribe(rules => {
          for (const rule of rules.records) {
            this.dataRuleService.requestDeleteConcessionRule(rule.id).subscribe();
          }
          for (const concession of this.concessions) {
            this.dataRuleService.requestCreateConcesssionRule(this.currentRuleId, concession).subscribe();
          }
        });
        this.route.navigate(['/gestion-regle']);
      });
    }
  }
}
