import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-configuration-section',
  templateUrl: './create-configuration-section.component.html',
  styleUrls: ['./create-configuration-section.component.scss']
})
export class CreateConfigurationSectionComponent implements OnInit {
  configurationType = {
    name: null,
    order: null
  };
  constructor( private dataService: DataConfigurationConcessionService, private router: Router ) { }

  ngOnInit() {
  }
  onCreate() {
    this.dataService.createConfigurationType(this.configurationType).subscribe(result => {
      this.router.navigate(['/gestion-section-configuration']);
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-section-configuration']);
  }
}
