import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import {Router} from '@angular/router';
import {DataRuleService} from '../utils/data-services/dataRule.service';
import {MatTableDataSource} from '@angular/material/table';
import swal from 'sweetalert2';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';

export interface RulesElement {
  id: number;
  firstField: string;
  firstCompOperator: string;
  firstValue: string;
  logicalOperator: boolean;
  secondField: string;
  secondCompOperator: string;
  secondValue: string;
  isActive: boolean;
  isRecurrent: boolean;
  day: number;
  dateNotification: any;
  endDateNotification: any;
  recurrentStart: number;
}

const ELEMENT_DATA: RulesElement[] = [];

@Component({
  selector: 'app-gestion-regle',
  templateUrl: './gestion-regle.component.html',
  styleUrls: ['./gestion-regle.component.scss']
})
export class GestionRegleComponent implements OnInit {

  displayedColumns: string[] = [
    'title',
    'firstField',
    'firstCompOperator',
    'firstValue',
    'logicalOperator',
    'secondField',
    'secondCompOperator',
    'secondValue',
    'dateNotification',
    'recurrenceStart',
    'recurrence',
    'day',
    'isActive',
    'priority',
    'edit',
    'delete'
  ];
  dataSource: any;
  isDataSourceExists = false;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private dataRuleService: DataRuleService,
    private dataConcessionService: DataConcessionService
  ) { }

  ngOnInit(): void {
    ELEMENT_DATA.splice(0, ELEMENT_DATA.length);
    this.dataRuleService.requestGetAllRules().subscribe(data => {
      for (const rule of data.records) {
        let i = 0;
        for (const [keyName, element] of Object.entries(rule)) {
          const ruleKey = `${keyName}`;
          rule[ruleKey] = this.getSimplifyExpression(`${element}`);
          i++;
        }
        this.dataConcessionService.getConcessionByRuleId(rule.id).subscribe(response => {
          rule.concessions = '';
          for (const concessionRule of response.records) {
            this.dataConcessionService.getConcessionById(concessionRule.concession_id).subscribe(concession => {
              rule.concessions += concession.nom_concession + ',';
            });
          }
        });
        ELEMENT_DATA.push(rule);
        this.isDataSourceExists = true;
      }
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.dataSource.data.sort((a: any, b: any) => {
        if (a.priority < b.priority) {
          return -1;
        } else if (a.priority > b.priority) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }

  editRule(id): void {
    this.route.navigate(['/modification-regle/' + id]);
  }

  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataRuleService.requestDeleteConcessionRule(id).subscribe();
        this.dataRuleService.requestDeleteRule(id).subscribe();
        swal.fire('La règle a bien été supprimée !', '', 'success').then(res => {
          window.location.reload();
        });
      }
    });
  }

  getSimplifyExpression(expression: string) {
    switch (expression) {
      case 'DTSAALLOCATIONSTART':
        return 'Date de réception';
      case 'DREPAIRENDDATE':
        return 'Date de restitution';
      case 'dateNow':
        return 'Aujourd\'hui';
      case 'statut_local':
        return 'Statut';
      case 'lt':
        return '<';
      case 'le':
        return '<=';
      case 'eq':
        return '=';
      case 'neq':
        return '!=';
      case 'ge':
        return '>=';
      case 'gt':
        return '>';
      case 'OR':
        return 'OU';
      case 'AND':
        return 'ET';
      case '':
        return '-';
      default:
        return expression;
    }
  }
}
