import { Component, OnInit } from '@angular/core';
import {DataCampaignDiscountsService} from '../utils/data-services/data-campaign-discounts.service';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

@Component({
  selector: 'app-gestion-campaign-discounts',
  templateUrl: './gestion-campaign-discounts.component.html',
  styleUrls: ['./gestion-campaign-discounts.component.scss']
})
export class GestionCampaignDiscountsComponent implements OnInit {

  dataSource = new MatTableDataSource();

  constructor( private dataService: DataCampaignDiscountsService, private route: Router ) {}
  displayedColumns: string[] = ['name', 'limit', 'count_actual_app', 'count_actual_site', 'start_date', 'end_date', 'message', 'requirement', 'active', 'brand', 'debug', 'modifier'];

  ngOnInit() {
    this.dataService.getCampaignDiscounts().subscribe(campaignDiscounts => {
      this.dataSource.data = campaignDiscounts;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  frenchDate(date: any) {
    return moment(date).format('L');
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-campagne-sms']);
  }
  SelectChangeHandlerModifier(campaignDiscountsId) {
    this.route.navigate(['../modification-campagne-sms/' + campaignDiscountsId]);
  }

}
