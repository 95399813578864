import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataReceptionOptionsMappingService extends HandleErrorService {
  getReceptionOptionsMapping() {
    const apiReceptionOptionsMapping = environment.pngoApi + 'api/backoffice/autonomous-reception-agencies-options-map';
    return this.http.get<any>(apiReceptionOptionsMapping, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createReceptionOptionsMapping(body) {
    const apiReceptionOptionsMapping = environment.pngoApi + '/api/backoffice/autonomous-reception-agencies-options-map/create';
    return this.http.post<any>(apiReceptionOptionsMapping, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateReceptionOptionsMapping(body) {
    const apiReceptionOptionsMapping = environment.pngoApi + '/api/backoffice/autonomous-reception-agencies-options-map/update';
    return this.http.put<any>(apiReceptionOptionsMapping, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getReceptionOptionsMappingById(parameter) {
    const apiReceptionOptionsMapping = environment.pngoApi + 'api/backoffice/autonomous-reception-agencies-options-map/' + parameter;
    return this.http.get<any>(apiReceptionOptionsMapping, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  // getConcession() {
  //   const apiBackupConcession = environment.pngoApi + 'api/backoffice/concession';
  //   return this.http.get<any>(apiBackupConcession, environment.httpOptions).pipe(
  //     catchError(this.handleError)
  //   );
  // }
}
