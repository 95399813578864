import { Injectable } from '@angular/core';
import { HttpErrorResponse  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataCreationService extends HandleErrorService {

  getNomConcessionSelected(idConcession: any) {
    // tslint:disable-next-line:max-line-length
    const apiConcession = environment.globalUrlDomaine + '/records/concession?include=nom_concession&filter=concession_id,eq,' + idConcession;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getNomProduitSelected(idProduit: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?include=nom_produit&filter=produit_id,eq,' + idProduit;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getNomParametreSelected(idParametre: any) {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre?include=nom_parametre&filter=parametre_id,eq,' + idParametre;
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public createValeur(text: any, date1: any, date2: any, idparametre: any, idConcession: any, marqueId: any) {
    return new Promise((resolve, reject) => {
      let dateF: any;
      const apiValeur = environment.globalUrlDomaine + '/records/valeur';
      const dateD = this.datePipe.transform(date1, 'yyyy-MM-dd');
      const brandId = marqueId == null ? 1 : marqueId;
      if (date2 == null || date2 === 'undefined') {
        dateF = '9999-01-01';
      } else {  dateF = this.datePipe.transform(date2, 'yyyy-MM-dd');
      }
      const body = {valeur: text, date_debut_param: dateD, date_fin_param: dateF, parametre_id: idparametre, concession_id: idConcession, marque_id: brandId};
      this.http.post<number>(apiValeur, body, environment.httpOptions)
        .toPromise()
        .then(
          (response: any) => {
            resolve(response);
          },
          error => {
            this.handleError(error);
          }
        );
    });
  }

  public getMarque() {
    const api = environment.globalUrlDomaine + '/records/marque';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public createMarqueValeur(idMarque: number, idValeur: unknown): Observable<HttpErrorResponse> {
    const api = environment.globalUrlDomaine + '/records/marque_valeur';
    const body = {marque_id: idMarque, valeur_id: idValeur};
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
