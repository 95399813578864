<h2>Modification Formation</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px;margin: auto;">
    <form [formGroup]="formationCreationForm" (ngSubmit)="onSubmit(formationCreationForm.value)" class="formation-form">
      <div class="form-row w-75">
        <div class="form-group">
          <label for="type">Ligne titre 1 (ou type):</label>
          <input type="text" class="form-control" [value]="currentFormation?.type" name="type" id="type" formControlName="type">
          <small *ngIf="error.type" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="title">Ligne titre 2 (Titre):</label>
          <input type="text" class="form-control" [value]="currentFormation?.title" name="title" id="title" formControlName="title">
          <small *ngIf="error.title" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-50">
        <div class="form-group">
          <label for="product">Produit:</label>
          <select name="product" id="product" [ngModel]="currentFormation?.product" (change)="resetTuto()" class="form-control" formControlName="product">
            <option value="9999">Non Renseigné</option>
            <option *ngFor="let product of products" [value]="product.produit_id">{{product.nom_produit}}</option>
          </select>
        </div>
        <div class="form-group" *ngIf="currentFormation?.product != 9999">
          <mat-radio-group id="tuto" formControlName="tuto" class="ml-2">
            <mat-radio-button [value]="0" [checked]="!currentFormation?.tuto" class="mr-2">Brochure</mat-radio-button>
            <mat-radio-button [value]="1" [checked]="currentFormation?.tuto">Tutoriel</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="image">Image:</label>
          <input type="file" class="form-control" (change)="setImage($event)" name="image" id="image" formControlName="image">
          <small class="input-error mb-3 ml-2" style="color: grey">Veuillez choisir un fichier au format .png, .jpg ou .jpeg</small>
        </div>
      </div>
      <div class="form-row w-75 mt-3">
        <div class="form-group">
          <label for="image">PDF:</label>
          <input type="file" (change)="setPdf($event)" class="form-control" name="pdf" id="pdf" formControlName="pdf">
          <small *ngIf="error.pdfFormat" class="input-error" style="color: red">Veuillez choisir un fichier au format .pdf</small>
        </div>
      </div>
      <div class="form-row w-75 d-none">
        <div class="form-group">
          <label for="description">Description:</label>
          <textarea class="form-control" [value]="currentFormation?.description" name="description" id="description" formControlName="description"></textarea>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="notification">Détails:</label>
          <textarea class="form-control" [value]="currentFormation?.notification" name="notification" id="notification" formControlName="notification"></textarea>
        </div>
      </div>
      <div class="form-row w-25">
        <div class="form-group checkbox-group">
          <label for="is_active">Active:</label>
          <input type="checkbox" class="form-control checkbox" [checked]="currentFormation?.is_active" name="is_active" id="is_active" formControlName="is_active">
        </div>
        <div>
          <button type="submit" class="btn btn100px btn-dark btn-create p-2 mr-2">Modifier</button>
        </div>
        <div>
          <button class="btn btn100px btn-light border-dark btn-create p-2" type="button" routerLink="/gestion-formation">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="d-flex justify-content-center mt-5">
  <img id="image-preview" [src]="currentFormation?.image" height="500" alt="">
</div>
