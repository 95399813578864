import { Component, OnInit } from '@angular/core';
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";
import {DataReceptionOptionsMappingService} from "../../utils/data-services/data-reception-options-mapping.service";
import {Router} from "@angular/router";
import {DataRentDealershipsAgenciesService} from "../../utils/data-services/data-rent-dealerships-agencies.service";
import {DataOperationService} from "../../utils/data-services/data-operation.service";
import {DataMobiliteService} from "../../utils/data-services/data-mobilite.service";
import {DataSnapshotScenarioService} from "../../utils/data-services/data-snapshot-scenario.service";
import {DataCampaignDiscountsService} from "../../utils/data-services/data-campaign-discounts.service";

@Component({
  selector: 'app-create-rent-dealerships-agencies',
  templateUrl: './create-rent-dealerships-agencies.component.html',
  styleUrls: ['./create-rent-dealerships-agencies.component.scss']
})
export class CreateRentDealershipsAgenciesComponent implements OnInit {
  rentDealershipAgency = {
    concession: null,
    dealership_id: null,
    brand_id: null,
    ucar_agency_id: null,
    typologie_client: null
  };
  concessionArray = [];
  brandArray = [];
  constructor(private dataServiceBrand: DataCampaignDiscountsService, private dataServiceConcession: DataConcessionService, private dataService: DataRentDealershipsAgenciesService, private route: Router ) { }

  ngOnInit() {
    this.getAllConcession();
    this.getAllBrand();
  }
  createRent() {
    this.dataService.createRentDealershipAgency(this.rentDealershipAgency).subscribe(result => {
      this.route.navigate(['../gestion-rent']);
    });
  }
  getAllConcession() {
    this.dataServiceConcession.getConcession().subscribe(result => {
      this.concessionArray = result['records'];
    });
  }
  getAllBrand() {
    this.dataServiceBrand.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-rent']);
  }
}
