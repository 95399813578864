import { Component, OnInit } from '@angular/core';
import { DataModificationService } from '../../utils/data-services/dataModification.service';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import swal from 'sweetalert2';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DataConsultationService} from '../../utils/data-services/dataConsultation.service';

@Component({
  selector: 'app-modification',
  templateUrl: './modification.component.html',
  styleUrls: ['./modification.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ModificationComponent implements OnInit {

  numberIdConcession: any;
  numberIdProduit: any;
  numberIdParametre: any;
  numberIdValeur: any;
  trValeur: any;
  trDateDebut: any;
  trDateFin: any;
  nomConcession: any;
  nomProduit: any;
  nomParametre: any;
  products: any;
  concessions: any;
  parameters: any;
  datepickerData2: any;
  datepickerData1: any;
  textId: any;
  startDate: any;
  endDate: any;
  arrayMarque: any;
  selectedMarqueId: number = null;
  marqueValeur: any;
  marqueValeurID: any;
  selectedMarqueValeurMarqueId: any;

  // tslint:disable-next-line:max-line-length
  constructor(private dataModificationService: DataModificationService,
              private dataConsultationService: DataConsultationService,
              private dataInteractionService: DataInteractionService,
              private datePipe: DatePipe,
              private route: Router,
              private _adapter: DateAdapter<any>) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.dataInteractionService.idConcession.subscribe(num1 => this.numberIdConcession = num1);
    this.dataInteractionService.idProduit.subscribe(num2 => this.numberIdProduit = num2);
    this.dataInteractionService.idParametre.subscribe(num3 => this.numberIdParametre = num3);
    this.dataInteractionService.valeur1.subscribe(num4 => this.trValeur = num4);
    this.dataInteractionService.dateDebut.subscribe(num5 => this.trDateDebut = num5);
    this.dataInteractionService.dateFin.subscribe(num6 => this.trDateFin = num6);
    this.dataInteractionService.idValeur.subscribe(num7 => this.numberIdValeur = num7);
    this.dataInteractionService.idMarque.subscribe(num8 => this.marqueValeurID = num8);

    // tslint:disable-next-line:max-line-length
    this.dataModificationService.getNomConcessionSelected(this.numberIdConcession).subscribe(data1 => { this.nomConcession = data1.records; });
    this.dataModificationService.getNomProduitSelected(this.numberIdProduit).subscribe(data2 => { this.nomProduit = data2.records; });
    this.dataModificationService.getNomParametreSelected(this.numberIdParametre).subscribe(data3 => { this.nomParametre = data3.records; });

    // tslint:disable-next-line:max-line-length
    /* maybe there is a problem here cause the values of datepickerData1 and datepickerData2 always (even if i change their values within HTML page) equale to trDateDebut and trDateFin */
    this.datepickerData1 = this.trDateDebut;
    this.datepickerData2 = this.trDateFin;
    this.textId = this.trValeur;

    this.dataModificationService.getMarque().subscribe(dataMarque => { this.arrayMarque = dataMarque.records; });
    this.dataModificationService.getMarqueValeur(this.numberIdValeur).subscribe(resMV => {
      this.marqueValeur = resMV.records;
      this.dataModificationService.getValeur(this.numberIdValeur).subscribe(res => {
        this.selectedMarqueValeurMarqueId = res.records[0].marque_id;
        this.selectedMarqueId = res.records[0].marque_id;
        // this.marqueValeur = dataMarqueValeur.records[0];
        // this.selectedMarqueId = this.selectedMarqueValeurMarqueId;
      });
    });

    /* Fill DropDownLists */
    this.dataConsultationService.getProduit().subscribe(dataProduit => {
      this.products = dataProduit.records;
    });
    this.dataConsultationService.getParametre().subscribe(dataParametre => {
      this.parameters = dataParametre.records;
    });
    this.dataConsultationService.getConcession().subscribe(dataConcession => {
      this.concessions = dataConcession.records;
    });
    /* End */
  }

  SelectChangeHandlerModifier() {
    this.startDate = this.datePipe.transform(this.datepickerData1, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.datepickerData2, 'yyyy-MM-dd');
    if (this.startDate > this.endDate) {
      swal.fire({
        title: 'Êtes-vous sûr!',
        text: 'La date de fin doit être supérieure à la date de début.',
        type: 'warning',
        confirmButtonText: 'Ok'
      });
    } else {
      // tslint:disable-next-line:max-line-length radix
      this.dataModificationService.updateValeur(this.textId, this.startDate, this.endDate, this.numberIdValeur, this.marqueValeurID).then(
        response => {
          if (response) {
            swal.fire({
              title: 'Success!',
              text: 'La valeur a bien été Modifiée',
              type: 'success',
              confirmButtonText: 'Ok'
            });
            this.route.navigate(['../consultation']);
          }
        });
    }
  }

  SelectChangeHandlerMarque($event: any) {
    this.marqueValeurID = $event.target.value;
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../consultation']);
  }

  onDeleteValeur() {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
       if (this.marqueValeur.length > 0) {
         this.dataModificationService.deleteMarqueValeur(this.marqueValeur[0].id).subscribe(res => {
           this.deleteValue();
         }, error => {
           swal.fire({
             title: 'Erreur',
             text: 'Un problème est survenu lors de la suppression.',
             type: 'error',
             confirmButtonText: 'Ok'
           });
         });
       } else {
         this.deleteValue();
       }
      }
    });
  }
  deleteValue(): any {
    this.dataModificationService.deleteValeur(this.numberIdValeur).subscribe(data => {
      swal.fire('La valeur a bien été supprimée!', '', 'success').then(response => {
        this.route.navigate(['../consultation']);
      });
    }, error => {
      swal.fire({
        title: 'Erreur',
        text: 'Un problème est survenu lors de la suppression.',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
}

