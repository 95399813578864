import { Injectable } from '@angular/core';
import {HandleErrorService} from "../handleError.service";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataDealershipSynchronizationService  extends HandleErrorService {

  dealershipSynchronization(parameter) {
    const apiSynchronization = environment.pngoApi + 'api/backoffice/dealership/synchronization/' + parameter;
    return this.http.get<any>(apiSynchronization, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
