import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataBackupConcessionService extends HandleErrorService {
  getBackupConcession() {
    const apiBackupConcession = environment.pngoApi + 'api/backoffice/monitoring-backup-dealership';
    return this.http.get<any>(apiBackupConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createBackupConcession(body) {
    const apiBackupConcession = environment.pngoApi + '/api/backoffice/monitoring-backup-dealership/create';
    return this.http.post<any>(apiBackupConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateBackupConcession(body) {
    const apiBackupConcession = environment.pngoApi + '/api/backoffice/monitoring-backup-dealership/update';
    return this.http.put<any>(apiBackupConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getBackupConcessionById(parameter) {
    const apiBackupConcession = environment.pngoApi + 'api/backoffice/monitoring-backup-dealership/' + parameter;
    return this.http.get<any>(apiBackupConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
