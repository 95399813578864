<!-- page title area end -->
<div class="main-content-inner mt-2">
  <h1 class="text-center">Maintenances des services Soft-Nrg France</h1>
  <div class="text-center">Déclenchement manuel des tâches planifiées</div>
  <div class="inactive text-center mb-4"><small>Vous devez être connecté au VPN pour déclencher les tâches.</small></div>
  <div class="d-flex flex-column align-items-center mt-4">
    <h4>UCAR</h4>
    <button class="btn btn-maintain" (click)="onClickButton('syncUcar')">Synchronisation des véhicules</button>
  </div>
  <div class="d-flex flex-column align-items-center mt-4">
    <h4>PNGO</h4>
    <button class="btn btn-maintain" (click)="onClickButton('anonymizationPngo')">Anonymisation des informations</button>
    <button class="btn btn-maintain" (click)="onClickButton('fixFirstDatePngo')">
      Correction des concessions pour le 1er créneau
      <br>
      <small class="text-danger">Attention : peut générer des problèmes dans l'application Qlik si effectuée lors d'un après-midi.</small>
    </button>
    <button class="btn btn-maintain" (click)="onClickButton('ipsMonitoringPngo')">
      Gestion du monitoring IPS<br>
      <small class="text-danger">Attention : peut générer des alertes Xploit indésirables</small>
    </button>
  </div>
</div>
<div class="loader-maintenance" *ngIf="isLoading">
  <div class="loader-dialog">
    <h4 class="m-3 text-center">{{titleLoader}}</h4>
    <div class="loader-container">
      <app-spinner></app-spinner>
    </div>
    <button class="btn btn-outline-dark m-3">Charger en arriere-plan</button>
  </div>
</div>
