<div class="inputs-section">
  <div class="box2 col-sm-3" >
    <ng-container
      *ngIf = "concessionID == null || concessionID == 'all' || this.concessionID === 'undefined'; then comparerConcessionTrue; else comparerConcessionFalse">
    </ng-container>
    <ng-template #comparerConcessionFalse>
      <label>Concessions</label>
      <ng-select [ngModel]="concessionID">
        <ng-option *ngFor = "let con of nomConcession" [value]="concessionID">
          {{con.nom_concession}}
        </ng-option>
      </ng-select>
    </ng-template>
    <ng-template #comparerConcessionTrue>
        <label>Concessions</label>
        <ng-select (change)="SelectChangeHandlerConcession($event)">
          <ng-option *ngFor = "let concession of variableConcession" [value]="concession.concession_id">
            {{concession.nom_concession}}
          </ng-option>
        </ng-select>
    </ng-template>
  </div>

  <div class="box2 col-sm-3">
    <ng-container
      *ngIf = "produitID == null || produitID == 'all' || produitID === 'undefined'; then comparerProduitTrue; else comparerProduitFalse">
    </ng-container>
    <ng-template #comparerProduitFalse>
      <label>Produits</label>
      <ng-select [ngModel]="produitID">
        <ng-option *ngFor = "let pro of nomProduit" [value]="produitID">
          {{pro.nom_produit}}
        </ng-option>
      </ng-select>
    </ng-template>
    <ng-template #comparerProduitTrue>
        <label>Produits</label>
        <ng-select (change)="SelectChangeHandlerProduit($event)">
          <ng-option *ngFor = "let produit of variableProduit" [value]="produit.produit_id">
            {{produit.nom_produit}}
          </ng-option>
        </ng-select>
    </ng-template>
  </div>

  <div class="box2 col-sm-3">
    <ng-container
      *ngIf = "parametreID == null || parametreID == 'all' || parametreID === 'undefined'; then comparerParametreTrue; else comparerParametreFalse">
    </ng-container>
    <ng-template #comparerParametreFalse>
      <label>Paramètres</label>
      <ng-select [ngModel]="parametreID">
        <ng-option *ngFor = "let par of nomParametre" [value]="parametreID">
          {{par.nom_parametre}}
        </ng-option>
      </ng-select>
    </ng-template>
    <ng-template #comparerParametreTrue>
        <label>Paramètres</label>
        <ng-select (change)="SelectChangeHandlerParametre($event)">
          <ng-option *ngFor = "let parametre of variableParametre" [value]="parametre.parametre_id">
            {{parametre.nom_parametre}}
          </ng-option>
        </ng-select>
    </ng-template>
  </div>

  <div class="box3 col-sm-2">
    <label>Marque</label>
    <select class="form-control" (change)="SelectChangeHandlerMarque($event)">
      <option *ngFor = "let marque of arrayMarque" style="text-align: center" [value]="marque.id">
        {{marque.nom}}
      </option>
    </select>
  </div>
</div>

<div class="container">
  <div class="mt-2 value-container">
    <div class="row text-value">
      <div class="col-12 col-md-7">
        <label>Valeur</label>
      </div>
      <div>Nb Caractères: {{textId?.length}}</div>
    </div>
    <div sm="10">
      <textarea [(ngModel)]="textId" placeholder="Insérer la valeur" rows="12" cols="100" no-auto-shrink></textarea>
    </div>
  </div>
</div>

<div class="date">
  <div class="dates-container">
    <div class="box41 col-6 col-md-3 ">
      <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
        <mat-label>Date de Début</mat-label>
        <input matInput [(ngModel)]="datepickerData1" [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="box51 col-6 col-md-3 ">
      <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
        <mat-label>Date de Fin</mat-label>
        <input matInput [(ngModel)]="datepickerData2" [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="btn-section">
  <div class="row btn">
    <div class="btn1">
      <button (click)="SelectChangeHandlerCreer()" class="btn btn-success">Modifier</button>
    </div>

    <div class="btn2">
      <button (click)="SelectChangeHandlerAnnuler()" class="btn btn-danger">Annuler</button>
    </div>
  </div>
</div>
