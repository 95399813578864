<h1 class="text-center mt-2" id="group-manager-top-page">Gestion des groupes</h1>

<div class="form-add-group mt-4">
  <h3 id="group-create-update-title">Ajouter un groupe : </h3>
  <form [formGroup]="groupForm" (ngSubmit)="onSubmit()"
        class="d-flex group-form mr-5">
    <mat-form-field style="width: 400px" class="mr-5 mt-4">
      <mat-label>Nom du groupe</mat-label>
      <input matInput [formControl]="groupName">
    </mat-form-field>
    <div class="row">
      <button type="submit" class="btn btn-dark d-flex align-items-center btn-create p-2 ml-4'" id="group-create-update-btn">
        <mat-icon class="mr-2">add_circle</mat-icon>
        Ajouter le groupe
      </button>
      <button (click)="onCancel()" *ngIf="isEditing" class="ml-2 btn btn-outline-dark d-flex align-items-center btn-create p-2 ml-4'" id="group-cancel-btn">
        Annuler
      </button>
    </div>
  </form>
</div>

<div class="user-table">
  <div class="mt-4 col-12 text-right d-flex justify-content-end align-items-center">
    <mat-form-field style="width: 250px">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>
<table class="mt-3 table table-striped table-bordered table-group">
  <thead>
    <th (click)="sortId()" style="width: 100px" class="pointer">ID</th>
    <th (click)="sortName()" class="pointer">Nom</th>
    <th style="width: 50px">Modifier</th>
    <th style="width: 50px">Supprimer</th>
  </thead>
  <tbody>
    <tr *ngFor="let group of groups.filteredData">
      <td class="text-center">{{group.groupe_id}}</td>
      <td class="text-center">{{group.nom}}</td>
      <td class="text-center" style="cursor: pointer" (click)="onEdit(group.groupe_id, group.nom)"><mat-icon>edit</mat-icon></td>
      <td class="text-center" style="cursor: pointer; color: red;" (click)="onDelete(group.groupe_id)"><mat-icon>delete</mat-icon></td>
    </tr>
  </tbody>
</table>
