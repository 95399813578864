import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, NgForm, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AuthenticationService} from '../../utils/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import swal from 'sweetalert2';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  error = {err: false, code: 200};
  resetPwdForm: any;
  isSubmited = false;

  pwd: FormControl;
  isPwdValid = false;
  confirmPwd: FormControl;
  isConfirmPwdValid = false;
  token: any;

  constructor(
    private authenticationService: AuthenticationService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.resetPwdForm = this.formBuilder.group({
      password: '',
      confirmPassword: ''
    });
  }

  ngOnInit() {
    this.token = this.activeRoute.snapshot.paramMap.get('token');
    this.pwd = new FormControl('', [Validators.required, Validators.minLength(12), Validators.pattern(new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[+ù/:;£{}()\[\]\\"'~°|#?!@$%^&*.-]).+/))]);
    this.confirmPwd = new FormControl('', [Validators.required, this.checkSecondPassword(this.pwd)]);
    this.resetPwdForm = this.formBuilder.group({
      password: this.pwd,
      confirmPassword: this.confirmPwd,
    });
  }

  checkSecondPassword(password): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value !== '' && value != null && value === password.value) {
        return null;
      }
      return { checkPassword: false };
    };
  }

  onSubmit(post) {
    this.isSubmited = true;
    this.authenticationService.resetPassword(post, this.token).subscribe(data => {
      if (data.statusCode === 200) {
        this.route.navigate(['/login']);
      } else {
        this.isSubmited = false;
        swal.fire({
                title: 'Oops...!',
                text: data.content,
                type: 'error',
                confirmButtonText: 'Ok'
              });
      }
    });
  }

  onCheckPwd() {
    if (this.resetPwdForm.controls.password.status === 'VALID') {
      this.isPwdValid = true;
    } else {
      this.isPwdValid = false;
    }
    this.isConfirmPwdValid = false;
  }
  onCheckConfirmPwd() {
    if (this.resetPwdForm.controls.confirmPassword.status === 'VALID') {
      this.isConfirmPwdValid = true;
    } else {
      this.isConfirmPwdValid = false;
    }
  }

  Annuler() {
    this.route.navigate(['../login']);
  }

}
