import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-marques',
  templateUrl: './gestion-marques.component.html',
  styleUrls: ['./gestion-marques.component.scss']
})
export class GestionMarquesComponent implements OnInit {
  brands: MatTableDataSource<any>;
  brandForm: FormGroup;
  brandName: FormControl;
  isEditing = false;
  editedBrandId: number;

  constructor(
    private dataService: DataCreationConcessionService,
    private formBuilder: FormBuilder
  ) {
    this.brands = new MatTableDataSource<any>();
    this.brandName = new FormControl(null);
    this.brandForm = this.formBuilder.group({
      brandName: null
    });
  }

  ngOnInit() {
    this.getBrands();
  }
  getBrands() {
    this.dataService.getBrands().subscribe(brands => {
      this.brands.data = brands.records;
    });
  }
  onSubmit() {
    if (!this.isEditing) {
      this.dataService.createBrand({nom: this.brandName.value}).subscribe(res => {
        swal.fire({
          title: 'Success!',
          text: 'La marque ' + this.brandName.value + ' a bien été créée',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getBrands();
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création de la marque',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    } else {
      this.dataService.updateBrand({nom: this.brandName.value}, this.editedBrandId).subscribe(res => {
        swal.fire({
          title: 'Success!',
          text: 'La marque ' + this.brandName.value + ' a bien été modifiée',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getBrands();
        document.getElementById('brand-create-update-title').innerText = 'Ajouter une marque';
        document.getElementById('brand-create-update-btn').innerText = 'Ajouter la marque';
        this.brandName = new FormControl(null);
        this.isEditing = false;
        this.editedBrandId = null;
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la modification de la marque',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onEdit(id, brandName) {
    this.isEditing = true;
    this.editedBrandId = id;
    this.brandName = new FormControl(brandName);
    scroll(0, 0);
    document.getElementById('brand-create-update-btn').innerText = 'Modifier la marque';
    document.getElementById('brand-create-update-title').innerText = 'Modifier une marque';
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataService.deleteBrand(id).subscribe(res => {
          swal.fire({
            title: 'Success!',
            text: 'La marque ' + this.brandName.value + ' a bien été supprimée',
            type: 'success',
            confirmButtonText: 'Ok'
          });
          this.getBrands();
        }, error => {
          swal.fire({
            title: 'Erreur!',
            text: 'Un problème est survenu lors de la suppression de la marque',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  onCancel() {
    document.getElementById('brand-create-update-title').innerText = 'Ajouter une marque';
    document.getElementById('brand-create-update-btn').innerText = 'Ajouter la marque';
    this.brandName = new FormControl(null);
    this.isEditing = false;
    this.editedBrandId = null;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.brands.filter = filterValue.trim();
  }
}
