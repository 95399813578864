import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataMobiliteService} from "../utils/data-services/data-mobilite.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../utils/data-services/dataConcession.service";
import swal from "sweetalert2";

@Component({
  selector: 'app-gestion-mobilite',
  templateUrl: './gestion-mobilite.component.html',
  styleUrls: ['./gestion-mobilite.component.scss']
})
export class GestionMobiliteComponent implements OnInit {
  mobiliteId: any;
  dataSource = new MatTableDataSource();
  concessionName: any;

  constructor(private dataService: DataMobiliteService, private activeRoute: ActivatedRoute, private route: Router, private dataServiceConcession: DataConcessionService) { }
  displayedColumns: string[] = ['icon', 'sofNet_id', 'nom', 'prix', 'ordre', 'brand_authorized', 'description', 'actif', 'is_external_fleet', 'is_waiting', 'modifier'];

  ngOnInit() {
    this.mobiliteId = Number(this.activeRoute.snapshot.paramMap.get('idConcession'));
    this.getMobilite();
    this.getConcessionById();
  }
  getMobilite() {
    this.dataService.getMobiliteByConcession(this.mobiliteId).subscribe(result => {
      this.dataSource.data = result;
    });
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.mobiliteId).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-mobilite/' + this.mobiliteId]);
  }
  SelectChangeHandlerModifier(idMobilite) {
    this.route.navigate(['../update-mobilite/' + idMobilite]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
}
