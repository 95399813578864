import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-update-configuration-section',
  templateUrl: './update-configuration-section.component.html',
  styleUrls: ['./update-configuration-section.component.scss']
})
export class UpdateConfigurationSectionComponent implements OnInit {
  id: any;
  configurationType = {
    name: null,
    order: null
  };
  constructor(private dataService: DataConfigurationConcessionService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getConfigurationType();
  }
  onUpdate() {
    this.dataService.updateConfigurationType(this.configurationType, this.id).subscribe(result => {
      this.router.navigate(['/gestion-section-configuration']);
    });
  }
  getConfigurationType() {
    this.dataService.getConfigurationTypeById(this.id).subscribe(result => {
      this.configurationType = result;
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-section-configuration']);
  }
}
