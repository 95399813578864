import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {DataCreationConcessionService} from '../../utils/data-services/dataCreation-concession.service';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';
import {DataFonctionService} from '../../utils/data-services/data-fonction.service';
import {DataContactService} from '../../utils/data-services/dataContact.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-creation-contact',
  templateUrl: './creation-contact.component.html',
  styleUrls: ['./creation-contact.component.scss']
})
export class CreationContactComponent implements OnInit {
  contact = {
    nom: '',
    prenom: '',
    tel_fix: '',
    tel_mobile: '',
    email: '',
    commentaire: null,
    concessions_id: [],
    fonction_id: null,
  };
  n = -1;
  dropdownConcessionSettings = {};
  dropdownConcessionList = [];
  concessionSelected = [];
  dropdownFonctionSettings = {};
  dropdownFonctionList = [];
  fonctionSelected = [];
  formError = false;
  contactDuplicateList = [];

  // tslint:disable-next-line:max-line-length
  constructor(public dialog: MatDialog, private dataConcessionService: DataConcessionService, private dataInteractionService: DataInteractionService, private dataContactService: DataContactService, private dataFonctionService: DataFonctionService, private route: Router) { }

  ngOnInit() {
    this.dropdownConcessionSettings = {
      singleSelection: false,
      idField: 'concession_id',
      textField: 'nom_concession',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownFonctionSettings = {
      singleSelection: true,
      idField: 'id_conf',
      textField: 'valeur',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.getConcessions();
    this.getFonctions();
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-contact']);
  }

  getConcessions(callback?): void {
    this.dataContactService.getDealership().subscribe(concessions => {
      this.dropdownConcessionList = concessions;
      if (callback)
        callback();
    });
  }
  getFonctions(callback?): void {
    this.dataFonctionService.getFonction().subscribe(fonctions => {
      this.dropdownFonctionList = fonctions;
      if (callback)
        callback();
    });
  }
  checkForm(): void {
    this.formError = false;
    const notRequiredFields = ['tel_fix', 'tel_mobile', 'email', 'commentaire'];
    for (const item of Object.entries(this.contact)) {
      if (!notRequiredFields.includes(item[0]) && (item[1] == '' || item[1] == null)) {
        this.formError = true;
      }
    }
  }
  /***
   * Create a new contact
   ***/
  SelectChangeHandlerCreer() {
    this.formError = false;
    for (const concession of this.concessionSelected) {
      this.contact.concessions_id.push(concession.concession_id);
    }
    const body = JSON.stringify(this.contact);
    if (!this.formError) {
      this.dataContactService.createContact(body).subscribe( response => {
        this.route.navigate(['../gestion-contact']);
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création du contact',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onConcessionSelect(item: any) {
    this.concessionSelected.push([item]);
  }

  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessionSelected.length; i++) {
      if (this.concessionSelected[i] === item) {
        this.concessionSelected.splice(i, 1);
      }
    }
  }
  onFonctionSelect(item: any) {
    this.fonctionSelected = [item];
    this.contact.fonction_id = item.id_conf;
  }

  onFonctionDeselect(item: any) {
    for (let i = 0; i < this.fonctionSelected.length; i++) {
      if (this.fonctionSelected[i] === item) {
        this.fonctionSelected.splice(i, 1);
        this.contact.fonction_id = null;
      }
    }
  }
  isContactDuplicate(name, firstname) {
    this.dataContactService.getContact().subscribe(contacts => {
      for (const contact of contacts) {
        const contactIdentity = contact.nom + ' ' + contact.prenom;
        if (contactIdentity === name + ' ' + firstname) {
          this.contactDuplicateList.push(contact);
        }
      }
      if (this.contactDuplicateList.length != 0) {
        this.openDialog();
      } else {
        this.SelectChangeHandlerCreer();
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogContactDuplicateDialog, {
      width: '1200px',
      data: {contactsList: this.contactDuplicateList, contact: this.contact},
    });

    dialogRef.afterClosed().subscribe(isValid => {
      if (isValid){
        this.SelectChangeHandlerCreer();
      }
    });
  }
}

@Component({
  selector: 'dialog-contact-duplicate-dialog',
  templateUrl: 'dialog-contact-duplicate-dialog.html',
})
export class DialogContactDuplicateDialog implements OnInit {
  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<DialogContactDuplicateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  displayedColumns: string[] = ['nom', 'prenom', 'fonction', 'telephone_fixe', 'telephone_portable', 'mail', 'commentaire', 'concession', 'modifier'];
  dataSource: any;

  ngOnInit() {
    for (const contact of this.data.contactsList) {
      contact.concessions = contact.concessions.split(';');
    }
    this.dataSource = new MatTableDataSource(this.data.contactsList);
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }
  SelectChangeHandlerModifier(contactId) {
    this.route.navigate(['../modification-contact/' + contactId]);
  }
}
