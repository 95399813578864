import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataConcessionService} from '../utils/data-services/dataConcession.service';
import { Concessions } from './concessions';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {DataContactService} from '../utils/data-services/dataContact.service';


@Component({
  selector: 'app-gestion-concession',
  templateUrl: './gestion-concession.component.html',
  styleUrls: ['./gestion-concession.component.css']
})
export class GestionConcessionComponent implements OnInit {

  variable: any;
  /* Variable list for the Textarea components */
  sort = null;
  concessionID: any;
  nom: any;
  ville: any;
  villeP: any;
  rue: any;
  codeP: any;
  tel: any;
  email: any;
  pays: any;
  ELEMENT_DATA: any;
  dataSource: any;
  groups = [];
  groupFilter = null;
  originalDataSource = null;
  selectedGroup = null;
  groupsArray = {
    filtered: null,
    default: null
  };

  // tslint:disable-next-line:max-line-length
  constructor(private dataConcessionService: DataConcessionService, private route: Router, private dataService: DataCreationConcessionService, private  dataContactService: DataContactService) {  }
  displayedColumns: string[] = ['concession_id', 'nom_concession', 'group', 'raison_sociale', 'ville', 'ville_principale', 'code_ej', 'code_site', 'modifier'];

  ngOnInit() {
    this.dataConcessionService.getConcession().subscribe(data => {
      this.dataService.getGroups().subscribe(groups => {
        this.groups = groups.records;
        const currentDate = new Date();
        // const twelveMonthsAgo = new Date();
        // twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);
        // const thirteenMonthsAgo = new Date();
        // thirteenMonthsAgo.setMonth(currentDate.getMonth() - 13);
        this.groupsArray.filtered = groups.records;
        this.groupsArray.default = groups.records;
        this.variable = [];
        for (const concession of data.records) {
          if (this.groups.filter(x => x.groupe_id == concession.groupe_id)[0]) {
            concession.group = this.groups.filter(x => x.groupe_id == concession.groupe_id)[0].nom;
          }

          const startCertification = concession.certification_start_at ? concession.certification_start_at : '01-15';
          const startCertificationDate = new Date(currentDate.getFullYear() + '-' + startCertification);
          const endCertificationDate = new Date(startCertificationDate);
          endCertificationDate.setMonth(endCertificationDate.getMonth() + 1);

          if (currentDate >= startCertificationDate && (!concession.last_user_certification_at || new Date(concession.last_user_certification_at) < startCertificationDate)) {
            if (currentDate > endCertificationDate) {
              concession.usersCertified = -1;
            } else if (currentDate <= endCertificationDate) {
              concession.usersCertified = 0;
            }
          } else {
            concession.usersCertified = 1;
          }
          this.variable.push(concession);
        }

        this.ELEMENT_DATA = this.variable;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.originalDataSource = this.ELEMENT_DATA;
        let bigestId = 0;
        for (const concession of data.records) {
          if (concession.concession_id > bigestId) {
            bigestId = concession.concession_id;
          }
        }
        sessionStorage.setItem('autoIncrement', (bigestId + 1).toString());
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom_concession < b.nom_concession ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom_concession > b.nom_concession ? 1 : -1;
      });
    }
  }
  sortId() {
    if (this.sort == 'idAcs') {
      this.sort = 'idDesc';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.concession_id < b.concession_id ? 1 : -1;
      });
    } else {
      this.sort = 'idAcs';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.concession_id > b.concession_id ? 1 : -1;
      });
    }
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
  filterDealershipGroup(group): void {
    if (group == 'all') {
      this.dataSource.data = this.originalDataSource;
    } else {
      this.dataSource.data = this.originalDataSource.filter((e: any) => e.groupe_id == group);
    }
  }
  filterGroup(event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.groupsArray.filtered = this.groupsArray.default.filter((e: any) => e.nom.toLowerCase().includes(filterValue));
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-concession']);
  }

  SelectChangeHandlerModifier(concessionId) {
    this.route.navigate(['../modification-concession/' + concessionId]);
  }

}
