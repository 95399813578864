import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataMobiliteService extends HandleErrorService {
  getMobiliteByConcession(parameter) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-mobilite-by-dealership/' + parameter;
    return this.http.get<any>(apiOperation, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createMobilite(body) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-mobilite/create';
    return this.http.post<any>(apiOperation, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getMobiliteById(parameter) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-mobilite-by-id/' + parameter;
    return this.http.get<any>(apiOperation, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateMobilite(body) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-mobilite/update';
    return this.http.put<any>(apiOperation, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
