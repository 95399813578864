<div class="row center" style="margin: 0; padding: 20px; width: auto;">
  <div class="d-flex flex-column align-items-center justify-content-center w-100" (click)="backToProductList()">
    <!-- <p>{{item.nom_produit}}</p> -->
    <h1>{{ product.nom_produit }}</h1>
    <p>{{ product.description }}</p>
  </div>
  <div class="d-flex justify-content-around w-100 mt-4">
    <div><strong>Lien :</strong> {{ product.lien }}</div>
    <div><strong>Type :</strong> {{ product.type }}</div>
    <div><strong>Version :</strong> {{ product.version }}</div>
  </div>
  <div class="mt-4 p-4 w-100 d-flex justify-content-center shadow">
    <div>Work In Progress..</div>
  </div>
</div>
