import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataFonctionService {

  constructor(private http: HttpClient) { }

  errorMessage: string | undefined;

  public handleError(error: HttpErrorResponse): Observable<never> {
    this.errorMessage = (error.error instanceof ErrorEvent) ? `Error: ${error.error.message}` : `Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(this.errorMessage);
  }

  getFonction() {
    const apiContact = environment.globalApiUrl + 'api/get-function';
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createFonction(body) {
    const apiContact = environment.globalApiUrl + 'api/create-function';
    return this.http.post<any>(apiContact, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateFonction(body) {
    const apiContact = environment.globalApiUrl + 'api/put-function';
    return this.http.put<any>(apiContact, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteFonction(fonctionId) {
    const apiContact = environment.globalApiUrl + 'api/delete-function/' + fonctionId;
    return this.http.delete<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
