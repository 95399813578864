import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../utils/authentication.service';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import swal from 'sweetalert2';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  error = {err: false, code: 200};
  resetPwdMailForm: any;
  resetPwdForm: any;
  userMail = '';
  isSubmited = false;
  isEmailConfirmed = false;
  tokenLifeTime: number;

  constructor(
    private authenticationService: AuthenticationService,
    private route: Router,
    private formBuilder: FormBuilder
  ) {
    this.resetPwdMailForm = this.formBuilder.group({
      email: '',
      from: ''
    });
  }

  found: boolean = false;
  eMail: any;

  ngOnInit() {
  }

  onSubmit(post) {
    this.isSubmited = true;
    this.userMail = post.email;
    post.from = environment.website;

    this.authenticationService.forgotPassword(post).subscribe(data => {
      if (data.statusCode === 200) {
        this.tokenLifeTime = data.content / 3600;
        this.isEmailConfirmed = true;
        this.isSubmited = false;
      } else if (data.statusCode === 404) {
        this.error = {err: true, code: data.statusCode};
        this.isSubmited = false;
        swal.fire({
          title: 'Erreur',
          text: "Cet email n'existe pas",
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    });
  }

  Annuler() {
    this.route.navigate(['../login']);
  }

}
