import { Component, OnInit } from '@angular/core';
import {DataBackupConcessionService} from "../../utils/data-services/data-backup-concession.service";
import {Router} from "@angular/router";
import {DataReceptionOptionsMappingService} from "../../utils/data-services/data-reception-options-mapping.service";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";

@Component({
  selector: 'app-create-reception-options-mapping',
  templateUrl: './create-reception-options-mapping.component.html',
  styleUrls: ['./create-reception-options-mapping.component.scss']
})
export class CreateReceptionOptionsMappingComponent implements OnInit {
  receptionOptionsMapping = {
    concession: null,
    dealership_id: '',
    uniq_concession_id: null,
    reception_expires_at_delay: null,
    reception_not_before_delay: null,
    restitution_expires_at_delay: null,
    service: null,
  };
  concessionArray = [];
  constructor(private dataServiceConcession: DataConcessionService, private dataService: DataReceptionOptionsMappingService, private route: Router ) { }

  ngOnInit() {
    this.getAllConcession();
  }
  createReceptionOptionsMapping() {
    this.dataService.createReceptionOptionsMapping(this.receptionOptionsMapping).subscribe(result => {
      this.route.navigate(['../gestion-reception-options-mapping']);
    });
  }
  getAllConcession() {
    this.dataServiceConcession.getConcession().subscribe(result => {
      this.concessionArray = result['records'];
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-reception-options-mapping']);
  }
  onChangeCheckbox(type) {
    this.receptionOptionsMapping[type] = !this.receptionOptionsMapping[type];
  }
}
