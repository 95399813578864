import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataUtilisateurService extends HandleErrorService {
  apiUser = environment.globalUrlDomaine + '/records/user';

  getUser() {
    return this.http.get<any>(this.apiUser, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getRoles() {
    return this.http.get<any>(environment.globalUrlDomaine + '/records/roles', environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getUserConcessiions(concessionId) {
    return this.http.get<any>(environment.globalUrlDomaine + '/records/user_concession' + '?filter=concession_id,eq,' + concessionId , environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getUserById(id) {
    return this.http.get<any>(this.apiUser + '/' + id , environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createUser(post) {
    const jsonPost = JSON.stringify(post);
    return this.http.post<any>(environment.globalApiUrl + 'api/register', jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  updateUser(post) {
    const jsonPost = JSON.stringify(post);
    return this.http.put<any>(environment.globalApiUrl + 'api/register', jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  deleteUser(post) {
    const jsonPost = JSON.stringify(post);
    return this.http.put<any>(environment.globalApiUrl + 'api/delete/user', jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
