<div class="main-content-inner mt-2">
  <h1 class="text-center">FAQ - Gestion des grands thèmes </h1>
  <div class="faq-theme-form w-100 mb-3 mat-elevation-z8" *ngIf="!isEdit">
    <form [formGroup]="form" class="d-flex justify-content-around align-items-end formation-form">
      <div class="font-weight-bold p-4">Ajouter un thème : </div>
      <div class="form-group">
        <input type="text" class="form-control" name="name" placeholder="Nom du thème" formControlName="name">
      </div>
      <button type="button" id="btn-add-theme" class="btn btn100px btn-dark" [disabled]="form.controls.name.value == '' || form.controls.name.value == null" (click)="onSubmit(form.value, 'create')">
        <mat-icon>add_circle</mat-icon>
        Valider
      </button>
    </form>
  </div>
  <div class="faq-theme-form w-100 mb-3 mat-elevation-z8" *ngIf="isEdit">
    <form [formGroup]="form" class="d-flex justify-content-around align-items-end formation-form">
      <div class="font-weight-bold p-4">Modifier le thème : </div>
      <div class="form-group">
        <input type="text" class="form-control" name="name" placeholder="Nom du thème" formControlName="name">
      </div>
      <div class="d-flex">
        <button type="button" id="btn-put-theme" class="btn btn-dark" (click)="onSubmit(form.value, 'update')">
          <mat-icon>add_circle</mat-icon>
          Modifier
        </button>
        <button type="button"  class="btn btn100px btn-dark" (click)="onCancelEdit()">
          Annuler
        </button>
      </div>
    </form>
  </div>
  <div class="header-rules-action w-75 mx-auto">
    <div class="col-12 text-right">
      <mat-form-field style="width: 250px">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>
  <table class="mat-elevation-z8 mx-auto mt-2" mat-table [dataSource]="dataSource" style="width: 90%">
    <ng-container matColumnDef="id">
      <th class="text-center" mat-header-cell *matHeaderCellDef> id</th>
      <td class="text-center" mat-cell *matCellDef="let element"
          [ngStyle]="{'border-left': 'solid' + element.color}"> {{element.id}}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th class="text-center" mat-header-cell *matHeaderCellDef> name</th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Editer</th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onEdit(element.id, element.name)"
          style="cursor: pointer; width: 50px">
        <mat-icon>edit</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Supprimer</th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onDelete(element.id)"
          style="cursor: pointer; color: red; width: 30px">
        <mat-icon>delete</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="!isDataSourceExists" style="display: flex;justify-content: center;padding: 25px; align-items: center">
    <span class="material-icons" style="padding: 5px">error</span> Aucun résultat trouvé.
  </div>
</div>
