import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from './handleError.service';
import {Observable} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HandleErrorService {
  authURL = environment.globalApiUrl + 'api/login_check';
  resetPasswordUrl = environment.globalApiUrl + 'reset-password';

  private LoggedIn: any = undefined;
  private body: any;
  Login(post) {
    this.body = post;
    return this.http.post<any>(this.authURL, this.body, environment.httpOptions);
  }
  forgotPassword(post) {
    this.body = post;
    return this.http.post<any>(this.resetPasswordUrl, this.body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  resetPassword(post, token) {
    this.body = post;
    return this.http.post<any>(this.resetPasswordUrl + '/reset/' + token, this.body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  public request2faVerification(tokenAccess: string, body: any): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + tokenAccess);
    return this.http.post<any>(`${environment.globalApiUrl}api/auth2fa`, body,  { headers: httpHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }
  /////////////////////
  ///// FUNCTIONS /////
  /////////////////////
  loginSuccess(data): any {
    const jwtPayload = jwt_decode(data.token);
    if (jwtPayload.has2fa) {
      sessionStorage.setItem('token', data.token);
      this.route.navigate(['../2fa']);
      return null;
    }
    if (jwtPayload.roles.includes('ADMIN')) {
      sessionStorage.setItem('isConnected', 'true');
      sessionStorage.setItem('User', jwtPayload.firstname + ' ' + jwtPayload.lastname);
      sessionStorage.setItem('token', data.token);
      //this.appComponent.isConnected = true;
      this.route.navigate(['../'])
        .then(() => {
          window.location.reload();
        });
    } else {
      this.tokenError();
    }
  }
  tokenError(): void {
    swal.fire({
      title: 'Echec de connexion',
      text: 'Le code d\'authentification est erroné ou expiré.',
      confirmButtonText: 'Ok',
        type: 'error',
    });
  }
  public setCookie(name, value, hours): any {
    let expires = '';
    if (hours) {
      const date = new Date();
      date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '')  + expires + '; path=/';
  }
  public getCookie(name): any {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') { c = c.substring(1, c.length); }
      if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length, c.length); }
    }
    return null;
  }
  public eraseCookie(name): any {
    document.cookie = name + '=; Max-Age=-99999999;';
  }
}
