import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import swal from 'sweetalert2';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataCreationProduitService extends HandleErrorService {

  getParametre() {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre';
    return this.http.get<any>(apiParametre, environment.httpOptions);
  }

  getConcession() {
    const apiConcession = environment.globalUrlDomaine + '/records/concession';
    return this.http.get<any>(apiConcession, environment.httpOptions);
  }

  addProduitParametre(arg1: any, arg2: any) {
    const api = environment.globalUrlDomaine + '/records/produit_parametre';
    const body = { produit_id: arg1,
      parametre_id: arg2
    };
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addProduitConcession(arg1: any, arg2: any, arg3: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit';
    const body = { concession_id: arg1,
      produit_id: arg2, version: arg3
    };
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  createProduct(body) {
    const api = environment.globalApiUrl + 'api/product/create';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateProduct(body) {
    const api = environment.globalApiUrl + 'api/product/update';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getRoles() {
    return this.http.get<any>(environment.globalUrlDomaine + '/records/roles', environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getProducts() {
    const api = environment.globalApiUrl + 'api/product/getProducts';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
      );
  }

  getProductById(id) {
    const api = environment.globalApiUrl + 'api/product/get-product-by-id/' + id;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
      );
  }
}
