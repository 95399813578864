import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})

export class DataConsultationService extends HandleErrorService {

  getConcession() {
    const apiConcession = environment.globalUrlDomaine + '/records/concession?include=concession_id,nom_concession';
    // return this.http.get<any>(apiConcession, httpOptions);
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getConcessionLocalStorage(idC: any) {
    // tslint:disable-next-line:max-line-length
    const apiConcession = environment.globalUrlDomaine + '/records/concession?filter=concession_id,eq,' + idC + '&include=nom_concession';
    // return this.http.get<any>(apiConcession, httpOptions);
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getProduit() {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?include=produit_id,nom_produit&filter=actif,eq,1&filter=type,eq,technique';
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getProduitLocalStorage(idPr: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?filter=produit_id,eq,' + idPr + '&include=nom_produit';
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getParametre() {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre_produit?include=parametre_id,nom_parametre,produit_id,nom_produit';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getParametreLocalStorage(idPa: any) {
    // tslint:disable-next-line:max-line-length
    const apiParametre = environment.globalUrlDomaine + '/records/parametre?filter=parametre_id,eq,' + idPa + '&include=nom_parametre';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getConcession_Produit(idConcession: any) {
    const apiValeur = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + idConcession;
    return this.http.get<any>(apiValeur, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getParametre_Produit(idProduit: any) {
    const apiValeur1 = environment.globalUrlDomaine + '/records/parametre';
    const apiValeur2 = environment.globalUrlDomaine + '/records/parametre_produit?filter=produit_id,eq,' + idProduit;
    if (idProduit === 'all') {
      return this.http.get<any>(apiValeur1, environment.httpOptions).pipe(
        catchError(this.handleError)
      );
    } else {
      return this.http.get<any>(apiValeur2, environment.httpOptions).pipe(
        catchError(this.handleError)
      );
    }
  }

  getValeur(idConcession: any, idProduit: any, idParametre: any, idTag) {
    // const UrlDomain = 'http://api.softgate.fr';
    const Domaine = environment.globalUrlDomaine;
    const  UrlApi = '/records';
    const UrlRoute  = '/concession_produit_parametre_valeur?';
    const UrlFilterConcession = 'filter=concession_id,eq,';
    const UrlFilterProduit = 'filter=produit_id,eq,';
    const UrlFilterParametre = 'filter=parametre_id,in';
    const UrlFilterTag = 'filter=tag_id,eq,';
    const UrlFilterType = 'filter=type,eq,technique';
    let FilterConcession = '';
    let FilterProduit = '';
    let FilterParametre = '';
    let FilterTag = '';
    if (idConcession !== 'all') {
      FilterConcession = UrlFilterConcession + idConcession + '&';
    }
    if (idProduit !== 'all') {
      FilterProduit = UrlFilterProduit + idProduit + '&';
    }
    if (idParametre != null) {
      // FilterParametre = UrlFilterParametre + idParametre + '&';
      let urlParametre = '';
      for (const parametre of idParametre) {
        if (parametre != 'all') {
          urlParametre += ',' + parametre.parametre_id ;
        }
      }
      FilterParametre = UrlFilterParametre + urlParametre + '&';
    }
    if (idTag !== 'all') {
      FilterTag = UrlFilterTag + idTag + '&';
    }

    const apiValeur = Domaine + UrlApi + UrlRoute + FilterConcession + FilterProduit + FilterParametre + FilterTag + UrlFilterType;
    return this.http.get<any>(apiValeur, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
