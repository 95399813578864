<h2>Configuration {{dealershipConfiguration.nomConcession}}</h2>
<div class="wrapper-config">
  <form>
    <div *ngFor="let configurationType of configurationTypeArray">
      <h4 class="mt-3">{{configurationType.name}}</h4>
      <div class="form-row mt-3">
        <div *ngFor="let configuration of filterConfiguration(configurationArray, configurationType.name)" class="form-group col-md-3">
          <label for="{{configuration.name}}">{{camelCaseToTitleCase(configuration.name)}}</label>
          <input *ngIf="configuration.field == 'input'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}">
          <textarea *ngIf="configuration.field == 'textarea'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}"></textarea>
          <select *ngIf="configuration.field == 'select'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}" (change)="changeSelectedValue()">
            <option *ngFor="let option of getSelectOptions(configuration.id)" [value]="option.name">{{option.name}}</option>
          </select>
          <input *ngIf="configuration.field == 'date'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}" type="date">
        </div>
      </div>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="this.selectChangeHandlerCreer()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
      </div>
      <div>
        <button (click)="this.selectHandlerCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
