<h1 class="text-center mt-2">Gestion des configurations de concession</h1>
<div class="user-table">
  <div class="container-row top-field d-flex justify-content-start mr-5 mt-4">
    <mat-form-field class="m-3">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
  <div class="pb-3">
    <fieldset class="mat-form-field">
      <legend>Versions actuelles</legend>
      <div class="d-flex">
          <div *ngFor="let config of objectToValues(defaultConfiguration); let index = index" class="m-auto d-flex flex-column default-value">
            <span>{{camelCaseToTitleCase(objectToKey(defaultConfiguration)[index])}}</span>
            <span>{{config}}</span>
          </div>
      </div>
    </fieldset>
  </div>
</div>
<div class="example-container mt-4">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="user-table mat-elevation-z8">
    <ng-container matColumnDef="concessionId">
      <th mat-header-cell *matHeaderCellDef class="text-center"> ID </th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.concessionId}}</td>
    </ng-container>
    <ng-container matColumnDef="nomConcession">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Nom </th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.nomConcession}}</td>
    </ng-container>
    <ng-container *ngFor="let column of displayedConfiguration" matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef class="text-center"> {{camelCaseToTitleCase(column)}} </th>
      <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'no-product' :element[column] == null || element[column] == '', 'uptodate' :defaultConfiguration[column] == element[column],  'outdated' : defaultConfiguration[column] != element[column] && (element[column] != null || element[column] != '')}">{{element[column]}}</td>
    </ng-container>
    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="text-center pointer"><mat-icon (click)="onUpdate(element.concessionId)">edit</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
<!--      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>-->
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns?.length">
        <div class="example-element-detail flex-column" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div *ngFor="let configurationType of configurationTypeArray">
            <div *ngIf="configurationType.name !== 'Version Applications BMW'" class="ml-2 font-weight-bolder">{{configurationType.name}}</div>
            <table class="mt-2">
              <thead class="row h-auto m-0">
                <th class="col text-center" *ngFor="let configuration of filterConfiguration(expandedConfiguration, configurationType.name)">{{configuration.name}}</th>
              </thead>
              <tbody>
                <tr class="row h-auto m-0">
                  <td class="col text-center" *ngFor="let configuration of filterConfiguration(expandedConfiguration, configurationType.name)">{{element[configuration.name]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row pointer"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
    >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50]" class="user-table mb-3"></mat-paginator>
</div>
