import { Component, OnInit } from '@angular/core';
import {DataSmsSubscriptionService} from "../utils/data-services/data-sms-subscription.service";
import {MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";

@Component({
  selector: 'app-gestion-subscription',
  templateUrl: './gestion-subscription.component.html',
  styleUrls: ['./gestion-subscription.component.scss']
})
export class GestionSubscriptionComponent implements OnInit {

  constructor(private dataService: DataSmsSubscriptionService, private route: Router) { }
  dataSource: any;
  columnsToDisplay: string[] = ['id', 'color', 'label', 'monthlyCost', 'smsCost', 'update'];
  expandedElement: any | null;
  isLoading = true;
  ngOnInit() {
    this.getSubscriptions();
  }
  getSubscriptions() {
    this.dataService.getSubscriptionsSms().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.isLoading = false;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-subscription']);
  }
  SelectChangeHandlerModifier(id) {
    this.route.navigate(['../update-subscription/' + id]);
  }
}
