<h2>Gestion marque Planngo</h2>
<div class="wrapper">
  <form style="width: fit-content">
    <section class="d-flex align-content-center align-items-center">
      <div class="d-flex flex-column ml-4 mt-4">
        <img src="./assets/Imgs/bmw-logo-grey.svg" alt="bmw-logo-grey" height="70px">
        <mat-checkbox class="ml-4 mt-3" [(ngModel)]="brands.bmw" name="bmw"></mat-checkbox>
      </div>
      <div class="d-flex flex-column ml-4 mt-4">
        <img src="./assets/Imgs/mini-logo-grey.svg" alt="mini-logo-grey" height="70px">
        <mat-checkbox class="ml-5 mt-3" [(ngModel)]="brands.mini" name="mini"></mat-checkbox>
      </div>
      <div class="d-flex flex-column ml-4 mt-4 mr-4">
        <img src="./assets/Imgs/bmw-motorad-logo-grey.svg" alt="bmw-motorad-logo-grey" height="70px">
        <mat-checkbox class="ml-4 mt-3" [(ngModel)]="brands.motorrad" name="motorrad"></mat-checkbox>
      </div>
    </section>
    <div class="d-flex justify-content-start mt-3 mb-5 w-100">
      <button (click)="onUpdate()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
      <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
    </div>
  </form>
</div>

