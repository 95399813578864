import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DataFaqService} from '../dataFaq.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-faq-questions',
  templateUrl: './gestion-faq-questions.component.html',
  styleUrls: ['./gestion-faq-questions.component.scss']
})
export class GestionFaqQuestionsComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'question',
    'edit',
    'delete'
  ];
  dataSource = new MatTableDataSource<any>();
  isDataSourceExists = false;

  constructor(
    private dataFaqService: DataFaqService,
    private route: Router
  ) { }

  ngOnInit() {
    this.getQuestions();
  }
  getQuestions(): any {
    this.dataFaqService.getAllQuestions().subscribe(questions => {
      if (questions.records.length > 0) {
        this.isDataSourceExists = true;
      }
      this.dataSource.data = questions.records;
    });
  }
  onEdit(id): any {
    this.route.navigate(['modification-faq-question/' + id]);
  }
  onDelete(id): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataFaqService.deleteQuestion(id).subscribe(() => {
          this.getQuestions();
        });
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
}
