<h1 class="text-center mt-2">Gestion abonnements actifs</h1>
<div class="user-table">
  <div class="container-row top-field ml-4">
    <mat-form-field>
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-between ml-4">
    <div class="d-flex">
      <mat-form-field class="mr-2" appearance="fill" style="width: 200px">
        <mat-label>Type de souscription</mat-label>
        <mat-select placeholder="SMS" [formControl]="subscriptionTypeFormControl" multiple (selectionChange)="filterBySubscriptionType()">
          <mat-option *ngFor="let subscriptionType of subscriptionTypeArray" [value]="subscriptionType">
            {{subscriptionType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill" style="width: 200px">
        <mat-label>Type d'abonnement</mat-label>
        <mat-select placeholder="SMS" [formControl]="subscriptionPriceFormControl" multiple (selectionChange)="filterBySubscriptionType()">
          <mat-option *ngFor="let subscriptionPrice of subscriptionPriceArray" [value]="subscriptionPrice">
            <div *ngIf="subscriptionPrice.monthlyCost !== 0">Abonnement à {{subscriptionPrice.monthlyCost}} €</div>
            <div *ngIf="subscriptionPrice.monthlyCost === 0">Acquisition à {{subscriptionPrice.smsCost}} € par SMS</div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill" style="width: 100px">
        <mat-label>Actif</mat-label>
        <mat-select placeholder="Actif" [formControl]="activeFormControl" multiple (selectionChange)="filterBySubscriptionType()">
          <mat-option [value]="true">Oui</mat-option>
          <mat-option [value]="false">Non</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex">
      <span class="mr-3" style="color: #42A948">{{this.dataSource.data.length}}</span>
      <span class="mr-3" style="color: #ca2222">{{this.originalDataSource?.length-this.dataSource.data.length}}</span>
    </div>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 user-table mb-3">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element"><strong>{{element.id}}</strong>
      </td>
    </ng-container>
    <ng-container matColumnDef="dealership">
      <th mat-header-cell *matHeaderCellDef> Nom</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element"> {{element.dealership}} </td>
    </ng-container>
    <ng-container matColumnDef="town">
      <th mat-header-cell *matHeaderCellDef> Ville</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element"> {{element.town}} </td>
    </ng-container>
    <ng-container matColumnDef="subscriptionType">
      <th mat-header-cell *matHeaderCellDef> Type de souscription</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element">
        <div *ngIf="element.currentSubscription">
          <span *ngIf="element.currentSubscription.smsSubscription[0].monthlyCost === 0">Acquisition</span>
          <span *ngIf="element.currentSubscription.smsSubscription[0].monthlyCost !== 0">Abonnement</span>
        </div>
        <div *ngIf="!element.currentSubscription">Aucun abonnement</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="monthlyCost">
      <th mat-header-cell *matHeaderCellDef> Coût mensuel</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element">
        <div *ngIf="element.currentSubscription">{{element.currentSubscription.smsSubscription[0].monthlyCost}} €</div>
        <div *ngIf="!element.currentSubscription">-</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="smsCost">
      <th mat-header-cell *matHeaderCellDef> Coût SMS</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element">
        <div *ngIf="element.currentSubscription"> {{element.currentSubscription.smsSubscription[0].smsCost}} €</div>
        <div *ngIf="!element.currentSubscription">-</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef> Date de début</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element">
        <div *ngIf="element.currentSubscription">  {{element.currentSubscription.dateDebut}} </div>
        <div *ngIf="!element.currentSubscription">-</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef> Date de fin</th>
      <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element">
        <div *ngIf="element.currentSubscription">
          <span *ngIf="!element.currentSubscription.dateFin">-</span>
          <span *ngIf="element.currentSubscription.dateFin">{{element.currentSubscription.dateFin}}</span>
        </div>
        <div *ngIf="!element.currentSubscription">-</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="stop">
      <th mat-header-cell *matHeaderCellDef> Arreter</th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="element.currentSubscription" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon (click)="onStopSubscription(element.currentSubscription.id)">pause</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="assign">
      <th mat-header-cell *matHeaderCellDef> Ajouter</th>
      <td mat-cell *matCellDef="let element">
        <button class="m-2 btn btn-round-40 btn-dark" (click)="onAddSubscription(element.id)">
          <mat-icon>add_circle</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="element.oldSubscriptions && element.oldSubscriptions.length > 0" mat-icon-button
                aria-label="expand row"
                (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    <!--       Expanded Content Column - The detail row is made up of this one column that spans across all columns-->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail flex-column"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <h4></h4>
          <table *ngIf="element.oldSubscriptions && element.oldSubscriptions.length > 0" class="mt-2 mb-4">
            <thead>
            <th scope="col">Type de souscription</th>
            <th scope="col">Coût mensuel</th>
            <th scope="col">Coût SMS</th>
            <th scope="col">Date de début</th>
            <th scope="col">Date de fin</th>
            <th scope="col"></th>
            </thead>
            <tbody>
            <tr *ngFor="let subscription of objectToArray(element.oldSubscriptions), let index = index">
              <td>
                <span *ngIf="subscription.smsSubscription[0].monthlyCost === 0">Acquisition</span>
                <span *ngIf="subscription.smsSubscription[0].monthlyCost !== 0">Abonnement</span>
              </td>
              <td>{{subscription.smsSubscription[0].monthlyCost}}€</td>
              <td>{{subscription.smsSubscription[0].smsCost}}€</td>
              <td>{{subscription.dateDebut}}</td>
              <td>
                <span *ngIf="subscription.dateFin">{{subscription.dateFin}}</span>
                <span *ngIf="!subscription.dateFin">-</span>
              </td>
              <td class="d-flex justify-content-end">
                <mat-icon *ngIf="index === 0 && !subscriptionIsActive(element, subscription.id)" (click)="onActiveSubscription(subscription.id);stopPropagation($event)" class="pointer mr-2">play_arrow</mat-icon>
                <mat-icon *ngIf="isDateToday(subscription.creationDate)" (click)="deleteSubscription(subscription.id);stopPropagation($event)" class="pointer mr-2">delete</mat-icon>
                <mat-icon (click)="onUpdateSubscription(subscription.id);stopPropagation($event)" class="pointer">edit</mat-icon>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row pointer"
        [class.example-expanded-row]="expandedElement === element"
        [ngStyle]="{'background-color' : element.currentSubscription?.smsSubscription[0].color}">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
<app-spinner *ngIf="isLoading"></app-spinner>
