import { Component, OnInit } from '@angular/core';
import {DataReceptionOptionsMappingService} from "../../utils/data-services/data-reception-options-mapping.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";

@Component({
  selector: 'app-update-reception-options-mapping',
  templateUrl: './update-reception-options-mapping.component.html',
  styleUrls: ['./update-reception-options-mapping.component.scss']
})
export class UpdateReceptionOptionsMappingComponent implements OnInit {
  receptionOptionsMappingId: any;
  receptionOptionsMapping = {
    autonomous_reception_agencies_options_map_id: null,
    concession: null,
    dealership_id: '',
    uniq_concession_id: null,
    reception_expires_at_delay: null,
    reception_not_before_delay: null,
    restitution_expires_at_delay: null,
    service: null,
  };
  concessionArray = [];
  constructor(private dataServiceConcession: DataConcessionService, private dataService: DataReceptionOptionsMappingService, private route: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.receptionOptionsMappingId = Number(this.activeRoute.snapshot.paramMap.get('idReceptionOptionsMapping'));
    this.getAllConcession();
    this.getReceptionOptionsMapping();
  }
  updateReceptionOptionsMapping() {
    this.dataService.updateReceptionOptionsMapping(this.receptionOptionsMapping).subscribe(result => {
      this.route.navigate(['../gestion-reception-options-mapping']);
    });
  }
  getAllConcession() {
    this.dataServiceConcession.getConcession().subscribe(result => {
      this.concessionArray = result['records'];
    });
  }
  getReceptionOptionsMapping() {
    this.dataService.getReceptionOptionsMappingById(this.receptionOptionsMappingId).subscribe(result => {
      this.receptionOptionsMapping = result;
      this.receptionOptionsMapping.autonomous_reception_agencies_options_map_id = this.receptionOptionsMappingId;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-reception-options-mapping']);
  }
  onChangeCheckbox(type) {
    this.receptionOptionsMapping[type] = !this.receptionOptionsMapping[type];
  }
}
