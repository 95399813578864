import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataCampaignDiscountsService} from "../utils/data-services/data-campaign-discounts.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import swal from "sweetalert2";
import {DataBackupConcessionService} from "../utils/data-services/data-backup-concession.service";

@Component({
  selector: 'app-gestion-backup-concession',
  templateUrl: './gestion-backup-concession.component.html',
  styleUrls: ['./gestion-backup-concession.component.scss']
})
export class GestionBackupConcessionComponent implements OnInit {

  dataSource = new MatTableDataSource();

  constructor( private dataService: DataBackupConcessionService, private route: Router ) {}
  displayedColumns: string[] = ['id', 'concession', 'project', 'modifier'];

  ngOnInit() {
    this.dataService.getBackupConcession().subscribe(backupConcession => {
      this.dataSource.data = backupConcession;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-backup-concession']);
  }
  SelectChangeHandlerModifier(backupConcessionId) {
    this.route.navigate(['../update-backup-concession/' + backupConcessionId]);
  }
}
