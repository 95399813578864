import { Component, OnInit } from '@angular/core';
import {DataMaintenancesService} from '../utils/data-services/dataMaintenances.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  isLoading = false;
  titleLoader = '';

  constructor(
    private maintenanceService: DataMaintenancesService
  ) { }

  ngOnInit() {
  }

  onClickButton(action): void {
    this.isLoading = true;
    switch (action) {
      case 'syncUcar':
        this.titleLoader = 'UCAR - synchronisation des véhicules';
        this.preventMissClick('Synchronisation des véhicules', () => {
          this.maintenanceService.syncUcar().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
      case 'anonymizationPngo':
        this.titleLoader = 'PNGO - Anonymisation des informations';
        this.preventMissClick('Anonymisation des informations', () => {
          this.maintenanceService.anonymizationPngo().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
      case 'fixFirstDatePngo':
        this.titleLoader = 'PNGO - Correction des concessions pour le 1er créneau';
        this.preventMissClick('Correction des concessions pour le 1er créneau', () => {
          this.maintenanceService.fixFirstDatePngo().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
      case 'ipsMonitoringPngo':
        this.titleLoader = 'PNGO - Gestion du monitoring IPS';
        this.preventMissClick('Gestion du monitoring IPS', () => {
          this.maintenanceService.ipsMonitoringPngo().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
    }
  }
  preventMissClick(taskName, callback) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir effectuer la tâche suivante : ' + taskName + ' ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        callback();
      }
    });
  }
  showSuccessMsg() {
    this.isLoading = false;
    swal.fire({
      title: 'Succès',
      text: 'La tâche a bien été effectuée.',
      type: 'success',
      confirmButtonText: 'Ok'
    });
  }
  showErrorMsg(error) {
    this.isLoading = false;
    const errorMsg = error.message ? error.message : error;
    swal.fire({
      title: 'Erreur!',
      text: 'Une erreur est survenue : ' + errorMsg,
      type: 'error',
      confirmButtonText: 'Ok'
    });
  }
  loadInBackground() {
    this.isLoading = false;
  }
}
