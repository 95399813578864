<h1 class="text-center mt-2">Délai de rendez-vous - Scénarios</h1>
<div class="user-table mt-4">
  <div class="container-row d-flex justify-content-between top-field">
    <div class="btn1">
      <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
        <mat-icon>add_circle</mat-icon> Scénarios
      </button>
    </div>
    <mat-form-field class="m-3">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8 mb-3">

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef> Marque </th>
      <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
    </ng-container>


    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.label}} </td>
    </ng-container>

    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef> Opérations </th>
      <td mat-cell *matCellDef="let element"> {{element.operations}} </td>
    </ng-container>

    <ng-container matColumnDef="is_courtesy_car">
      <th mat-header-cell *matHeaderCellDef> Voiture de courtoisie </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_courtesy_car">check</mat-icon>
        <mat-icon *ngIf="!element.is_courtesy_car">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_wating">
      <th mat-header-cell *matHeaderCellDef > En attente </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_wating">check</mat-icon>
        <mat-icon *ngIf="!element.is_wating">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef > Actif </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_active">check</mat-icon>
        <mat-icon *ngIf="!element.is_active">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="SelectChangeHandlerModifier(element.id)" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
