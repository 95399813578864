<h1 class="text-center mt-2">Gestion des produits par concession</h1>
<div class="container-row top-field">
  <mat-form-field>
    <mat-label>Rechercher</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>
</div>

<div class="example-container">
  <mat-tab-group animationDuration="0ms">
    <mat-tab *ngFor="let product of dealershipByProduct">
      <ng-template mat-tab-label class="w-100 h-100">
        <span (click)="arrayToDataSource(product.dealership)" class="w-100 h-100">{{product.name}}</span>
      </ng-template>
      <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="town">
          <th mat-header-cell *matHeaderCellDef> Ville </th>
          <td mat-cell *matCellDef="let element"> {{element.town}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>
</div>
