import { Component, OnInit } from '@angular/core';
import {DataCampaignDiscountsService} from '../../utils/data-services/data-campaign-discounts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataOperationService} from '../../utils/data-services/data-operation.service';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';

@Component({
  selector: 'app-create-operation',
  templateUrl: './create-operation.component.html',
  styleUrls: ['./create-operation.component.scss']
})
export class CreateOperationComponent implements OnInit {
  concessionName: any;
  concessionId: any;
  operation = {
    softNet_id: '',
    bmw_id: null,
    categorie_id: null,
    concession_id: null,
    nom: '',
    prix: null,
    vt: null,
    requiredStandartRangeService: false,
    dynamicTest: null,
    actif: false,
    ordre: null,
    blocked: false,
    aideAdditionnel: false,
    commentaireAdditionnel: null,
    type_id: null,
    attente_autorise: false,
    iconOperation: null,
    minimum_delay: null
  };
  categorieArray = [];
  typeArray = [];
  iconArray = [
    'icon-huile-moteur.png',
    'icon-filtre.png',
    'icon-plaquettes-frein.png',
    'icon-recharge-climatisation.png',
    'icon-essuie-glaces-arrière.png',
    'icon-permutation.png',
    'icon-remplacemen-des-pneus.png',
    'icon-contrôle-technique.png',
    'icon-reparation.png',
    'icon-contrôle.png',
    'icon-campagnes-techniques.png',
    'icon-climatisation.png',
    'icon-essuie-glaces-avant.png',
    'icon-vide.png',
    'icon-bougies-allumage.png',
    'icon-plaquettes-disques-frein-arrière.png',
    'icon-plaquettes-disques-frein-avant.png',
    'icon-indicateur-de-maintenance.png',
    'icon-liquide-de-freins.png',
    'icon-demande-rappel.png',
    'icon-inspection.png',
    'icon-contrôle-géométrie.png',
    'icon-reparation-parebrise.png',
    'icon-embrayage.png'
  ];
  constructor( private dataService: DataOperationService, private route: Router, private activeRoute: ActivatedRoute, private dataServiceConcession: DataConcessionService ) { }

  ngOnInit() {
    this.concessionId = Number(this.activeRoute.snapshot.paramMap.get('idConcession'));
    this.operation.concession_id = this.concessionId;
    this.getAllCategorie();
    this.getAllType();
    this.getConcessionById();
  }
  createCampaignDiscounts() {
    this.dataService.createOperation(this.operation).subscribe(result => {
      this.route.navigate(['../gestion-operation/' + this.concessionId]);
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-operation/' + this.concessionId]);
  }
  onChangeCheckbox(type) {
    this.operation[type] = !this.operation[type];
  }
  getAllCategorie() {
    this.dataService.getAllCategorie().subscribe(result => {
      this.categorieArray = result;
    });
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.concessionId).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
  getAllType() {
    this.dataService.getAllType().subscribe(result => {
      this.typeArray = result;
    });
  }
}
