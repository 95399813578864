import { Injectable } from '@angular/core';
import {HandleErrorService} from "../handleError.service";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataProduitConcessionServiceService extends HandleErrorService {

  getProductByDealership() {
    const apiProduitConcession = environment.globalApiUrl + 'api/product/get-product-by-dealership';
    return this.http.get<any>(apiProduitConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDealershipByProduct() {
    const apiProduitConcession = environment.globalApiUrl + 'api/product/get-dealership-by-product';
    return this.http.get<any>(apiProduitConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
