<h2>Modification Paramètre</h2>
<div class="wrapper">
    <form>
      <div class="form-group col-md-3 mt-4">
        <label for="name">Nom</label>
        <input [(ngModel)]="localStorageNomParametre" class="form-control" id="name" name="name" [formControl]="nameFormControl">
      </div>
      <div class="form-group col-md-3">
        <label for="description">Description</label>
        <textarea [(ngModel)]="localStorageDescriptionParametre" class="form-control" id="description" name="description" ></textarea>
      </div>
      <div class="form-group col-md-3" >
        <label for="tag">Tag</label>
        <select [(ngModel)]="localStorageTagIDParametre" class="form-control" name="tag" id="tag">
          <option *ngFor="let tag of tagArray" [value]="tag.id">
            {{tag.nom}}
          </option>
        </select>
      </div>
      <div class="btn-group mt-4 mb-2">
        <div>
          <button (click)="selectChangeHandlerSoumettre()" class="btn btn-dark btn100px p-2 mr-2" [disabled]="nameFormControl.invalid">Modifier</button>
        </div>

        <div>
          <button (click)="selectChangeHandlerAnnuler()" class="btn btn-light border-dark btn100px p-2">Annuler</button>
        </div>
      </div>
    </form>
</div>

<!-- *************************************************************************************************************************************************************************** -->

<div class="con5">
  <div class="row btn float-right">
    <div class="float float-right">
      <button (click)="SelectChangeHandlerAjouter()" class="btn btn-warning" data-toggle="modal" data-target="#popup" aria-pressed="false" autocomplete="off">
        <mat-icon>add_circle</mat-icon> Produit
      </button>
    </div>
  </div>

  <div class="form-group">
    <div class="table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-bordered table-striped mb-0" style="border: solid silver;">
        <thead>
        <tr>
          <th>Nom de Produit</th>
          <th>Supprimer</th>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngIf = "variable?.length === 0">
            <h6 class="font-italic">Aucun parametre n'est trouvé, veuillez en rajouter</h6>
        </ng-container>

        <ng-container *ngIf = "variable?.length !== 0">
          <tr *ngFor = "let produit of variable; let i = index" style="text-align: center" [id]="i">
            <td>{{produit.nom_produit}}</td>
            <td>
              <button (click)="dialogProduit(produit.produit_id, produit.nom_produit)" class="btn btn-round-40 btn-dark m-auto">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
</div>

<!-- ************************************************************************************************************************************************************************* -->

<div id="">
  <div class="modal fade" id="popup" tabindex="-1" role="dialog" aria-labelledby="popupTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="title">Liste Des Produits</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-bordered table-striped mb-0">
              <thead>
              <tr>
                <th>No.</th>
                <th>Nom de Produits</th>
                <th>Selectionner</th>
              </tr>
              </thead>

              <tbody>
              <tr *ngFor = "let produits of variableProduit; let i = index" style="text-align: center" [id]="i">
                <th>{{i+1}}</th>
                <td>{{produits.nom_produit}}</td>
                <td>
                  <input type="checkbox" [value]="produits.produit_id" (change)="checkClick($event)"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="modal-footer">
          <button (click)="rechargerPage()" type="button" class="btn btn-warning" data-dismiss="modal">Ajouter</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ***************************************************************************************************************************************************************************** -->
