import { Component, OnInit } from '@angular/core';
import {DataOperationService} from '../../utils/data-services/data-operation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';

@Component({
  selector: 'app-modification-operation',
  templateUrl: './modification-operation.component.html',
  styleUrls: ['./modification-operation.component.scss']
})
export class ModificationOperationComponent implements OnInit {

  concessionName: any;
  idOperation: any;
  operation = {
    sofNet_id: '',
    bmw_id: null,
    categorie_id: null,
    concession_id: null,
    nom: '',
    prix: null,
    vt: null,
    requiredStandartRangeService: false,
    dynamicTest: null,
    actif: false,
    ordre: null,
    blocked: false,
    aideAdditionnel: false,
    commentaireAdditionnel: null,
    type_id: null,
    attente_autorise: false,
    iconOperation: null,
    minimum_delay: null,
    id: null
  };
  iconArray = [
    'icon-huile-moteur.png',
    'icon-filtre.png',
    'icon-plaquettes-frein.png',
    'icon-recharge-climatisation.png',
    'icon-essuie-glaces-arrière.png',
    'icon-permutation.png',
    'icon-remplacemen-des-pneus.png',
    'icon-contrôle-technique.png',
    'icon-reparation.png',
    'icon-contrôle.png',
    'icon-campagnes-techniques.png',
    'icon-climatisation.png',
    'icon-essuie-glaces-avant.png',
    'icon-vide.png',
    'icon-bougies-allumage.png',
    'icon-plaquettes-disques-frein-arrière.png',
    'icon-plaquettes-disques-frein-avant.png',
    'icon-indicateur-de-maintenance.png',
    'icon-liquide-de-freins.png',
    'icon-demande-rappel.png',
    'icon-inspection.png',
    'icon-contrôle-géométrie.png',
    'icon-reparation-parebrise.png',
    'icon-embrayage.png'
  ];
  categorieArray = [];
  typeArray = [];
  constructor( private dataService: DataOperationService, private route: Router, private activeRoute: ActivatedRoute, private dataServiceConcession: DataConcessionService ) { }

  ngOnInit() {
    this.idOperation = Number(this.activeRoute.snapshot.paramMap.get('idOperation'));
    this.getAllCategorie();
    this.getAllType();
    this.getOperationById();
  }
  updateOperation() {
    this.dataService.updateOperation(this.operation).subscribe(result => {
      this.route.navigate(['../gestion-operation/' + this.operation.concession_id]);
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-operation/' + this.operation.concession_id]);
  }
  onChangeCheckbox(type) {
    this.operation[type] = !this.operation[type];
  }
  getAllCategorie() {
    this.dataService.getAllCategorie().subscribe(result => {
      this.categorieArray = result;
    });
  }
  getOperationById() {
    this.dataService.getOperationById(this.idOperation).subscribe(result => {
      this.operation = result;
    });
  }

  getAllType() {
    this.dataService.getAllType().subscribe(result => {
      this.typeArray = result;
    });
  }

}
