import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import moment from "moment/moment";
import swal from "sweetalert2";
import {DataSnapshotScenarioService} from "../utils/data-services/data-snapshot-scenario.service";

@Component({
  selector: 'app-gestion-snapshot-scenario',
  templateUrl: './gestion-snapshot-scenario.component.html',
  styleUrls: ['./gestion-snapshot-scenario.component.scss']
})
export class GestionSnapshotScenarioComponent implements OnInit {
  dataSource = new MatTableDataSource();

  constructor( private dataService: DataSnapshotScenarioService, private route: Router ) {}
  displayedColumns: string[] = ['brand', 'label', 'operations', 'is_courtesy_car', 'is_wating', 'is_active', 'modifier'];

  ngOnInit() {
    this.dataService.getSnapshotScenario().subscribe(snapshotScenario => {
      this.dataSource.data = snapshotScenario;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  frenchDate(date: any) {
    return moment(date).format('L');
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-snapshot-scenario']);
  }
  SelectChangeHandlerModifier(snapshotScenarioId) {
    this.route.navigate(['../update-snapshot-scenario/' + snapshotScenarioId]);
  }


}
