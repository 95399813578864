import { Component, OnInit } from '@angular/core';
import {DataProduitConcessionServiceService} from "../utils/data-services/data-produit-concession-service.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-gestion-concession-produit',
  templateUrl: './gestion-concession-produit.component.html',
  styleUrls: ['./gestion-concession-produit.component.scss']
})
export class GestionConcessionProduitComponent implements OnInit {

  dealershipByProduct: any;
  dataSource: any;
  displayedColumns: string[] = ['id', 'name', 'town'];
  constructor(private dataService: DataProduitConcessionServiceService) { }

  ngOnInit() {
    this.getDealershipByProduct();
  }
  getDealershipByProduct() {
    this.dataService.getDealershipByProduct().subscribe(result => {
      this.dealershipByProduct = result;
      this.dataSource = result[0].dealership;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  arrayToDataSource(array) {
    this.dataSource = new MatTableDataSource(array);
  }
}
