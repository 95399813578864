import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { DataModificationParametreService} from '../../utils/data-services/dataModification_parametre.service';
import { DataInteractionService } from '../../utils/data-services/dataInteraction.service';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import {FormControl, Validators} from "@angular/forms";
import {DataSmsService} from "../../utils/data-services/data-sms.service";
import {DataCreationParametreService} from "../../utils/data-services/dataCreation_parametre.service";

@Component({
  selector: 'app-modification-parametre',
  templateUrl: './modification-parametre.component.html',
  styleUrls: ['./modification-parametre.component.css']
})
export class ModificationParametreComponent implements OnInit {
  parameter = {
    id: null,
    name: null,
    description: null,
    tagId: null
  };
  tagArray = [];
  nameFormControl = new FormControl('', [Validators.required]);
  variable: any;
  variableProduit: any;
  produitParametreID: any;
  result = '';
  /* ModificationParametre page display variables */
  localStorageNomParametre: any;
  localStorageDescriptionParametre: any;
  localStorageTagIDParametre: any;
  localStorageTypeParametre: any;
  localStorageBDDParametre: any;
  localStorageTablaBDDParametre: any;
  localStorageChampBDDParametre: any;
  localStorageParametreID: any;
  /* End */
  n = -1;
  array: any[] = [];
  indice: any = 0;
  nProduitParametre = 0;
  arrayProduitParametre1: any[] = [];
  arrayProduitParametre2: any[] = [];
  arrayProduitParametre3: any[] = [];

  // tslint:disable-next-line:max-line-length
  constructor(private dataCreationParametreService: DataCreationParametreService, private dataModificationParametreService: DataModificationParametreService, private tagService: DataSmsService, private dataInteractionService: DataInteractionService, private route: Router, public dialog: MatDialog) { }

  ngOnInit() {
    /* Fill page and reserve variables in case of back navigation */
    if (localStorage.getItem('softgateParametreNom') == null || localStorage.getItem('softgateParametreNom') === 'undefined') {
      this.localStorageNomParametre = '';
    } else {this.localStorageNomParametre = localStorage.getItem('softgateParametreNom'); }

    // tslint:disable-next-line:max-line-length
    if (localStorage.getItem('softgateParametreDescription') == null || localStorage.getItem('softgateParametreDescription') === 'undefined') {
      this.localStorageDescriptionParametre = '';
    } else {this.localStorageDescriptionParametre = localStorage.getItem('softgateParametreDescription'); }

    if (localStorage.getItem('softgateParametreTag') == null || localStorage.getItem('softgateParametreTag') === 'undefined') {
      this.localStorageTagIDParametre = '';
    } else {this.localStorageTagIDParametre = localStorage.getItem('softgateParametreTag'); }

    if (localStorage.getItem('softgateParametreType') == null || localStorage.getItem('softgateParametreType') === 'undefined') {
      this.localStorageTypeParametre = '';
    } else {this.localStorageTypeParametre = localStorage.getItem('softgateParametreType'); }

    if (localStorage.getItem('softgateParametreBDD') == null || localStorage.getItem('softgateParametreBDD') === 'undefined') {
      this.localStorageBDDParametre = '';
    } else {this.localStorageBDDParametre = localStorage.getItem('softgateParametreBDD'); }

    if (localStorage.getItem('softgateParametreTableBDD') == null || localStorage.getItem('softgateParametreTableBDD') === 'undefined') {
      this.localStorageTablaBDDParametre = '';
    } else {this.localStorageTablaBDDParametre = localStorage.getItem('softgateParametreTableBDD'); }

    if (localStorage.getItem('softgateParametreChampBDD') == null || localStorage.getItem('softgateParametreChampBDD') === 'undefined') {
      this.localStorageChampBDDParametre = '';
    } else {this.localStorageChampBDDParametre = localStorage.getItem('softgateParametreChampBDD'); }

    if (localStorage.getItem('softgateparametreID') == null || localStorage.getItem('softgateparametreID') === 'undefined') {
      this.localStorageParametreID = '';
    } else {this.localStorageParametreID = localStorage.getItem('softgateparametreID'); }
    /* End */
    // tslint:disable-next-line:max-line-length
    this.dataModificationParametreService.getParametreProduit(this.localStorageParametreID).subscribe(data8 => { this.variable = data8.records; });
    this.getTag();
  }
  getTag() {
    this.tagService.getTag().subscribe(result => {
      this.tagArray = result;
    });
  }
  /***
   * Delete product for a parameter
   * Parameter: produit_id, Type: Int
   * Parameter: nom_produit, Type: String
   ***/
  dialogProduit(arg: any, arg1: any): void {
    const message = ' Etes vous sûre de vouloir supprimer ' + arg1 + ' ? ';
    const dialogData = new ConfirmDialogModel('Confirmer l\'action', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: '200px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (this.result) {
        this.deleteProduit(arg);
      } else {
      }
    });
  }

  /***
   * Update parameter variable values
   ***/
  selectChangeHandlerSoumettre() {
    this.parameter.id = this.localStorageParametreID;
    this.parameter.name = this.localStorageNomParametre;
    this.parameter.description = this.localStorageDescriptionParametre;
    this.parameter.tagId = this.localStorageTagIDParametre;
    this.dataCreationParametreService.updateParameter(this.parameter).subscribe(result => {
      this.selectChangeHandlerAnnuler();
    });
  }

  /***
   * Retrieve all products for a parameter and only display the ones that don't contain the parameter
   ***/
  SelectChangeHandlerAjouter() {
    this.dataModificationParametreService.getProduitPourParametre(this.localStorageParametreID).subscribe( data1 => {
      this.arrayProduitParametre1 = data1.records;
      this.dataModificationParametreService.getProduit().subscribe( data2 => {
        this.arrayProduitParametre2 = data2.records;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.arrayProduitParametre2.length; i++) {
          if (!((this.arrayProduitParametre1).some(ID => ID.produit_id === this.arrayProduitParametre2[i].produit_id))) {
            this.arrayProduitParametre3[this.nProduitParametre] = this.arrayProduitParametre2[i];
            this.nProduitParametre++;
          }
        }
        this.variableProduit = this.arrayProduitParametre3;
      });
    });
  }

  selectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-parametre']);
  }

  /***
   * Add product parameter relation
   * Parameter: produit_id, Type: Int
   ***/
  ajouterProduit(arg: any) {
    this.dataModificationParametreService.addProduitParametre(arg, this.localStorageParametreID).subscribe( data => {
        swal.fire({
          title: 'Success!',
          text: 'Le produit a été bien rajouté',
          type: 'success',
          showConfirmButton: false
        });
    });
  }

  rechargerPage() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.array.length; i++) {
      this.ajouterProduit(this.array[i].value);
    }
    setTimeout(() => {
        window.location.reload();
      },
      2000);
  }

  /***
   * Delete product parameter relation
   * Parameter: produit_id, Type: Int
   ***/
  deleteProduit(arg: any) {
    this.dataModificationParametreService.getIdProduitParametre(arg, this.localStorageParametreID).subscribe( data => {
      this.produitParametreID = data.records;
      this.dataModificationParametreService.deleteProduitParametre((this.produitParametreID)[0].produit_parametre_id).subscribe( data1 => {
        swal.fire({
          title: 'Success!',
          text: 'Le produit a été bien supprimé',
          type: 'success',
          showConfirmButton: false
        });
        setTimeout(() => {
            window.location.reload();
          },
          1000);
      });
    });
  }

  /***
   * Create a product object array to be added to parameter based on checkbox selection
   ***/
  checkClick($event: any) {
    if ($event.target.checked) {
      this.n++;
      this.array[this.n] = {key: 'softgateAjouterProduitId' + $event.target.value, value: $event.target.value};
    } else if (!($event.target.checked)) {
      this.indice = this.array.findIndex(item => item.value === $event.target.value);
      this.array.splice(this.indice, 1);
      this.n--;
    }
  }


}
