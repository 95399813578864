import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DataIspaccService} from '../utils/data-services/dataIspacc.service';
import swal from 'sweetalert2';
import {AppComponent} from '../app.component';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-gestion-statut',
  templateUrl: './gestion-statut.component.html',
  styleUrls: ['./gestion-statut.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionStatutComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  columnsToDisplay = ['nom_concession', 'ville', 'rue', 'code_postal', 'tel', 'open'];
  expandedElement: any | null;
  concessionStatus = [];
  statusCreationForm: any;
  currentConcession = null;
  draggedStatusId = null;
  dropLocalStatusId = null;
  errors = {
    statut_local: false,
    statut_concession: false,
    statut_concession_label: false,
    id_concession: false,
  };

  constructor(
    private dataConcessionService: DataConcessionService,
    private dataIspaccService: DataIspaccService,
    private formBuilder: FormBuilder,
    private route: Router,
    private appComponent: AppComponent
  ) {
    this.statusCreationForm = this.formBuilder.group({
      id: null,
      id_concession: null,
      statut_concession: null,
      statut_concession_label: null,
      statut_local: null
    });
  }

  ngOnInit() {
    this.dataConcessionService.getConcession().subscribe(data => {
      this.dataSource.data = data.records;
    });
  }
  getStatus(concessionId): void {
    this.appComponent.isLoading = true;
    this.dataIspaccService.requestGetCorrespondanceStatutsByConcessionId(concessionId).subscribe(result => {
      // set object
      this.concessionStatus = [];
      this.concessionStatus.push({statutId: 1, name: 'Rendez-vous fixé', statusConcession: result.records.filter(x => x.statut_local == 1)});
      this.concessionStatus.push({statutId: 7, name: 'Véhicule réceptionné', statusConcession: result.records.filter(x => x.statut_local == 7)});
      this.concessionStatus.push({statutId: 2, name: 'Travaux en cours', statusConcession: result.records.filter(x => x.statut_local == 2)});
      this.concessionStatus.push({statutId: 8, name: 'Travaux en cours de finalisations', statusConcession: result.records.filter(x => x.statut_local == 8)});
      this.concessionStatus.push({statutId: 5, name: 'Travaux en attente', statusConcession: result.records.filter(x => x.statut_local == 5)});
      this.concessionStatus.push({statutId: 3, name: 'Travaux terminés', statusConcession: result.records.filter(x => x.statut_local == 3)});
      this.concessionStatus.push({statutId: 4, name: 'Véhicule restitué', statusConcession: result.records.filter(x => x.statut_local == 4)});
      this.concessionStatus.push({statutId: 6, name: 'Statuts non remontés par KIDI', statusConcession: result.records.filter(x => x.statut_local == 6)});
      this.appComponent.isLoading = false;
    }, error => {
      this.appComponent.isLoading = false;
    });
  }
  onOpenRow(concessionId): void {
    if (this.expandedElement != null) {
      this.getStatus(concessionId);
      document.getElementById('chevron-' + concessionId).style.transform = 'rotate(180deg)';
      if (this.currentConcession != null) {
        document.getElementById('chevron-' + this.currentConcession).style.transform = 'rotate(0deg)';
      }
      this.currentConcession = concessionId;
    } else {
      document.getElementById('chevron-' + concessionId).style.transform = 'rotate(0deg)';
      this.currentConcession = null;
    }
  }
  onDeleteStatus(idStatus): void {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataIspaccService.requestDeleteCorrespondanceStatutsById(idStatus).subscribe(response => {
          for (const statutLocal of this.concessionStatus) {
            // remove item from object
            statutLocal.statusConcession = statutLocal.statusConcession.filter(x => x.id != idStatus);
          }
        });
      }
    });
  }
  onSubmit(form): void {
    // reset error
    this.errors = {
      statut_local: false,
      statut_concession: false,
      statut_concession_label: false,
      id_concession: false,
    };

    form.id_concession = this.expandedElement.concession_id;

    // check errors
    if (form.id_concession == null) { this.errors.id_concession = true; }
    if (form.statut_concession == null) { this.errors.statut_concession = true; }
    if (form.statut_concession_label == null) { this.errors.statut_concession_label = true; }
    if (form.statut_local == null) { this.errors.statut_local = true; }
    const isFormValid = (
      this.errors.statut_local === false &&
      this.errors.statut_concession_label === false &&
      this.errors.statut_concession === false &&
      this.errors.id_concession === false
    );

    // send request
    if (isFormValid) {
      this.dataIspaccService.requestCreateCorrespondanceStatuts(form).subscribe(res => {
        for (const status of this.concessionStatus) {
          if (form.statut_local == status.statutId) {
            form.id = res;
            status.statusConcession.push(form);
          }
        }
        this.statusCreationForm = this.formBuilder.group({
          id: null,
          id_concession: null,
          statut_concession: null,
          statut_concession_label: null,
          statut_local: null
        });
      }, error => {
        swal.fire({
          title: 'Attention!',
          text: 'Une erreur s\'est produite lors de l\'enregistrement du statut. Si l\'erreur persiste veuillez contacter le support technique.' ,
          type: 'warning',
          confirmButtonText: 'Ok',
        });
      });
    } else {
      swal.fire({
        title: 'Attention!',
        text: 'Une erreur s\'est produite lors de l\'enregistrement du statut. Si l\'erreur persiste veuillez contacter le support technique.' ,
        type: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }
  onDragStart(event, status): void {

    this.draggedStatusId = status.id;
  }
  onDragOver(event, status): void {
    event.preventDefault();
  
    this.dropLocalStatusId = status.statutId;
  }
  onDrop(event): void {
    event.preventDefault();
    this.appComponent.isLoading = true;
    const allStatus = this.concessionStatus;
    for (const localStatus of allStatus) {
      const status = localStatus.statusConcession.filter(x => x.id == this.draggedStatusId);
      localStatus.statusConcession = localStatus.statusConcession.filter(x => x.id != this.draggedStatusId);
      if (status.length != 0) {
        const newLocalStatus = allStatus.filter(x => x.statutId == this.dropLocalStatusId);
        newLocalStatus[0].statusConcession.push(status[0]);
        this.concessionStatus = allStatus;
        const body = {
          statut_local: this.dropLocalStatusId
        };
        this.dataIspaccService.requestUpdateCorrespondanceStatuts(this.draggedStatusId, body).subscribe(res => {
          this.appComponent.isLoading = false;
        }, error => {
          this.appComponent.isLoading = true;
          swal.fire({
            title: 'Attention!',
            text: 'Une erreur s\'est produite lors de l\'enregistrement du statut. Si l\'erreur persiste veuillez contacter le support technique.' ,
            type: 'warning',
            confirmButtonText: 'Ok',
          });
        });
      }
    }
  }
  applyFilter(event: Event) {
    this.appComponent.isLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    setTimeout(() => { this.appComponent.isLoading = false; });
  }
}
