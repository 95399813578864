import { Injectable } from '@angular/core';
import {HandleErrorService} from "../handleError.service";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataSmsService extends HandleErrorService {

  getSms() {
    const apiSms = environment.globalApiUrl + 'api/sms/get-sms-by-concession';
    return this.http.get<any>(apiSms, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getTag() {
    const apiSms = environment.globalApiUrl + 'api/sms/tag';
    return this.http.get<any>(apiSms, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createTag(body) {
    const api = environment.globalApiUrl + 'api/sms/tag/create';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateTag(body) {
    const api = environment.globalApiUrl + 'api/sms/tag/update';
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteTag(id) {
    const api = environment.globalApiUrl + 'api/sms/tag/delete/' + id;
    return this.http.delete<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
