<h2>Création Utilisateur</h2>
<div class="d-flex">
  <div class="user-form">
    <form [formGroup]="userCreationForm" (ngSubmit)="onSubmit(userCreationForm.value)">
      <div class="form-row mt-4">
        <div class="form-group col-md-5">
          <label for="firstname">Prénom:</label>
          <input class="form-control" id="firstname" name="firstname" formControlName="username">
          <small *ngIf="errors.username" class="input-error" style="color: red">Champ requis</small>
        </div>
        <div class="form-group col-md-5 offset-md-1">
          <label for="lastname">Nom:</label>
          <input class="form-control" id="lastname" name="lastname" formControlName="lastname">
          <small *ngIf="errors.lastname" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row mt-5">
        <div class="form-group col-md-8">
          <label for="email">Email:</label>
          <input class="form-control" id="email" name="email" formControlName="email">
          <small *ngIf="errors.email" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row mt-5">
        <div class="form-group col-md-5">
          <label for="concessions">Concessions:</label>
          <ng-multiselect-dropdown id="concessions" name="concessions" [ngModelOptions]="{standalone: true}"
                                   [placeholder]="'Select'"
                                   [data]="dropdownConcessionList"
                                   [(ngModel)]="selectedConcession"
                                   [settings]="dropdownSettings"
                                   (onSelect)="onConcessionSelect($event)"
                                   (onDeSelect)="onConcessionDeselect($event)"
          >
          </ng-multiselect-dropdown>
          <small *ngIf="errors.concessions" class="input-error" style="color: red">Champ requis</small>
        </div>
        <div class="form-group col-md-5 offset-md-1">
          <label for="roles">Roles:</label>
          <ng-multiselect-dropdown id="roles" name="roles" [ngModelOptions]="{standalone: true}"
                                   [placeholder]="'Rôles'"
                                   [data]="dropdownRolesList"
                                   [(ngModel)]="selectedRoles"
                                   [settings]="dropdownSettings"
                                   (onSelect)="onRoleSelect($event)"
                                   (onDeSelect)="onRoleDeselect($event)"
          >
          </ng-multiselect-dropdown>
          <small *ngIf="errors.role" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row mt-5" *ngIf="hasRoleIspacc()">
        <div class="form-group col-md-11">
          <div class="d-flex align-items-center ml-2">
            <input type="checkbox" id="ispacc_right_level" name="ispacc_right_level" [value]="0" formControlName="ispacc_right_level">
            <span class="font-weight-normal ml-2" style="font-size: 12px;">Droit à l'importation de fichier de type "Commerciale".</span>
          </div>
        </div>
      </div>
      <div class="btn-pannel">
        <div>
          <button type="submit" class="btn btn100px btn-dark btn-create p-2 mr-2">Créer</button>
        </div>
        <div>
          <button class="btn btn100px btn-light btn-create border-dark p-2" type="button" routerLink="/gestion-utilisateur">Annuler</button>
        </div>
      </div>
    </form>
  </div>
  <div class="d-flex flex-wrap">
    <div class="concession-list m-4">
      <h4>Concessions Actives:</h4>
      <div *ngFor="let concession of selectedConcession">{{ concession.item_text }}</div>
    </div>
    <div class="concession-list m-4">
      <h4>Rôles actifs:</h4>
      <div *ngFor="let role of selectedRoles">{{ role.item_text }}</div>
    </div>
  </div>
</div>
