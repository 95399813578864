import { Injectable } from '@angular/core';
import {HandleErrorService} from "../handleError.service";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataSmsSubscriptionService extends HandleErrorService {

  getSubscriptions() {
    const apiSms = environment.psmsApi + 'api/subscription';
    return this.http.get<any>(apiSms, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getSubscriptionsSms() {
    const apiSms = environment.psmsApi + 'api/subscription-sms';
    return this.http.get<any>(apiSms, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getSubscriptionsById(id) {
    const apiSms = environment.psmsApi + 'api/subscription-sms/' + id;
    return this.http.get<any>(apiSms, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  createSubscriptionsSms(body) {
    const apiSms = environment.psmsApi + 'api/subscription/create';
    return this.http.post<any>(apiSms, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  updateSubscriptionsSms(body) {
    const apiSms = environment.psmsApi + 'api/subscription/update';
    return this.http.put<any>(apiSms, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  assignSubscriptionDealership(body) {
    const apiSms = environment.psmsApi + 'api/subscription/assign-dealership';
    return this.http.post<any>(apiSms, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleErrorSubscription)
    );
  }
  getSubscriptionsSmsById(id) {
    const apiSms = environment.psmsApi + 'api/subscription/' + id;
    return this.http.get<any>(apiSms, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  changeDateSubscriptionsSms(body) {
    const apiSms = environment.psmsApi + 'api/subscription/change-date';
    return this.http.put<any>(apiSms, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleErrorSubscription)
    );
  }
  stopSubscription(body) {
    const apiSms = environment.psmsApi + 'api/subscription/stop';
    return this.http.post<any>(apiSms, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  activeSubscription(body) {
    const apiSms = environment.psmsApi + 'api/subscription/active';
    return this.http.post<any>(apiSms, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  deleteSubscription(id) {
    const apiSms = environment.psmsApi + 'api/subscription/delete/' + id;
    return this.http.delete<any>(apiSms, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
