import { Component, OnInit } from '@angular/core';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-select-concessions-dialog',
  templateUrl: './select-concessions-dialog.component.html',
  styleUrls: ['./select-concessions-dialog.component.scss']
})
export class SelectConcessionsDialogComponent implements OnInit {
  concessions = [];
  dropdownConcessionList = [];
  selectedConcession = [];
  dropdownSettings = {};
  constructor(
    private dataConcessionService: DataConcessionService,
    public dialogRef: MatDialogRef<SelectConcessionsDialogComponent>,
  ) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: true
    };
    this.dataConcessionService.getConcession().subscribe(data => {
      const concessionList = [];
      for (const concession of data.records) {
        concessionList.push({ item_id: concession.concession_id, item_text: concession.nom_concession });
      }
      this.dropdownConcessionList = concessionList;
    });
  }
  getActiveConcessions() {
    this.dataConcessionService.getConcession().subscribe(data => {

    });
  }
  onConcessionSelect(item: any) {
    this.concessions.push(item.item_id);
  }
  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessions.length; i++) {
      if (this.concessions[i] === item.item_id) {
        this.concessions.splice(i, 1);
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
