import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProduitService } from 'src/app/utils/data-services/dataProduit.service';

@Component({
  selector: 'app-informations-produit',
  templateUrl: './informations-produit.component.html',
  styleUrls: ['./informations-produit.component.scss']
})
export class InformationsProduitComponent implements OnInit {

  productId = null;
  product: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private dataProduitService: DataProduitService,
    private router: Router
    ) { }

  ngOnInit() {
    this.productId = this.activeRoute.snapshot.paramMap.get('id')
    this.dataProduitService.getProductById(this.productId).subscribe(res => {
      this.product = res;
    });
  }

  backToProductList() {
    this.router.navigate(['../gestion-informations-produit']);
  }

}
