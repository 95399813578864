import { Component, OnInit } from '@angular/core';
import {DataBackupConcessionService} from "../../utils/data-services/data-backup-concession.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";

@Component({
  selector: 'app-update-backup-concession',
  templateUrl: './update-backup-concession.component.html',
  styleUrls: ['./update-backup-concession.component.scss']
})
export class UpdateBackupConcessionComponent implements OnInit {
  backupConcessionId: any;
  backupConcession = {
    monitoring_backup_dealership_id: null,
    concession: null,
    dealership_id: '',
    project: null
  };
  concessionArray = [];
  constructor( private dataServiceConcession: DataConcessionService, private dataService: DataBackupConcessionService, private route: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.backupConcessionId = Number(this.activeRoute.snapshot.paramMap.get('idBackupConcession'));
    this.getAllConcession();
    this.getBackupConcession();
  }
  updateBackupConcession() {
    this.dataService.updateBackupConcession(this.backupConcession).subscribe(result => {
      this.route.navigate(['../gestion-backup-concession']);
    });
  }
  getAllConcession() {
    this.dataServiceConcession.getConcession().subscribe(result => {
      this.concessionArray = result['records'];
    });
  }
  getBackupConcession() {
    this.dataService.getBackupConcessionById(this.backupConcessionId).subscribe(result => {
      this.backupConcession = result;
      this.backupConcession.monitoring_backup_dealership_id = this.backupConcessionId;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-backup-concession']);
  }
  onChangeCheckbox(type) {
    this.backupConcession[type] = !this.backupConcession[type];
  }
}
