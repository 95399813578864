<h2>Création d'une surveillance de sauvegardes</h2>
<div class="wrapper">
  <form>
    <div class="form-group mt-3" style="width: 445px">
      <label for="brand">Concession</label>
      <mat-select [(ngModel)]="backupConcession.dealership_id" (click)="focusSearch('dealership')" class="form-control" name="brand" id="brand">
        <input id="select-search-dealership" (keyup)="dealershipFilter($event)" autocomplete="off" placeholder="Rechercher" class="input-search-select form-control custom-search-input" (keydown)="$event.stopPropagation()">
        <mat-option *ngFor="let concession of concessionArray.filtered" [value]="concession.concession_id">
          {{concession.nom_concession}}
        </mat-option>
      </mat-select>
    </div>
    <div class="form-group mt-3" style="width: 445px">
      <label for="count_actual_site">Projet :</label>
      <input [(ngModel)]="backupConcession.project" class="form-control" id="count_actual_site"
             name="count_actual_site">
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createBackupConcession()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>
      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
