import {HttpHeaders} from '@angular/common/http';

export const environment = {
  production: false,
  website: 'softgate',
  globalUrlDomaine: 'https://dev-auth-api.softgate.fr/api/resources',
  globalApiUrl: 'https://dev-auth-api.softgate.fr/',
  ispaccUrlDomaine: 'https://dev-api-ispacc.altimisgroup.fr',
  pngoApi: 'https://int-apv.altimisgroup.fr/',
  psmsApi: 'https://dev-api-psms.altimisgroup.fr/',
  softgateFrontUrl: 'https://dev-dashboard-cs.softgate.fr',
  tokenBooklet: '3wo1l76bj8dewngpi828',
  httpOptions: {headers: new HttpHeaders({
      'X-Nrg-Token': '1OggdeqJNDQFJ2ip8<_a',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
  })},
  httpOptionsSGApi: {headers: new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      'Content-Type': 'application/json'
    })},
  httpOptionMultipart: {headers: new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      'Content-Type': 'Multipart/form-data',
    })},
  httpOptionSimple: {headers: new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    })},
  ///////////////////////
  // Maintenances Urls //
  ///////////////////////
  syncUcarUrl: 'https://int-apv.altimisgroup.fr/ucar/UcarUSolClient/getVehiclesId',
  anonymizationPngoUrl: 'https://int-apv.altimisgroup.fr/api/planning/appointment/anonymize',
  fixFirstDatePngoUrl: 'https://int-apv.altimisgroup.fr/api/planning/snapshots/retry/scenario',
  ipsMonitoringPngoUrl: 'https://int-apv.altimisgroup.fr/api/monitoring/check',
};
