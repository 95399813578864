import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {DataFormationService} from '../../utils/data-services/dataFormation.service';
import swal from "sweetalert2";
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-creation-formation',
  templateUrl: './creation-formation.component.html',
  styleUrls: ['./creation-formation.component.scss']
})
export class CreationFormationComponent implements OnInit {
  formationCreationForm: any;
  image: File = null;
  imgBase64 = null;
  pdf: File = null;
  pdfBase64 = null;
  error = {
    title: false,
    type: false,
    imageType: false,
    pdfFormat: false,
    pdfRequired: false
  };
  products = [];

  constructor(
    private formBuilder: FormBuilder,
    private formationService: DataFormationService,
    private route: Router,
    private http: HttpClient
  ) {
    this.formationCreationForm = this.formBuilder.group({
      id: null,
      title: null,
      image: null,
      description: null,
      notification: null,
      type: null,
      product: '9999',
      tuto: 0,
      is_active: false,
      pdf: null
    });
  }

  ngOnInit() {
    this.http.get('/assets/Imgs/computer-1185569_640.jpg', { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onload = () => {
          this.imgBase64 = reader.result;
          document.getElementById('image-preview').setAttribute('src', reader.result as string);
        };
        reader.readAsDataURL(res);
      });
    this.formationService.getProducts().subscribe(res => {
      this.products = res.records.filter(x => x.type == 'commercial');
    });
  }
  resetTuto(): any {
    this.formationCreationForm.value.tuto = false;
  }
  setImage(event) {
    this.error.imageType = false;
    const allowedImageType = ['image/png', 'image/jpg', 'image/jpeg'];
    this.image = event.target.files[0];
    if (!allowedImageType.includes(event.target.files[0].type)) {
      this.error.imageType = true;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.imgBase64 = reader.result;
        document.getElementById('image-preview').setAttribute('src', reader.result as string);
      };
      reader.readAsDataURL(this.image);
    }
  }
  setPdf(event) {
    this.error.pdfFormat = false;
    const allowedType = ['application/pdf'];
    this.pdf = event.target.files[0];
    if (!allowedType.includes(event.target.files[0].type)) {
      this.error.pdfFormat = true;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.pdfBase64 = reader.result;
      };
      reader.readAsDataURL(this.pdf);
    }
  }

  onSubmit(post): any {
    post.image = this.imgBase64;
    post.pdf = this.pdfBase64;
    this.error = {
      title: false,
      type: false,
      imageType: false,
      pdfFormat: false,
      pdfRequired: false
    };
    post.is_active = post.is_active === false ? 0 : 1;
    if (post.title === '' || post.title === null) {
      this.error.title = true;
    }
    if (post.type === '' || post.type === null) {
      this.error.type = true;
    }
    if (post.pdf === '' || post.pdf === null) {
      this.error.pdfRequired = true;
    }
    if (!this.error.type && !this.error.title && !this.error.imageType && !this.error.pdfRequired && !this.error.pdfFormat) {
      this.formationService.createFormation(post).subscribe(response => {
        swal.fire({
          title: 'Success!',
          text: 'La formation a bien été créée',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(() => {
          this.route.navigate(['/gestion-formation']);
        });
      }, error => {
        swal.fire({
          title: 'Oops...!',
          text: 'Une erreur est surnevue lors de la sauvegarde..',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
}
