import {Component, Inject, OnInit} from '@angular/core';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {DataFonctionService} from '../utils/data-services/data-fonction.service';
import {Router} from '@angular/router';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {DataContactService} from '../utils/data-services/dataContact.service';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import swal from 'sweetalert2';
import {DialogContactDuplicateDialog} from '../gestion-contact/creation-contact/creation-contact.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-gestion-fonction',
  templateUrl: './gestion-fonction.component.html',
  styleUrls: ['./gestion-fonction.component.scss']
})
export class GestionFonctionComponent implements OnInit {
  fonctions: MatTableDataSource<any>;
  fonctionForm: FormGroup;
  fonctionName: FormControl;
  isEditing = false;
  editedFonctionId: number;
  sort = null;
  fonctionDuplicateList = [];

  constructor(
    public dialog: MatDialog,
    private dataService: DataFonctionService,
    private formBuilder: FormBuilder
  ) {
    this.fonctions = new MatTableDataSource<any>();
    this.fonctionName = new FormControl(null);
    this.fonctionForm = this.formBuilder.group({
      fonctionName: null
    });
  }

  ngOnInit() {
    this.getFonctions();
  }
  getFonctions() {
    this.dataService.getFonction().subscribe(fonctions => {
      this.fonctions.data = fonctions;
    });
  }
  onSubmit() {
    if (!this.isEditing) {
      this.dataService.createFonction({valeur: this.fonctionName.value}).subscribe(res => {
        swal.fire({
          title: 'Success!',
          text: 'La fonction ' + this.fonctionName.value + ' a bien été créé',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getFonctions();
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création de la fonction',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    } else {
      this.dataService.updateFonction({idConf: this.editedFonctionId, valeur: this.fonctionName.value}).subscribe(res => {
        swal.fire({
          title: 'Success!',
          text: 'La fonction ' + this.fonctionName.value + ' a bien été modifié',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getFonctions();
        document.getElementById('fonction-create-update-title').innerText = 'Ajouter une fonction';
        document.getElementById('fonction-create-update-btn').innerText = 'Ajouter la fonction';
        this.fonctionName = new FormControl(null);
        this.isEditing = false;
        this.editedFonctionId = null;
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la modification de la fonction',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onEdit(id, fonctionName) {
    this.isEditing = true;
    this.editedFonctionId = id;
    this.fonctionName = new FormControl(fonctionName);
    scroll(0, 0);
    document.getElementById('fonction-create-update-btn').innerText = 'Modifier la fonction';
    document.getElementById('fonction-create-update-title').innerText = 'Modifier une fonction';
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataService.deleteFonction(id).subscribe(res => {
          swal.fire({
            title: 'Success!',
            text: 'La fonction ' + this.fonctionName.value + ' a bien été supprimé',
            type: 'success',
            confirmButtonText: 'Ok'
          });
          this.getFonctions();
        }, error => {
          swal.fire({
            title: 'Erreur!',
            text: 'Un problème est survenu lors de la suppression de la fonction',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  onCancel() {
    document.getElementById('fonction-create-update-title').innerText = 'Ajouter une fonction';
    document.getElementById('fonction-create-update-btn').innerText = 'Ajouter la fonction';
    this.fonctionName = new FormControl(null);
    this.isEditing = false;
    this.editedFonctionId = null;
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.fonctions.filteredData = this.fonctions.filteredData.sort((a, b) => {
        return a.nom < b.nom ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.fonctions.filteredData = this.fonctions.filteredData.sort((a, b) => {
        return a.nom > b.nom ? 1 : -1;
      });
    }
  }
  sortId() {
    if (this.sort == 'idAcs') {
      this.sort = 'idDesc';
      this.fonctions.filteredData = this.fonctions.filteredData.sort((a, b) => {
        return a.fonction_id < b.fonction_id ? 1 : -1;
      });
    } else {
      this.sort = 'idAcs';
      this.fonctions.filteredData = this.fonctions.filteredData.sort((a, b) => {
        return a.fonction_id > b.fonction_id ? 1 : -1;
      });
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.fonctions.filter = filterValue.trim();
  }
  isFonctionDuplicate(name) {
    this.dataService.getFonction().subscribe(fonctions => {
      for (const fonction of fonctions) {
        if (fonction.valeur == name.value ) {
          this.fonctionDuplicateList.push(fonction);
        }
      }
      if (this.fonctionDuplicateList.length != 0) {
        this.openDialog();
      } else {
        this.onSubmit();
      }
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogFonctionDuplicateDialog, {
      width: '1200px',
      data: {fonctionList: this.fonctionDuplicateList},
    });

    dialogRef.afterClosed().subscribe(isValid => {
      if (isValid){
        this.onSubmit()
      }
    });
  }
}
@Component({
  selector: 'dialog-fonction-duplicate-dialog',
  templateUrl: 'dialog-fonction-duplicate-dialog.html',
})
export class DialogFonctionDuplicateDialog implements OnInit {
  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<DialogFonctionDuplicateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  displayedColumns: string[] = ['id', 'nom'];
  dataSource: any;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.fonctionList);
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
