import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataFaqService {
  private errorMessage: string;
  api = environment.globalApiUrl + 'api/resources/records';
  apiQuestion = environment.globalApiUrl + 'api/faq';

  // crudFormation = environment.globalApiUrl + 'api/formation';
  constructor(private http: HttpClient) {
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    // tslint:disable-next-line:max-line-length
    this.errorMessage = (error.error instanceof ErrorEvent) ? `Error: ${error.error.message}` : `Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(this.errorMessage);
  }

  // CRUD Questions
  getAllQuestions() {
    return this.http.get<any>(this.api + '/faq_question', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getQuestionById(id) {
    return this.http.get<any>(this.apiQuestion + '/get/' + id, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  postQuestion(post) {
    return this.http.post<any>(this.apiQuestion + '/create', JSON.stringify(post), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  updateQuestion(id, post) {
    return this.http.put<any>(this.apiQuestion + '/update', JSON.stringify(post), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  deleteQuestion(id) {
    return this.http.delete<any>(this.apiQuestion + '/delete/' + id, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }

  // CRUD Grand themes
  getAllGrandThemes() {
    return this.http.get<any>(this.api + '/faq_grand_theme', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getGrandThemeById(id) {
    return this.http.get<any>(this.api + '/faq_grand_theme/' + id, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  postGrandTheme(post) {
    return this.http.post<any>(this.api + '/faq_grand_theme', JSON.stringify(post), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  updateGrandTheme(id, post) {
    return this.http.put<any>(this.api + '/faq_grand_theme/' + id, JSON.stringify(post), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  deleteGrandTheme(id) {
    return this.http.delete<any>(this.api + '/faq_grand_theme/' + id, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
