import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataConcessionService extends HandleErrorService {

  getConcession() {
    const apiConcession = environment.globalUrlDomaine + '/records/concession';
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConcessionById(id) {
    const apiConcession = environment.globalUrlDomaine + '/records/concession/' + id;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConcessionByUserId(userId) {
    const apiConcession = environment.globalUrlDomaine + '/records/user_concession?filter=user_id,eq,' + userId;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConcessionByRuleId(ruleId) {
    const apiConcession = environment.globalUrlDomaine + '/records/concession_rule?filter=rule_id,eq,' + ruleId;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getAllCommentsKidi() {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi';
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getCommentsKidiGeneral() {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi?filter=concession_id,is,null';
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getCommentsKidiByConcession(id) {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi?filter=concession_id,eq,' + id;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getOneCommentsKidiGeneral(id) {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi/'+id;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  postCommentsKidi(body) {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi';
    return this.http.post<any>(apiConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  putCommentsKidi(body, id) {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi/' + id;
    return this.http.put<any>(apiConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteCommentsKidi(id) {
    const apiConcession = environment.globalUrlDomaine + '/records/comment_kidi/' + id;
    return this.http.delete<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  
  concessionProducts(body) {
    const apiConcession = environment.globalApiUrl + 'api/concession/concession-products';
    return this.http.post<any>(apiConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
