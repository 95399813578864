<div class="home-bgd-img"><img src="assets/Imgs/computer-supprt.jpg" class="w-100" alt="image d'arrière plan"></div>
<div class="home-content">
  <div class="pt-5">
    <h1 *ngIf="appComponent.isConnected" class="home-title"><strong class="text-center white-text-shadow">Accueil Portail-SoftGate</strong></h1>
    <h1 *ngIf="!appComponent.isConnected" class="home-title"><strong class="text-center white-text-shadow">Portail-SoftGate</strong></h1>
    <!--<div class="home-inner-content" *ngIf="appComponent.isConnected">
          <h3>Travaux en cours...</h3>
          <div class="d-flex justify-content-center">
            <iframe src="https://giphy.com/embed/f74WDV59cP0NArh8gu" width="240" height="240" frameBorder="0"
                    class="giphy-embed"></iframe>
          </div>
        </div>-->
    <div *ngIf="appComponent.isConnected">
      <h3 class="mt-5">Vous êtes connecté sur le Portail-SoftGate</h3>
    </div>
    <div *ngIf="!appComponent.isConnected">
      <h3 class="mt-5">Veuillez vous connecter pour accéder au Portail-SoftGate</h3>
      <div class="mb-2 mt-5 text-center"><button class="btn btn100px btn-dark" routerLink="/login">Connexion</button></div>
    </div>
  </div>
  <div class="link-list-home d-none" *ngIf="appComponent.isConnected">
    <div class="mt-5">
      <div class="mb-2">Liens Utiles :</div>
      <div class="mb-2"><a routerLink="/consultation">Consultations</a></div>
      <div class="mb-2"><a routerLink="/gestion-concession">Gestion Concessions</a></div>
      <div class="mb-2"><a routerLink="/gestion-produit">Gestion Produits</a></div>
      <div class="mb-2"><a routerLink="/gestion-parametre">Gestion Paramètres</a></div>
      <div class="mb-2"><a routerLink="/gestion-utilisateur">Gestion Utilisateurs</a></div>
    </div>
    <div class="mt-5">
      <div class="mb-2">KIDI :</div>
      <div class="mb-2"><a routerLink="/gestion-regle">Gestion Règles</a></div>
      <div class="mb-2"><a routerLink="/gestion-formation">Gestion Formations</a></div>
      <div class="mb-2"><a routerLink="/gestion-notification">Gestion Notifications</a></div>
      <div class="mb-2"><a routerLink="/gestion-statut">Gestion Statuts</a></div>
      <div class="mb-2"><a routerLink="/gestion-statut">FAQ - Grands Thèmes</a></div>
      <div class="mb-2"><a routerLink="/gestion-statut">FAQ - Question</a></div>
    </div>
  </div>
</div>
