<form [formGroup]="form" (ngSubmit)="SelectChangeHandlerCreer(form.value)">
  <div class="inputs-section pt-5">
    <div class="box1 col-sm-3" >
      <label>Concessions</label>
      <div *ngIf="numberIdConcession != 'all' && numberIdConcession != 'undefined'">{{ currentConcession[0]?.nom_concession }}</div>
      <select #co class="form-control" *ngIf="numberIdConcession == 'all' || numberIdConcession == 'undefined'" (change)="setInStorage(co.value, 'concession')">
        <option style="text-align: center">Choisir une concession</option>
        <option *ngFor = "let concession of concessions" style="text-align: center" [value]="concession.concession_id">
          {{concession.nom_concession}}
        </option>
      </select>
    </div>

    <div class="box2 col-sm-3">
      <label>Produits</label>
      <div *ngIf="numberIdProduit != 'all' || numberIdProduit !== 'undefined'">{{currentProduct[0]?.nom_produit}}</div>
      <select #pr class="form-control" *ngIf="numberIdProduit == 'all' || numberIdProduit === 'undefined'" (change)="setInStorage(pr.value, 'product'); filterParameterByProduct()">
        <option style="text-align: center">Choisir un produit</option>
        <option *ngFor = "let produit of products" style="text-align: center" [value]="produit.produit_id">
          {{produit.nom_produit}}
        </option>
      </select>
    </div>

    <div class="box3 col-sm-3">
      <label>Parametres</label>
      <div *ngIf="numberIdParametre.length == 1">{{numberIdParametre[0]?.nom_parametre}}</div>
      <select #pa class="form-control" *ngIf="numberIdParametre.length !== 1 || numberIdParametre == 'undefined'" (change)="changeParameterId(pa.value)">
        <option style="text-align: center">Choisir un paramètre</option>
        <option *ngFor = "let parametre of parameters.filtered" style="text-align: center" (click)="setInStorage(parametre, 'parameter')" [value]="parametre.parametre_id">
          {{parametre.nom_parametre}}
        </option>
      </select>
    </div>

    <div class="box3 col-sm-2">
      <label>Marque</label>
      <select class="form-control" (change)="SelectChangeHandlerMarque($event)">
        <option *ngFor = "let marque of arrayMarque" style="text-align: center" [value]="marque.marque_id">
          {{marque.nom}}
        </option>
      </select>
    </div>
  </div>
  <div class="container">
    <div class="mt-2 value-container">
      <div sm="10" class="mt-5">
        <textarea formControlName="value" placeholder="Insérer la valeur" rows="12" cols="75" no-auto-shrink></textarea>
      </div>
    </div>
  </div>
  <div class="date">
    <div class="dates-container">
      <div class="box41 col-6 col-md-3">
        <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
          <mat-label>Date de Début</mat-label>
          <input matInput [(ngModel)]="datepickerData1" [matDatepicker]="picker1" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="box51 col-6 col-md-3">
        <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
          <mat-label>Date de Fin</mat-label>
          <input matInput [(ngModel)]="datepickerData2" [matDatepicker]="picker2" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="btn-section">
    <div class="row btn">
      <div class="btn1">
        <button type="submit" class="btn btn-dark btn100px">Creer</button>
      </div>
      <div class="btn2">
        <button (click)="SelectChangeHandlerAnnuler()" class="btn btn-light border-dark border-dark btn100px">Annuler</button>
      </div>
    </div>
  </div>
</form>
