<div class="home-bgd-img"><img src="assets/Imgs/computer-supprt.jpg" class="w-100" alt="image d'arrière plan"></div>
<div class="list-group pt-5">
  <div class="reset-pwd-form-container mt-6">
    <h3 class="titre">Réinitialisation du mot de passe</h3>
    <div class="form-content" *ngIf="!isEmailConfirmed">
      <form [formGroup]="resetPwdMailForm" (ngSubmit)="onSubmit(resetPwdMailForm.value)" style="margin-top: 50px">
        <div class="form-group">
          <label for="resetPasswordMail">Email du compte</label>
          <input type="email" class="form-control" id="resetPasswordMail" placeholder="Insérez votre Email"
                 name="email" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                 ngModel required formControlName="email">
        </div>
        <div>
          <small>
            Entrez votre adresse email et nous vous enverrons un lien de réinitialisation de votre mot de passe
          </small>
        </div>
        <div class="btn-group row">
          <button type="submit" class="btn btn100px btn-dark p-2 mr-2">Valider</button>
          <button type="button" (click)="Annuler()" class="btn btn100px btn-light border-dark p-2">Annuler</button>
        </div>
      </form>
    </div>
    <div *ngIf="isEmailConfirmed" style="text-align: center">
      <p>
        Un email contenant un lien de réinitialisation de votre mot de passe vous a été envoyé à l'adresse:
        <strong>{{ userMail }}</strong>. Ce lien expirera dans <strong>{{ tokenLifeTime }}</strong> heures.
      </p>
      <p>
        Rien reçu ? Regardez dans vos courriers indésirables ou
        <a href="/reset-password">essayez à nouveau</a>.
      </p>
    </div>
  </div>
</div>
<!--Loader/Spinner-->
<app-spinner *ngIf="isSubmited"></app-spinner>
