<div class="inputs-section pt-5">
  <div class="box1 col-sm-3" >
    <label>Concessions</label>
    <!-- <div *ngIf="numberIdConcession != 'all' || numberIdConcession != 'undefined'">{{ currentConcession[0]?.nom_concession }}</div> -->
    <select #co class="form-control" [ngModel]="numberIdConcession" (change)="setInStorage(co.value, 'concession')">
      <option style="text-align: center">Choisir une concession</option>
      <option *ngFor = "let concession of concessions" style="text-align: center" [value]="concession.concession_id">
        {{concession.nom_concession}}
      </option>
    </select>
  </div>

  <div class="box2 col-sm-3">
    <label>Produits</label>
    <!-- <div *ngIf="numberIdProduit != 'all' || numberIdProduit != 'undefined'">{{currentProduct[0]?.nom_produit}}</div> -->
    <select #pr class="form-control" [ngModel]="numberIdProduit" (change)="setInStorage(pr.value, 'product')">
      <option style="text-align: center">Choisir un produit</option>
      <option *ngFor = "let produit of products" style="text-align: center" [value]="produit.produit_id">
        {{produit.nom_produit}}
      </option>
    </select>
  </div>

  <div class="box3 col-sm-3">
    <label>Parametres</label>
    <!-- <div *ngIf="numberIdParametre != 'all' || numberIdParametre != 'undefined'">{{currentParameter[0]?.nom_parametre}}</div> -->
    <select #pa class="form-control" [ngModel]="numberIdParametre" (change)="setInStorage(pa.value, 'parameter')">
      <option style="text-align: center">Choisir un paramètre</option>
      <option *ngFor = "let parametre of parameters" style="text-align: center" [value]="parametre.parametre_id">
        {{parametre.nom_parametre}}
      </option>
    </select>
  </div>

  <div class="box3 col-sm-2">
    <label>Marque</label>
    <select class="form-control" (change)="SelectChangeHandlerMarque($event)">
      <option *ngFor = "let marque of arrayMarque" style="text-align: center" [value]="marque.marque_id">
        {{marque.nom}}
      </option>
    </select>
  </div>
</div>


<div class="container">
  <div class="mt-2 value-container">
    <div sm="10" class="mt-5">
      <textarea [(ngModel)]="textValeur" placeholder="Insérer la valeur" rows="12" cols="75" no-auto-shrink></textarea>
    </div>
  </div>
</div>

<div class="date">
  <div class="dates-container">
    <div class="box41 col-6 col-md-3">
      <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
        <mat-label>Date de Début</mat-label>
        <input matInput [(ngModel)]="datepickerData1" [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="box51 col-6 col-md-3">
      <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
        <mat-label>Date de Fin</mat-label>
        <input matInput [(ngModel)]="datepickerData2" [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="btn-section">
  <div class="row btn">
    <div class="btn1">
      <button (click)="SelectChangeHandlerCreer()" class="btn btn-dark btn100px">Dupliquer</button>
    </div>

    <div class="btn2">
      <button (click)="SelectChangeHandlerAnnuler()" class="btn btn100px border-dark btn-light">Annuler</button>
    </div>
  </div>
</div>
