<div class="content-404">
  <iframe src="https://giphy.com/embed/3oEduTObiUMV3vD69y" width="180" height="180" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
  <div class="code-404"><strong>404</strong></div>
  <div class="text-404">La page que vous recherchez semble introuvable..</div>
  <div>Il se peut que vous ayez renseigné la mauvaise adresse dans l'url ou que la page que vous recherchez n'existe plus.</div>
  <div class="mt-5">
    <div class="mb-3">Voici quelques liens utiles à la place :</div>
    <div class="mb-2"><a *ngIf="!appComponent.isConnected" routerLink="/login">Connexion</a></div>
    <div class="mb-2"><a *ngIf="appComponent.isConnected" routerLink="/consultation">Consultations</a></div>
    <div class="mb-2"><a *ngIf="appComponent.isConnected" routerLink="/gestion-utilisateur">Gestion Utilisateurs</a></div>
  </div>
</div>
