<h2>FAQ - Modification question</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="faq-question-form">
      <div class="form-row">
        <div class="form-group">
          <label for="question">Question:</label>
          <input type="text" class="form-control" name="question" id="question" formControlName="question" required>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="answer">Réponse (
            <span *ngIf="nbCaracter > 1000" class="text-danger">{{nbCaracter}}</span>
            <span *ngIf="nbCaracter <= 1000">{{nbCaracter}}</span>
            /1000):
          </label>
          <textarea type="text" rows="10" cols="40" (keyup)="countCaraters($event)" class="form-control" name="answer" id="answer" formControlName="answer" required></textarea>
        </div>
      </div>
      <div class="form-row" style="align-self: baseline;margin-left: 100px;">
        <div class="form-group checkbox-form">
          <label for="isActive">Actif:</label>
          <input class="form-control" type="checkbox" id="isActive" name="isActive" formControlName="is_active" style="width: 20px">
        </div>
      </div>
      <div class="btn-pannel">
        <div>
          <button type="submit" class="btn btn100px btn-dark btn-create p-2 mr-2">Modifier</button>
        </div>
        <div>
          <button class="btn btn100px btn-light border-dark btn-create p-2" type="button" routerLink="/gestion-faq-question">Annuler</button>
        </div>
      </div>
    </form>
  </div>
  <div>
    <div class="form-group" style="margin: 50px; width: 300px">
      <label for="themes">Thèmes:</label>
      <ng-multiselect-dropdown id="themes" name="themes" [ngModelOptions]="{standalone: true}"
                               [placeholder]="'Select'"
                               [data]="dropdownThemesList"
                               [(ngModel)]="selectedThemes"
                               [settings]="dropdownSettings"
                               (onSelect)="onThemeSelect($event)"
                               (onDeSelect)="onThemeDeselect($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div>
      <div class="concession-list" style="margin: 50px; overflow: hidden;">
        <div class="font-weight-bold">Thèmes actifs:</div>
        <div *ngIf="selectedThemes.length === 0">Aucun thème selectionné</div>
        <ul>
          <li *ngFor="let theme of selectedThemes">{{ theme.item_text }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
