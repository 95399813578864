<h1 class="text-center mt-2">Gestion des opérations de {{concessionName}}</h1>
<div class="container-row top-field">
  <div class="btn1">
    <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
      <mat-icon>add_circle</mat-icon> Opération
    </button>
  </div>
  <mat-form-field>
    <mat-label>Rechercher</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">

    <ng-container matColumnDef="sofNet_id">
      <th mat-header-cell *matHeaderCellDef> ID SOFT Net </th>
      <td mat-cell *matCellDef="let element"> {{element.sofNet_id}} </td>
    </ng-container>

    <ng-container matColumnDef="requiredStandartRangeService">
      <th mat-header-cell *matHeaderCellDef> Gamme standard </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.requiredStandartRangeService">check</mat-icon>
        <mat-icon *ngIf="!element.requiredStandartRangeService">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="bmw_id">
      <th mat-header-cell *matHeaderCellDef> ID BMW </th>
      <td mat-cell *matCellDef="let element"> {{element.bmw_id}} </td>
    </ng-container>

    <ng-container matColumnDef="categorie">
      <th mat-header-cell *matHeaderCellDef> Catégorie </th>
      <td mat-cell *matCellDef="let element"> {{element.categorie}} </td>
    </ng-container>

    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
    </ng-container>

    <ng-container matColumnDef="vt">
      <th mat-header-cell *matHeaderCellDef > Temps (VT) </th>
      <td mat-cell *matCellDef="let element"> {{element.vt}} </td>
    </ng-container>

    <ng-container matColumnDef="actif">
      <th mat-header-cell *matHeaderCellDef > Actif </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.actif">check</mat-icon>
        <mat-icon *ngIf="!element.actif">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="ordre">
      <th mat-header-cell *matHeaderCellDef > Ordre </th>
      <td mat-cell *matCellDef="let element"> {{element.ordre}} </td>
    </ng-container>

    <ng-container matColumnDef="blocked">
      <th mat-header-cell *matHeaderCellDef > Bloquée </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.blocked">check</mat-icon>
        <mat-icon *ngIf="!element.blocked">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="dynamicTest">
      <th mat-header-cell *matHeaderCellDef > Test dynamique </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.dynamicTest">check</mat-icon>
        <mat-icon *ngIf="!element.dynamicTest">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="commentaireAdditionnel">
      <th mat-header-cell *matHeaderCellDef > Commentaire additionnel </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.commentaireAdditionnel">
<!--          {{element.commentaireAdditionnel}}-->
          <mat-icon (click)="onClickMessage(element.commentaireAdditionnel)" class="concession-icon pointer"> message</mat-icon>
        </div>
        <div *ngIf="!element.commentaireAdditionnel">-</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef > Type </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>aideAdditionnel

    <ng-container matColumnDef="attente_autorise">
      <th mat-header-cell *matHeaderCellDef > Attente autorisé </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.attente_autorise">check</mat-icon>
        <mat-icon *ngIf="!element.attente_autorise">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="aideAdditionnel">
      <th mat-header-cell *matHeaderCellDef > Aide additionnelle </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.aideAdditionnel">check</mat-icon>
        <mat-icon *ngIf="!element.aideAdditionnel">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="iconOperation">
      <th mat-header-cell *matHeaderCellDef > Icône </th>
      <td mat-cell *matCellDef="let element"><img src="assets/icons_operations_PNGO/{{element.iconOperation}}"></td>
    </ng-container>

    <ng-container matColumnDef="minimum_delay">
      <th mat-header-cell *matHeaderCellDef > Délai minimum </th>
      <td mat-cell *matCellDef="let element"> {{element.minimum_delay}} </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button class="m-2 btn btn-round-40 btn-dark" (click)="SelectChangeHandlerModifier(element.id)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
