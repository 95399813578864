<h1 class="text-center mt-2" id="brand-manager-top-page">Gestion des marques</h1>

<div class="form-add-brand mt-4">
  <h3 id="brand-create-update-title">Ajouter une marque : </h3>
  <form [formGroup]="brandForm" (ngSubmit)="onSubmit()"
        class="d-flex brand-form mr-5">
    <mat-form-field style="width: 400px" class="mr-5 mt-4">
      <mat-label>Nom de la marque</mat-label>
      <input matInput [formControl]="brandName">
    </mat-form-field>
    <div class="row">
      <button type="submit" class="btn btn-dark d-flex align-items-center btn-create p-2 ml-4'" id="brand-create-update-btn">
        <mat-icon class="mr-2">add_circle</mat-icon>
        Ajouter la marque
      </button>
      <button (click)="onCancel()" *ngIf="isEditing" class="ml-2 btn btn-outline-dark d-flex align-items-center btn-create p-2 ml-4'">
        Annuler
      </button>
    </div>
  </form>
</div>

<div class="user-table">
  <div class="mt-4 col-12 text-right d-flex justify-content-end align-items-center">
    <mat-form-field style="width: 250px">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>
<table class="mt-3 table table-striped table-bordered table-brand">
  <thead>
  <th style="width: 100px">ID</th>
  <th>Nom</th>
  <th style="width: 50px">Modifier</th>
  <th style="width: 50px">Supprimer</th>
  </thead>
  <tbody>
  <tr *ngFor="let brand of brands.data">
    <td class="text-center">{{brand.marque_id}}</td>
    <td class="text-center">{{brand.nom}}</td>
    <td class="text-center" style="cursor: pointer" (click)="onEdit(brand.marque_id, brand.nom)"><mat-icon>edit</mat-icon></td>
    <td class="text-center" style="cursor: pointer; color: red;" (click)="onDelete(brand.marque_id)" *ngIf="brand.marque_id != 1"><mat-icon>delete</mat-icon></td>
  </tr>
  </tbody>
</table>

