import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {DataNotificationService} from '../../utils/data-services/dataNotification.service';
import swal from "sweetalert2";
import {not} from 'rxjs/internal-compatibility';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-creation-notification',
  templateUrl: './creation-notification.component.html',
  styleUrls: ['./creation-notification.component.scss']
})
export class CreationNotificationComponent implements OnInit {
  notificationCreationForm;
  isLink = false;
  linkLabel = '';
  linkUrl = '';
  targets = [];
  selectedTarget = ['softgate'];
  types = [
    {label: 'Problème', name: 'danger'},
    {label: 'Divers', name: 'warning'},
    {label: 'Information', name: 'info'},
    {label: 'Résolution', name: 'success'},
  ];
  error = {
    title: false,
    type: false,
    target: false,
    notification: false,
  };
  createIndex = 1;

  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private dataNotificationService: DataNotificationService,
    private appComponent: AppComponent,
  ) {
    this.notificationCreationForm = this.formBuilder.group({
      id: null,
      title: '',
      type: 'danger',
      target: [],
      text: null,
      is_active: false,
    });
  }

  ngOnInit() {
    this.dataNotificationService.getNotificationTargets().subscribe(res => {
      this.targets = res;
    });
  }

  addLinkForm(): void {
    this.isLink = !this.isLink;
    if (!this.isLink) {
      this.linkLabel = '';
      this.linkUrl = '';
    }
  }
  addLink(): void {
    const value = this.notificationCreationForm.controls.text.value ? this.notificationCreationForm.controls.text.value : '';
    this.notificationCreationForm.controls.text.value = value +
      '<a href="' + this.linkUrl + '" target="_blank">' + this.linkLabel + '</a>';
    this.addLinkForm();
  }

  onSubmit(post): any {
    this.appComponent.isLoading = true;
    this.error = {
      title: false,
      type: false,
      target: false,
      notification: false,
    };
    post.is_active = post.is_active === false ? 0 : 1;
    if (post.title === '' || post.title === null) {
      this.error.title = true;
    }
    if (post.type === '' || post.type === null) {
      this.error.notification = true;
    }
    if (!this.error.notification && !this.error.title) {
      this.createIndex = 1;
      for (const target of this.selectedTarget) {
        this.dataNotificationService.getNotificationByTargetAndIsActive(target).subscribe(notification => {
          if (notification.records.length > 0) {
            notification.records[0].is_active = 0;
            this.dataNotificationService.updateNotification(notification.records[0], notification.records[0].id).subscribe(response => {
              post.target = target;
              this.createNotification(post);
            }, error => {
              swal.fire({
                title: 'Oops...!',
                text: 'Une erreur est surnevue lors de la sauvegarde..',
                type: 'error',
                confirmButtonText: 'Ok'
              });
            });
          } else {
            post.target = target;
            this.createNotification(post);
          }
        });
      }
    }
  }

  createNotification(post): void {
    this.dataNotificationService.createNotification(post).subscribe(response => {
      this.createIndex++
      if (this.createIndex == this.selectedTarget.length) {
        this.appComponent.isLoading = false;
        swal.fire({
          title: 'Success!',
          text: 'La notification a bien été créée',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(() => {
          this.route.navigate(['/gestion-notification']);
        });
      }
    }, error => {
      this.appComponent.isLoading = false;
      swal.fire({
        title: 'Oops...!',
        text: 'Une erreur est surnevue lors de la sauvegarde..',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
}
