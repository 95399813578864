import { Component, OnInit } from '@angular/core';
import { DataInteractionService } from '../../utils/data-services/dataInteraction.service';
import { DataCreationSelectionService } from '../../utils/data-services/dataCreationSelection.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-creation-selection',
  templateUrl: './creation-selection.component.html',
  styleUrls: ['./creation-selection.component.css'],
  providers: [
    // Add Local time provider to change datePicker date format
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class CreationSelectionComponent implements OnInit {

  variableConcession: any;
  variableProduit: any;
  variableParametre: any;
  nomConcession: any;
  nomProduit: any;
  nomParametre: any;
  concessionID: any;
  produitID: any;
  parametreID: any;
  datepickerData2: any;
  datepickerData1: any;
  textId: any;
  selectedConcessionId: any;
  selectedProduitId: any;
  selectedParametreId: any;
  variable: any;
  variableCheck: any;
  arrayMarque: any;
  selectedMarqueId: number = null;

  // tslint:disable-next-line:max-line-length
  constructor(private dataCreationSelectionService: DataCreationSelectionService,
              private dataInteractionService: DataInteractionService ,
              private route: Router,
              private _adapter: DateAdapter<any>) { }

  ngOnInit() {
    /* In case of back navigation */
    this.concessionID = localStorage.getItem('softgateConcessionIdVariable');
    this.produitID = localStorage.getItem('softgateProduitIdVariable');
    this.parametreID = JSON.parse(localStorage.getItem('softgateParametreList'));
    this.selectedConcessionId = this.concessionID;
    this.selectedProduitId = this.produitID;
    this.selectedParametreId = this.parametreID;
    /* End */

    /* Fill DropDwonLists */
    // tslint:disable-next-line:triple-equals
    if (this.concessionID == null || this.concessionID === 'all' || this.concessionID === 'undefined') {
      this.dataCreationSelectionService.getConcession().subscribe(dataConcession => {
        this.variableConcession = dataConcession.records;
      });
    } else {
      // tslint:disable-next-line:max-line-length
      this.dataCreationSelectionService.getNomConcessionSelected(this.concessionID).subscribe(data1 => { this.nomConcession = data1.records; });
    }

    // tslint:disable-next-line:triple-equals
    if (this.produitID == null || this.produitID === 'all' || this.produitID === 'undefined') {
      // tslint:disable-next-line:triple-equals
      if (this.concessionID != null || this.concessionID !== 'all' || this.concessionID !== 'undefined') {
        this.dataCreationSelectionService.getProduit_Concession(this.concessionID).subscribe(dataProduit => {
          this.variableProduit = dataProduit.records;
        });
      } else {
        this.dataCreationSelectionService.getProduit().subscribe(dataProduit => {
          this.variableProduit = dataProduit.records;
        });
      }
    } else {
      this.dataCreationSelectionService.getNomProduitSelected(this.produitID).subscribe(data2 => { this.nomProduit = data2.records; });
    }

    // tslint:disable-next-line:triple-equals
    if (this.parametreID == null || this.parametreID === 'all'  || this.parametreID === 'undefined') {
      // tslint:disable-next-line:triple-equals
      if (this.produitID != null || this.produitID !== 'all' || this.produitID !== 'undefined') {
        this.dataCreationSelectionService.getParametre_Produit(this.produitID).subscribe(dataProduit => {
          this.variableParametre = dataProduit.records;
        });
      } else {
        this.dataCreationSelectionService.getParametre().subscribe(dataParametre => {
          this.variableParametre = dataParametre.records;
        });
      }
    } else {
      // tslint:disable-next-line:max-line-length
      this.dataCreationSelectionService.getNomParametreSelected(this.parametreID).subscribe(data3 => { this.nomParametre = data3.records; });
    }
    /* End */
    this.dataCreationSelectionService.getMarque().subscribe(dataMarque => { this.arrayMarque = dataMarque.records; });
  }

  // Manage concession variable selection
  SelectChangeHandlerConcession($event: any) {
    this.selectedConcessionId = $event;
    localStorage.setItem('softgateConcessionIdVariable', this.selectedConcessionId);
    this.dataCreationSelectionService.getProduit_Concession(this.selectedConcessionId).subscribe(dataProduit => {
      this.variableProduit = dataProduit.records;
    });
  }

  // Manage product variable selection
  SelectChangeHandlerProduit($event: any) {
    this.selectedProduitId = $event;
    localStorage.setItem('softgateProduitIdVariable', this.selectedProduitId);
    this.dataCreationSelectionService.getParametre_Produit(this.selectedProduitId).subscribe(dataParametre => {
      this.variableParametre = dataParametre.records;
    });
  }

  // Manage parameter variable selection
  SelectChangeHandlerParametre($event: any) {
    this.selectedParametreId = $event;
    localStorage.setItem('softgateParametreList', this.selectedParametreId);
  }

  // Manage brand variable selection
  SelectChangeHandlerMarque($event: any) {
    this.selectedMarqueId = $event.target.value;
  }

  /***
   * Create a new value
   * Create a new releation between value and brand
   ***/
  SelectChangeHandlerCreer() {
    if (this.datepickerData1 > this.datepickerData2) {
      swal.fire({
        title: 'Êtes-vous sûr!',
        text: 'Il faut que la date de fin soit supérieure à la date de début',
        type: 'warning',
        confirmButtonText: 'Ok'
      });
    } else {
      this.dataCreationSelectionService.checkConcessionProduit(this.selectedConcessionId, this.selectedProduitId).subscribe(data => {
        this.variable = data.records;
        this.variableCheck = this.variable;
        if (this.variableCheck.length === 0) {
          // tslint:disable-next-line:no-shadowed-variable
          this.dataCreationSelectionService.createConcessionProduit(this.selectedConcessionId, this.selectedProduitId).subscribe(data => {
          });
        }
      });
      this.dataCreationSelectionService.createValeur(this.textId, this.datepickerData1, this.datepickerData2, this.selectedParametreId, this.selectedConcessionId, this.selectedMarqueId).then(
        response => {
          if (response) {
            swal.fire({
              title: 'Success!',
              text: 'Valeur a bien été Créée',
              type: 'success',
              confirmButtonText: 'Ok'
            });
            this.route.navigate(['../consultation']);
          }
        });
    }
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../consultation']);
  }


}
