import { Component, OnInit } from '@angular/core';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {Router} from '@angular/router';
import {DataCreationParametreService} from '../../utils/data-services/dataCreation_parametre.service';
import swal from 'sweetalert2';
import {DataSmsService} from "../../utils/data-services/data-sms.service";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-creation-parametre',
  templateUrl: './creation-parametre.component.html',
  styleUrls: ['./creation-parametre.component.css']
})
export class CreationParametreComponent implements OnInit {
  parameter = {
    name: null,
    description: null,
    tagId: null
  };
  tagArray = [];
  nameFormControl = new FormControl('', [Validators.required]);

  constructor(private dataCreationParametreService: DataCreationParametreService, private tagService: DataSmsService, private route: Router) { }

  ngOnInit() {
    this.getTag();
  }
  selectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-parametre']);
  }
  selectChangeHandlerCreer() {
    this.dataCreationParametreService.createParameter(this.parameter).subscribe(result => {
      this.selectChangeHandlerAnnuler();
    });
  }
  getTag() {
    this.tagService.getTag().subscribe(result => {
      this.tagArray = result;
    });
  }
}
