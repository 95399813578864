<h2>Modification Règle</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px">
    <form [formGroup]="ruleEditForm" (ngSubmit)="onSubmit(ruleEditForm.value)" class="rule-form">
      <div class="form-row">
        <div class="form-group">
          <label for="title">Titre:</label>
          <input type="text" class="form-control" name="title" id="title" formControlName="title" [value]="currentRule.title">
          <small *ngIf="errors.title.err" class="input-error" style="color: red; margin-top: 115px">Champ requis</small>
        </div>
        <div class="form-group">
          <label for="description_softnrg">Description Softnrg:</label>
          <textarea type="text" rows="3" cols="40" class="form-control" name="description_softnrg" id="description_softnrg" formControlName="description_softnrg"  [value]="currentRule.description_softnrg"></textarea>
          <small *ngIf="errors.description_softnrg.err" class="input-error" style="color: red; margin-top: 115px">Champ requis</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="description_kidi">Description KIDI:</label>
          <textarea type="text" rows="3" cols="70" class="form-control" name="description_kidi" id="description_kidi" formControlName="description_kidi"  [value]="currentRule.description_kidi"></textarea>
          <small *ngIf="errors.description_kidi.err" class="input-error" style="color: red; margin-top: 115px">Champ requis</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="first_field">Critère 1:</label>
          <select class="form-control" id="first_field" name="first_field" formControlName="first_field" [value]="currentRule.first_field">
            <option value="DTSAALLOCATIONSTART">Réception</option>
            <option value="DREPAIRENDDATE">Restitution</option>
            <option value="statut_local">Statut</option>
          </select>
          <small *ngIf="errors.first_field.err" class="input-error" style="color: red">Champ requis</small>
        </div>
        <div class="form-group">
          <label for="first_comparison_operator">Opérateur 1:</label>
          <select class="form-control" name="first_comparison_operator" id="first_comparison_operator" formControlName="first_comparison_operator" [value]="currentRule.first_comparison_operator" style="width: 90px;">
            <option value="lt"><</option>
            <option value="le"><=</option>
            <option value="eq">=</option>
            <option value="neq">!=</option>
            <option value="ge">>=</option>
            <option value="gt">></option>
          </select>
          <small *ngIf="errors.first_comparison_operator.err" class="input-error" style="color: red">Champ requis</small>
        </div>
        <div class="form-group">
          <label for="first_value">Valeur 1:</label>
          <select class="form-control" id="first_value" name="first_value" formControlName="first_value" [value]="currentRule.first_value">
            <option value="dateNow">Date du jour</option>
            <option value="1">Rendez-vous fixé</option>
            <option value="7">Véhicule réceptionné</option>
            <option value="2">Travaux en cours</option>
            <option value="8">Travaux en cours de finalisation</option>
            <option value="3">Travaux terminés</option>
            <option value="4">Véhicule restitué</option>
            <option value="5">Travaux suspendus</option>
          </select>
          <small *ngIf="errors.first_value.err" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="logical_operator">Condition:</label>
          <select class="form-control" id="logical_operator" name="logical_operator" (change)="setFieldToNull()" formControlName="logical_operator" [value]="currentRule.logical_operator" style="width: 90px;">
            <option value="-">null</option>
            <option value="AND">ET</option>
            <option value="OR">OU</option>
          </select>
          <small *ngIf="errors.logical_operator.err" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="second_field">Critère 2:</label>
          <select class="form-control" id="second_field" name="second_field" formControlName="second_field" [value]="currentRule.second_field">
            <option value="-">null</option>
            <option value="DTSAALLOCATIONSTART">Réception</option>
            <option value="DREPAIRENDDATE">Restitution</option>
            <option value="statut_local">Statut</option>
          </select>
        </div>
        <div class="form-group">
          <label for="second_comparison_operator">Opérateur 2:</label>
          <select class="form-control" name="second_comparison_operator" id="second_comparison_operator" formControlName="second_comparison_operator" [value]="currentRule.second_comparison_operator" style="width: 90px;">
            <option value="-">null</option>
            <option value="lt"><</option>
            <option value="le"><=</option>
            <option value="eq">=</option>
            <option value="neq">!=</option>
            <option value="ge">>=</option>
            <option value="gt">></option>
          </select>
        </div>
        <div class="form-group">
          <label for="second_value">Valeur 2:</label>
          <select class="form-control" id="second_value" name="second_value" formControlName="second_value" [value]="currentRule.second_value">
            <option value="-">null</option>
            <option value="dateNow">Date du jour</option>
            <option value="1">Rendez-vous fixé</option>
            <option value="7">Véhicule réceptionné</option>
            <option value="2">Travaux en cours</option>
            <option value="8">Travaux en cours de finalisation</option>
            <option value="3">Travaux terminés</option>
            <option value="4">Véhicule restitué</option>
            <option value="5">Travaux suspendus</option>
          </select>
        </div>
      </div>


      <div class="form-row">
        <div class="form-group">
          <label for="date_notification">Commencement:</label>
          <select class="form-control" id="date_notification" name="date_notification" formControlName="date_notification" [value]="currentRule.date_notification">
            <option value="dateNow">Date jour</option>
            <option value="DTSAALLOCATIONSTART">Date réception</option>
            <option value="DREPAIRENDDATE">Date restitution</option>
          </select>
          <small *ngIf="errors.day.err" class="input-error" style="color: red">Champ requis</small>
        </div>
        <div class="form-group col-md-1 offset-md-1 checkbox-form" style="margin-left: 60px">
          <label for="isDelayed" style="width: 110px;">Différé:</label>
          <input class="form-control" type="checkbox" id="isDelayed" name="isDelayed" [checked]="currentRule.recurrent_start" formControlName="recurrent_start" style="width: 20px">
        </div>
        <div class="form-group col-md-1 offset-md-1 checkbox-form" style="margin-left: 60px">
          <label for="isRecurrent">Récurrence:</label>
          <input class="form-control" type="checkbox" id="isRecurrent" name="isRecurrent" [checked]="currentRule.is_recurrent" formControlName="is_recurrent" style="width: 20px">
        </div>
        <div class="form-group col-md-3">
          <label for="day">Jour(s):</label>
          <input class="form-control col-6" type="number" id="day" name="day" formControlName="day" [value]="currentRule.day">
          <small *ngIf="errors.day.err" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="color">Couleur:</label>
          <input type="color" id="color" name="color" formControlName="color" [value]="currentRule.color">
        </div>
        <div class="form-group col-md-1 offset-md-1 checkbox-form" style="margin-left: 60px">
          <label for="isActive">Actif:</label>
          <input class="form-control" type="checkbox" id="isActive" name="isActive" [checked]="currentRule.is_active" formControlName="is_active" style="width: 20px">
        </div>
        <div class="form-group col-md-3">
          <label for="day">Priorité:</label>
          <input class="form-control col-6" type="number" id="priority" name="priority" formControlName="priority">
          <small *ngIf="errors.priority.err" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="color-btn-pannel">
        <div class="btn-pannel">
          <div>
            <strong *ngIf="errors.logic.err" class="input-error"
                    style="color: red; margin-top: -35px">{{ errors.logic.message }}</strong>
          </div>
          <div>
            <button type="submit" class="btn btn-dark btn100px btn-create p-2 mr-2">Valider</button>
          </div>
          <div>
            <button class="btn btn-light btn100px border-dark btn-create p-2" type="button" routerLink="/gestion-regle">Annuler</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="form-group col-md-3 offset-md-1" style="margin-top: 50px">
    <label for="concessions">Concessions:</label>
    <ng-multiselect-dropdown id="concessions" name="concessions" [ngModelOptions]="{standalone: true}"
                             [placeholder]="'Select'"
                             [data]="dropdownConcessionList"
                             [(ngModel)]="selectedConcession"
                             [settings]="dropdownSettings"
                             (onSelect)="onConcessionSelect($event)"
                             (onDeSelect)="onConcessionDeselect($event)"
    >
    </ng-multiselect-dropdown>
    <small *ngIf="errors.concessions.err" class="input-error" style="color: red">Champ requis</small>
    <div class="concession-list" style="margin-top: 100px">
      <h4>Concessions Actives:</h4>
      <ul>
        <li *ngFor="let concession of selectedConcession">{{ concession.item_text }}</li>
      </ul>
    </div>
  </div>
</div>

