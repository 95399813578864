import { Component, OnInit } from '@angular/core';
import {DataSmsSubscriptionService} from "../../utils/data-services/data-sms-subscription.service";
import {Router} from "@angular/router";
import swal from "sweetalert2";

@Component({
  selector: 'app-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss']
})
export class CreateSubscriptionComponent implements OnInit {

  constructor(private dataService: DataSmsSubscriptionService, private route: Router ) { }
  subscription = {
    monthlyCost : null,
    smsCost : null,
    color : null
  };
  ngOnInit() {
  }
  onCreate() {
    this.dataService.createSubscriptionsSms(this.subscription).subscribe(result => {
      this.route.navigate(['../gestion-subscription']);
    }, error => {
      swal.fire({
        title: 'Erreur!',
        text: 'Un problème est survenu lors de la création de l\'abonnement',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  onCancel() {
    this.route.navigate(['../gestion-subscription']);
  }
}
