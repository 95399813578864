import { Component, OnInit } from '@angular/core';
import { DataProduitService } from '../utils/data-services/dataProduit.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-gestion-informations-produit',
  templateUrl: './gestion-informations-produit.component.html',
  styleUrls: ['./gestion-informations-produit.component.scss']
})
export class GestionInformationsProduitComponent implements OnInit {

  dataSource: any;
  products: any;
  isTechniqueFilter: boolean = false;
  isCommercialFilter: boolean = false;
  searchValue: string = '';

  constructor(
    private dataProduitService: DataProduitService,
    private router: Router) {
    }

  ngOnInit() {
    this.dataProduitService.getProduit().subscribe(dataProducts => {
      this.products = dataProducts.records;
      this.dataSource = new MatTableDataSource(this.products);
    });
  }

  selectedProduct(productId){
    this.router.navigate(['../informations-produit/' + productId]);
  }

  applyFilter() {
    const filterValue = this.searchValue;
    this.dataSource.filter = filterValue.trim();
    // console.log(this.dataSource.filter);
  }

  technique(){
    this.isTechniqueFilter = !this.isTechniqueFilter;
    if (this.isTechniqueFilter){
      this.isCommercialFilter = false;
      const filteredProducts = this.products.filter(product => product.type == 'technique')
      this.dataSource = new MatTableDataSource(filteredProducts);
      this.applyFilter();
    } else {
      this.reset();
    }
  }

  commercial(){
    this.isCommercialFilter = !this.isCommercialFilter;
    if (this.isCommercialFilter){
      this.isTechniqueFilter = false;
      const filteredProducts = this.products.filter(product => product.type == 'commercial')
      this.dataSource = new MatTableDataSource(filteredProducts);
      this.applyFilter();
    } else {
      this.reset();
    }
  }
  reset(){
    this.dataSource = new MatTableDataSource(this.products);
    // this.searchValue = '';
    this.applyFilter();
  }
}
