<!-- page title area end -->
<div class="main-content-inner mt-2">
  <h1 class="text-center">Gestion des notifications</h1>
  <div class="header-rules-action user-table mt-5">
    <div class="d-flex justify-content-between">
      <div>
        <button type="button" id="btn-add-param" class="btn btn-dark" routerLink="/creation-notification">
          <mat-icon>add_circle</mat-icon> Ajouter une notification
        </button>
      </div>
      <div class="d-flex flex-column align-items-end">
        <div>
          <mat-slide-toggle labelPosition="before" class="pt-3" (change)="displayActives()" checked>Actifs uniquement</mat-slide-toggle>
        </div>
        <mat-form-field style="width: 250px" class="mr-3">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
    </div>
  </div>
  <table class="mat-elevation-z8 user-table mt-2" mat-table [dataSource]="dataSource" style="width: 90%">
    <ng-container matColumnDef="action">
      <th class="text-center" mat-header-cell *matHeaderCellDef style="width: 72px;">
        <span [matMenuTriggerFor]="menuOption" class="pointer">action <i class="ml-2 fa-solid fa-ellipsis-vertical"></i></span>
        <mat-menu #menuOption="matMenu">
          <div class="pl-3 pr-3 pb-2 d-flex pointer" (click)="onMultipleDeactivate()"><mat-icon>close</mat-icon><span class="ml-2">Désactiver</span></div>
          <div class="pl-3 pr-3 d-flex pointer" (click)="onMultipleDelete()"><mat-icon class="text-danger">delete</mat-icon><span class="ml-2">Supprimer</span></div>
        </mat-menu>
      </th>
      <td class="text-center" mat-cell *matCellDef="let element" [ngStyle]="{'border-left': 'solid ' + element.color}">
        <input type="checkbox" [value]="selectedItems.includes(element.id)" (click)="onSelectItem(element.id)">
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Titre </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>
    <ng-container matColumnDef="text">
      <th class="text-center" mat-header-cell *matHeaderCellDef style="width: 650px"> Notification </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.text}} </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Type </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="target">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Cible </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.target}} </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Actif </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_active">check</mat-icon>
        <mat-icon *ngIf="!element.is_active">close</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Editer </th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="editNotification(element.id)" style="cursor: pointer; width: 50px"><mat-icon>edit</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Supprimer </th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onDelete(element.id)" style="cursor: pointer; color: red; width: 30px"><mat-icon>delete</mat-icon></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="dataSource.data.length === 0" style="display: flex;justify-content: center;padding: 25px; align-items: center">
    <span class="material-icons" style="padding: 5px">error</span> Aucun résultat trouvé.
  </div>
</div>


