import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataCampaignDiscountsService extends HandleErrorService {

  getCampaignDiscounts() {
    const apiCampaignDiscounts = environment.pngoApi + 'api/backoffice/campaign-discounts';
    return this.http.get<any>(apiCampaignDiscounts, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createCampaignDiscounts(body) {
    const apiCampaignDiscounts = environment.pngoApi + 'api/backoffice/campaign-discounts/create';
    return this.http.post<any>(apiCampaignDiscounts, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateCampaignDiscounts(body) {
    const apiCampaignDiscounts = environment.pngoApi + 'api/backoffice/campaign-discounts/update';
    return this.http.put<any>(apiCampaignDiscounts, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getAllBrand() {
    const apiBrand = environment.pngoApi + 'api/backoffice/brand';
    return this.http.get<any>(apiBrand, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getCampaignDiscountsById(parameter) {
    const apiCampaignDiscounts = environment.pngoApi + 'api/backoffice/campaign-discounts/' + parameter;
    return this.http.get<any>(apiCampaignDiscounts, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getBrandByLabel(parameter) {
    const apiCampaignDiscounts = environment.pngoApi + 'api/backoffice/brand/' + parameter;
    return this.http.get<any>(apiCampaignDiscounts, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
