<h1 class="text-center mt-2">Gestion des paramètres</h1>
<div class="container-row btn float-right">
  <div class="btn1">
    <button (click)="SelectChangeHandlerCreer()" id="btn-add-param" class="btn btn-dark mr-4">
      <mat-icon>add_circle</mat-icon> Parametre
    </button>
  </div>
</div>

<div class="con3">
  <div>
    <table class="table table-striped table-bordered user-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>Nom de Parametre</th>
        <th>Description</th>
        <th>Tag</th>
        <th>Modifier</th>
      </tr>
      </thead>

      <tbody>

      <tr *ngFor = "let parametre of variable" style="text-align: center">
        <th>{{parametre.parametre_id}}</th>
        <td>{{parametre.nom_parametre}}</td>
        <td>{{parametre.description}}</td>
        <td>{{getTagNameFromId(parametre.tag_id)}}</td>
        <td>
          <button (click)="SelectChangeHandlerModifier(parametre.nom_parametre, parametre.description, parametre.tag_id, parametre.type, parametre.bdd, parametre.bdd_table, parametre.bdd_champ, parametre.parametre_id)" class="btn btn-dark btn-round-40 m-auto">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </tr>

      </tbody>
    </table>
  </div>
</div>


