import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataProduitService extends HandleErrorService {

  getProduit() {
    const apiProduit = environment.globalUrlDomaine + '/records/produit';
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getProductById(id) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit/'+id;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getCommercialProduct() {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?include=produit_id,nom_produit&filter=actif,eq,1&filter=type,eq,commercial';
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getTechnicalProduct() {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?include=produit_id,nom_produit&filter=actif,eq,1&filter=type,eq,technique';
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
