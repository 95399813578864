<h1 mat-dialog-title>Attention ! Le contact semble déjà exister.</h1>
<div mat-dialog-content>
  <div class="example-container">
    <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">

      <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef style="width: 50px"> Nom </th>
        <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
      </ng-container>

      <ng-container matColumnDef="prenom">
        <th mat-header-cell *matHeaderCellDef> Prénom </th>
        <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
      </ng-container>

      <ng-container matColumnDef="fonction">
        <th mat-header-cell *matHeaderCellDef> Fonction </th>
        <td mat-cell *matCellDef="let element"> {{element.fonction}} </td>
      </ng-container>

      <ng-container matColumnDef="telephone_fixe">
        <th mat-header-cell *matHeaderCellDef  style="width: 150px"> Téléphone fixe </th>
        <td mat-cell *matCellDef="let element"> {{element.telephone_fixe}} </td>
      </ng-container>

      <ng-container matColumnDef="telephone_portable">
        <th mat-header-cell *matHeaderCellDef> Téléphone portable </th>
        <td mat-cell *matCellDef="let element"> {{element.telephone_portable}} </td>
      </ng-container>

      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef> E-mail </th>
        <td mat-cell *matCellDef="let element"> {{element.mail}} </td>
      </ng-container>

      <ng-container matColumnDef="commentaire">
        <th mat-header-cell *matHeaderCellDef> Commentaire </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.commentaire"> </td>
      </ng-container>

      <ng-container matColumnDef="concession">
        <th mat-header-cell *matHeaderCellDef> Concession </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon [matTooltip]="element.concessions | tooltipList" matTooltipClass="tooltip-list" class="concession-icon">public</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="modifier">
        <th mat-header-cell *matHeaderCellDef> Modifier </th>
        <td mat-cell *matCellDef="let element">
          <button class="m-2 btn btn-round-40 btn-dark" (click)="SelectChangeHandlerModifier(element.id_contact); onNoClick()">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center" mat-dialog-actions>
  <button mat-raised-button (click)="onYesClick()" class="btn btn-dark m-4">Ajouter la fonction</button>
  <button mat-raised-button (click)="onNoClick()" class="btn btn-dark m-4">Annuler</button>
</div>
