import { Component, OnInit } from '@angular/core';
import {DataMobiliteService} from "../../utils/data-services/data-mobilite.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";
import {DataCampaignDiscountsService} from "../../utils/data-services/data-campaign-discounts.service";

@Component({
  selector: 'app-update-mobilite',
  templateUrl: './update-mobilite.component.html',
  styleUrls: ['./update-mobilite.component.scss']
})
export class UpdateMobiliteComponent implements OnInit {

  concessionName: any;
  mobiliteId: any;
  brandArray = [];
  mobilite = {
    sofNet_id: null,
    concession_id: null,
    nom: null,
    prix: null,
    icon: null,
    ordre: null,
    actif: false,
    brand_authorized: null,
    description: null,
    is_external_fleet: false,
    is_waiting: false
  };
  iconArray = [
    'fa-times',
    'fa-car',
    'fa-bus',
    'fa-taxi',
    'fa-handshake',
    'fa-key',
    'fa-car-side',
    'fa-clock',
    'fa-car-alt',
    'fa-bicycle',
    'fa-ellipsis-v',
    'fa-golf-ball',
    'fa-motorcycle',
    'fa-battery-full'
  ];
  constructor( private dataServiceBrand: DataCampaignDiscountsService, private dataService: DataMobiliteService, private route: Router, private activeRoute: ActivatedRoute, private dataServiceConcession: DataConcessionService ) { }

  ngOnInit() {
    this.mobiliteId = Number(this.activeRoute.snapshot.paramMap.get('idMobilite'));
    this.getMobiliteById();
    this.getBrand();
  }
  updateMobilite() {
    this.dataService.updateMobilite(this.mobilite).subscribe(result => {
      this.route.navigate(['../gestion-mobilite/' + this.mobilite.concession_id]);
    });
  }
  getMobiliteById() {
    this.dataService.getMobiliteById(this.mobiliteId).subscribe(result => {
      this.mobilite = result;
      this.getConcessionById();
    });
  }
  getBrand() {
    this.dataServiceBrand.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-mobilite/' + this.mobilite.concession_id]);
  }
  onChangeCheckbox(type) {
    this.mobilite[type] = !this.mobilite[type];
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.mobilite.concession_id).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
}
