<!-- page title area end -->
<div class="main-content-inner mt-2">
  <h1 class="text-center">Gestion des formations</h1>
  <div class="header-rules-action mt-5 user-table">
    <div class="d-flex justify-content-between">
      <button type="button" id="btn-add-param" class="btn btn-dark" routerLink="/creation-formation">
        <mat-icon>add_circle</mat-icon> Ajouter une formation
      </button>
      <mat-form-field style="width: 250px" class="mr-3">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>
  <table class="mat-elevation-z8 user-table mt-2" mat-table [dataSource]="dataSource" style="width: 90%">
    <ng-container matColumnDef="type">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Type </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Titre </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Actif </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.isActive">check</mat-icon>
        <mat-icon *ngIf="!element.isActive">close</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Editer </th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="editFormation(element.id)" style="cursor: pointer; width: 50px"><mat-icon>edit</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Supprimer </th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onDelete(element.id)" style="cursor: pointer; color: red; width: 30px"><mat-icon>delete</mat-icon></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="!isDataSourceExists" style="display: flex;justify-content: center;padding: 25px; align-items: center">
    <span class="material-icons" style="padding: 5px">error</span> Aucun résultat trouvé.
  </div>
</div>
<div class="text-center m-4">
  <span class="font-weight-bold">Lien vers la page publique des brochures :</span>
  <br>
  <a target="_blank" style="color: black;" href="{{urlSoftgate}}/brochures/{{tokenBooklet}}">{{urlSoftgate}}/brochures/{{tokenBooklet}}</a>
  <button class="btn ml-2" (click)="copyToClipboard(urlSoftgate + '/brochures/' + tokenBooklet)" matTooltip="Copier vers le presse-papier" style="height: 33px;margin:0;"><mat-icon style="font-size: 17px">file_copy</mat-icon></button>
</div>

