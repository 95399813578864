import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataInteractionService {
  getProduit() {
    throw new Error('Method not implemented.');
  }

  /* Déclaration des variables de Gestion Valeur */

  private subject0 = new BehaviorSubject<any>('');
  public idTag = this.subject0.asObservable();

  private subject1 = new BehaviorSubject<any>('');
  public idConcession = this.subject1.asObservable();

  private subject2 = new BehaviorSubject<any>('');
  public idProduit = this.subject2.asObservable();

  private subject3 = new BehaviorSubject<any>('');
  public idParametre = this.subject3.asObservable();

  private subject4 = new BehaviorSubject<any>('');
  public valeur1 = this.subject4.asObservable();

  private subject5 = new BehaviorSubject<any>('');
  public dateDebut = this.subject5.asObservable();

  private subject6 = new BehaviorSubject<any>('');
  public dateFin = this.subject6.asObservable();

  private subject7 = new BehaviorSubject<any>('');
  public idValeur = this.subject7.asObservable();

  private subject23 = new BehaviorSubject<any>('');
  public idMarque = this.subject23.asObservable();

  /* Fin de déclaration */
/* *************************************************************************************************************** */
  /* Déclaration des variables de Gestion Produit */

  private subject8 = new  BehaviorSubject<any>('');
  public nomProduit = this.subject8.asObservable();

  private subject9 = new  BehaviorSubject<any>('');
  public descriptionProduit = this.subject9.asObservable();

  private subject10 = new  BehaviorSubject<any>('');
  public lienProduit = this.subject10.asObservable();

  private subject11 = new  BehaviorSubject<any>('');
  public etatProduit = this.subject11.asObservable();

  private subject12 = new  BehaviorSubject<any>('');
  public imgProduit = this.subject12.asObservable();

  private subject13 = new  BehaviorSubject<any>('');
  public lienCommerceProduit = this.subject13.asObservable();

  private subject14 = new BehaviorSubject<any>('');
  public  IdProduit = this.subject14.asObservable();

  private subject15 = new BehaviorSubject<any>('');
  public  ProduitVersion = this.subject15.asObservable();
  private subject24 = new BehaviorSubject<any>('');
  public  ProduitType = this.subject24.asObservable();



  /* Fin de déclaration */

  /* *************************************************************************************************************** */
  /* Déclaration des variables de Gestion Parametre */

  private subject16 = new  BehaviorSubject<any>('');
  public nomParametre = this.subject16.asObservable();

  private subject17 = new  BehaviorSubject<any>('');
  public descriptionParametre = this.subject17.asObservable();

  private subject18 = new  BehaviorSubject<any>('');
  public tagParametre = this.subject18.asObservable();

  private subject19 = new  BehaviorSubject<any>('');
  public typeParamete = this.subject19.asObservable();

  private subject20 = new  BehaviorSubject<any>('');
  public BDDParametre = this.subject20.asObservable();

  private subject21 = new  BehaviorSubject<any>('');
  public TableBDDParametre = this.subject21.asObservable();

  private subject22 = new BehaviorSubject<any>('');
  public  ChampBDDParametre = this.subject22.asObservable();

  /* Fin de déclaration */

  constructor() { }

  /* Transmettre les variables de Gestion Valeur */

  sendIdTag(idTag: any) {
    this.subject0.next(idTag);
  }
  sendIdConcession(idConcession: any) {
    this.subject1.next(idConcession);
  }

  sendIdProduit(idProduit: any) {
    this.subject2.next(idProduit);
  }

  sendIdParametre(idParametre: any) {
    this.subject3.next(idParametre);
  }

  sendvaleur(valeur1: any) {
    this.subject4.next(valeur1);
  }

  sendDateDebut(dateDebut: any) {
    this.subject5.next(dateDebut);
  }

  sendDateFin(dateFin: any) {
    this.subject6.next(dateFin);
  }

  sendIdValeur(idValeur: any) {
    this.subject7.next(idValeur);
  }

  sendIdMarque(idMarque: any) {
    this.subject23.next(idMarque);
  }

  /* Fin de transmission */
/* *************************************************************************************************************** */
  /* Transmettre les variables de Gestion Produit */

  sendNomProduit(nomProduit: any) {
    this.subject8.next(nomProduit);
  }

  sendDescriptionProduit(descriptionProduit: any) {
    this.subject9.next(descriptionProduit);
  }

  sendLienProduit(lienProduit: any) {
    this.subject10.next(lienProduit);
  }

  sendActifProduit(etatProduit: any) {
    this.subject11.next(etatProduit);
  }

  sendImgProduit(imgProduit: any) {
    this.subject12.next(imgProduit);
  }

  sendLienCommerceProduit(lienCommerceProduit: any) {
    this.subject13.next(lienCommerceProduit);
  }

  sendProduitId(IdProduit: any) {
    this.subject14.next(IdProduit);
  }

  sendProduitVersion(ProduitVersion: any) {
    this.subject15.next(ProduitVersion);
  }
  sendProduitType(ProduitType: any) {
    this.subject24.next(ProduitType);
  }

  /* Fin de transmission */

  /* *************************************************************************************************************** */
  /* Transmettre les variables de Gestion Parametre */

  sendNomParametre(nomParametre: any) {
    this.subject16.next(nomParametre);
  }

  sendDescriptionParametre(descriptionParametre: any) {
    this.subject17.next(descriptionParametre);
  }

  sendTagParametre(tagParametre: any) {
    this.subject18.next(tagParametre);
  }


  sendTypeParametre(typeParamete: any) {
    this.subject19.next(typeParamete);
  }

  sendBDDParametre(BDDParametre: any) {
    this.subject20.next(BDDParametre);
  }

  sendTableBDDParametre(TableBDDParametre: any) {
    this.subject21.next(TableBDDParametre);
  }

  sendChampBDDParametre(ChampBDDParametre: any) {
    this.subject22.next(ChampBDDParametre);
  }

  /* Fin de transmission */

}
