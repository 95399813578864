<h1 class="text-center mt-2">Gestion des concessions</h1>
<div class="user-table">
  <div class="m-5 d-flex justify-content-between align-items-center">
    <div class="d-flex">
    <div>
      <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
        <mat-icon>add_circle</mat-icon> Concession
      </button>
    </div>
    <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
      <mat-label>Groupe</mat-label>
      <mat-select [(ngModel)]="selectedGroup" (click)="focusSearch('section')" (selectionChange)="filterDealershipGroup(selectedGroup)"  appearance="fill" >
        <mat-option value="all">Tous</mat-option>
        <input id="select-search-section" class="form-control select-input custom-search-input" (keyup)="filterGroup($event)" type="text" placeholder="Recherche">
        <mat-option *ngFor="let group of groupsArray.filtered"  [value]="group.groupe_id">
          {{group.nom}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Cherchez une concession</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>
<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">

    <ng-container matColumnDef="concession_id">
      <th mat-header-cell *matHeaderCellDef style="width: 50px" class="pointer" (click)="sortId()"> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.concession_id}} </td>
    </ng-container>

    <ng-container matColumnDef="nom_concession">
      <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortName()"> Nom </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon matTooltip="Utilisateurs certifiés depuis moins d'un an" *ngIf="element.usersCertified === 1" style="font-size: 17px; color: green;">check_circle_outline</mat-icon>
        <mat-icon matTooltip="Certification des utilisateurs à effetcuer par le responsable IAM de la concession" *ngIf="element.usersCertified === 0 && element.last_user_certification_at" style="font-size: 17px; color: orange;">report</mat-icon>
        <mat-icon matTooltip="Aucune date de dernière certification des utilisateurs enregistrée" *ngIf="element.usersCertified === 0 && !element.last_user_certification_at" style="font-size: 17px; color: orange;">report</mat-icon>
        <mat-icon matTooltip="Certification des utilisateurs non effectuée, utilisateurs désativés" *ngIf="element.usersCertified === -1" style="font-size: 17px; color: red;">highlight_off</mat-icon>
        {{element.nom_concession}}
      </td>
    </ng-container>

    <ng-container matColumnDef="raison_sociale">
      <th mat-header-cell *matHeaderCellDef  style="width: 150px"> Raison sociale </th>
      <td mat-cell *matCellDef="let element"> {{element.raison_sociale}} </td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef style="width: 150px"> Groupe </th>
      <td mat-cell *matCellDef="let element"> {{element.group}} </td>
    </ng-container>

    <ng-container matColumnDef="ville">
      <th mat-header-cell *matHeaderCellDef> Ville </th>
      <td mat-cell *matCellDef="let element"> {{element.ville}} </td>
    </ng-container>

    <ng-container matColumnDef="ville_principale">
      <th mat-header-cell *matHeaderCellDef> Ville Principale </th>
      <td mat-cell *matCellDef="let element"> {{element.ville_principale}} </td>
    </ng-container>

    <ng-container matColumnDef="code_ej">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Code EJ/ Outlet </th>
      <td mat-cell *matCellDef="let element"> {{element.code_ej+'_'+element.outlet}} </td>
    </ng-container>

    <ng-container matColumnDef="code_site">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Code Site </th>
      <td mat-cell *matCellDef="let element"> {{element.code_site}} </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="SelectChangeHandlerModifier(element.concession_id)" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
