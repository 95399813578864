import { Component, OnInit } from '@angular/core';
import {DataOperationService} from '../utils/data-services/data-operation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-gestion-operation',
  templateUrl: './gestion-operation.component.html',
  styleUrls: ['./gestion-operation.component.scss']
})
export class GestionOperationComponent implements OnInit {

  operationId: any;
  dataSource = new MatTableDataSource();
  concessionName: any;
  constructor(private dataService: DataOperationService, private activeRoute: ActivatedRoute, private route: Router, private dataServiceConcession: DataConcessionService) { }
  displayedColumns: string[] = ['iconOperation', 'bmw_id', 'sofNet_id', 'nom', 'minimum_delay', 'requiredStandartRangeService', 'dynamicTest', 'vt', 'actif', 'ordre', 'blocked', 'commentaireAdditionnel', 'aideAdditionnel', 'type', 'attente_autorise', 'modifier'];

  ngOnInit() {
    this.operationId = Number(this.activeRoute.snapshot.paramMap.get('idConcession'));
    this.getOperation();
    this.getConcessionById();
  }
  getOperation() {
    this.dataService.getOperationByConcession(this.operationId).subscribe(result => {
      this.dataSource.data = result;
    });
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.operationId).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-operation/' + this.operationId]);
  }
  SelectChangeHandlerModifier(idOperation) {
    this.route.navigate(['../modification-operation/' + idOperation]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
}
