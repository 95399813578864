import { Component, OnInit } from '@angular/core';
import {DataCreationProduitService} from '../../utils/data-services/dataCreation-produit.service';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-creation-produit',
  templateUrl: './creation-produit.component.html',
  styleUrls: ['./creation-produit.component.css']
})
export class CreationProduitComponent implements OnInit {
  
  roleArray = [];
  selectedRole = [];

  product = {
    name: null,
    description: null,
    type: null,
    version: 0,
    actif: false,
    roles: [],
  };
  typeArray = ['technique', 'commercial'];
  nameFormControl = new FormControl('', [Validators.required]);
  typeFormControl = new FormControl('', [Validators.required]);
  constructor(private dataCreationProduitService: DataCreationProduitService, private dataInteractionService: DataInteractionService, private route: Router) { }


  ngOnInit() {
    this.getRoles();
    
    // @ts-ignore
    this.product.version = this.product.version.toString().padStart(4, '0');


  }

  // getrole
  getRoles(): any {
    this.dataCreationProduitService.getRoles().subscribe(roles => {
      this.roleArray = [];
      for (const role of roles.records) {
        this.roleArray.push({ item_id: role.id, item_text: role.label});
      }
    })
  }

  selectChangeHandlerCreer() {

    this.product.roles = this.selectedRole;
    this.dataCreationProduitService.createProduct(JSON.stringify(this.product)).subscribe(result => {
      this.selectChangeHandlerAnnuler();

      swal.fire({
        title: 'Success!',
        text: 'Produit a bien été créé',
        type: 'success',
        confirmButtonText: 'Ok'
      });
    });
  }
  incriseDecriseVersion(signe) {
    if (signe === '+') {
      this.product.version++;
      // @ts-ignore
      this.product.version = this.product.version.toString().padStart(4, '0');
    } else if (signe === '-') {
      this.product.version--;
      // @ts-ignore
      this.product.version = this.product.version.toString().padStart(4, '0');
    }
  }
  selectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-produit']);
  }

  onChangeCheckbox(type) {
    this.product[type] = !this.product[type];
  }

  getAllPriduct(products) {
    this.product[products] = !this.product[products];
  }

}
