<div class="inputs-section pt-5">
  <div class="box1 col-sm-3" >
    <label>Concession :</label>
    <p *ngFor = "let concession of nomConcession" style="text-align: center">
      {{concession.nom_concession}}
    </p>
  </div>

  <div class="box2 col-sm-3">
    <label>Produit :</label>
    <p *ngFor = "let produit of nomProduit" style="text-align: center">
      {{produit.nom_produit}}
    </p>
  </div>

  <div class="box3 col-sm-3">
    <label>Parametre :</label>
    <p *ngFor = "let parametre of nomParametre" style="text-align: center">
      {{parametre.nom_parametre}}
    </p>
  </div>

  <div class="box3 col-sm-2">
    <label>Marque</label>
    <select [ngModel]="marqueValeurID" class="form-control" (change)="SelectChangeHandlerMarque($event)">
      <option *ngFor = "let marque of arrayMarque" style="text-align: center" [value]="marque.marque_id">
        {{marque.nom}}
      </option>
    </select>
  </div>
</div>

<div class="container">
  <div class="mt-2 value-container">
    <div sm="10" class="mt-5">
      <textarea [(ngModel)]="textId" placeholder="Insérer la valeur" rows="12" cols="75" no-auto-shrink></textarea>
    </div>
  </div>
</div>

<div class="date">
  <div class="dates-container">
    <div class="box41 col-6 col-md-3">
      <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
        <mat-label>Date de Début</mat-label>
        <input matInput [(ngModel)]="datepickerData1" [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="box51  col-6 col-md-3">
      <mat-form-field class="w-100" appearance="fill" style="flex: 1; margin-right: 10px">
        <mat-label>Date de Fin</mat-label>
        <input matInput [(ngModel)]="datepickerData2" [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="btn-section">
  <div class="row btn">
    <div class="btn1">
      <button (click)="SelectChangeHandlerModifier()" class="btn btn-dark btn100px">Modifier</button>
    </div>

    <div class="btn2" id="bt1">
      <button (click)="SelectChangeHandlerAnnuler()" class="btn btn-light border-dark btn100px">Annuler</button>
    </div>
  </div>
  <div class="row btn mt-4">
    <div class="btn1">
      <button (click)="onDeleteValeur()" [disabled]="marqueValeurID == null" class="btn btn-outline-danger">
        <span *ngIf="marqueValeurID != null">Supprimer définitivement</span>
        <span *ngIf="marqueValeurID == null"><mat-spinner></mat-spinner></span>
      </button>
    </div>
  </div>
</div>

