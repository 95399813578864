<h2>Création Configuration</h2>
<div class="wrapper">
  <form>
    <div class="form-row mt-4">
      <div class="form-group col-md-5">
        <label for="configuration">Configuration :</label>
        <select [(ngModel)]="defaultConfiguration.configuration" class="form-control" id="configuration" name="configuration">
          <option *ngFor="let configuration of configurationArray" [value]="configuration.id">{{configuration.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="value">Valeur :</label>
        <input [(ngModel)]="defaultConfiguration.value" class="form-control" id="value" name="value" required>
      </div>
      <div class="form-group col-md-3 offset-md-1">
        <label for="type">Type :</label>
        <input [(ngModel)]="defaultConfiguration.type" class="form-control" id="type" name="type" required>
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="onUpdate()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
      </div>

      <div>
        <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
