import { Component, OnInit } from '@angular/core';
import {DataMobiliteService} from "../../utils/data-services/data-mobilite.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";

@Component({
  selector: 'app-create-mobilite',
  templateUrl: './create-mobilite.component.html',
  styleUrls: ['./create-mobilite.component.scss']
})
export class CreateMobiliteComponent implements OnInit {
  concessionName: any;
  concessionId: any;
  mobilite = {
    softNet_id: null,
    concession_id: null,
    nom: null,
    prix: null,
    icon: null,
    ordre: null,
    actif: false,
    brand_authorized: null,
    description: null,
    is_external_fleet: false,
    is_waiting: false
  };
  iconArray = [
    'fa-times',
    'fa-car',
    'fa-bus',
    'fa-taxi',
    'fa-handshake',
    'fa-key',
    'fa-car-side',
    'fa-clock',
    'fa-car-alt',
    'fa-bicycle',
    'fa-ellipsis-v',
    'fa-golf-ball',
    'fa-motorcycle',
    'fa-battery-full'
  ];
  constructor( private dataService: DataMobiliteService, private route: Router, private activeRoute: ActivatedRoute, private dataServiceConcession: DataConcessionService ) { }

  ngOnInit() {
    this.concessionId = Number(this.activeRoute.snapshot.paramMap.get('idConcession'));
    this.mobilite.concession_id = this.concessionId;
    this.getConcessionById();
  }
  createMobilite() {
    this.dataService.createMobilite(this.mobilite).subscribe(result => {
      this.route.navigate(['../gestion-mobilite/' + this.concessionId]);
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-mobilite/' + this.concessionId]);
  }
  onChangeCheckbox(type) {
    this.mobilite[type] = !this.mobilite[type];
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.concessionId).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
}
