<div class="w-100">
  <h3>Dupliquer la communication</h3>
  <div class="w-100">
    <div class="form-group w-100" style="margin-top: 50px">
      <label for="concessions">Concessions:</label>
      <ng-multiselect-dropdown id="concessions" name="concessions" [ngModelOptions]="{standalone: true}"
                               [placeholder]="'Select'"
                               [data]="dropdownConcessionList"
                               [(ngModel)]="selectedConcession"
                               [settings]="dropdownSettings"
                               (onSelect)="onConcessionSelect($event)"
                               (onDeSelect)="onConcessionDeselect($event)"
      >
      </ng-multiselect-dropdown>
      <!--  <small *ngIf="errors.concessions.err" class="input-error" style="color: red">Champ requis</small>-->

      <div class="concession-list" style="margin-top: 100px" *ngIf="selectedConcession.length > 0">
        <h4>Concessions sélectionnées:</h4>
        <ul>
          <li *ngFor="let concession of selectedConcession">{{ concession.item_text }}</li>
        </ul>
      </div>
    </div>
    <div class="d-flex justify-content-around mt-5 p-4">
      <div>
        <button class="btn btn-light btn100px border-dark btn-create p-2 mt-3" type="button" (click)="onNoClick()">Annuler</button>
      </div>
      <div>
        <button type="submit" class="btn btn-dark btn100px btn-create p-2 mt-3 mr-2" [class.inactive]="concessions.length == 0" [mat-dialog-close]="selectedConcession">Valider</button>
      </div>
    </div>
    <div class="w-100 text-center"><small>La duplication crée de nouvelles communications indépendantes de celle ci.</small></div>
  </div>
</div>
