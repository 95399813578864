import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataBrandService extends HandleErrorService {
  getDealershipBrand(dealershipId) {
    const api = environment.pngoApi + 'api/backoffice/dealership-brand/' + dealershipId;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateDealershipBrand(body) {
    const api = environment.pngoApi + 'api/backoffice/dealership-brand-update';
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
