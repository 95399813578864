import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import { ErrorHandler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import swal from 'sweetalert2';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataCreationConcessionService extends HandleErrorService {

  getPrpduit() {
    const apiParametre = environment.globalUrlDomaine + '/records/produit';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getBrands() {
    const apiParametre = environment.globalUrlDomaine + '/records/marque';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createBrand(body) {
    body = JSON.stringify(body);
    const apiParametre = environment.globalUrlDomaine + '/records/marque';
    return this.http.post<any>(apiParametre, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateBrand(body, id) {
    body = JSON.stringify(body);
    const apiParametre = environment.globalUrlDomaine + '/records/marque/' + id;
    return this.http.put<any>(apiParametre, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteBrand(id) {
    const apiParametre = environment.globalUrlDomaine + '/records/marque/' + id;
    return this.http.delete<any>(apiParametre,  environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getGroups() {
    const apiParametre = environment.globalUrlDomaine + '/records/groupe';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createGroup(body) {
    body = JSON.stringify(body);
    const apiParametre = environment.globalApiUrl + 'api/group/create';
    return this.http.post<any>(apiParametre, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateGroup(body, id) {
    body = JSON.stringify(body);
    const apiParametre = environment.globalApiUrl + 'api/group/update/' + id;
    return this.http.put<any>(apiParametre, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteGroup(id) {
    const apiParametre = environment.globalUrlDomaine + '/records/groupe/' + id;
    return this.http.delete<any>(apiParametre,  environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  sauronDispatchGroup(groupId) {
    const sauron = environment.globalApiUrl + 'api/sauron/dispatch/group/' + groupId;
    return this.http.get<any>(sauron, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }

  // tslint:disable-next-line:max-line-length
  createConcession(body): Observable<HttpErrorResponse> {
    // const api = environment.globalUrlDomaine + '/records/concession';
    const api = environment.globalApiUrl + 'api/concession/create';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addConcessionMarque(body): Observable<HttpErrorResponse> {
    body = JSON.stringify(body);
    const api = environment.globalUrlDomaine + '/records/concession_marque';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  removeConcessionMarque(id) {
    const api = environment.globalUrlDomaine + '/records/concession_marque/' + id;
    return this.http.delete<any>(api, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addConcessionProduit(arg1: any, arg2: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit';
    const body = { concession_id: arg1,
      produit_id: arg2
    };
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  callSauron(concessionId) {
    const sauron = environment.globalApiUrl + 'api/sauron/dispatch/concession/' + concessionId;
    return this.http.get<any>(sauron, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
