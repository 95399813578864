import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import swal from "sweetalert2";
import {FormBuilder} from "@angular/forms";
import {DataBrandService} from "../utils/data-services/data-brand.service";

@Component({
  selector: 'app-gestion-marque-planngo',
  templateUrl: './gestion-marque-planngo.component.html',
  styleUrls: ['./gestion-marque-planngo.component.scss']
})
export class GestionMarquePlanngoComponent implements OnInit {
  dealershipId: any;
  brands: {
    bmw: false,
    mini: false,
    motorrad: false
  };
  constructor(private activeRoute: ActivatedRoute, private route: Router, private formBuilder: FormBuilder, private dataService: DataBrandService) { }

  ngOnInit() {
    this.dealershipId = Number(this.activeRoute.snapshot.paramMap.get('dealershipId'));
    this.getDealershipBrand();
  }
  getDealershipBrand() {
    this.dataService.getDealershipBrand(this.dealershipId).subscribe(result => {
      this.brands = result;
    });
  }
  onUpdate() {
    const body =  {
      dealershipId: this.dealershipId,
      brands: []
    };
    if (this.brands.bmw) { body.brands.push(66); }
    if (this.brands.mini) { body.brands.push(71); }
    if (this.brands.motorrad) { body.brands.push(76); }
    this.dataService.updateDealershipBrand(body).subscribe(result => {
      this.route.navigate(['../gestion-mapping-planngo']);
    });
  }
  onCancel() {
    this.route.navigate(['../gestion-mapping-planngo']);
  }
}
