import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataModificationService extends HandleErrorService{

  getNomConcessionSelected(idConcession: any) {
    // tslint:disable-next-line:max-line-length
    const apiConcession = environment.globalUrlDomaine + '/records/concession?include=nom_concession&filter=concession_id,eq,' + idConcession;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getNomProduitSelected(idProduit: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?include=nom_produit&filter=produit_id,eq,' + idProduit;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getValeur(idValeur: any) {
    // tslint:disable-next-line:max-line-length
    const apiConcession = environment.globalUrlDomaine + '/records/valeur?filter=valeur_id,eq,' + idValeur;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getNomParametreSelected(idParametre: any) {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre?include=nom_parametre&filter=parametre_id,eq,' + idParametre;
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /*updateValeur(text: any, date1: any, date2: any, Id: any): Observable<HttpErrorResponse> {
    let dateF: any;
    const apiValeur = environment.globalUrlDomaine + '/records/valeur/' + Id;
    const dateD = this.datePipe.transform(date1, 'yyyy-MM-dd');
    if (date2 == null || date2 === 'undefined') {
       dateF = '9999-01-01';
    } else {  dateF = this.datePipe.transform(date2, 'yyyy-MM-dd');
    }
    const body = {valeur: text, date_debut_param: dateD, date_fin_param: dateF};

    return this.http.put<any>(apiValeur, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }*/

  public updateValeur(text: any, date1: any, date2: any, Id: any, marqueId: any) {
    return new Promise((resolve, reject) => {
      let dateF: any;
      const apiValeur = environment.globalUrlDomaine + '/records/valeur/' + Id;
      const dateD = this.datePipe.transform(date1, 'yyyy-MM-dd');
      const brandId = marqueId == null ? 1 : marqueId;
      if (date2 == null || date2 === 'undefined') {
        dateF = '9999-01-01';
      } else {  dateF = this.datePipe.transform(date2, 'yyyy-MM-dd');
      }
      const body = {valeur: text, date_debut_param: dateD, date_fin_param: dateF, marque_id: brandId};
      this.http.put<any>(apiValeur, body, environment.httpOptions)
        .toPromise()
        .then(
          (response: any) => {
            resolve(response);
          },
          error => {
            this.handleError(error);
          }
        );
    });
  }

  public updateDateFinValeur(body, id) {
    body = JSON.stringify(body);
    const apiValeur = environment.globalUrlDomaine + '/records/valeur/' + id;
    return this.http.put<any>(apiValeur, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public deleteValeur(id) {
    const apiValeur = environment.globalUrlDomaine + '/records/valeur/' + id;
    return this.http.delete<any>(apiValeur, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public deleteMarqueValeur(marqueValeurId) {
    const apiValeur = environment.globalUrlDomaine + '/records/marque_valeur/' + marqueValeurId;
    return this.http.delete<any>(apiValeur, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public getMarque() {
    const api = environment.globalUrlDomaine + '/records/marque';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public getMarqueValeur(valeurID: any) {
    const api = environment.globalUrlDomaine + '/records/marque_valeur?filter=valeur_id,eq,' + valeurID;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public updateMarqueValeur(idMarque: number, idValeur: number, idTable: number): Observable<HttpErrorResponse> {
    const api = environment.globalUrlDomaine + '/records/marque_valeur/' + idTable;
    const body = {marque_id: idMarque, valeur_id: idValeur};
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getProduitParametre(arg: any) {
    const api = environment.globalUrlDomaine + '/records/produit_parametre?filter=parametre_id,eq,' + arg + '&include=produit_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getIDConcessionProduit(arg1: any, arg2: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + arg1 + '&filter=produit_id,eq,' + arg2 + '&include=concession_produit_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  updateVersionConcessionProduit(id: any): Observable<HttpErrorResponse> {
    const api = environment.globalUrlDomaine + '/records/concession_produit/' + id;
    const body = {version: '0000'};
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
}
