import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataRuleService extends HandleErrorService {
  apiRule = environment.globalUrlDomaine + '/records/rule_notification';
  apiConcessionRule = environment.globalUrlDomaine + '/records/concession_rule';
  private errorMessage: string;

  /**
   * CRUD methodes for rules
   */
  requestGetAllRules() {
    return this.http.get<any>(this.apiRule, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestGetRuleById(id) {
    return this.http.get<any>(this.apiRule + '/' + id, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestCreateRule(post) {
    const body = JSON.stringify(post);
    return this.http.post<any>(this.apiRule, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestCreateConcesssionRule(ruleId, concessoinId) {
    const body = JSON.stringify({rule_id: ruleId, concession_id: concessoinId});
    return this.http.post<any>(this.apiConcessionRule, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestGetConcessionRuleByRuleId(ruleId) {
    return this.http.get<any>(this.apiConcessionRule + '?filter=rule_id,eq,' + ruleId, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestUpdateRule(post, id) {
    const body = JSON.stringify(post);
    return this.http.put<any>(this.apiRule + '/' + id, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestDeleteRule(id) {
    return this.http.delete<any>(this.apiRule + '/' + id, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  requestDeleteConcessionRule(concessionRuleId) {
    return this.http.delete<any>(this.apiConcessionRule + '/' + concessionRuleId, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
