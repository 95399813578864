import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataContactService {

  constructor(private http: HttpClient) { }

  errorMessage: string | undefined;

  public handleError(error: HttpErrorResponse): Observable<never> {
    this.errorMessage = (error.error instanceof ErrorEvent) ? `Error: ${error.error.message}` : `Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(this.errorMessage);
  }

  getContact() {
    const apiContact = environment.globalApiUrl + 'api/get-contact';
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getContactByConcession(concessionId) {
    const apiContact = environment.globalApiUrl + 'api/get-contact-by-concession/' + concessionId;
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getContactById(contactId) {
    const apiContact = environment.globalApiUrl + 'api/get-contact/' + contactId;
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConcessionByContact(contactId) {
    const apiContact = environment.globalApiUrl + 'api/get-concession-by-contact/' + contactId;
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createContact(body) {
    const apiContact = environment.globalApiUrl + 'api/create-contact';
    return this.http.post<any>(apiContact, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateContact(body) {
    const apiContact = environment.globalApiUrl + 'api/put-contact';
    return this.http.put<any>(apiContact, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getGroup() {
    const apiContact = environment.globalApiUrl + 'api/get-group';
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDealership() {
    const apiContact = environment.globalApiUrl + 'api/get-dealership';
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getBrand() {
    const apiContact = environment.globalApiUrl + 'api/get-brand';
    return this.http.get<any>(apiContact, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
