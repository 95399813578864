<div class="main-content-inner gestion-statut mt-2">
  <h1>Mapping des statuts</h1>
  <div class="user-table mt-4">
    <div class="header-rules-action d-flex flex-row-reverse w-100 mx-auto">
      <div class="col-12 text-right d-flex justify-content-end align-items-center">
        <mat-form-field style="width: 250px">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="pb-4 user-table">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
      <ng-container matColumnDef="nom_concession">
        <th mat-header-cell *matHeaderCellDef> Nom de la concession</th>
        <td mat-cell *matCellDef="let element"> <strong>{{element.nom_concession}}</strong> </td>
      </ng-container>
      <ng-container matColumnDef="ville">
        <th mat-header-cell *matHeaderCellDef> Ville </th>
        <td mat-cell *matCellDef="let element"> {{element.ville}} </td>
      </ng-container>
      <ng-container matColumnDef="rue">
        <th mat-header-cell *matHeaderCellDef> Rue </th>
        <td mat-cell *matCellDef="let element"> {{element.rue}} </td>
      </ng-container>
      <ng-container matColumnDef="code_postal">
        <th mat-header-cell *matHeaderCellDef> Code postal </th>
        <td mat-cell *matCellDef="let element"> {{element.code_postal}} </td>
      </ng-container>
      <ng-container matColumnDef="tel">
        <th mat-header-cell *matHeaderCellDef> Téléphone </th>
        <td mat-cell *matCellDef="let element"> {{element.tel}} </td>
      </ng-container>
      <ng-container matColumnDef="open">
        <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
        <td mat-cell *matCellDef="let element"><mat-icon id="chevron-{{element.concession_id}}">keyboard_arrow_down</mat-icon></td>
      </ng-container>
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail flex-column" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="d-flex justify-content-center m-3">
              <div class="w-100" style="display: flex">
                <div *ngIf="element == expandedElement" style="position: inherit;padding: 10px;margin: auto;">
                  <form [formGroup]="statusCreationForm" (ngSubmit)="onSubmit(statusCreationForm.value)" class="d-flex justify-content-around align-items-end formation-form">
                    <div class="form-group">
                      <label for="statut-local">Statut Pilot-Statut:</label>
                      <select class="form-control" name="statut-local" id="statut-local" formControlName="statut_local">
                        <option value="1" class="mat-list-option">Rendez-vous fixé</option>
                        <option value="7">Véhicule réceptionné</option>
                        <option value="2">Travaux en cours</option>
                        <option value="8">Travaux en cours de finalisations</option>
                        <option value="5">Travaux en attente</option>
                        <option value="3">Travaux terminés</option>
                        <option value="4">Véhicule restitué</option>
                        <option value="6">Statuts non remontés par KIDI</option>
                      </select>
                      <small *ngIf="errors.statut_local" class="input-error" style="color: red">Champ requis</small>
                    </div>
                    <div class="form-group">
                      <label for="statut-concession-raw">Statut Soft-Planning (sans accent) :</label>
                      <input type="text" class="form-control" name="statut-concession-raw" id="statut-concession-raw" formControlName="statut_concession">
                      <small *ngIf="errors.statut_concession" class="input-error" style="color: red">Champ requis</small>
                    </div>
                    <div class="form-group">
                      <label for="statut-concession">Libellé affiché sur KIDI :</label>
                      <input type="text" class="form-control" name="statut-concession" id="statut-concession" formControlName="statut_concession_label">
                      <small *ngIf="errors.statut_concession_label" class="input-error" style="color: red">Champ requis</small>
                    </div>
                    <div>
                      <button type="submit" class="btn btn-dark d-flex align-items-center btn-create p-2 ml-2"><mat-icon class="mr-2">add_circle</mat-icon>Ajouter un statut</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="example-element-description w-75 d-flex m-auto">
              <table class="mat-elevation-z8 w-100">
                <thead>
                  <th scope="col" class="status-th">Statut Pilot-Statut</th>
                  <th scope="col" class="status-th">Statut Soft-Planning (sans accent)</th>
                  <th scope="col" class="status-th">Libellé affiché sur KIDI</th>
                </thead>
                <tbody>
                  <tr *ngFor="let statutLocal of concessionStatus">
                    <th (dragover)="onDragOver($event, statutLocal)" (drop)="onDrop($event)">{{statutLocal.name}}</th>
                    <td>
                      <div *ngFor="let status of statutLocal.statusConcession" class="statut-list statut-list-drg" (dragstart)="onDragStart($event, status)" draggable="true">{{status.statut_concession}}</div>
                    </td>
                    <td>
                      <div *ngFor="let status of statutLocal.statusConcession" class="statut-list">
                        <span>{{status.statut_concession_label}}</span>
                        <div class="action">
                          <button class="btn btn-edit-small" matTooltip="Supprimer le statut" matTooltipPosition="above" (click)="onDeleteStatus(status.id)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row pointer"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element; onOpenRow(element.concession_id);">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>
