<h2>Création Notification</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px;margin: auto;">
    <form [formGroup]="notificationCreationForm" (ngSubmit)="onSubmit(notificationCreationForm.value)" class="notification-form">
      <div class="form-row w-75">
        <div class="form-group">
          <mat-form-field class="mr-2" appearance="standard" style="width: 587px">
            <mat-label>Titre</mat-label>
            <input matInput formControlName="title" [(ngModel)]="notificationCreationForm.title">
          </mat-form-field>
          <small *ngIf="error.title" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <mat-form-field class="mr-2" appearance="standard" style="width: 587px">
            <mat-label>Cible</mat-label>
            <mat-select placeholder="Cible" formControlName="target" [(ngModel)]="selectedTarget" multiple>
              <mat-option *ngFor="let target of targets" [value]="target.slug">
                {{target.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
<!--          <select class="form-control" name="target" id="target" formControlName="target">-->
<!--            <option *ngFor="let target of targets" value="{{target.slug}}">{{target.label}}</option>-->
<!--          </select>-->
          <small *ngIf="error.target" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <mat-form-field class="mr-2" appearance="standard" style="width: 587px">
            <mat-label>Type</mat-label>
            <mat-select placeholder="Type" formControlName="type" [(value)]="notificationCreationForm.type">
              <mat-option *ngFor="let type of types" [value]="type.name">
                {{type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

<!--          <label for="type">Type:</label>-->
<!--          <select class="form-control" name="type" id="type" formControlName="type">-->
<!--            <option *ngFor="let type of types" value="{{type.name}}">{{type.label}}</option>-->
<!--          </select>-->
          <small *ngIf="error.type" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="text">Notification:</label>
          <textarea class="form-control" name="text" id="text" [(ngModel)]="notificationCreationForm.controls.text.value" formControlName="text"></textarea>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group checkbox-group">
          <button type="button" *ngIf="!isLink" (click)="addLinkForm()" class="btn btn-light border-dark btn-create p-2 mt-3 mr-2">Ajouter un lien</button>
       </div>
      </div>
      <div id="links" class="form-row w-75 mb-2" *ngIf="isLink">
        <div class="w-100 d-flex">
          <div class="col-3">
            <label for="label">Texte affiché:</label>
            <input type="text" class="form-control" [ngModelOptions]="{standalone : true}" [(ngModel)]="linkLabel" name="label" id="label">
          </div>
          <div class="col-8">
            <label for="url">Url:</label>
            <input type="text" class="form-control" [ngModelOptions]="{standalone : true}" [(ngModel)]="linkUrl" name="url" id="url">
          </div>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group checkbox-group justify-content-end" style="margin-right: 106px">
          <button type="button" *ngIf="isLink" (click)="addLinkForm()" class="btn btn-light border-dark btn-create p-2 mt-3 mr-2">Annuler</button>
          <button type="button" *ngIf="isLink" (click)="addLink()" class="btn btn-dark btn-create p-2 mt-3 mr-2">valider</button>
        </div>
      </div>
      <div class="form-row w-25">
        <div class="form-group checkbox-group">
          <label for="is_active">Active:</label>
          <input type="checkbox" class="form-control checkbox" name="is_active" id="is_active" formControlName="is_active">
        </div>
        <div>
          <button type="submit" class="btn btn-dark btn100px btn-create p-2 mt-3 mr-2">Créer</button>
        </div>
        <div>
          <button class="btn btn-light btn100px border-dark btn-create p-2 mt-3" type="button" routerLink="/gestion-notification">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>
