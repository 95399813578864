import { Component, OnInit } from '@angular/core';
import { DataModificationProduitService } from '../../utils/data-services/dataModification-produit.service';
import { DataInteractionService } from '../../utils/data-services/dataInteraction.service';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {FormControl, Validators} from "@angular/forms";
import {DataCreationProduitService} from "../../utils/data-services/dataCreation-produit.service";

@Component({
  selector: 'app-modification-produit',
  templateUrl: './modification-produit.component.html',
  styleUrls: ['./modification-produit.component.css']
})
export class ModificationProduitComponent implements OnInit {
  
  roleArray = [];
  selectedRole = [];

  result = '';
  // tslint:disable-next-line:max-line-length
  constructor(
    private dataCreationProduitService: DataCreationProduitService, 
    private dataModificationProduitService: DataModificationProduitService, 
    private dataInteractionService: DataInteractionService, 
    private route: Router, 
    public dialog: MatDialog,
    ) { }

  variablePar: any;
  variableCon: any;
  variableParametre: any;
  parametreID: any;
  concessionID: any;
  produitParametreID: any;
  concessionProduitID: any;
  variableConcession: any;
  nConcession = -1;
  arrayConcession: any[] = [];
  indiceConcession: any = 0;
  nParametre = -1;
  arrayParametre: any[] = [];
  indiceParametre: any = 0;
  nConcessionProduit = 0;
  arrayConcessionProduit1: any[] = [];
  arrayConcessionProduit2: any[] = [];
  arrayConcessionProduit3: any[] = [];
  nProduitParametre = 0;
  arrayProduitParametre1: any[] = [];
  arrayProduitParametre2: any[] = [];
  arrayProduitParametre3: any[] = [];
  product = {
    id: null,
    name: null,
    description: null,
    type: null,
    version: (0).toString().padStart(4,'0'),
    actif: false,
    roles: [],
  };
  typeArray = ['technique', 'commercial'];
  nameFormControl = new FormControl('', [Validators.required]);
  typeFormControl = new FormControl('', [Validators.required]);

  ngOnInit() {

    this.getRoles();
    /* Fill page and reserve variables in case of back navigation */
    if (localStorage.getItem('softgateProduitID') == null || localStorage.getItem('softgateProduitID') === 'undefined') {
      this.product.id = '';
    } else {this.product.id = localStorage.getItem('softgateProduitID'); }

    this.dataCreationProduitService.getProductById(this.product.id).subscribe(product => { 
  
      this.selectedRole = [];

      this.product.name = product.nom_produit;
      this.product.description = product.description;
      this.product.type = product.type;
      this.product.actif = product.actif;
      this.product.version = parseInt(product.version).toString().padStart(4, '0');
  
      for ( let role of product.linked_roles) {
        this.selectedRole.push(role.id);
      }
     });
     
    /* End */
    // tslint:disable-next-line:max-line-length
    this.dataModificationProduitService.getParametreProduit(this.product.id).subscribe(data8 => { this.variablePar = data8.records; });
    // tslint:disable-next-line:max-line-length
    this.dataModificationProduitService.getConcessionProduit(this.product.id).subscribe(data9 => { this.variableCon = data9.records; });
    
    
    }

    // getRole
    getRoles(): any {
      this.dataCreationProduitService.getRoles().subscribe(roles => {

        this.roleArray = [];
        for (const role of roles.records) {
          this.roleArray.push({ item_id: role.id, item_text: role.label});
        }
      })

    // getProductById
    // getProductById(): any {

    // }
    
  }

  /***
   * Delete a concession for a product
   * Parameter: concession_id, Type: Int
   * Parameter: nom_concession, Type: String
   ***/
  dialogConcession(arg: any, arg1: any): void {
    const message = ' Etes vous sûre de vouloir supprimer ' + arg1 + ' ? ';
    const dialogData = new ConfirmDialogModel('Confirmer l\'action', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: '200px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (this.result) {
        this.deleteConcession(arg);
      } else {
      }
    });
  }
  incriseDecriseVersion(signe) {
    let value = parseInt(this.product.version);
    if (signe === '+') {
      value++; 
      // @ts-ignore
      this.product.version = value.toString().padStart(4, '0');
    } else if (signe === '-') {
      value--;
      // @ts-ignore
      this.product.version = value.toString().padStart(4, '0');
    }
  }
  /***
   * Delete a parameter for a product
   * Parameter: parametre_id, Type: Int
   * Parameter: nom_parametre, Type: String
   ***/
  dialogParametre(arg: any, arg1: any): void {
    const message = ' Etes vous sûre de vouloir supprimer ' + arg1 + ' ? ';
    const dialogData = new ConfirmDialogModel('Confirmer l\'action', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: '200px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (this.result) {
        this.deleteParametre(arg);
      } else {
      }
    });
  }

  /***
   * Update product variable values
   ***/
    selectChangeHandlerSoumettre() {
    
    this.product.roles = this.selectedRole;
     
    this.dataCreationProduitService.updateProduct(JSON.stringify(this.product)).subscribe(result => {
      this.selectChangeHandlerAnnuler();
     
      swal.fire({
        title: 'Success!',
        text: 'Produit a bien été modifié',
        type: 'success',
        confirmButtonText: 'Ok'
      });
      this.selectChangeHandlerAnnuler();
    });
  }

  selectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-produit']);
  }



  rechargerPageConcession() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.arrayConcession.length; i++) {
      this.ajouterConcession(this.arrayConcession[i].value);
    }
    setTimeout(() => {
        window.location.reload();
      },
      2000);
  }

  rechargerPageParametre() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.arrayParametre.length; i++) {
      this.ajouterParametre(this.arrayParametre[i].value);
    }
    setTimeout(() => {
        window.location.reload();
      },
      2000);
  }

  /***
   * Retrieve all parameters for a product and only display the ones that don't belong to the product
   ***/
  SelectChangeHandlerAjouterParametre() {
    this.dataModificationProduitService.getParametrePourProduit(this.product.id).subscribe( data1 => {
      this.arrayProduitParametre1 = data1.records;
      this.dataModificationProduitService.getParametre().subscribe( data2 => {
        this.arrayProduitParametre2 = data2.records;
        this.arrayProduitParametre3 = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.arrayProduitParametre2.length; i++) {
          if (!((this.arrayProduitParametre1).some(ID => ID.parametre_id === this.arrayProduitParametre2[i].parametre_id))) {
            this.arrayProduitParametre3.push(this.arrayProduitParametre2[i]);
            this.nProduitParametre++;
          }
        }
        this.variableParametre = this.arrayProduitParametre3;
      });
    });
  }

  /***
   * Add product parameter relation
   * Parameter: parametre_id, Type: Int
   ***/
  ajouterParametre(arg: any) {
    this.parametreID = arg;
    this.dataModificationProduitService.addProduitParametre(this.product.id, this.parametreID).subscribe( data => {
      swal.fire({
        title: 'Success!',
        text: 'Parametre a été bien rajouté',
        type: 'success',
        showConfirmButton: false
      });
    });
  }

  /***
   * Delete product parameter relation
   * Parameter: parametre_id, Type: Int
   ***/
  deleteParametre(arg: any) {
    this.dataModificationProduitService.getIdProduitParametre(this.product.id, arg).subscribe( data => {
      this.produitParametreID = data.records;
      this.dataModificationProduitService.deleteProduitParametre((this.produitParametreID)[0].produit_parametre_id).subscribe( data1 => {
        swal.fire({
          title: 'Success!',
          text: 'Le Parametre a été bien supprimé',
          type: 'success',
          showConfirmButton: false
        });
        setTimeout(() => {
            window.location.reload();
          },
          1000);
      });
    });
  }

  /***
   * Retrieve all Concessions for a product and only display the ones that don't contain to the product
   ***/
  SelectChangeHandlerAjouterConcession() {
    this.dataModificationProduitService.getConcessionPourProduit(this.product.id).subscribe( data1 => {
      this.arrayConcessionProduit1 = data1.records;
      this.dataModificationProduitService.getConcession().subscribe( data2 => {
        this.arrayConcessionProduit2 = data2.records;
        this.arrayConcessionProduit3 = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.arrayConcessionProduit2.length; i++) {
           if (!((this.arrayConcessionProduit1).some(ID => ID.concession_id === this.arrayConcessionProduit2[i].concession_id))) {
             this.arrayConcessionProduit3.push(this.arrayConcessionProduit2[i]);
             this.nConcessionProduit++;
          }
        }
        this.variableConcession = this.arrayConcessionProduit3;
      });
    });
  }

  /***
   * Add concession product relation
   * Parameter: concession_id, Type: Int
   ***/
  ajouterConcession(arg: any) {
    this.concessionID = arg;
    this.dataModificationProduitService.addProduitConcession(arg, this.product.id).subscribe( data => {
      swal.fire({
        title: 'Success!',
        text: 'Concession a bien été rajouté',
        type: 'success',
        showConfirmButton: false
      });
    });
  }

  /***
   * Delete concession product relation
   * Parameter: concession_id, Type: Int
   ***/
  // tslint:disable-next-line:adjacent-overload-signatures
  deleteConcession(arg: any) {
    this.dataModificationProduitService.getIdconcessionProduit(arg, this.product.id).subscribe( data => {
      this.concessionProduitID = data.records;
      this.dataModificationProduitService.deleteConcessionProduit((this.concessionProduitID)[0].concession_produit_id).subscribe( data1 => {
        swal.fire({
          title: 'Success!',
          text: 'La Concession a été bien supprimée',
          type: 'success',
          showConfirmButton: false
        });
        setTimeout(() => {
            window.location.reload();
          },
          1000);
      });
    });
  }

  /***
   * Create a concession object array to be added to product based on checkbox selection
   ***/
  checkClickConcession($event: any) {
    if ($event.target.checked) {
      this.nConcession++;
      this.arrayConcession[this.nConcession] = {key: 'softgateAjouterParametreId' + $event.target.value, value: $event.target.value};
    } else if (!($event.target.checked)) {
      this.indiceConcession = this.arrayConcession.findIndex(item => item.value === $event.target.value);
      this.arrayConcession.splice(this.indiceConcession, 1);
      this.nConcession--;
    }
  }

  /***
   * Create a parameter object array to be added to product based on checkbox selection
   ***/
  checkClickParametre($event: any) {
    if ($event.target.checked) {
      this.nParametre++;
      this.arrayParametre[this.nParametre] = {key: 'softgateAjouterParametreId' + $event.target.value, value: $event.target.value};
    } else if (!($event.target.checked)) {
      this.indiceParametre = this.arrayParametre.findIndex(item => item.value === $event.target.value);
      this.arrayParametre.splice(this.indiceParametre, 1);
      this.nParametre--;
    }
  }

  onChangeCheckbox(type) {
    this.product[type] = !this.product[type];
  }

  getProductById(id) {
    this.product[id] = !this.product[id];
  }

  getAllPriduct(products) {
    
    this.product[products] = !this.product[products];
  }
}
