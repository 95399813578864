import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataCreationNouvelleService extends HandleErrorService{

  getNomConcessionSelected(idConcession: any) {
    // tslint:disable-next-line:max-line-length
    const apiConcession = environment.globalUrlDomaine + '/records/concession?include=nom_concession&filter=concession_id,eq,' + idConcession;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getNomProduitSelected(idProduit: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit?include=nom_produit&filter=produit_id,eq,' + idProduit;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getNomParametreSelected(idParametre: any) {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre?include=nom_parametre&filter=parametre_id,eq,' + idParametre;
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getParametre_Produit(idProduit: any) {
    const apiValeur1 = environment.globalUrlDomaine + '/records/parametre_produit?filter=produit_id,eq,' + idProduit;
    return this.http.get<any>(apiValeur1, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public createValeur(text: any, date1: any, date2: any, idparametre: any, idConcession: any, marqueId: any) {
    return new Promise((resolve, reject) => {
      let dateF: any;
      const apiValeur = environment.globalUrlDomaine + '/records/valeur';
      const dateD = this.datePipe.transform(date1, 'yyyy-MM-dd');
      const brandId = marqueId == null ? 1 : marqueId;
      if (date2 == null || date2 === 'undefined') {
        dateF = '9999-01-01';
      } else {
        dateF = this.datePipe.transform(date2, 'yyyy-MM-dd');
      }
      const body = {
        valeur: text,
        date_debut_param: dateD,
        date_fin_param: dateF,
        parametre_id: idparametre,
        concession_id: idConcession,
        marque_id: brandId
      };
      this.http.post<any>(apiValeur, body, environment.httpOptions)
        .toPromise()
        .then(
          (response: any) => {
            resolve(response);
          },
          error => {
            this.handleError(error);
          }
        );
    });
  }

  public getMarque() {
    const api = environment.globalUrlDomaine + '/records/marque';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  public createMarqueValeur(idMarque: number, idValeur: unknown): Observable<HttpErrorResponse> {
    const api = environment.globalUrlDomaine + '/records/marque_valeur';
    const body = {marque_id: idMarque, valeur_id: idValeur};
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  checkConcessionProduit(idConcession: any, idProduit: any) {
    // tslint:disable-next-line:max-line-length
    const apiValeur = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + idConcession + '&filter=produit_id,eq,' + idProduit;
    return this.http.get<any>(apiValeur, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  createConcessionProduit(idConcession: any, idProduit: any) {
    const apiValeur = environment.globalUrlDomaine + '/records/concession_produit';
    const body = { concession_id: idConcession,
      produit_id: idProduit,
      version: '0000'
     };
    return this.http.post<any>(apiValeur, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getProduitParametre(parameterId: number) {
    const api = environment.globalUrlDomaine + '/records/produit_parametre?filter=parametre_id,eq,' + parameterId;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getIDConcessionProduit(arg1: any, arg2: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + arg1 + '&filter=produit_id,eq,' + arg2 + '&include=concession_produit_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  updateVersionConcessionProduit(id: any): Observable<HttpErrorResponse> {
    const api = environment.globalUrlDomaine + '/records/concession_produit/' + id;
    const body = {version: '0000'};
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
}
