import { BrowserModule } from '@angular/platform-browser';
import {ChangeDetectorRef, NgModule} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// tslint:disable-next-line:import-spacing
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSortModule } from '@angular/material/sort';
import {
  MatDateFormats,
  MatInputModule, MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { FilterPipe } from './utils/pipe.service';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, FormControl } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule, MatCheckboxModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// import { MatAutocompleteModule } from '@angular/material/typings/esm5/autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';



import {AppComponent, PhonePipe} from './app.component';
import { ConsultationComponent } from './consultation';
import { ModificationComponent } from './consultation/modification';
import { CreationNouvelleComponent } from './consultation/creationNouvelle';
import { HomeComponent } from './home';
import { CreationComponent } from './consultation/creation';
import { CreationProduitComponent } from './gestion-produit/creation-produit';
import { GestionProduitComponent } from './gestion-produit';
import { GestionParametreComponent } from './gestion-parametre';
import { ModificationProduitComponent } from './gestion-produit/modification-produit';
import { ModificationParametreComponent } from './gestion-parametre/modification-parametre';
import { CreationParametreComponent } from './gestion-parametre/creation-parametre';
import { GestionConcessionComponent } from './gestion-concession';
import { ModificationConcessionComponent } from './gestion-concession/modification-concession';
import { CreationConcessionComponent } from './gestion-concession/creation-concession';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CreationSelectionComponent } from './consultation/creationSelection/creation-selection.component';


import { DataConsultationService } from './utils/data-services/dataConsultation.service';
import { DataCreationNouvelleService } from './utils/data-services/dataCreationNouvelle.service';
import { DataInteractionService } from './utils/data-services/dataInteraction.service';
import { DataCreationService } from './utils/data-services/dataCreation.service';
import { DataModificationService } from './utils/data-services/dataModification.service';
import { DataCreationProduitService } from './utils/data-services/dataCreation-produit.service';
import { DataParametreService } from './utils/data-services/dataParametre.service';
import { DataProduitService } from './utils/data-services/dataProduit.service';
import { DataModificationProduitService } from './utils/data-services/dataModification-produit.service';
import {DataModificationParametreService} from './utils/data-services/dataModification_parametre.service';
import { DataCreationParametreService } from './utils/data-services/dataCreation_parametre.service';
import { DataModificationConcessionService } from './utils/data-services/dataModification-concession.service';
import { DataCreationConcessionService } from './utils/data-services/dataCreation-concession.service';
import { DataConcessionService } from './utils/data-services/dataConcession.service';
import { DataCreationSelectionService } from './utils/data-services/dataCreationSelection.service';
import { LoginComponent } from './authentification/login/login.component';
import { RegisterComponent } from './authentification/register/register.component';
import { ResetPasswordComponent } from './authentification/reset-password/reset-password.component';
import { GestionUtilisateurComponent } from './gestion-utilisateur/gestion-utilisateur.component';
import { CreationUtilisateurComponent } from './gestion-utilisateur/creation-utilisateur/creation-utilisateur.component';
import { ModificationUtilisateurComponent } from './gestion-utilisateur/modification-utilisateur/modification-utilisateur.component';
import {DataUtilisateurService} from './utils/data-services/dataUtilisateurService';
import { SpinnerComponent } from './utils/spinner/spinner.component';
import { ForgotPasswordComponent } from './authentification/forgot-password/forgot-password.component';
import { GestionRegleComponent } from './gestion-regle/gestion-regle.component';
import { CreationRegleComponent } from './gestion-regle/creation-regle/creation-regle.component';
import { ModificationRegleComponent } from './gestion-regle/modification-regle/modification-regle.component';
import { GestionFormationComponent } from './gestion-formation/gestion-formation.component';
import { CreationFormationComponent } from './gestion-formation/creation-formation/creation-formation.component';
import { ModificationFormationComponent } from './gestion-formation/modification-formation/modification-formation.component';
import { GestionNotificationComponent } from './gestion-notification/gestion-notification.component';
import { CreationNotificationComponent } from './gestion-notification/creation-notification/creation-notification.component';
import { ModificationNotificationComponent } from './gestion-notification/modification-notification/modification-notification.component';
import { GestionStatutComponent } from './gestion-statut/gestion-statut.component';
import {DataIspaccService} from './utils/data-services/dataIspacc.service';
import { Error404Component } from './error404/error404.component';
import { GestionFaqThemesComponent } from './gestion-faq-themes/gestion-faq-themes.component';
import { GestionFaqQuestionsComponent } from './gestion-faq-questions/gestion-faq-questions.component';
import { CreationFaqQuestionsComponent } from './gestion-faq-questions/creation-faq-questions/creation-faq-questions.component';
import { ModificationFaqQuestionsComponent } from './gestion-faq-questions/modification-faq-questions/modification-faq-questions.component';
import {DataFaqService} from './dataFaq.service';
import {HandleErrorService} from './utils/handleError.service';
import {LogoutService} from './utils/logout.service';
import { GestionGroupeComponent } from './gestion-groupe/gestion-groupe.component';
import { GestionMarquesComponent } from './gestion-marques/gestion-marques.component';
import { GestionCommunicationsKidiComponent } from './gestion-communications-kidi/gestion-communications-kidi.component';
import { FormCommunicationsKidiComponent } from './gestion-communications-kidi/form-communications-kidi/form-communications-kidi.component';
import { SelectConcessionsDialogComponent } from './select-concessions-dialog/select-concessions-dialog.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import {DataMaintenancesService} from './utils/data-services/dataMaintenances.service';
import {GestionContactComponent, TooltipListPipe} from './gestion-contact/gestion-contact.component';
import {DialogFonctionDuplicateDialog, GestionFonctionComponent} from './gestion-fonction/gestion-fonction.component';
import {CreationContactComponent, DialogContactDuplicateDialog} from './gestion-contact/creation-contact/creation-contact.component';
import { ModificationContactComponent } from './gestion-contact/modification-contact/modification-contact.component';
import {MatSelectFilterModule} from 'mat-select-filter';
import { GestionMappingPlanngoComponent } from './gestion-mapping-planngo/gestion-mapping-planngo.component';
import { GestionCampaignDiscountsComponent } from './gestion-campaign-discounts/gestion-campaign-discounts.component';
import { CreationCampaignDiscountsComponent } from './gestion-campaign-discounts/creation-campaign-discounts/creation-campaign-discounts.component';
import { ModificationCampaignDiscountsComponent } from './gestion-campaign-discounts/modification-campaign-discounts/modification-campaign-discounts.component';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CreateOperationComponent } from './gestion-operation/create-operation/create-operation.component';
import { GestionOperationComponent } from './gestion-operation/gestion-operation.component';
import { ModificationOperationComponent } from './gestion-operation/modification-operation/modification-operation.component';
import { GestionConfigurationConcessionComponent } from './gestion-configuration-concession/gestion-configuration-concession.component';
import { ModificationConfigurationConcessionComponent } from './gestion-configuration-concession/modification-configuration-concession/modification-configuration-concession.component';
import { GestionMobiliteComponent } from './gestion-mobilite/gestion-mobilite.component';
import { CreateMobiliteComponent } from './gestion-mobilite/create-mobilite/create-mobilite.component';
import { UpdateMobiliteComponent } from './gestion-mobilite/update-mobilite/update-mobilite.component';
import { GestionSnapshotScenarioComponent } from './gestion-snapshot-scenario/gestion-snapshot-scenario.component';
import { CreateSnapshotScenarioComponent } from './gestion-snapshot-scenario/create-snapshot-scenario/create-snapshot-scenario.component';
import { UpdateSnapshotScenarioComponent } from './gestion-snapshot-scenario/update-snapshot-scenario/update-snapshot-scenario.component';
import { GestionBackupConcessionComponent } from './gestion-backup-concession/gestion-backup-concession.component';
import { CreateBackupConcessionComponent } from './gestion-backup-concession/create-backup-concession/create-backup-concession.component';
import { UpdateBackupConcessionComponent } from './gestion-backup-concession/update-backup-concession/update-backup-concession.component';
import { GestionReceptionOptionsMappingComponent } from './gestion-reception-options-mapping/gestion-reception-options-mapping.component';
import { UpdateReceptionOptionsMappingComponent } from './gestion-reception-options-mapping/update-reception-options-mapping/update-reception-options-mapping.component';
import { GestionDemandesRgpdComponent } from './gestion-demandes-rgpd/gestion-demandes-rgpd.component';
import {MatPaginator} from '@angular/material/paginator';
import {CreateReceptionOptionsMappingComponent} from './gestion-reception-options-mapping/create-reception-options-mapping/create-reception-options-mapping.component';
import {MatRadioModule} from "@angular/material/radio";
import { GestionSmsComponent } from './gestion-sms/gestion-sms.component';
import { GestionProduitConcessionComponent } from './gestion-produit-concession/gestion-produit-concession.component';
import { GestionConcessionProduitComponent } from './gestion-concession-produit/gestion-concession-produit.component';
import {MatTabsModule} from "@angular/material/tabs";
import { GestionSmsSubscriptionComponent } from './gestion-sms-subscription/gestion-sms-subscription.component';
import { GestionSubscriptionComponent } from './gestion-subscription/gestion-subscription.component';
import { CreateSubscriptionComponent } from './gestion-subscription/create-subscription/create-subscription.component';
import { UpdateSubscriptionComponent } from './gestion-subscription/update-subscription/update-subscription.component';
import { AssignDealershipSubscriptionComponent } from './gestion-sms-subscription/assign-dealership-subscription/assign-dealership-subscription.component';
import { GestionRentDealershipsAgenciesComponent } from './gestion-rent-dealerships-agencies/gestion-rent-dealerships-agencies.component';
import { CreateRentDealershipsAgenciesComponent } from './gestion-rent-dealerships-agencies/create-rent-dealerships-agencies/create-rent-dealerships-agencies.component';
import { UpdateRentDealershipsAgenciesComponent } from './gestion-rent-dealerships-agencies/update-rent-dealerships-agencies/update-rent-dealerships-agencies.component';
import { UpdateSmsSubscriptionComponent } from './gestion-sms-subscription/update-sms-subscription/update-sms-subscription.component';
import { GestionTagComponent } from './gestion-tag/gestion-tag.component';
import { GestionMarquePlanngoComponent } from './gestion-marque-planngo/gestion-marque-planngo.component';
import { GestionInformationsProduitComponent } from './gestion-informations-produit/gestion-informations-produit.component';
import { InformationsProduitComponent } from './gestion-informations-produit/informations-produit/informations-produit.component';
import { GestionConfigurationComponent } from './gestion-configuration/gestion-configuration.component';
import { CreateConfigurationComponent } from './gestion-configuration/create-configuration/create-configuration.component';
import { UpdateConfigurationComponent } from './gestion-configuration/update-configuration/update-configuration.component';
import { GestionDefaultConfigurationComponent } from './gestion-default-configuration/gestion-default-configuration.component';
import { CreateDefaultConfigurationComponent } from './gestion-default-configuration/create-default-configuration/create-default-configuration.component';
import { UpdateDefaultConfigurationComponent } from './gestion-default-configuration/update-default-configuration/update-default-configuration.component';
import { GestionConfigurationSectionComponent } from './gestion-configuration-section/gestion-configuration-section.component';
import { Auth2faComponent } from './authentification/auth2fa/auth2fa.component';
import { UpdateConfigurationSectionComponent } from './gestion-configuration-section/update-configuration-section/update-configuration-section.component';
import { CreateConfigurationSectionComponent } from './gestion-configuration-section/create-configuration-section/create-configuration-section.component';

// import { ModuleThatDeclaresModalTest } from 'path/to/module';
export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ConsultationComponent,
    ModificationComponent,
    HomeComponent,
    CreationNouvelleComponent,
    CreationComponent,
    GestionParametreComponent,
    GestionProduitComponent,
    CreationProduitComponent,
    ModificationProduitComponent,
    ModificationParametreComponent,
    CreationParametreComponent,
    GestionConcessionComponent,
    ModificationConcessionComponent,
    CreationConcessionComponent,
    FilterPipe,
    ConfirmationDialogComponent,
    CreationSelectionComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    GestionUtilisateurComponent,
    CreationUtilisateurComponent,
    ModificationUtilisateurComponent,
    SpinnerComponent,
    ForgotPasswordComponent,
    GestionRegleComponent,
    CreationRegleComponent,
    ModificationRegleComponent,
    GestionFormationComponent,
    CreationFormationComponent,
    ModificationFormationComponent,
    GestionNotificationComponent,
    CreationNotificationComponent,
    ModificationNotificationComponent,
    GestionStatutComponent,
    Error404Component,
    GestionFaqThemesComponent,
    GestionFaqQuestionsComponent,
    CreationFaqQuestionsComponent,
    ModificationFaqQuestionsComponent,
    GestionGroupeComponent,
    GestionMarquesComponent,
    GestionCommunicationsKidiComponent,
    FormCommunicationsKidiComponent,
    SelectConcessionsDialogComponent,
    MaintenanceComponent,
    GestionContactComponent,
    TooltipListPipe,
    GestionFonctionComponent,
    CreationContactComponent,
    ModificationContactComponent,
    DialogContactDuplicateDialog,
    DialogFonctionDuplicateDialog,
    GestionMappingPlanngoComponent,
    GestionCampaignDiscountsComponent,
    CreationCampaignDiscountsComponent,
    ModificationCampaignDiscountsComponent,
    CreateOperationComponent,
    GestionOperationComponent,
    ModificationOperationComponent,
    GestionConfigurationConcessionComponent,
    ModificationConfigurationConcessionComponent,
    GestionMobiliteComponent,
    CreateMobiliteComponent,
    UpdateMobiliteComponent,
    GestionSnapshotScenarioComponent,
    CreateSnapshotScenarioComponent,
    UpdateSnapshotScenarioComponent,
    GestionBackupConcessionComponent,
    CreateBackupConcessionComponent,
    UpdateBackupConcessionComponent,
    GestionReceptionOptionsMappingComponent,
    CreateReceptionOptionsMappingComponent,
    UpdateReceptionOptionsMappingComponent,
    GestionDemandesRgpdComponent,
    PhonePipe,
    GestionSmsComponent,
    GestionProduitConcessionComponent,
    GestionConcessionProduitComponent,
    GestionSmsSubscriptionComponent,
    GestionSubscriptionComponent,
    CreateSubscriptionComponent,
    UpdateSubscriptionComponent,
    AssignDealershipSubscriptionComponent,
    GestionRentDealershipsAgenciesComponent,
    CreateRentDealershipsAgenciesComponent,
    UpdateRentDealershipsAgenciesComponent,
    UpdateSmsSubscriptionComponent,
    GestionTagComponent,
    GestionMarquePlanngoComponent,
    GestionInformationsProduitComponent,
    InformationsProduitComponent,
    GestionConfigurationComponent,
    CreateConfigurationComponent,
    UpdateConfigurationComponent,
    GestionDefaultConfigurationComponent,
    CreateDefaultConfigurationComponent,
    UpdateDefaultConfigurationComponent,
    GestionConfigurationSectionComponent,
    Auth2faComponent,
    UpdateConfigurationSectionComponent,
    CreateConfigurationSectionComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    SelectConcessionsDialogComponent,
    DialogContactDuplicateDialog,
    DialogFonctionDuplicateDialog,
    AssignDealershipSubscriptionComponent
  ],
    imports: [
        SweetAlert2Module,
        MatTableModule,
        MatSortModule,
        MatSlideToggleModule,
        BrowserModule,
        MatInputModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatButtonToggleModule,
        BrowserAnimationsModule,
        MatDialogModule,
        ReactiveFormsModule,
        RouterModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        NgSelectModule,
        BrowserModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatTooltipModule,
        MatMenuModule,
        MatSelectFilterModule,
        MatRadioModule,
        MatPaginatorModule,
        MatTabsModule,
    ],
  exports: [
    SweetAlert2Module,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule,
    BrowserModule,
    MatInputModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NgSelectModule,
    FilterPipe,
    MatProgressSpinnerModule,
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
    DataConsultationService,
    DataCreationNouvelleService,
    DataInteractionService,
    DataCreationService,
    DataModificationService,
    DataParametreService,
    DataProduitService,
    DataCreationProduitService,
    DataModificationProduitService,
    DataModificationParametreService,
    DataCreationParametreService,
    DataCreationConcessionService,
    DataModificationConcessionService,
    DataConcessionService,
    DataIspaccService,
    DataCreationSelectionService,
    DataUtilisateurService,
    DataFaqService,
    DataMaintenancesService,
    HandleErrorService,
    LogoutService,
    DatePipe,
    ErrorHandler,
    FilterPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    MatPaginator,
    // @ts-ignore
    ChangeDetectorRef
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }


