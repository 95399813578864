<h2>Création Formation</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px;margin: auto;">
    <form [formGroup]="formationCreationForm" (ngSubmit)="onSubmit(formationCreationForm.value)" class="formation-form">
      <div class="form-row w-75">
        <div class="form-group">
          <label for="type">Ligne titre 1 (ou type):</label>
          <input type="text" class="form-control" name="type" id="type" formControlName="type">
          <small *ngIf="error.type" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="title">Ligne titre 2 (Titre):</label>
          <input type="text" class="form-control" name="title" id="title" formControlName="title">
          <small *ngIf="error.title" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-25">
        <div class="form-group">
          <label for="product">Produit:</label>
          <select name="product" id="product" [value]="'9999'" (change)="resetTuto()" class="form-control" formControlName="product">
            <option value="9999">Non Renseigné</option>
            <option *ngFor="let product of products" [value]="product.produit_id">{{product.nom_produit}}</option>
          </select>
        </div>
        <div class="form-group" *ngIf="formationCreationForm?.value.product != 9999">
          <mat-radio-group id="tuto" formControlName="tuto" class="ml-2">
            <mat-radio-button [value]="0" [checked]="!formationCreationForm.value.tuto" class="mr-2">Brochure</mat-radio-button>
            <mat-radio-button [value]="1">Tutoriel</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-row w-75">
        <div class="form-group">
          <label for="image">Image:</label>
          <input type="file" (change)="setImage($event)" class="form-control" name="image" id="image" formControlName="image">
          <small *ngIf="error.imageType" class="input-error" style="color: red">Veuillez choisir un fichier au format .png, .jpg ou .jpeg</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="image">PDF:</label>
          <input type="file" (change)="setPdf($event)" class="form-control" name="pdf" id="pdf" formControlName="pdf">
          <small *ngIf="error.pdfFormat" class="input-error" style="color: red">Veuillez choisir un fichier au format .pdf</small>
          <small *ngIf="error.pdfRequired" class="input-error" style="color: red">Fichier .pdf requis</small>
        </div>
      </div>
      <div class="form-row w-75 d-none">
        <div class="form-group">
          <label for="description">Description:</label>
          <textarea class="form-control" name="description" id="description" formControlName="description"></textarea>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="notification">Détails:</label>
          <textarea class="form-control" name="notification" id="notification" formControlName="notification"></textarea>
        </div>
      </div>
      <div class="form-row w-25">
        <div class="form-group checkbox-group">
          <label for="is_active">Active:</label>
          <input type="checkbox" class="form-control checkbox" name="is_active" id="is_active" formControlName="is_active">
        </div>
        <div>
          <button type="submit" class="btn btn100px btn-dark btn-create p-2 mr-2">Créer</button>
        </div>
        <div>
          <button class="btn btn100px btn-light border-dark btn-create p-2" type="button" routerLink="/gestion-formation">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="d-flex justify-content-center mt-5">
  <img id="image-preview" [src]="imgBase64" height="500" alt="">
</div>
