import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataSnapshotScenarioService extends HandleErrorService {
  getSnapshotScenario() {
    const apiOperation = environment.pngoApi + 'api/backoffice/snapshots-scenario';
    return this.http.get<any>(apiOperation, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createSnapshotScenario(body) {
    const apiOperation = environment.pngoApi + 'api/backoffice/snapshots-scenario/create';
    return this.http.post<any>(apiOperation, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getSnapshotScenarioById(parameter) {
    const apiOperation = environment.pngoApi + 'api/backoffice/snapshots-scenario/' + parameter;
    return this.http.get<any>(apiOperation, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateSnapshotScenario(body) {
    const apiOperation = environment.pngoApi + 'api/backoffice/snapshots-scenario/update';
    return this.http.put<any>(apiOperation, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
