<h1 class="text-center mt-2">Gestion des surveillances de sauvegardes</h1>
<div class="user-table  mt-4">
  <div class="container-row d-flex align-content-end justify-content-between w-100">
    <div class="btn1">
      <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
        <mat-icon>add_circle</mat-icon> Backup de concession
      </button>
    </div>
    <mat-form-field class="m-3">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8 mb-3">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>


    <ng-container matColumnDef="concession">
      <th mat-header-cell *matHeaderCellDef> Concession </th>
      <td mat-cell *matCellDef="let element"> {{element.concession}} </td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef> Projet </th>
      <td mat-cell *matCellDef="let element"> {{element.project}} </td>
    </ng-container>


    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="SelectChangeHandlerModifier(element.id)" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
