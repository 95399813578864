import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DataCreationConcessionService} from "../utils/data-services/dataCreation-concession.service";
import swal from "sweetalert2";
import {DataSmsService} from "../utils/data-services/data-sms.service";

@Component({
  selector: 'app-gestion-tag',
  templateUrl: './gestion-tag.component.html',
  styleUrls: ['./gestion-tag.component.scss']
})
export class GestionTagComponent implements OnInit {
  tags: MatTableDataSource<any>;
  tagForm: FormGroup;
  tagName: FormControl;
  isEditing = false;
  editedTagId: number;
  sort = null;

  constructor(
    private dataService: DataSmsService,
    private formBuilder: FormBuilder
  ) {
    this.tags = new MatTableDataSource<any>();
    this.tagName = new FormControl(null);
    this.tagForm = this.formBuilder.group({
      tagName: null
    });
  }

  ngOnInit() {
    this.getTags();
  }
  getTags() {
    this.dataService.getTag().subscribe(tags => {
      this.tags.data = tags;
    });
  }
  onSubmit() {
    if (!this.isEditing) {
      this.dataService.createTag({nom: this.tagName.value}).subscribe(res => {
        swal.fire({
          title: 'Success!',
          text: 'Le tag ' + this.tagName.value + ' a bien été créé',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getTags();
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création du tag',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    } else {
      this.dataService.updateTag({nom: this.tagName.value, id: this.editedTagId}).subscribe(res => {
        swal.fire({
          title: 'Success!',
          text: 'Le tag ' + this.tagName.value + ' a bien été modifié',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getTags();
        document.getElementById('tag-create-update-title').innerText = 'Ajouter un tag';
        document.getElementById('tag-create-update-btn').innerText = 'Ajouter le tag';
        this.tagName = new FormControl(null);
        this.isEditing = false;
        this.editedTagId = null;
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la modification du tag',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onEdit(id, tagName) {
    this.isEditing = true;
    this.editedTagId = id;
    this.tagName = new FormControl(tagName);
    scroll(0, 0);
    document.getElementById('tag-create-update-btn').innerText = 'Modifier le tag';
    document.getElementById('tag-create-update-title').innerText = 'Modifier un tag';
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataService.deleteTag(id).subscribe(res => {
          swal.fire({
            title: 'Success!',
            text: 'Le tag ' + this.tagName.value + ' a bien été supprimé',
            type: 'success',
            confirmButtonText: 'Ok'
          });
          this.getTags();
        }, error => {
          swal.fire({
            title: 'Erreur!',
            text: 'Un problème est survenu lors de la suppression du tag',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  onCancel() {
    document.getElementById('tag-create-update-title').innerText = 'Ajouter un tag';
    document.getElementById('tag-create-update-btn').innerText = 'Ajouter le tag';
    this.tagName = new FormControl(null);
    this.isEditing = false;
    this.editedTagId = null;
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.tags.filteredData = this.tags.filteredData.sort((a, b) => {
        return a.nom < b.nom ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.tags.filteredData = this.tags.filteredData.sort((a, b) => {
        return a.nom > b.nom ? 1 : -1;
      });
    }
  }
  sortId() {
    if (this.sort == 'idAcs') {
      this.sort = 'idDesc';
      this.tags.filteredData = this.tags.filteredData.sort((a, b) => {
        return a.id < b.id ? 1 : -1;
      });
    } else {
      this.sort = 'idAcs';
      this.tags.filteredData = this.tags.filteredData.sort((a, b) => {
        return a.id > b.id ? 1 : -1;
      });
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tags.filter = filterValue.trim();
  }
}
