import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataOperationService extends HandleErrorService {
  getOperationByConcession(parameter) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-operation/' + parameter;
    return this.http.get<any>(apiOperation, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getOperationById(parameter) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-operation-by-id/' + parameter;
    return this.http.get<any>(apiOperation, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createOperation(body) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-operation/create';
    return this.http.post<any>(apiOperation, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateOperation(body) {
    const apiOperation = environment.pngoApi + 'api/backoffice/join-softnet-operation/update';
    return this.http.put<any>(apiOperation, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getAllCategorie() {
    const apiCategorie = environment.pngoApi + 'api/backoffice/categorie';
    return this.http.get<any>(apiCategorie, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getAllType() {
    const apiType = environment.pngoApi + 'api/backoffice/type';
    return this.http.get<any>(apiType, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
