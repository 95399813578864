import { Component, OnInit } from '@angular/core';
import {DataFaqService} from '../dataFaq.service';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder} from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-faq-themes',
  templateUrl: './gestion-faq-themes.component.html',
  styleUrls: ['./gestion-faq-themes.component.scss']
})
export class GestionFaqThemesComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'name',
    'edit',
    'delete'
  ];
  dataSource = new MatTableDataSource<any>();
  isDataSourceExists = false;
  form: any;
  isEdit = false;

  constructor(
    private dataFaqService: DataFaqService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      id: null,
      name: '',
    });
  }

  ngOnInit() {
    this.getAllThemes();
  }
  getAllThemes(): any {
    this.dataFaqService.getAllGrandThemes().subscribe(themes => {
      this.dataSource.data = themes.records;
      if (themes.records.length > 0) {
        this.isDataSourceExists = true;
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
  onSubmit(post, methode): any {
    if (methode === 'create') {
      this.dataFaqService.postGrandTheme(post).subscribe(() => {
        this.getAllThemes();
        this.form.controls.name.setValue('');
      });
    } else {
      this.dataFaqService.updateGrandTheme(post.id, post).subscribe(() => {
        this.getAllThemes();
        this.onCancelEdit();
      });
    }
  }
  onEdit(elementId, elementName): any {
    this.isEdit = true;
    this.form.controls.id.setValue(elementId);
    this.form.controls.name.setValue(elementName);
  }
  onCancelEdit(): any {
    this.isEdit = false;
    this.form.controls.id.setValue(null);
    this.form.controls.name.setValue('');
  }
  onDelete(elementId): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataFaqService.deleteGrandTheme(elementId).subscribe(() => {
          this.getAllThemes();
        });
      }
    });
  }
}
