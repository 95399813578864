import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataSmsSubscriptionService} from "../../utils/data-services/data-sms-subscription.service";
import moment from "moment";

@Component({
  selector: 'app-update-sms-subscription',
  templateUrl: './update-sms-subscription.component.html',
  styleUrls: ['./update-sms-subscription.component.scss']
})
export class UpdateSmsSubscriptionComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private dataService: DataSmsSubscriptionService, private route: Router) { }
  subscriptions: any;
  id: any;
  endDate = true;
  dateNow = new Date();
  subscription = {
    id: null,
    idAboSMS: null,
    startDate: null,
    endDate: null,
    dealershipName: null
  };
  currentMonth = this.dateNow.getMonth() + 1;
  minimumDate = this.dateNow.getFullYear() + '-' + this.currentMonth + '-' + this.dateNow.getDate();
  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getSubscriptions();
    this.getSubscription();
  }
  getSubscription() {
    this.dataService.getSubscriptionsSmsById(this.id).subscribe(result => {
      this.subscription = result;
      this.subscription.startDate = moment(result.startDate.date).format('YYYY-MM-DD');
      this.subscription.endDate = moment(result.endDate.date).format('YYYY-MM-DD');
    });
  }
  getSubscriptions() {
    this.dataService.getSubscriptionsSms().subscribe(result => {
      this.subscriptions = result;
    });
  }
  onAssign() {
    if (!this.endDate) {
      this.subscription.endDate = null;
    }
    this.dataService.changeDateSubscriptionsSms(this.subscription).subscribe(result => {
      this.route.navigate(['../gestion-sms-subscription']);
    });
  }
  onCancel() {
    this.route.navigate(['../gestion-sms-subscription']);
  }
  isAfterToday(date: any): boolean {
    return moment(date).format('yyyy-MM-DD') > moment(new Date()).format('yyyy-MM-DD');
  }
}
