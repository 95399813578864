<h1 mat-dialog-title>Attention ! Cette fonction semble déjà exister.</h1>
<div mat-dialog-content>
  <div class="example-container">
    <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 50px"> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id_conf}} </td>
      </ng-container>

      <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef style="width: 50px"> Nom </th>
        <td mat-cell *matCellDef="let element"> {{element.valeur}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center" mat-dialog-actions>
  <button mat-raised-button (click)="onYesClick()" class="btn btn-dark m-4">Ajouter la fonction</button>
  <button mat-raised-button (click)="onNoClick()" class="btn btn-dark m-4">Annuler</button>
</div>

