import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataReceptionOptionsMappingService} from "../utils/data-services/data-reception-options-mapping.service";
import {Router} from "@angular/router";
import {DataRentDealershipsAgenciesService} from "../utils/data-services/data-rent-dealerships-agencies.service";

@Component({
  selector: 'app-gestion-rent-dealerships-agencies',
  templateUrl: './gestion-rent-dealerships-agencies.component.html',
  styleUrls: ['./gestion-rent-dealerships-agencies.component.scss']
})
export class GestionRentDealershipsAgenciesComponent implements OnInit {

  dataSource = new MatTableDataSource();

  constructor( private dataService: DataRentDealershipsAgenciesService, private route: Router ) {}
  displayedColumns: string[] = ['id', 'concession', 'marque', 'ucar_agency_id', 'typologie_client', 'modifier'];


  ngOnInit() {
    this.dataService.getRentDealershipAgency().subscribe(rentDealershipAgencies => {
      this.dataSource.data = rentDealershipAgencies;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-rent']);
  }
  SelectChangeHandlerModifier(rentId) {
    this.route.navigate(['../update-rent/' + rentId]);
  }
}
