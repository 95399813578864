import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import { ErrorHandler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import swal from 'sweetalert2';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataModificationProduitService extends HandleErrorService{

  getParametre() {
    const apiParametre = environment.globalUrlDomaine + '/records/parametre';
    return this.http.get<any>(apiParametre, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getConcession() {
    const apiConcession = environment.globalUrlDomaine + '/records/concession';
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getConcessionPourProduit(arg: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/concession_produit?filter=produit_id,eq,' + arg + '&include=concession_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getParametrePourProduit(arg: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/produit_parametre?filter=produit_id,eq,' + arg + '&include=parametre_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getParametreProduit(arg: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/parametre_produit?filter=produit_id,eq,' + arg;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getConcessionProduit(arg: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit_concession?filter=produit_id,eq,' + arg;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getIdProduitParametre(arg1: any, arg2: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/produit_parametre?filter=produit_id,eq,' + arg1 + '&filter=parametre_id,eq,' + arg2 + '&include=produit_parametre_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getIdconcessionProduit(arg1: any, arg2: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + arg1 + '&filter=produit_id,eq,' + arg2 + '&include=concession_produit_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  deleteProduitParametre(arg: any) {
    const api = environment.globalUrlDomaine + '/records/produit_parametre/' + arg;
    return this.http.delete(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  deleteConcessionProduit(arg: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit/' + arg;
    return this.http.delete(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  addProduitParametre(arg1: any, arg2: any) {
    const api = environment.globalUrlDomaine + '/records/produit_parametre';
    const body = { produit_id: arg1,
      parametre_id: arg2
    };
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addProduitConcession(arg1: any, arg2: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit';
    const body = { concession_id: arg1,
      produit_id: arg2
    };
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateProduit(arg1: any, arg2: any, arg3: any, arg4: any, arg5: any, arg6: any, arg7: any, id: any): Observable<HttpErrorResponse> {
    const api = environment.globalUrlDomaine + '/records/produit/' + id;
    const body = {nom_produit: arg1, description: arg2, lien: arg3, actif: arg4, image: arg5, lien_commerce: arg6 , version: arg7};
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
}
