<h1 class="text-center mt-2">Gestion des operations et mobilités</h1>
<div class="user-table m-3">
  <div class="container-row d-flex justify-content-end top-field ml-4">
    <mat-form-field>
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8" >

    <ng-container matColumnDef="concession_id">
      <th mat-header-cell *matHeaderCellDef style="width: 50px" class="pointer" (click)="sortId()"> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.concession_id}} </td>
    </ng-container>

    <ng-container matColumnDef="nom_concession">
      <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortName()"> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.nom_concession}} </td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Marques </th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" (click)="onClickBrand(element.concession_id)">settings</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="operation">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Opérations </th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" (click)="onClickOperation(element.concession_id)">build</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="mobilite">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Mobilités </th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" (click)="onClickMobilite(element.concession_id)">directions_car</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="synchro">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Synchronisation </th>
      <td mat-cell *matCellDef="let element"><div class="pointer" style="width: fit-content" (click)="onClickSynchro(element.concession_id)" ><i class="fa-solid fa-arrows-rotate"></i></div></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" class="user-table mb-3"></mat-paginator>
</div>
