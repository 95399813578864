<h2><span>{{method}}</span> d'un commentaire KIDI</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px;margin: auto;">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form">
      <div class="form-row w-75">
        <div class="form-group">
          <label for="title">Titre:</label>
          <input type="text" class="form-control" name="title" id="title" formControlName="title">
          <small *ngIf="error.title" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="subject">Sujet:</label>
          <select class="form-control" name="subject" id="subject" formControlName="subject">
            <option *ngFor="let subject of subjects" value="{{subject}}">{{subject}}</option>
          </select>
          <small *ngIf="error.subject" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="text">Contenu:</label>
          <textarea class="form-control" name="text" id="text" formControlName="text"></textarea>
        </div>
      </div>
      <div class="form-row w-25">
        <div class="form-group checkbox-group">
          <label for="is_active">Active:</label>
          <input type="checkbox" class="form-control checkbox" name="is_active" id="is_active" formControlName="is_active">
        </div>
        <div>
          <button type="submit" class="btn btn-dark btn100px btn-create p-2 mt-3 mr-2" *ngIf="method == 'Création'">Créer</button>
          <button type="submit" class="btn btn-dark btn100px btn-create p-2 mt-3 mr-2" *ngIf="method != 'Création'">Modifier</button>
        </div>
        <div>
          <button class="btn btn-light btn100px border-dark btn-create p-2 mt-3" type="button" routerLink="/gestion-communication">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>

