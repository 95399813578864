import { Component, OnInit } from '@angular/core';
import {DataSmsSubscriptionService} from "../../utils/data-services/data-sms-subscription.service";
import {ActivatedRoute, Router} from "@angular/router";
import swal from "sweetalert2";

@Component({
  selector: 'app-update-subscription',
  templateUrl: './update-subscription.component.html',
  styleUrls: ['./update-subscription.component.scss']
})
export class UpdateSubscriptionComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private dataService: DataSmsSubscriptionService, private route: Router ) { }
  id: any;
  subscription = {
    id : null,
    monthlyCost : null,
    smsCost : null,
    color : null
  };
  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getSubscription();
  }
  getSubscription() {
    this.dataService.getSubscriptionsById(this.id).subscribe(result => {
      this.subscription = result;
    });
  }
  onUpdate() {
    this.dataService.updateSubscriptionsSms(this.subscription).subscribe(result => {
      this.route.navigate(['../gestion-subscription']);
    }, error => {
      swal.fire({
        title: 'Erreur!',
        text: 'Un problème est survenu lors de la création de l\'abonnement',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  onCancel() {
    this.route.navigate(['../gestion-subscription']);
  }
}
