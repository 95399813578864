<h2>Modifié une section</h2>
<div class="wrapper">
  <form>
    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="nom">Nom :</label>
        <input [(ngModel)]="configurationType.name" class="form-control" id="nom" name="nom" required>
      </div>
      <div class="form-group col-md-3 offset-md-1">
        <label for="nom">Ordre :</label>
        <input [(ngModel)]="configurationType.order" class="form-control" id="order" name="order" required>
      </div>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="onUpdate()" class="btn btn100px btn-dark p-2 mr-2" [disabled]="!configurationType.name|| !configurationType.order">Modifier</button>
      </div>
      <div>
        <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
