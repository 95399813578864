<h1 class="text-center mt-2">Gestion des configuration par défault</h1>
<div class="user-table mt-4">
  <div class="d-flex justify-content-between align-items-baseline">
    <mat-form-field class="view-filter mr-2" appearance="fill">
      <mat-label>Recherche</mat-label>
      <input matInput placeholder="Nom" (keyup)="applyFilter($event)"/>
    </mat-form-field>
    <button id="btn-add-concession" class="mr-3 btn btn-dark" (click)="onCreate()">
      <mat-icon>add_circle</mat-icon> Configuration par défault
    </button>
  </div>
</div>
<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8" >

    <ng-container matColumnDef="configuration">
      <th mat-header-cell *matHeaderCellDef> Configuration </th>
      <td mat-cell *matCellDef="let element"> {{element.configuration}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Valeur </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" (click)="onUpdate(element.id)">edit</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" (click)="onDelete(element.id)">delete</mat-icon> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50]" class="user-table mb-3"></mat-paginator>
</div>
