import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataFormationService} from '../../utils/data-services/dataFormation.service';
import {ActivatedRoute, Router} from '@angular/router';
import swal from "sweetalert2";

@Component({
  selector: 'app-modification-formation',
  templateUrl: './modification-formation.component.html',
  styleUrls: ['./modification-formation.component.scss']
})
export class ModificationFormationComponent implements OnInit {
  formationCreationForm: any;
  currentId: number;
  currentFormation: any;
  image: File = null;
  imgBase64 = null;
  pdf: File = null;
  pdfBase64 = null;
  products = [];
  error = {
    title: false,
    type: false,
    imageType: false,
    pdfFormat: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private formationService: DataFormationService,
    private route: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.formationCreationForm = this.formBuilder.group({
      id: null,
      title: null,
      image: null,
      description: null,
      notification: null,
      type: null,
      product: '9999',
      tuto: false,
      is_active: false,
      pdf: null
    });
    this.currentId = Number(this.activeRoute.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    this.formationService.getFormationById(this.currentId).subscribe(response => {
      this.currentFormation = response;
    });
    this.formationService.getProducts().subscribe(res => {
      this.products = res.records.filter(x => x.type == 'commercial');
    });
  }
  resetTuto(): any {
    this.currentFormation.tuto = false;
    this.formationCreationForm.value.tuto = false;
  }
  setImage(event) {
    this.error.imageType = false;
    const allowedImageType = ['image/png', 'image/jpg', 'image/jpeg'];
    this.image = event.target.files[0];
    if (!allowedImageType.includes(event.target.files[0].type)) {
      this.error.imageType = true;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.imgBase64 = reader.result;
        document.getElementById('image-preview').setAttribute('src', reader.result as string);
      };
      reader.readAsDataURL(this.image);
    }
  }
  setPdf(event) {
    this.error.pdfFormat = false;
    const allowedType = ['application/pdf'];
    this.pdf = event.target.files[0];
    if (!allowedType.includes(event.target.files[0].type)) {
      this.error.pdfFormat = true;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.pdfBase64 = reader.result;
      };
      reader.readAsDataURL(this.pdf);
    }
  }
  onSubmit(post): any {
    this.error = {
      title: false,
      type: false,
      imageType: false,
      pdfFormat: false
    };
    post.image = post.image ==  null ? this.currentFormation.image : this.imgBase64;
    post.pdf = post.pdf ==  null ? this.currentFormation.pdf : this.pdfBase64;
    post.title = post.title ==  null ? this.currentFormation.title : post.title;
    post.product = parseInt(post.product);
    post.type = post.type ==  null ? this.currentFormation.type : post.type;
    post.description = post.description ==  null ? this.currentFormation.description : post.description;
    post.notification = post.notification ==  null ? this.currentFormation.notification : post.notification;
    post.is_active = post.is_active ==  null ? this.currentFormation.notification : post.notification;
    post.is_active = post.is_active == false ? 0 : 1;
    if (post.title === '' || post.title === null) {
      this.error.title = true;
    }
    if (post.type === '' || post.type === null) {
      this.error.type = true;
    }
    if (!this.error.type && !this.error.title && !this.error.imageType) {
      this.formationService.updateFormation(post, this.currentId).subscribe(response => {
        swal.fire({
          title: 'Success!',
          text: 'La formation a bien été créée',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(() => {
          this.route.navigate(['/gestion-formation']);
        });
      }, error => {
        swal.fire({
          title: 'Oops...!',
          text: 'Une erreur est surnevue lors de la sauvegarde..',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
}
