import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {DataConfigurationConcessionService} from "../utils/data-services/data-configuration-concession.service";
import {Router} from "@angular/router";
import swal from "sweetalert2";

@Component({
  selector: 'app-gestion-default-configuration',
  templateUrl: './gestion-default-configuration.component.html',
  styleUrls: ['./gestion-default-configuration.component.scss']
})
export class GestionDefaultConfigurationComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  variable: any;
  /* Variable list for the Textarea components */
  sort = null;
  nom: any;
  dataSource = new MatTableDataSource();
  constructor(private dataConfiguration: DataConfigurationConcessionService, private route: Router) {  }
  displayedColumns: string[] = ['configuration', 'type', 'value', 'update', 'delete'];

  ngOnInit() {
    this.getDefaultConfiguration();
    this.dataSource.paginator = this.paginator;
  }
  getDefaultConfiguration() {
    this.dataConfiguration.getDefaultConfiguration().subscribe(result => {
      this.dataSource.data = result;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onUpdate(id) {
    this.route.navigate(['/update-default-configuration/' + id]);
  }
  onCreate() {
    this.route.navigate(['/create-default-configuration']);
  }
  onDelete(id: any) {
    swal.fire({
      title: 'Supprimer',
      text: 'Voulez-vous supprimer cette configuration par défault',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (!result.dismiss) {
        this.dataConfiguration.deleteDefaultConfiguration(id).subscribe(() => {
          this.getDefaultConfiguration();
        });
      }
    });
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
}
