<h2>Création Produit</h2>
<div class="checkbox-filter d-flex justify-content-around align-items-center">
  <form class="mr-5">
    <div class=" form-group  mt-4">
      <label for="name">Nom</label>
      <input [(ngModel)]="product.name" class="form-control" id="name" name="name" [formControl]="nameFormControl">
    </div>
<div class="form-group">
      <label for="description">Description</label>
      <textarea [(ngModel)]="product.description" class="form-control" id="description" name="description"></textarea>
    </div>
<div class="form-group mt-4">
      <label for="type">Type</label>
      <select [(ngModel)]="product.type" [formControl]="typeFormControl"  class="form-control" id="type" name="type">
        <option *ngFor="let type of typeArray" [value]="type">{{type}}</option>
      </select>
    </div>
    <div class="form-group mt-4">
      <label for="version">Version</label>
      <div class="d-flex">
        <button class="font-weight-bold version-button" [disabled]="product.version <= 0"
                (click)="incriseDecriseVersion('-')">-
        </button>
        <input [(ngModel)]="product.version" class="form-control ml-2 mr-2" id="version" name="version">
        <button class="font-weight-bold version-button" (click)="incriseDecriseVersion('+')">+</button>
      </div>
    </div>
<div class="form-group mt-4 text-align center">
  <mat-checkbox (change)="onChangeCheckbox('actif')" class="ml-2" [checked]="product.actif"></mat-checkbox>
  <label class="ml-2"> Actif </label>
</div>

<div class="form-group mt-4">
  <label for="role">Role</label>
  <mat-select [ngModel]="selectedRole" multiple [(value)]="selectedRole" class="form-control" id="role" name="role">
    <mat-option *ngFor="let role of roleArray" [value]="role.item_id">{{role.item_text}}</mat-option>
  </mat-select>
</div>

    <div class="btn-group mt-4 mb-2">
      <div>
        <button (click)="selectChangeHandlerCreer()" class="btn btn-dark btn100px p-2 mr-2"
                [disabled]="nameFormControl.invalid">Créer
        </button>
      </div>

      <div>
        <button (click)="selectChangeHandlerAnnuler()" class="btn btn-light border-dark btn100px p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
