import {Component, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {Router} from '@angular/router';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {DataContactService} from '../utils/data-services/dataContact.service';
import {MatTableDataSource} from '@angular/material/table';
import {DataFonctionService} from '../utils/data-services/data-fonction.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import writeXlsxFile from 'write-excel-file';
import {AppComponent} from '../app.component';
import swal from 'sweetalert2';
import {MatPaginator} from '@angular/material/paginator';

@Pipe({ name: 'tooltipList' })
export class TooltipListPipe implements PipeTransform {

  transform(lines: string[]): string {
    let list = '';

    lines.forEach(line => {
      list += '• ' + line + '\n';
    });

    return list;
  }
}
@Component({
  selector: 'app-gestion-contact',
  templateUrl: './gestion-contact.component.html',
  styleUrls: ['./gestion-contact.component.scss']
})
export class GestionContactComponent implements OnInit {

  variable: any;
  /* Variable list for the Textarea components */
  sort = null;
  nom: any;
  ville: any;
  rue: any;
  tel: any;
  email: any;
  pays: any;
  dataSource: any;
  originalDataSource: any;
  fonctionsList = [];
  groupsList = [];
  dealershipList = [];
  fonctionSelected = [];
  fonctionId: any;
  filterFormGroup;
  filter: any;
  nomFormControl = new FormControl('');
  fonctionFormControl = new FormControl('');
  groupeFormControl = new FormControl('');
  concessionFormControl = new FormControl('');
  formeFormControl = new FormControl('');
  public filteredFonction = [];
  public filteredGroup = [];
  public filteredDealership = [];
  public filterValues = {
    nom: '',
    fonction: [],
    groupe: [],
    concessions: []
  };
  // todo : variable à créer au début de la classe
  exportCollumnNameFr = [
    'Nom',
    'Prénom',
    'Fonction',
    'Téléphone fixe',
    'Téléphone portable',
    'E-mail',
    'Concessions'
  ];

  // tslint:disable-next-line:max-line-length
  constructor(
    public appComponent: AppComponent,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dataConcessionService: DataConcessionService,
    private dataFonctionService: DataFonctionService,
    private route: Router,
    private dataService: DataCreationConcessionService,
    private  dataContactService: DataContactService
  ) {
    this.filterFormGroup = this.fb.group({});
  }
  displayedColumns: string[] = ['nom', 'prenom', 'fonction', 'telephone_fixe', 'telephone_portable', 'mail', 'commentaire', 'concession', 'modifier', 'supprimer'];

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.appComponent.isLoading = true;
    this.dataContactService.getContact().subscribe(data => {
      for (const contact of data) {
        if (contact.concessions) {
          contact.concessions = contact.concessions.split(';');
        }
        if (contact.groupe) {
          contact.groupe = contact.groupe.split(';');
        }
      }
      this.dataSource = new MatTableDataSource(data);
      this.originalDataSource = data;
      this.nomFormControl.valueChanges.subscribe((nom) => {
        this.filterValues.nom = nom;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
      this.fonctionFormControl.valueChanges.subscribe((fonction) => {
        this.filterValues.fonction = fonction;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
      this.groupeFormControl.valueChanges.subscribe((groupe) => {
        this.filterValues.groupe = groupe;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
      this.concessionFormControl.valueChanges.subscribe((concessions) => {
        this.filterValues.concessions = concessions;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
      this.dataSource.filterPredicate = this.tableFilter();
      this.dataSource.paginator = this.paginator;
      this.appComponent.isLoading = false;
    });
    this.dataFonctionService.getFonction().subscribe(data => {
      for (const fonction of data) {
        this.fonctionsList.push(fonction.valeur);
      }
      this.filteredFonction = this.fonctionsList.slice();
    });
    this.dataContactService.getGroup().subscribe(data => {
      for (const group of data) {
        this.groupsList.push(group.valeur);
      }
      this.filteredGroup = this.groupsList.slice();
    });
    this.dataContactService.getDealership().subscribe(data => {
      for (const dealership of data) {
        this.dealershipList.push(dealership.nom_concession);
      }
      this.filteredDealership = this.dealershipList.slice();
    });
    //this.getBrand();
  }
  applyFilter() {
    if (this.filterValues.nom === '' && this.filterValues.fonction.length === 0 && this.filterValues.groupe.length === 0 && this.filterValues.concessions.length === 0) {
      this.dataSource.data = this.originalDataSource;
    }
  }
  // getBrand() {
  //   this.dataContactService.getBrand().subscribe(result => {
  //   });
  // }
  private tableFilter<T>(): (data: T, filter: string) => boolean {
    const filterFunction = function(data: T, filter: string,): boolean {
      const searchTerms: Partial<Record<keyof T, string>> = JSON.parse(filter);

      if (searchTerms === null || searchTerms === undefined) {
        return true;
      }

      return Object.keys(searchTerms).every((key) => {
        if (Array.isArray(searchTerms[key])) {
          return searchTerms[key].length > 0 ? searchTerms[key].some((term: string) => Array.isArray(data[key]) ? data[key].includes(term) : data[key] === term) : true;
        }
        if (data[key]) {
          if (Array.isArray(data[key])) {
            for (const value of data[key]) {
              return (value.toLowerCase().indexOf(searchTerms[key].toLowerCase()) !== -1);
            }
          } else {
            return (data[key].toLowerCase().indexOf(searchTerms[key].toLowerCase()) !== -1);
          }
        }
      });
    };
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    return filterFunction;
  }
  onClickComment(comment) {
    swal.fire({
      title: 'Commentaire',
      html: comment,
      confirmButtonText: 'Ok'
    });
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom < b.nom ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom > b.nom ? 1 : -1;
      });
    }
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-contact']);
  }
  SelectChangeHandlerModifier(contactId) {
    this.route.navigate(['../modification-contact/' + contactId]);
  }
  async exportExcel(): Promise<any> {
    const fileName = 'Export_Contacts_' + this.datePipe.transform(new Date(), 'yyyyMMdd') + '.xlsx';

    const HEADER_ROW = [];
    const ARRAY_ROW = [];
    const dataFinal = [];

    // création de la ligne de header
    for (const headerName of this.exportCollumnNameFr) {
      HEADER_ROW.push({
        value: headerName,
        fontWeight: 'bold'
      });
    }
    // création des lignes de données
    for (const dataContact of this.dataSource.filteredData) {
      ARRAY_ROW.push([
        // Name
        {
          type: String,
          value: dataContact.nom
        },
        // Firstname
        {
          type: String,
          value: dataContact.prenom
        },
        // Function
        {
          type: String,
          value: dataContact.fonction
        },
        // Phone fixe
        {
          type: String,
          value: dataContact.telephone_fixe
        },
        // Phone mobile
        {
          type: String,
          value: dataContact.telephone_portable
        },
        // Mail
        {
          type: String,
          value: dataContact.mail
        },
        // Concessions
        {
          type: String,
          value: dataContact.concessions.join()
        }
      ]);
    }

    // création de l'object final (header + lignes de données)
    dataFinal.push(HEADER_ROW);
    for (const dataRow of ARRAY_ROW) {
      dataFinal.push(dataRow);
    }

    // téléchargement du fichier
    await writeXlsxFile(dataFinal, {
      fileName
    });
  }
}
