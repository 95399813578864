<h1 class="text-center mt-2">Gestion des paramètres Talend</h1>


<div class="row pannel-top wrapper" style="text-align: -webkit-match-parent; margin: 0px;">
  <div class="col-10">
    <div class="row">
      <!-- Concessions -->
      <div class="box2 col" style="width: 100px;">
        <label>Concessions</label>
        <mat-select class="form-control" [(ngModel)]="defautSelectedConcession" (click)="focusSearch('dealership')" (selectionChange)="SelectChangeHandlerConcession()">
          <mat-option value="all">Tous</mat-option>
          <input id="select-search-dealership" class="form-control select-input custom-search-input" (keyup)="filterDealership($event)" type="text" placeholder="Recherche">
          <mat-option *ngFor="let concession of variableConcession" [hidden]="!filteredDealership.includes(concession)" [value]="concession">
            {{concession.nom_concession}}
          </mat-option>
        </mat-select>
      </div>
      <!-- Products -->
      <div class="box2 col" style="width: 100px;">
        <label>Produits</label>
        <mat-select class="form-control" [(ngModel)]="defautSelectedProduct" (click)="focusSearch('product')" (selectionChange)="SelectChangeHandlerProduit()">
          <mat-option value="all">Tous</mat-option>
          <input id="select-search-product" class="form-control select-input  custom-search-input" (keyup)="filterProduct($event)" type="text" placeholder="Recherche">
          <mat-option *ngFor = "let produit of variableProduit" [hidden]="!filteredProduct.includes(produit)" [value]="produit">
            {{produit.nom_produit}}
          </mat-option>
        </mat-select>
      </div>
      <!-- Parameter -->
      <div class="box2 col" style="width: 100px;">
        <label>Paramètres</label>
        <mat-select class="form-control" multiple [(value)]="defautSelectedParametre" (click)="focusSearch('params')" (selectionChange)="SelectChangeHandlerParametre()">
          <mat-option value="all">Tous</mat-option>
          <input id="select-search-params" class="form-control select-input custom-search-input" (keyup)="filterParam($event)" type="text" placeholder="Recherche">
          <mat-option *ngFor = "let parametre of variableParametre" [hidden]="!filteredParam.includes(parametre)" [value]="parametre">
            {{parametre.nom_parametre}}
          </mat-option>
        </mat-select>
      </div>
      <!-- Tag -->
      <div class="box2 col" style="width: 100px;">
        <label>Tag</label>
        <mat-select class="form-control" [(ngModel)]="defautSelectedTag" (click)="focusSearch('tag')" (selectionChange)="SelectChangeHandlerTag()">
          <mat-option value="all">Tous</mat-option>
          <input id="select-search-tag" class="form-control select-input custom-search-input" (keyup)="filterTag($event)" type="text" placeholder="Recherche">
          <mat-option *ngFor = "let tag of tagArray" [hidden]="!filteredTag.includes(tag)" [value]="tag">
            {{tag.nom}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>

  <div class="box2 col" style="width: 100px; text-align-last: center;">
    <div class="btn1 ml-0">
      <button (click)="SelectChangeHandlerValeur()" class="btn btn100px btn-dark">Valider</button>
    </div>
    <div>
      <p>Parametres Actifs</p>
      <label class="switch">
        <input (change)="filterToggle($event)" type="checkbox" [checked]="filter" id="togBtn">
        <div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div>
      </label>
    </div>
    <div class="btn1 ml-0">
      <button (click)="SelectChangeHandlerId3()" class="btn btn-dark">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<!-- Table -->
<!-- <div class="pannel-down  "> -->
<div class="pannel-down mt-5">
  <table class="table table-striped table-bordered" matSort (matSortChange)="sortData($event)">
    <thead>
    <tr>
      <th mat-sort-header="valeur_id">Id</th>
      <th mat-sort-header="concession_id">Concession_ID</th>
      <th mat-sort-header="nom_concession">Concession_Nom</th>
      <th mat-sort-header="nom_produit">Produit_Nom</th>
      <th mat-sort-header="nom_parametre">Param_Nom</th>
      <th mat-sort-header="valeur">Valeur</th>
      <th mat-sort-header="date_debut_param">Date_Début_Param</th>
      <th mat-sort-header="date_fin_param">Date_Fin_Param</th>
      <th mat-sort-header="marque">Marque</th>
      <th>Pause</th>
      <th>Modifier</th>
      <th>Dupliquer</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf = "variableValeurFiltered?.length === 0; let i = index">
      <div class="con2 flex-online-centered">
        <h4>Aucune valeur trouvée, veuillez en rajouter</h4>
        <button (click)="SelectChangeHandlerId3()" class="btn btn-round-40 btn-dark ml-3">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf = "filteredTableData?.length !== 0">
      <tr *ngFor = "let valeur of filteredTableData; let i = index" style="text-align: center" [id]="i">
        <td>{{valeur.valeur_id}}</td>
        <td>{{valeur.concession_id}}</td>
        <td>{{valeur.nom_concession}}</td>
        <td>{{valeur.nom_produit}}</td>
        <td>{{valeur.nom_parametre}}</td>
        <td>{{valeur.valeur}}</td>
        <td>{{valeur.date_debut_param | date :  "dd/MM/y" }}</td>
        <td>{{valeur.date_fin_param | date :  "dd/MM/y" }}</td>
        <td>{{valeur.nom_marque}}</td>
        <td>
          <button (click)="pauseConsultation(valeur.valeur_id, !(valeur.date_debut_param > todayDate || valeur.date_fin_param <= todayDateFormated))" class="btn btn-round-40 btn-dark m-auto">
            <mat-icon matTooltip="Changer la date de fin pour la date du jour." *ngIf="!(valeur.date_debut_param > todayDate || valeur.date_fin_param <= todayDateFormated)">pause</mat-icon>
            <mat-icon matTooltip="Changer la date de fin pour une date dans le futur." *ngIf="valeur.date_debut_param > todayDate || valeur.date_fin_param <= todayDateFormated">play_arrow</mat-icon>
          </button>
        </td>
        <td>
          <button (click)="SelectChangeHandlerId(valeur.concession_id, valeur.produit_id, valeur.parametre_id, valeur.valeur, valeur.date_debut_param, valeur.date_fin_param, valeur.valeur_id, valeur.marque_id)" class="btn btn-round-40 btn-dark m-auto">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
        <td>
          <button (click)="SelectChangeHandlerId2(valeur.concession_id, valeur.produit_id, valeur.parametre_id, valeur.valeur, valeur.date_debut_param, valeur.date_fin_param, valeur.valeur_id, valeur.marque_id)" class="btn btn-round-40 btn-dark m-auto">
            <mat-icon>add</mat-icon>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

