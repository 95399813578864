import {Component, HostListener, OnInit} from '@angular/core';
import {DataCreationService} from '../../utils/data-services/dataCreation.service';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DataConsultationService} from '../../utils/data-services/dataConsultation.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['./creation.component.css'],
  providers: [
    // Add Local time provider to change datePicker date format
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class CreationComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private dataCreationService: DataCreationService,
              private dataConsultationService: DataConsultationService,
              private dataInteractionService: DataInteractionService,
              private datePipe: DatePipe,
              private route: Router,
              private location: Location,
              private _adapter: DateAdapter<any>,
              private fb: FormBuilder) {
    this.inputSectionForm = this.fb.group({
      concession: '',
      produit: '',
      parametre: '',
      marque: '',
    });
  }
  /* Creation page display variables */
  numberIdConcession: any;
  numberIdProduit: any;
  numberIdParametre: any;
  trValeur: any;
  textValeur: any;
  currentConcession = [];
  currentProduct = [];
  currentParameter = [];
  concessions: any;
  products: any;
  parameters: any;
  /* End */
  datepickerData2: any;
  datepickerData1: any;
  textId: any;
  arrayMarque: any;
  selectedMarqueId: number = null;
  inputSectionForm;

  ngOnInit() {
    window.scroll(0, 0);
    /* Retrieve transfered value variables */
    this.numberIdConcession = localStorage.getItem('softgateConcessionIdVariable');
    this.numberIdProduit = localStorage.getItem('softgateProduitIdVariable');
    this.numberIdParametre = localStorage.getItem('softgateParametreIdCreation');

    // this.numberIdParametre = localStorage.getItem('softgateParametreList');
    this.dataInteractionService.valeur1.subscribe(num4 => this.trValeur = num4);

    this.dataCreationService.getNomConcessionSelected(this.numberIdConcession).subscribe(data1 => { this.currentConcession = data1.records; });
    this.dataCreationService.getNomProduitSelected(this.numberIdProduit).subscribe(data2 => { this.currentProduct = data2.records; });
    this.dataCreationService.getNomParametreSelected(this.numberIdParametre).subscribe(data3 => { this.currentParameter = data3.records; });


    /* End */
    /* Fill DropDownLists */
    this.dataConsultationService.getProduit().subscribe(dataProduit => {
      this.products = dataProduit.records;
    });
    this.dataConsultationService.getParametre().subscribe(dataParametre => {
      this.parameters = dataParametre.records;


    });
    this.dataConsultationService.getConcession().subscribe(dataConcession => {
      this.concessions = dataConcession.records;
    });
    /* End */
    this.datepickerData1 = new Date();
    this.textId = this.trValeur;
    this.textValeur = localStorage.getItem('valeurCreation');
    // Retrieve brands
    this.dataCreationService.getMarque().subscribe(dataMarque => { this.arrayMarque = dataMarque.records; });
  }

  // Manage brand selection from DropDownList
  SelectChangeHandlerMarque($event: any) {
    this.selectedMarqueId = $event.target.value;
  }

  /***
   * Create a new value
   * Create a new releation between value and brand
   ***/
  SelectChangeHandlerCreer() {
    if (this.datepickerData1 > this.datepickerData2) {
      swal.fire({
        title: 'Attention!',
        text: 'La date de fin doit être supérieure à la date de début.',
        type: 'warning',
        confirmButtonText: 'Ok'
      });
    } else {
      // tslint:disable-next-line:max-line-length
      this.dataCreationService.createValeur(
        this.textValeur,
        this.datepickerData1,
        this.datepickerData2,
        localStorage.getItem('softgateParametreIdCreation'),
        localStorage.getItem('softgateConcessionIdVariable'),
        this.selectedMarqueId,
      ).then(
        response => {
          if (response) {
            swal.fire({
              title: 'Success!',
              text: 'La valeur a bien été Créée',
              type: 'success',
              confirmButtonText: 'Ok'
            });
            this.route.navigate(['../consultation']);
          }
      });
    }
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../consultation']);
  }

  setInStorage(id: any, item: string): void {
    switch (item) {
      case 'concession':
        localStorage.setItem('softgateConcessionIdVariable', id);
        break;
      case 'product':
        localStorage.setItem('softgateProduitIdVariable', id);
        break;
      case 'parameter':
        localStorage.setItem('softgateParametreList', id);
        break;
    }
  }
}
