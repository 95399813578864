<h2>Création d'une reception autonome</h2>
<div class="wrapper">
  <form>
    <div class="form-row mt-3 ml-1" style="width: 445px">
      <label for="brand">Concession</label>
      <select [(ngModel)]="receptionOptionsMapping.dealership_id" class="form-control" name="brand" id="brand">
        <option *ngFor="let concession of concessionArray" [value]="concession.concession_id">
          {{concession.nom_concession}}
        </option>
      </select>
    </div>

    <div class="form-row mt-3 ml-1" style="width: 445px">
      <label for="reception_expires_at_delay">Reception expires :</label>
      <input [(ngModel)]="receptionOptionsMapping.reception_expires_at_delay" class="form-control" id="reception_expires_at_delay" name="reception_expires_at_delay" >
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="reception_not_before_delay">Reception pas avant :</label>
        <input [(ngModel)]="receptionOptionsMapping.reception_not_before_delay" class="form-control" id="reception_not_before_delay" name="reception_not_before_delay" >
      </div>
      <div class="form-group col-md-3">
        <label for="restitution_expires_at_delay">Restitution expires :</label>
        <input [(ngModel)]="receptionOptionsMapping.restitution_expires_at_delay" class="form-control" id="restitution_expires_at_delay" name="restitution_expires_at_delay" >
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="uniq_concession_id">Identifiant unique :</label>
        <input [(ngModel)]="receptionOptionsMapping.uniq_concession_id" class="form-control" id="uniq_concession_id" name="uniq_concession_id" >
      </div>
      <div class="form-group col-md-3">
        <label for="service">Service :</label>
        <input [(ngModel)]="receptionOptionsMapping.service" class="form-control" id="service" name="service" >
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createReceptionOptionsMapping()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
