import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataNotificationService} from '../../utils/data-services/dataNotification.service';
import {AppComponent} from '../../app.component';
import swal from 'sweetalert2';
import {now} from 'moment';
import {DatePipe} from '@angular/common';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';

@Component({
  selector: 'app-form-communications-kidi',
  templateUrl: './form-communications-kidi.component.html',
  styleUrls: ['./form-communications-kidi.component.scss']
})
export class FormCommunicationsKidiComponent implements OnInit {
  form: any;
  method = 'Création';
  idComment = null;
  concessionId = null;
  subjects = [
    'TRAVAUX EN COURS',
    'TRAVAUX SUSPENDUS',
    'AUTRES COMMUNICATIONS'
  ];
  error = {
    title: false,
    subject: false,
    text: false,
  };
  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private dataConcessionService: DataConcessionService,
    private appComponent: AppComponent,
    private datePipe: DatePipe,
  ) {
    this.form = this.formBuilder.group({
      title: '',
      subject: 'AUTRES COMMUNICATIONS',
      text: 'Bonjour, [...] . Cordialement.',
      created_at: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      updated_at: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      is_active: true,
      concession_id: null
    });
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(res => {
      if (res.concessionId) {
        this.concessionId = res.concessionId;
      }
      if (res.id) {
        this.idComment = res.id;
        this.method = 'Modification';
        this.dataConcessionService.getOneCommentsKidiGeneral(res.id).subscribe(result => {
          this.concessionId = result.concession_id;
          this.form = this.formBuilder.group({
            id: result.id,
            title: result.title,
            subject: result.subject,
            text: result.text,
            created_at: result.created_at,
            updated_at: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            is_active: result.is_active,
            concession_id: result.concession_id
          });
        });
      }
    });
  }

  onSubmit(post): any {
    this.error = {
      title: false,
      subject: false,
      text: false,
    };
    post.is_active = post.is_active === false ? 0 : 1;
    post.updated_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    post.concession_id = this.concessionId;
    if (post.title == '') {
      this.error.title = true;
    }
    if (post.text == '') {
      this.error.text = true;
    }
    if (!this.error.text && !this.error.title) {
      const body = JSON.stringify(post);
      if (this.method != 'Modification') {
        post.created_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.dataConcessionService.postCommentsKidi(body).subscribe(res => {
          swal.fire({
            title: 'Communication enregistrée!',
            text: 'La communication a bien été enregistrée.',
            type: 'success',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.route.navigate(['/gestion-communication']);
          });
        }, error1 => {
          swal.fire({
            title: 'Oops...!',
            text: 'Une erreur est surnevue lors de la sauvegarde..',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      } else {
        this.dataConcessionService.putCommentsKidi(body, this.idComment).subscribe(res => {
          swal.fire({
            title: 'Communication enregistrée!',
            text: 'La communication a bien été enregistrée.',
            type: 'success',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.route.navigate(['/gestion-communication']);
          });
        }, error1 => {
          swal.fire({
            title: 'Oops...!',
            text: 'Une erreur est surnevue lors de la sauvegarde..',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    }
  }
}
