import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {DataReceptionOptionsMappingService} from '../utils/data-services/data-reception-options-mapping.service';

@Component({
  selector: 'app-gestion-reception-options-mapping',
  templateUrl: './gestion-reception-options-mapping.component.html',
  styleUrls: ['./gestion-reception-options-mapping.component.scss']
})
export class GestionReceptionOptionsMappingComponent implements OnInit {

  dataSource = new MatTableDataSource();

  constructor( private dataService: DataReceptionOptionsMappingService, private route: Router ) {}
  displayedColumns: string[] = ['concession', 'uniq_concession_id', 'reception_expires_at_delay', 'reception_not_before_delay', 'restitution_expires_at_delay', 'service', 'modifier'];


  ngOnInit() {
    this.dataService.getReceptionOptionsMapping().subscribe(receptionOptionsMapping => {
      this.dataSource.data = receptionOptionsMapping;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-reception-options-mapping']);
  }
  SelectChangeHandlerModifier(receptionOptionsMappingId) {
    this.route.navigate(['../update-reception-options-mapping/' + receptionOptionsMappingId]);
  }
}
