import { NgModule } from '@angular/core';ConsultationComponent
import { Routes, RouterModule } from '@angular/router';

import { GestionInformationsProduitComponent } from './gestion-informations-produit/gestion-informations-produit.component';
import { InformationsProduitComponent } from './gestion-informations-produit/informations-produit/informations-produit.component';
import { ConsultationComponent } from './consultation';
import { ModificationComponent } from './consultation/modification';
import { CreationNouvelleComponent } from './consultation/creationNouvelle';
import { CreationComponent } from './consultation/creation';
import { HomeComponent } from './home';
import { GestionProduitComponent } from './gestion-produit';
import { GestionParametreComponent } from './gestion-parametre';
import { CreationProduitComponent } from './gestion-produit/creation-produit';
import { ModificationProduitComponent } from './gestion-produit/modification-produit';
import { ModificationParametreComponent} from './gestion-parametre/modification-parametre';
import { CreationParametreComponent} from './gestion-parametre/creation-parametre';
import { GestionConcessionComponent } from './gestion-concession';
import { ModificationConcessionComponent } from './gestion-concession/modification-concession';
import { CreationConcessionComponent } from './gestion-concession/creation-concession';
import { CreationSelectionComponent } from './consultation/creationSelection/creation-selection.component';
import { LoginComponent } from './authentification/login/login.component';
import { RegisterComponent } from './authentification/register/register.component';
import { ResetPasswordComponent } from './authentification/reset-password/reset-password.component';
import {AppComponent} from './app.component';
import {GestionUtilisateurComponent} from './gestion-utilisateur/gestion-utilisateur.component';
import {CreationUtilisateurComponent} from './gestion-utilisateur/creation-utilisateur/creation-utilisateur.component';
import {ModificationUtilisateurComponent} from './gestion-utilisateur/modification-utilisateur/modification-utilisateur.component';
import {AuthGuard} from './utils/guards/auth.guard';
import {ForgotPasswordComponent} from './authentification/forgot-password/forgot-password.component';
import {GestionRegleComponent} from './gestion-regle/gestion-regle.component';
import {CreationRegleComponent} from './gestion-regle/creation-regle/creation-regle.component';
import {ModificationRegleComponent} from './gestion-regle/modification-regle/modification-regle.component';
import {GestionFormationComponent} from './gestion-formation/gestion-formation.component';
import {CreationFormationComponent} from './gestion-formation/creation-formation/creation-formation.component';
import {ModificationFormationComponent} from './gestion-formation/modification-formation/modification-formation.component';
import {GestionNotificationComponent} from './gestion-notification/gestion-notification.component';
import {CreationNotificationComponent} from './gestion-notification/creation-notification/creation-notification.component';
import {ModificationNotificationComponent} from './gestion-notification/modification-notification/modification-notification.component';
import {GestionStatutComponent} from './gestion-statut/gestion-statut.component';
import {Error404Component} from './error404/error404.component';
import {GestionFaqQuestionsComponent} from './gestion-faq-questions/gestion-faq-questions.component';
import {GestionFaqThemesComponent} from './gestion-faq-themes/gestion-faq-themes.component';
import {CreationFaqQuestionsComponent} from './gestion-faq-questions/creation-faq-questions/creation-faq-questions.component';
import {ModificationFaqQuestionsComponent} from './gestion-faq-questions/modification-faq-questions/modification-faq-questions.component';
import {GestionGroupeComponent} from './gestion-groupe/gestion-groupe.component';
import {GestionMarquesComponent} from './gestion-marques/gestion-marques.component';
import {GestionCommunicationsKidiComponent} from './gestion-communications-kidi/gestion-communications-kidi.component';
import {FormCommunicationsKidiComponent} from './gestion-communications-kidi/form-communications-kidi/form-communications-kidi.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {GestionContactComponent} from './gestion-contact/gestion-contact.component';
import {GestionFonctionComponent} from './gestion-fonction/gestion-fonction.component';
import {CreationContactComponent} from './gestion-contact/creation-contact/creation-contact.component';
import {ModificationContactComponent} from './gestion-contact/modification-contact/modification-contact.component';
import {GestionMappingPlanngoComponent} from './gestion-mapping-planngo/gestion-mapping-planngo.component';
import {GestionCampaignDiscountsComponent} from './gestion-campaign-discounts/gestion-campaign-discounts.component';
import {GestionConfigurationConcessionComponent} from './gestion-configuration-concession/gestion-configuration-concession.component';
import {CreationCampaignDiscountsComponent} from './gestion-campaign-discounts/creation-campaign-discounts/creation-campaign-discounts.component';
import {ModificationCampaignDiscountsComponent} from './gestion-campaign-discounts/modification-campaign-discounts/modification-campaign-discounts.component';
import {GestionOperationComponent} from './gestion-operation/gestion-operation.component';
import {CreateOperationComponent} from './gestion-operation/create-operation/create-operation.component';
import {ModificationOperationComponent} from './gestion-operation/modification-operation/modification-operation.component';
import {ModificationConfigurationConcessionComponent} from "./gestion-configuration-concession/modification-configuration-concession/modification-configuration-concession.component";
import {GestionMobiliteComponent} from "./gestion-mobilite/gestion-mobilite.component";
import {CreateMobiliteComponent} from "./gestion-mobilite/create-mobilite/create-mobilite.component";
import {UpdateMobiliteComponent} from "./gestion-mobilite/update-mobilite/update-mobilite.component";
import {GestionSnapshotScenarioComponent} from "./gestion-snapshot-scenario/gestion-snapshot-scenario.component";
import {CreateSnapshotScenarioComponent} from "./gestion-snapshot-scenario/create-snapshot-scenario/create-snapshot-scenario.component";
import {UpdateSnapshotScenarioComponent} from "./gestion-snapshot-scenario/update-snapshot-scenario/update-snapshot-scenario.component";
import {GestionBackupConcessionComponent} from "./gestion-backup-concession/gestion-backup-concession.component";
import {CreateBackupConcessionComponent} from "./gestion-backup-concession/create-backup-concession/create-backup-concession.component";
import {UpdateBackupConcessionComponent} from "./gestion-backup-concession/update-backup-concession/update-backup-concession.component";
import {GestionReceptionOptionsMappingComponent} from "./gestion-reception-options-mapping/gestion-reception-options-mapping.component";
import {CreateReceptionOptionsMappingComponent} from "./gestion-reception-options-mapping/create-reception-options-mapping/create-reception-options-mapping.component";
import {UpdateReceptionOptionsMappingComponent} from "./gestion-reception-options-mapping/update-reception-options-mapping/update-reception-options-mapping.component";
import {GestionDemandesRgpdComponent} from './gestion-demandes-rgpd/gestion-demandes-rgpd.component';
import {GestionSmsComponent} from "./gestion-sms/gestion-sms.component";
import {GestionProduitConcessionComponent} from "./gestion-produit-concession/gestion-produit-concession.component";
import {GestionConcessionProduitComponent} from "./gestion-concession-produit/gestion-concession-produit.component";
import {GestionSmsSubscriptionComponent} from "./gestion-sms-subscription/gestion-sms-subscription.component";
import {GestionSubscriptionComponent} from "./gestion-subscription/gestion-subscription.component";
import {CreateSubscriptionComponent} from "./gestion-subscription/create-subscription/create-subscription.component";
import {UpdateSubscriptionComponent} from "./gestion-subscription/update-subscription/update-subscription.component";
import {AssignDealershipSubscriptionComponent} from "./gestion-sms-subscription/assign-dealership-subscription/assign-dealership-subscription.component";
import {GestionRentDealershipsAgenciesComponent} from "./gestion-rent-dealerships-agencies/gestion-rent-dealerships-agencies.component";
import {CreateRentDealershipsAgenciesComponent} from "./gestion-rent-dealerships-agencies/create-rent-dealerships-agencies/create-rent-dealerships-agencies.component";
import {UpdateRentDealershipsAgenciesComponent} from "./gestion-rent-dealerships-agencies/update-rent-dealerships-agencies/update-rent-dealerships-agencies.component";
import {UpdateSmsSubscriptionComponent} from "./gestion-sms-subscription/update-sms-subscription/update-sms-subscription.component";
import {GestionTagComponent} from "./gestion-tag/gestion-tag.component";
import {GestionMarquePlanngoComponent} from "./gestion-marque-planngo/gestion-marque-planngo.component";
import {GestionConfigurationComponent} from "./gestion-configuration/gestion-configuration.component";
import {
  CreateConfigurationComponent
} from "./gestion-configuration/create-configuration/create-configuration.component";
import {
  UpdateConfigurationComponent
} from "./gestion-configuration/update-configuration/update-configuration.component";
import {
  CreateDefaultConfigurationComponent
} from "./gestion-default-configuration/create-default-configuration/create-default-configuration.component";
import {
  UpdateDefaultConfigurationComponent
} from "./gestion-default-configuration/update-default-configuration/update-default-configuration.component";
import {
  GestionDefaultConfigurationComponent
} from "./gestion-default-configuration/gestion-default-configuration.component";
import {
  GestionConfigurationSectionComponent
} from "./gestion-configuration-section/gestion-configuration-section.component";
import {Auth2faComponent} from './authentification/auth2fa/auth2fa.component';
import {
  UpdateConfigurationSectionComponent
} from "./gestion-configuration-section/update-configuration-section/update-configuration-section.component";
import {
  CreateConfigurationSectionComponent
} from "./gestion-configuration-section/create-configuration-section/create-configuration-section.component";


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'informations-produit/:id', component: InformationsProduitComponent, canActivate: [AuthGuard]},
  {path: 'gestion-informations-produit', component: GestionInformationsProduitComponent, canActivate: [AuthGuard]},
  {path: 'consultation', component: ConsultationComponent, canActivate: [AuthGuard]},
  {path: 'modification', component: ModificationComponent, canActivate: [AuthGuard]},
  {path: 'creationNouvelle', component: CreationNouvelleComponent, canActivate: [AuthGuard]},
  {path: 'creation', component: CreationComponent, canActivate: [AuthGuard]},
  {path: 'gestion-produit', component: GestionProduitComponent, canActivate: [AuthGuard]},
  {path: 'gestion-produit-concession', component: GestionProduitConcessionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-concession-produit', component: GestionConcessionProduitComponent, canActivate: [AuthGuard]},
  {path: 'creation-produit', component: CreationProduitComponent, canActivate: [AuthGuard]},
  {path: 'modification-produit', component: ModificationProduitComponent, canActivate: [AuthGuard]},
  {path: 'gestion-parametre', component: GestionParametreComponent, canActivate: [AuthGuard]},
  {path: 'modification-parametre', component: ModificationParametreComponent, canActivate: [AuthGuard]},
  {path: 'creation-parametre', component: CreationParametreComponent, canActivate: [AuthGuard]},
  {path: 'gestion-groupe', component: GestionGroupeComponent, canActivate: [AuthGuard]},
  {path: 'gestion-marque', component: GestionMarquesComponent, canActivate: [AuthGuard]},
  {path: 'gestion-concession', component: GestionConcessionComponent, canActivate: [AuthGuard]},
  {path: 'creation-concession', component: CreationConcessionComponent, canActivate: [AuthGuard]},
  {path: 'modification-concession/:id', component: ModificationConcessionComponent, canActivate: [AuthGuard]},
  {path: 'creationSelection', component: CreationSelectionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-utilisateur', component: GestionUtilisateurComponent, canActivate: [AuthGuard]},
  {path: 'creation-utilisateur', component: CreationUtilisateurComponent, canActivate: [AuthGuard]},
  {path: 'modification-utilisateur/:id', component: ModificationUtilisateurComponent, canActivate: [AuthGuard]},
  {path: 'gestion-regle', component: GestionRegleComponent, canActivate: [AuthGuard]},
  {path: 'creation-regle', component: CreationRegleComponent, canActivate: [AuthGuard]},
  {path: 'modification-regle/:id', component: ModificationRegleComponent, canActivate: [AuthGuard]},
  {path: 'gestion-faq-question', component: GestionFaqQuestionsComponent, canActivate: [AuthGuard]},
  {path: 'creation-faq-question', component: CreationFaqQuestionsComponent, canActivate: [AuthGuard]},
  {path: 'modification-faq-question/:id', component: ModificationFaqQuestionsComponent, canActivate: [AuthGuard]},
  {path: 'gestion-faq-theme', component: GestionFaqThemesComponent, canActivate: [AuthGuard]},
  {path: 'gestion-formation', component: GestionFormationComponent, canActivate: [AuthGuard]},
  {path: 'creation-formation', component: CreationFormationComponent, canActivate: [AuthGuard]},
  {path: 'modification-formation/:id', component: ModificationFormationComponent, canActivate: [AuthGuard]},
  {path: 'gestion-notification', component: GestionNotificationComponent, canActivate: [AuthGuard]},
  {path: 'creation-notification', component: CreationNotificationComponent, canActivate: [AuthGuard]},
  {path: 'modification-notification/:id', component: ModificationNotificationComponent, canActivate: [AuthGuard]},
  {path: 'gestion-statut', component: GestionStatutComponent, canActivate: [AuthGuard]},
  {path: 'gestion-communication', component: GestionCommunicationsKidiComponent, canActivate: [AuthGuard]},
  {path: 'creation-communication', component: FormCommunicationsKidiComponent, canActivate: [AuthGuard]},
  {path: 'modification-communication/:id', component: FormCommunicationsKidiComponent, canActivate: [AuthGuard]},
  {path: 'creation-communication-concession/:concessionId', component: FormCommunicationsKidiComponent, canActivate: [AuthGuard]},
  {path: 'gestion-contact', component: GestionContactComponent, canActivate: [AuthGuard]},
  {path: 'creation-contact', component: CreationContactComponent, canActivate: [AuthGuard]},
  {path: 'modification-contact/:id', component: ModificationContactComponent, canActivate: [AuthGuard]},
  {path: 'gestion-fonction', component: GestionFonctionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-mapping-planngo', component: GestionMappingPlanngoComponent, canActivate: [AuthGuard]},
  {path: 'gestion-marque-planngo/:dealershipId', component: GestionMarquePlanngoComponent, canActivate: [AuthGuard]},
  {path: 'gestion-operation/:idConcession', component: GestionOperationComponent, canActivate: [AuthGuard]},
  {path: 'creation-operation/:idConcession', component: CreateOperationComponent, canActivate: [AuthGuard]},
  {path: 'modification-operation/:idOperation', component: ModificationOperationComponent, canActivate: [AuthGuard]},
  {path: 'gestion-mobilite/:idConcession', component: GestionMobiliteComponent, canActivate: [AuthGuard]},
  {path: 'create-mobilite/:idConcession', component: CreateMobiliteComponent, canActivate: [AuthGuard]},
  {path: 'update-mobilite/:idMobilite', component: UpdateMobiliteComponent, canActivate: [AuthGuard]},
  {path: 'gestion-rent', component: GestionRentDealershipsAgenciesComponent, canActivate: [AuthGuard]},
  {path: 'create-rent', component: CreateRentDealershipsAgenciesComponent, canActivate: [AuthGuard]},
  {path: 'update-rent/:idRent', component: UpdateRentDealershipsAgenciesComponent, canActivate: [AuthGuard]},
  {path: 'gestion-backup-concession', component: GestionBackupConcessionComponent, canActivate: [AuthGuard]},
  {path: 'create-backup-concession', component: CreateBackupConcessionComponent, canActivate: [AuthGuard]},
  {path: 'update-backup-concession/:idBackupConcession', component: UpdateBackupConcessionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-reception-options-mapping', component: GestionReceptionOptionsMappingComponent, canActivate: [AuthGuard]},
  {path: 'create-reception-options-mapping', component: CreateReceptionOptionsMappingComponent, canActivate: [AuthGuard]},
  {path: 'update-reception-options-mapping/:idReceptionOptionsMapping', component: UpdateReceptionOptionsMappingComponent, canActivate: [AuthGuard]},
  {path: 'gestion-snapshot-scenario', component: GestionSnapshotScenarioComponent, canActivate: [AuthGuard]},
  {path: 'create-snapshot-scenario', component: CreateSnapshotScenarioComponent, canActivate: [AuthGuard]},
  {path: 'update-snapshot-scenario/:idSnapshotScenario', component: UpdateSnapshotScenarioComponent, canActivate: [AuthGuard]},
  {path: 'gestion-campagne-sms', component: GestionCampaignDiscountsComponent, canActivate: [AuthGuard]},
  {path: 'creation-campagne-sms', component: CreationCampaignDiscountsComponent, canActivate: [AuthGuard]},
  {path: 'modification-campagne-sms/:id', component: ModificationCampaignDiscountsComponent, canActivate: [AuthGuard]},
  {path: 'gestion-configuration-concession', component: GestionConfigurationConcessionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-configuration-concession/:id', component: ModificationConfigurationConcessionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-configuration', component: GestionConfigurationComponent, canActivate: [AuthGuard]},
  {path: 'create-configuration', component: CreateConfigurationComponent, canActivate: [AuthGuard]},
  {path: 'update-configuration/:id', component: UpdateConfigurationComponent, canActivate: [AuthGuard]},
  {path: 'gestion-default-configuration', component: GestionDefaultConfigurationComponent, canActivate: [AuthGuard]},
  {path: 'create-default-configuration', component: CreateDefaultConfigurationComponent, canActivate: [AuthGuard]},
  {path: 'update-default-configuration/:id', component: UpdateDefaultConfigurationComponent, canActivate: [AuthGuard]},
  {path: 'gestion-section-configuration', component: GestionConfigurationSectionComponent, canActivate: [AuthGuard]},
  {path: 'create-section-configuration', component: CreateConfigurationSectionComponent, canActivate: [AuthGuard]},
  {path: 'update-section-configuration/:id', component: UpdateConfigurationSectionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-demandes-support', component: GestionDemandesRgpdComponent, canActivate: [AuthGuard]},
  {path: 'gestion-sms', component: GestionSmsComponent, canActivate: [AuthGuard]},
  {path: 'gestion-subscription', component: GestionSubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-tag', component: GestionTagComponent, canActivate: [AuthGuard]},
  {path: 'create-subscription', component: CreateSubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'update-subscription/:id', component: UpdateSubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'add-subscription/:id', component: AssignDealershipSubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'update-sms-subscription/:id', component: UpdateSmsSubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'gestion-sms-subscription', component: GestionSmsSubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'maintenances', component: MaintenanceComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  { path: '2fa', component: Auth2faComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: '404', component: Error404Component},
  {path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


/*  // Sinon rediriger vers AppComponent
  {path: '**', redirectTo: ''} */
