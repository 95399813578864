import { Component, OnInit } from '@angular/core';
import {DataCampaignDiscountsService} from "../../utils/data-services/data-campaign-discounts.service";
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";
import {DataRentDealershipsAgenciesService} from "../../utils/data-services/data-rent-dealerships-agencies.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-update-rent-dealerships-agencies',
  templateUrl: './update-rent-dealerships-agencies.component.html',
  styleUrls: ['./update-rent-dealerships-agencies.component.scss']
})
export class UpdateRentDealershipsAgenciesComponent implements OnInit {
  rentAgencyId: any;
  rentDealershipAgency = {
    id: null,
    concession: null,
    dealership_id: null,
    marque: null,
    brand_id: null,
    ucar_agency_id: null,
    typologie_client: null
  };
  concessionArray = [];
  brandArray = [];
  constructor(private dataServiceBrand: DataCampaignDiscountsService, private dataServiceConcession: DataConcessionService, private dataService: DataRentDealershipsAgenciesService, private route: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.rentAgencyId = Number(this.activeRoute.snapshot.paramMap.get('idRent'));
    this.getAllConcession();
    this.getAllBrand();
    this.getRent();
  }
  updateRent() {
    this.dataService.updateRentDealershipAgency(this.rentDealershipAgency).subscribe(result => {
      this.route.navigate(['../gestion-rent']);
    });
  }
  getAllConcession() {
    this.dataServiceConcession.getConcession().subscribe(result => {
      this.concessionArray = result['records'];
    });
  }
  getAllBrand() {
    this.dataServiceBrand.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  getRent() {
    this.dataService.getRentDealershipAgencyById(this.rentAgencyId).subscribe(result => {
      this.rentDealershipAgency = result;
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-rent']);
  }
}
