import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {HandleErrorService} from '../handleError.service';


@Injectable({
  providedIn: 'root'
})
export class DataCreationParametreService extends HandleErrorService {

  createParameter(body) {
    const api = environment.globalApiUrl + 'api/parameter/create';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateParameter(body) {
    const api = environment.globalApiUrl + 'api/parameter/update';
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
