import {Component, OnInit} from '@angular/core';
import {DataUtilisateurService} from '../../utils/data-services/dataUtilisateurService';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';
import swal from "sweetalert2";
import {AuthenticationService} from '../../utils/authentication.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-creation-utilisateur',
  templateUrl: './creation-utilisateur.component.html',
  styleUrls: ['./creation-utilisateur.component.scss']
})
export class CreationUtilisateurComponent implements OnInit {
  errors = {
    username: false,
    lastname: false,
    email: false,
    password: false,
    role: false,
    concessions: false,
  };

  userCreationForm: any;

  concessions = [];
  dropdownConcessionList = [];
  selectedConcession = [];
  dropdownRolesList = [];
  selectedRoles = [];
  dropdownSettings = {};
  roleOptionsString = '';
  generatedPwd = '';
  allRoles = [];
  concessionProductArray = [];
  grantedRoles = [];


  constructor(
    private dataUtilisateurService: DataUtilisateurService,
    private dataConcessionService: DataConcessionService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private route: Router
    ) {
    this.userCreationForm = this.formBuilder.group({
      id: null,
      username: '',
      lastname: '',
      email: '',
      roles: '',
      password: this.onPwdGenerate(),
      concessions: '',
      from: '',
      ispacc_right_level: 0
    });
  }



  ngOnInit() {
    this.getRoles();
    this.getDataConcession();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  getRoles(): any {
    this.dataUtilisateurService.getRoles().subscribe(res => {
      this.allRoles = res.records;
      this.manageRoleSelection();
    });
  }

  getDataConcession(): void {
    this.dataConcessionService.getConcession().subscribe(data => {
      const concessionList = [];
      for (const concession of data.records) {
        concessionList.push({ item_id: concession.concession_id, item_text: concession.nom_concession });
      }
      this.dropdownConcessionList = concessionList;
    });
  }

  onRoleSelect(item: any) {
    const role = this.allRoles.filter(x => x.id == item.item_id);
    this.roleOptionsString += (role[0].name + ',');
  }

  onRoleDeselect(item: any) {
    const role = this.allRoles.filter(x => x.id == item.item_id);
    this.roleOptionsString = this.roleOptionsString.replace(role[0].name + ',', '');
  }

  hasRoleIspacc(): boolean {
    const roleArray = this.roleOptionsString.split(',');
    return roleArray.includes('ISPACC');
  }

  onConcessionSelect(item: any) {
    this.concessions.push(item.item_id);
    this.manageRoleSelection();
  }

  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessions.length; i++) {
      if (this.concessions[i] === item.item_id) {
        this.concessions.splice(i, 1);
      }
    }
    this.manageRoleSelection(() => {

      const tempSelectedRole = [];

      for (const role of this.selectedRoles) {
        if (this.dropdownRolesList.some(x => x.item_id == role.item_id)) {
          tempSelectedRole.push(role)
        }
      }
      this.selectedRoles = tempSelectedRole;
    });
  }

  manageRoleSelection(callback?) {
    const grantedRoles = [];
    const body = {
      concessionArray: this.concessions
    };
    this.dataConcessionService.concessionProducts(body).subscribe(res => {
      this.concessionProductArray = res;
      const tempGrantedRoles = [];
      for (const concession of this.concessionProductArray) {
        for (const product of concession.productLinked) {
          for (const role of product.rolesLinked) {
            tempGrantedRoles.push(role);
          }
        }
      }
      for (const role of this.allRoles) {
        if (tempGrantedRoles.some(x => x.roleId == role.id) || role.id == 7 || role.id == 8 || role.id == 11) {
          grantedRoles.push({
            item_id: role.id,
            item_text: role.label,
          });
        }
      }
      this.dropdownRolesList = this.grantedRoles = grantedRoles;
      if (callback) {
        callback();
      }
    });
  }

  onPwdGenerate() {
    const length = 12;
    const  charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!:?()&!:?()&";
    let  retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  onSubmit(data) {
    const regexp = new RegExp(/ADMIN/g);
    this.errors = {
      username: false,
      lastname: false,
      email: false,
      password: false,
      role: false,
      concessions: false,
    };
    const email = data.email.trim();
    const username = data.username.trim();
    const lastname = data.lastname.trim();

    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (username === '') {
      this.errors.username = true;
    }
    if (lastname === '') {
      this.errors.lastname = true;
    }
    if (email === '') {
      this.errors.email = true;
    }
    if (data.password === '') {
      this.errors.password = true;
    }
    if (this.concessions.length === 0) {
      this.errors.concessions = true;
    }
    if (this.roleOptionsString === '') {
      this.errors.role = true;
    }
    this.roleOptionsString = '';
    for (const role of this.selectedRoles) {
      const currentRole = this.allRoles.find(x => x.id == role.item_id);
      this.roleOptionsString += (currentRole.name + ',');
    }
    data.username = capitalize(username);
    data.lastname = capitalize(lastname);
    data.roles = this.roleOptionsString.substring(0, (this.roleOptionsString.length - 1));
    data.concessions = this.concessions;
    if (regexp.test(data.roles)) {
      data.from = environment.website;
    } else {
      data.from = 'dashboard-cs';
    }
    if (
      this.errors.concessions || this.errors.role ||
      this.errors.username || this.errors.lastname ||
      this.errors.email || this.errors.password
    ) {
      swal.fire({
        title: 'Erreur!',
        text: 'Certains champs sont requis',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    } else {
      this.dataUtilisateurService.createUser(data).subscribe(res => {});
      setTimeout(() => {
        this.route.navigate(['../gestion-utilisateur']);
      }, 2000);
    }
  }
}
