import { Component, OnInit } from '@angular/core';
import {DataCampaignDiscountsService} from '../../utils/data-services/data-campaign-discounts.service';
import {ActivatedRoute, Router} from '@angular/router';
import moment from "moment";

@Component({
  selector: 'app-modification-campaign-discounts',
  templateUrl: './modification-campaign-discounts.component.html',
  styleUrls: ['./modification-campaign-discounts.component.scss']
})
export class ModificationCampaignDiscountsComponent implements OnInit {
  idCampaignDiscount: any;
  campaignDiscounts = {
    name: '',
    limite: 0,
    count_actual_app: 0,
    count_actual_site: 0,
    start_date: new Date(),
    end_date: new Date(),
    message: '',
    requirement: '',
    requirement_operator: '',
    active: false,
    brand_id: null,
    debug: false,
    brand: ''
  };
  brandArray = [];
  constructor( private dataService: DataCampaignDiscountsService, private route: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.idCampaignDiscount = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getCampaignDiscounts();
    this.getAllBrand();
  }
  updateCampaignDiscounts() {
    this.dataService.updateCampaignDiscounts(this.campaignDiscounts).subscribe(result => {
      this.route.navigate(['../gestion-campagne-sms']);
    });
  }
  getAllBrand() {
    this.dataService.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  getCampaignDiscounts() {
    this.dataService.getCampaignDiscountsById(this.idCampaignDiscount).subscribe(result => {
      this.campaignDiscounts = result;
      // @ts-ignore
      this.campaignDiscounts.start_date = moment(this.campaignDiscounts.start_date.date).format('yyyy-MM-DD');
      // @ts-ignore
      this.campaignDiscounts.end_date = moment(this.campaignDiscounts.end_date.date).format('yyyy-MM-DD');
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-campagne-sms']);
  }
  onChangeCheckbox(type) {
    this.campaignDiscounts[type] = !this.campaignDiscounts[type];
  }
}
