<div class="main-content-inner gestion-statut mt-2">
  <h1>Gestion abonnements SMS</h1>
  <div class="header-rules-action d-flex flex-row-reverse w-100 mx-auto">
    <div class="col-12 text-right d-flex justify-content-around align-items-center">
      <h3>Abonnements</h3>
      <mat-form-field style="width: 250px">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
      <div class="btn1">
        <button id="btn-add-concession" class="mr-3 btn btn-dark" (click)="SelectChangeHandlerCreer()">
          <mat-icon>add_circle</mat-icon> Abonnement
        </button>
      </div>
    </div>

  </div>
  <div class="p-4">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID</th>
        <td mat-cell *matCellDef="let element"> <strong>{{element.id}}</strong> </td>
      </ng-container>
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef> Couleur</th>
        <td mat-cell *matCellDef="let element"> <div [ngStyle]="{'color':  element.color}" class="circle-color"><i class="fas fa-tint"></i></div> </td>
      </ng-container>
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef> Label </th>
        <td mat-cell *matCellDef="let element">
         <div *ngIf="element.monthlyCost !== 0">Abonnement à {{element.monthlyCost}} €</div>
         <div *ngIf="element.monthlyCost === 0">Acquisition à {{element.smsCost}} € par SMS</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="monthlyCost">
        <th mat-header-cell *matHeaderCellDef> Coût mensuel </th>
        <td mat-cell *matCellDef="let element"> {{element.monthlyCost}} €</td>
      </ng-container>
      <ng-container matColumnDef="smsCost">
        <th mat-header-cell *matHeaderCellDef> Coût SMS </th>
        <td mat-cell *matCellDef="let element"> {{element.smsCost}} €</td>
      </ng-container>
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef> Modifier </th>
        <td mat-cell *matCellDef="let element">
          <button class="m-2 btn btn-round-40 btn-dark" (click)="SelectChangeHandlerModifier(element.id)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
  </div>
</div>
<app-spinner *ngIf="isLoading"></app-spinner>
