import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataNotificationService extends HandleErrorService {
  private errorMessage: string;
  apiFormation = environment.globalApiUrl + 'api/resources/records/softnrg_notification';

  getNotificationTargets() {
    return this.http.get<any>(environment.globalApiUrl + 'api/softgate-notification/targets', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getNotification() {
    return this.http.get<any>(this.apiFormation + '?order=id,desc', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getNotificationById(id) {
    return this.http.get<any>(this.apiFormation + '/' + id , environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  getNotificationByTargetAndIsActive(target) {
    return this.http.get<any>(this.apiFormation + '?filter=target,eq,' + target + '&filter=is_active,eq,1', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  createNotification(post) {
    const jsonPost = JSON.stringify(post);
    return this.http.post<any>(this.apiFormation, jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  updateNotification(post, id) {
    const jsonPost = JSON.stringify(post);
    return this.http.put<any>(this.apiFormation + '/' + id , jsonPost, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  deleteNotification(id) {
    return this.http.delete<any>(this.apiFormation + '/' + id, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
