<h2>Création Paramètre</h2>
<div class="wrapper">
    <form>
      <div class="form-group col-md-3 mt-4">
        <label for="name">Nom</label>
        <input [(ngModel)]="parameter.name" class="form-control" id="name" name="name" [formControl]="nameFormControl">
      </div>
      <div class="form-group col-md-3">
        <label for="description">Description</label>
        <textarea [(ngModel)]="parameter.description" class="form-control" id="description" name="description" ></textarea>
      </div>
      <div class="form-group col-md-3" >
        <label for="tag">Tag</label>
        <select [(ngModel)]="parameter.tagId" class="form-control" name="tag" id="tag">
          <option *ngFor="let tag of tagArray" [value]="tag.id">
            {{tag.nom}}
          </option>
        </select>
      </div>
      <div class="btn-group mt-4 mb-4">
        <div>
          <button (click)="selectChangeHandlerCreer()" class="btn btn-dark btn100px p-2 mr-2" [disabled]="nameFormControl.invalid">Créer</button>
        </div>

        <div>
          <button (click)="selectChangeHandlerAnnuler()" class="btn btn-light border-dark btn100px p-2">Annuler</button>
        </div>
      </div>
    </form>
</div>
