import { Component, OnInit } from '@angular/core';
import { DataParametreService} from '../utils/data-services/dataParametre.service';
import { DataInteractionService } from '../utils/data-services/dataInteraction.service';
import { Router } from '@angular/router';
import {DataSmsService} from "../utils/data-services/data-sms.service";

@Component({
  selector: 'app-gestion-parametre',
  templateUrl: './gestion-parametre.component.html',
  styleUrls: ['./gestion-parametre.component.css']
})
export class GestionParametreComponent implements OnInit {

  constructor(private dataParametreService: DataParametreService, private tagService: DataSmsService, private dataInteractionService: DataInteractionService , private route: Router) { }

  variable: any;
  /* Variable list for the Textarea components */
  parametreID: any;
  nom: any;
  texte: any;
  tag: any;
  type: any;
  BDD: any;
  tableBDD: any;
  champBDD: any;
  /* End */
  tagArray = [];

  ngOnInit() {
    this.dataParametreService.getParametre().subscribe(data => {
      this.variable = data.records;
    });
    this.getTag();
  }
  getTag() {
    this.tagService.getTag().subscribe(result => {
      this.tagArray = result;
    });
  }
  getTagNameFromId(id) {
    const tag = this.tagArray.find(x => x.id === id);
    return tag ? tag.nom : '-';
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-parametre']);
  }

  /***
   * Send selected parameter variables to ModificationParametre component
   * Parameter: parametre_id, Type: Int
   * Parameter: nom, Type: String
   * Parameter: texte, Type: String
   * Parameter: tag, Type: String
   * Parameter: type, Type: String
   * Parameter: BDD postal, Type: String
   * Parameter: tableBDD, Type: String
   * Parameter: champBDD, Type: String
   ***/
  SelectChangeHandlerModifier(arg: any, arg1: any, arg2: any, arg3: any, arg4: any, arg5: any, arg6: any, arg7: any) {
    localStorage.setItem('softgateparametreID', arg7);
    this.parametreID = arg7;
    localStorage.setItem('softgateParametreNom', arg);
    this.nom = arg;
    localStorage.setItem('softgateParametreDescription', arg1);
    this.texte = arg1;
    localStorage.setItem('softgateParametreTag', arg2);
    this.tag = arg2;
    localStorage.setItem('softgateParametreType', arg3);
    this.type = arg3;
    localStorage.setItem('softgateParametreBDD', arg4);
    this.BDD = arg4;
    localStorage.setItem('softgateParametreTableBDD', arg5);
    this.tableBDD = arg5;
    localStorage.setItem('softgateParametreChampBDD', arg6);
    this.champBDD = arg6;
    this.dataInteractionService.sendNomParametre(this.nom);
    this.dataInteractionService.sendDescriptionParametre(this.texte);
    this.dataInteractionService.sendTagParametre(this.tag);
    this.dataInteractionService.sendTypeParametre(this.type);
    this.dataInteractionService.sendBDDParametre(this.BDD);
    this.dataInteractionService.sendTableBDDParametre(this.tableBDD);
    this.dataInteractionService.sendChampBDDParametre(this.champBDD);
    this.route.navigate(['../modification-parametre']);
  }

}
