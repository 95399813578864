import { Component, OnInit } from '@angular/core';
import {DataFormationService} from '../utils/data-services/dataFormation.service';
import {MatTableDataSource} from '@angular/material';
import swal from "sweetalert2";
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-gestion-formation',
  templateUrl: './gestion-formation.component.html',
  styleUrls: ['./gestion-formation.component.scss']
})
export class GestionFormationComponent implements OnInit {
  displayedColumns: string[] = [
    'type',
    'title',
    'isActive',
    'edit',
    'delete'
  ];
  isDataSourceExists = false;
  dataSource = new MatTableDataSource<any>();
  urlSoftgate = environment.softgateFrontUrl;
  tokenBooklet = environment.tokenBooklet;

  constructor(
    private formationService: DataFormationService,
    private route: Router
  ) { }

  ngOnInit() {
    this.formationService.getFormation().subscribe(formations => {
      this.dataSource.data = formations;
      this.isDataSourceExists = formations.length !== 0;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
  editFormation(id) {
    this.route.navigate(['/modification-formation/' + id]);
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.formationService.deleteFormation(id).subscribe(data => {
            swal.fire('La formation a bien été supprimée!', '', 'success').then(res => {
              window.location.reload();
            });
        }, error => {
          swal.fire({
            title: 'Erreur',
            text: 'Un problème est survenu lors de la suppression.',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  copyToClipboard(url): void {
    navigator.clipboard.writeText(url);
  }
}
