import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataRgpdDemandsService extends HandleErrorService {
  CrudSoftgate = environment.globalApiUrl + 'api/resources/records/';
  private errorMessage: string;

  requestGetRgpdDemands(): Observable<any> {
    return this.http.get<any>(this.CrudSoftgate + 'support_demands', environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestTreatRgpdDemands(body, id): Observable<any> {
    return this.http.put<any>(this.CrudSoftgate + 'support_demands/' + id, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
}
