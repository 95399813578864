<div class="list-group">
  <h3 class="titre">S'inscrire</h3>
  <div class="col-md-6 offset-md-4 mt-6">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" style="margin-top: 50px">
      <div class="form-group">
        <label for="registerUsername">Utilisateur</label>
        <input type="text" class="form-control" id="registerUsername" placeholder="Insérer le nom d'utilisateur" name="User" ngModel required #User="ngModel">
      </div>

      <div class="form-group">
        <label for="registerMail">Mail</label>
        <input type="email" class="form-control" id="registerMail" placeholder="Insérer le Mail" name="Mail" ngModel required #Mail="ngModel">
      </div>

      <div class="form-group">
        <label for="registerPassword">Mot de passe</label>
        <input type="password" class="form-control" id="registerPassword" placeholder="Insérer le mot de passe" name="MDP" ngModel required #MDP="ngModel">
      </div>

      <div class="form-group">
        <label for="registerRepeatePassword">Confirmer le mot de passe</label>
        <input type="password" class="form-control" id="registerRepeatePassword" placeholder="Retaper le mot de passe" name="RMDP" ngModel required #RMDP="ngModel">
      </div>


      <div class="btn-group row">
        <button type="submit" [disabled]="f.invalid" class="btn btn-success p-2 mr-2">S'inscrire</button>
        <button type="button" (click)="Annuler()" class="btn btn-danger p-2">Annuler</button>
      </div>
    </form>
  </div>
</div>

