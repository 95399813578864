import { Component, OnInit } from '@angular/core';
import {DataSnapshotScenarioService} from "../../utils/data-services/data-snapshot-scenario.service";
import {DataCampaignDiscountsService} from "../../utils/data-services/data-campaign-discounts.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-update-snapshot-scenario',
  templateUrl: './update-snapshot-scenario.component.html',
  styleUrls: ['./update-snapshot-scenario.component.scss']
})
export class UpdateSnapshotScenarioComponent implements OnInit {
  idSnapshotScenario: any;
  snapshotScenario = {
    id: null,
    brand_name: null,
    brand_id: null,
    label: null,
    operations: null,
    is_courtesy_car: false,
    is_wating: false,
    is_active: false
  };
  brandArray = [];
  constructor( private dataService: DataSnapshotScenarioService, private dataCampaignDiscountsSerive: DataCampaignDiscountsService, private route: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.idSnapshotScenario = Number(this.activeRoute.snapshot.paramMap.get('idSnapshotScenario'));
    this.getSnapshotScenario();
    this.getAllBrand();
  }
  updateSnapshotScenario() {
    this.snapshotScenario.operations = this.snapshotScenario.operations.split(',');
    this.dataService.updateSnapshotScenario(this.snapshotScenario).subscribe(result => {
      this.route.navigate(['../gestion-snapshot-scenario']);
    });
  }
  getAllBrand() {
    this.dataCampaignDiscountsSerive.getAllBrand().subscribe(result => {
      this.brandArray = result;
    });
  }
  getSnapshotScenario() {
    this.dataService.getSnapshotScenarioById(this.idSnapshotScenario).subscribe(result => {
      this.snapshotScenario = result;
      this.snapshotScenario.operations = this.snapshotScenario.operations.join(',');
    });
  }
  onCancelClik() {
    this.route.navigate(['../gestion-snapshot-scenario']);
  }
  onChangeCheckbox(type) {
    this.snapshotScenario[type] = !this.snapshotScenario[type];
  }

}
