import { Component, OnInit } from '@angular/core';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-groupe',
  templateUrl: './gestion-groupe.component.html',
  styleUrls: ['./gestion-groupe.component.scss']
})
export class GestionGroupeComponent implements OnInit {
  groups: MatTableDataSource<any>;
  groupForm: FormGroup;
  groupName: FormControl;
  isEditing = false;
  editedGroupId: number;
  sort = null;

  constructor(
    private dataService: DataCreationConcessionService,
    private formBuilder: FormBuilder
  ) {
    this.groups = new MatTableDataSource<any>();
    this.groupName = new FormControl(null);
    this.groupForm = this.formBuilder.group({
      groupName: null
    });
  }

  ngOnInit() {
    this.getGroups();
  }
  getGroups() {
    this.dataService.getGroups().subscribe(groups => {
      this.groups.data = groups.records;
    });
  }
  onSubmit() {
    if (!this.isEditing) {
      this.dataService.createGroup({nom: this.groupName.value}).subscribe(res => {
        this.dataService.sauronDispatchGroup(res).subscribe();
        swal.fire({
          title: 'Success!',
          text: 'Le groupe ' + this.groupName.value + ' a bien été créé',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getGroups();
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création du groupe',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    } else {
      this.dataService.updateGroup({nom: this.groupName.value}, this.editedGroupId).subscribe(res => {
        this.dataService.sauronDispatchGroup(res).subscribe();
        swal.fire({
          title: 'Success!',
          text: 'Le groupe ' + this.groupName.value + ' a bien été modifié',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        this.getGroups();
        document.getElementById('group-create-update-title').innerText = 'Ajouter un groupe';
        document.getElementById('group-create-update-btn').innerText = 'Ajouter le groupe';
        this.groupName = new FormControl(null);
        this.isEditing = false;
        this.editedGroupId = null;
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la modification du groupe',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onEdit(id, groupName) {
    this.isEditing = true;
    this.editedGroupId = id;
    this.groupName = new FormControl(groupName);
    scroll(0, 0);
    document.getElementById('group-create-update-btn').innerText = 'Modifier le groupe';
    document.getElementById('group-create-update-title').innerText = 'Modifier un groupe';
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataService.deleteGroup(id).subscribe(res => {
          swal.fire({
            title: 'Success!',
            text: 'Le groupe ' + this.groupName.value + ' a bien été supprimé',
            type: 'success',
            confirmButtonText: 'Ok'
          });
          this.getGroups();
        }, error => {
          swal.fire({
            title: 'Erreur!',
            text: 'Un problème est survenu lors de la suppression du groupe',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  onCancel() {
    document.getElementById('group-create-update-title').innerText = 'Ajouter un groupe';
    document.getElementById('group-create-update-btn').innerText = 'Ajouter le groupe';
    this.groupName = new FormControl(null);
    this.isEditing = false;
    this.editedGroupId = null;
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.groups.filteredData = this.groups.filteredData.sort((a, b) => {
        return a.nom < b.nom ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.groups.filteredData = this.groups.filteredData.sort((a, b) => {
        return a.nom > b.nom ? 1 : -1;
      });
    }
  }
  sortId() {
    if (this.sort == 'idAcs') {
      this.sort = 'idDesc';
      this.groups.filteredData = this.groups.filteredData.sort((a, b) => {
        return a.groupe_id < b.groupe_id ? 1 : -1;
      });
    } else {
      this.sort = 'idAcs';
      this.groups.filteredData = this.groups.filteredData.sort((a, b) => {
        return a.groupe_id > b.groupe_id ? 1 : -1;
      });
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.groups.filter = filterValue.trim();
  }
}
