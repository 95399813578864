<h2>Création d'une reception autonome</h2>
<div class="wrapper">
  <form>
    <div class="form-row mt-3 ml-1" style="width: 445px">
      <label for="dealership">Concession</label>
      <select [(ngModel)]="rentDealershipAgency.dealership_id" class="form-control" name="dealership" id="dealership">
        <option *ngFor="let concession of concessionArray" [value]="concession.concession_id">
          {{concession.nom_concession}}
        </option>
      </select>
    </div>

    <div class="form-row mt-3 ml-1" style="width: 445px">
      <label for="brand">Marque</label>
      <select [(ngModel)]="rentDealershipAgency.brand_id" class="form-control" name="brand" id="brand">
        <option *ngFor="let brand of brandArray" [value]="brand.id">
          {{brand.name}}
        </option>
      </select>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="reception_not_before_delay">Identifiant agence :</label>
        <input [(ngModel)]="rentDealershipAgency.ucar_agency_id" class="form-control" id="reception_not_before_delay" name="reception_not_before_delay" >
      </div>
      <div class="form-group col-md-3">
        <label for="restitution_expires_at_delay">Typologie client :</label>
        <input [(ngModel)]="rentDealershipAgency.typologie_client" class="form-control" id="restitution_expires_at_delay" name="restitution_expires_at_delay" >
      </div>
    </div>

    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="createRent()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>

      <div>
        <button (click)="onCancelClik()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
