<h2>Création Concession</h2>
<div class="wrapper">
    <form>
      <div class="form-row mt-4">
<!--        <div class="form-group col-md-1">-->
<!--          <label for="id">ID :</label>-->
<!--          <textarea [(ngModel)]="id" class="form-control" id="id" name="id" rows="1" no-auto-shrink></textarea>-->
<!--        </div>-->
        <div class="form-group col-md-5">
          <label for="nom-concession">Nom concession:</label>
          <input [(ngModel)]="concession.nom_concession" class="form-control" id="nom-concession" name="nom-concession" required="required">
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="form-group col-md-4">
          <label for="raisonSocial">Raison sociale :</label>
          <input [(ngModel)]="concession.raison_sociale" class="form-control" id="raisonSocial" name="raisonSocial" required="required">
        </div>
        <div class="form-group col-md-2 offset-md-1">
          <label for="codeSite">Code Site :</label>
          <input [(ngModel)]="concession.code_site" class="form-control" id="codeSite" name="codeSite" required="required">
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="form-group col-md-2">
          <label for="raisonSocial">Code EJ :</label>
          <input [(ngModel)]="concession.code_ej" class="form-control" id="codeEj" name="codeEj" required="required">
        </div>
        <div class="form-group col-md-2">
          <label for="raisonSocial">Outlet :</label>
          <input [(ngModel)]="concession.outlet" class="form-control" id="outlet" name="outlet" required="required">
        </div>
        <div class="form-group col-md-2 offset-md-1">
          <label for="raisonSocial">District :</label>
          <input [(ngModel)]="concession.district" class="form-control" id="district" name="district">
        </div>
      </div>

      <div class="form-row mt-3">
        <div class="form-group col-md-4">
          <label for="group">Groupe :</label>
          <ng-multiselect-dropdown id="group" name="group"
                                   [placeholder]="'Select'"
                                   [data]="dropdownGroupList"
                                   [(ngModel)]="groupSelected"
                                   [settings]="dropdownGroupSettings"
                                   (onSelect)="onGroupSelect($event)"
                                   (onDeSelect)="onGroupDeselect($event)"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-md-2 offset-md-1">
          <button class="btn btn-dark mt-4 p-2 mr-2" (click)="onClickCreateGroup()">Ajouter Groupe</button>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="form-group col-md-4">
          <label for="rue">Adresse :</label>
          <input [(ngModel)]="concession.rue" class="form-control" id="rue" name="rue" required="required">
        </div>
        <div class="form-group col-md-2 offset-md-1">
          <label for="codeP">CP :</label>
          <input [(ngModel)]="concession.code_postal" class="form-control" id="codeP" name="codeP" required="required">
        </div>
      </div>

      <div class="form-row mt-3">
        <div class="form-group col-md-3">
          <label for="ville">Ville :</label>
          <input [(ngModel)]="concession.ville" class="form-control" id="ville" name="ville" required="required">
        </div>
        <div class="form-group col-md-3 offset-md-1">
          <label for="villeP">Ville Principale :</label>
          <input [(ngModel)]="concession.ville_principale" class="form-control" id="villeP" name="villeP" required="required">
        </div>
      </div>

      <div class="form-row mt-3">
        <div class="form-group col-md-2">
          <label for="pays">Pays :</label>
          <input [(ngModel)]="concession.pays" class="form-control" id="pays" name="pays" required="required">
        </div>
        <div class="form-group col-md-2 offset-md-3">
          <label for="tel">Tel :</label>
          <input [(ngModel)]="concession.tel" class="form-control" id="tel" name="tel" required="required">
        </div>
      </div>

      <div class="form-row mt-3">
        <div class="form-group col-md-3">
          <label for="latitude">Latitude:</label>
          <input [(ngModel)]="concession.latitude" class="form-control" id="latitude" name="latitude">
        </div>
        <div class="form-group col-md-3 offset-md-1">
          <label for="longitude">Longitude:</label>
          <input [(ngModel)]="concession.longitude" class="form-control" id="longitude" name="longitude">
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="form-group col-md-4">
          <label for="group">Mapping Pngo :</label>
          <ng-multiselect-dropdown id="marque" name="marque" [ngModelOptions]="{standalone: true}"
                                   [placeholder]="'Select'"
                                   [data]="dropdownBrandList"
                                   [(ngModel)]="brand"
                                   [settings]="dropdownBrandSettings"
                                   (onSelect)="onBrandSelect($event)"
                                   (onDeSelect)="onBrandDeselect($event)"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="form-group col-md-2 offset-md-1">
          <label for="slug">Slug :</label>
          <input [(ngModel)]="concession.slug" class="form-control" id="slug" name="slug" required="required">
        </div>
      </div>
<!--      <div class="form-row mt-3">-->
<!--        <div class="ml-2 mt-3 col-6 form-group">-->
<!--          <input type="checkbox" [(ngModel)]="concession.has_kpi_kidi" class="form-control"  id="has_kpi_kidi" name="has_kpi_kidi" class="checkbox-med ml-3">-->
<!--          <label for="has_kpi_kidi" class="ml-3">KPIs Kidi visibles dans le tableau général</label>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="ml-2 col-6  form-group">&ndash;&gt;-->
<!--&lt;!&ndash;          <input type="checkbox" [(ngModel)]="concession.has_soft_screen" class="form-control"  id="has_soft_screen" name="has_soft_screen" class="checkbox-med ml-3">&ndash;&gt;-->
<!--&lt;!&ndash;          <label for="has_soft_screen" class="ml-3">Soft-Screen actif</label>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="ml-2 col-6  form-group">-->
<!--          <input type="checkbox" [(ngModel)]="concession.has_auto_treatment" class="form-control"  id="has_auto_treatment" name="has_auto_treatment" class="checkbox-med ml-3">-->
<!--          <label for="has_auto_treatment" class="ml-3">Traitement auto des notifications actif</label>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group col-md-8 d-flex" *ngIf="concession.has_auto_treatment">-->
<!--        <label for="nb_days_min_auto_treatment">Nombre de jour minimum avant traitement auto :</label>-->
<!--        <input type="number" [(ngModel)]="concession.nb_days_min_auto_treatment" class="form-control col-md-2 ml-3" id="nb_days_min_auto_treatment" name="nb_days_min_auto_treatment">-->
<!--      </div>-->
      <div class="form-row mt-3">
        <small class="text-danger ml-4" *ngIf="formError">Veuillez remplir tous les champs</small>
      </div>
      <div class="btn-group mt-3 mb-5">
        <div>
          <button (click)="SelectChangeHandlerCreer()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
        </div>

        <div>
          <button (click)="SelectChangeHandlerAnnuler()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
        </div>
      </div>
    </form>
</div>
